import {
  ComponentsFilterValue,
  DateFilterValue,
  GenericFilterValue,
  RangeFilterValue,
  TypedFilterValue
} from "@core/model/filter-option.interface";

export interface Filter {
  [key: string]: any;
}

export interface ScopeFilter extends Filter {
  name?: string;
  newCreatedSince?: string;
  approvedSince?: string;
  toReviewAndSignSince?: string;
  toReviewAndSignSelected: boolean;
  approvedSelected: boolean;
  newScopeCreatedSelected: boolean;
  scopeStatuses?: any[];
  folderStatuses?: any[];
  secondParties?: GenericFilterValue[];
  ratecards?: GenericFilterValue[];
  brands?: GenericFilterValue[];
  currencies?: string[];
  creators?: GenericFilterValue[];
  createdBy?: GenericFilterValue[];
  companyAccounts?: GenericFilterValue[];
  isTemplate: boolean;
  inFolder: string;
  date?: DateFilterValue;
  budget?: RangeFilterValue;
  value?: RangeFilterValue;
  deliverables?: TypedFilterValue[];
  components?: ComponentsFilterValue;
}

export const ScopeFilterDefaults: ScopeFilter = {
  name: undefined,
  newCreatedSince: undefined,
  approvedSince: undefined,
  toReviewAndSignSince: undefined,
  toReviewAndSignSelected: false,
  approvedSelected: false,
  newScopeCreatedSelected: false,
  scopeStatuses: [],
  folderStatuses: [],
  secondParties: [],
  ratecards: [],
  brands: [],
  currencies: [],
  creators: [],
  createdBy: [],
  companyAccounts: [],
  isTemplate: false,
  inFolder: 'All',
  date: new DateFilterValue(),
  budget: new RangeFilterValue(),
  value: new RangeFilterValue(),
  deliverables: [],
  components: new ComponentsFilterValue()
}
