import { Pipe, PipeTransform } from '@angular/core'
import { CoreState } from '@core/store'
import { Store } from '@ngrx/store'

import { LanguageService } from '@core/service/language.service'

@Pipe({ name: 'lang', pure: true })
export class LangPipe implements PipeTransform {
    constructor(private store: Store<CoreState>, private langService: LanguageService) {}

    cleanKey(key: string | undefined) {
        if (key == null) {
            return ''
        }
        if (key[0] == "'") {
            key = key.substring(1, key.length)
        }
        if (key[key.length] == "'") {
            key = key.substring(1, key.length)
        }
        return key.toLowerCase().trim()
    }

    getValue(keys: string) {
        const key = keys.split('|')
        return this.langService.get(this.cleanKey(key[0]).concat('|', key[1]))
    }

    transform(key: string) {
      return this.getValue(key);
    }
}
