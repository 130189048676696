import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core'
import { UserComment } from '@core/model/comment.model'
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms'
import { CommentingService } from '@app/features/scope-overview/service/commenting.service'
import { instanceToInstance } from 'class-transformer'
import { MatMenuTrigger } from '@angular/material/menu'
import { OverlayContainer } from '@angular/cdk/overlay'
import { fromEvent } from 'rxjs'
import { Store } from '@ngrx/store'
import { AuthService } from '@core/service/auth.service'

@Component({
  selector: 'commentable',
  templateUrl: './commentable.component.html',
  styleUrls: ['./commentable.component.scss'],
})
export class CommentableComponent implements OnChanges, AfterViewInit {
  @Input('c-root-entity') commentableRootEntity: any;
  @Input('c-entity') set commentableEntity(value: any) {
    if (value) {
      this.entity = instanceToInstance(value)
    }
  }
  @Input('c-action-text') commentableActionText: any;
  @Input('c-action-privilege') commentableActionPrivilege: any;
  @Input('c-if') commentableIf: boolean;
  @Input('c-action') commentableAction: any;
  @Input('c-rollup-comments-count') commentableRollupCommentsCount: any;
  @Input('c-use-icon') commentableUseIcon: any;
  @Input('c-force-input') commentableForceInput: any;
  @Input('c-set-commentable') commentableSetCommentable: any;
  @Input('c-key') commentableKey!: string;
  @Input('c-type') commentableType!: string;
  @Output() onSetCommentable: EventEmitter<any>
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger

  entity: any
  commentable: {
    showComment: boolean,
    comments: UserComment[],
    hasUnreadComment: boolean,
    key: string,
    newComment?: string
  }
  commentForm: UntypedFormGroup
  disableAnimation: boolean = false

  constructor(private commentingService: CommentingService, private overlayContainer: OverlayContainer, private store: Store, private authService: AuthService) {
    this.commentForm = new FormGroup([])
    this.commentForm.setControl('comment', new FormControl(''))
    this.onSetCommentable = new EventEmitter<any>()
  }

  ngOnInit() {
    fromEvent(this.overlayContainer.getContainerElement(), 'click').subscribe(() => {
      if (this.disableAnimation) {
        this.disableAnimation = false
        this.commentingService.openComment = null
      }
    });
  }

  ngOnChanges() {
    if (this.entity) {
      this.disableAnimation = this.commentingService.openComment === this.commentableType + this.entity.id + this.commentableKey

      if (!this.entity.commentable) {
        this.entity.commentable = {};
        this.commentable = this.entity.commentable[this.commentableKey] = {
          showComment: false,
          comments: this.entity.comments ? this.filterComments(this.entity.comments, this.commentableKey) : [],
          hasUnreadComment: false,
          key: this.commentableKey,
          newComment: ''
        };
        this.onSetCommentable.emit(this.commentable)
      }

      if (!this.entity.comments || this.entity.comments.length == 0) {
        this.commentable.comments = [];
        this.commentable.hasUnreadComment = false;
      } else {
        var comments = this.filterComments(this.entity?.comments, this.commentableKey);
        if (comments.length != this.commentable.comments.length) {
          this.commentable.comments = comments;
          if (!this.commentable.showComment) {
            this.commentable.hasUnreadComment = true;
          }
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.disableAnimation) {
      this.menuTrigger?.openMenu()
    }
  }

  filterComments(comments: UserComment[], key: string) {
    return comments.filter(function (comment) {
      return comment.sourceAttribute == key;
    })
  }

  callAction() {
    this.commentableAction()
    this.toggleClosed();
  };

  toggleOpened() {
    this.commentable.showComment = true;
    this.commentable.hasUnreadComment = false;
    this.commentingService.openComment = this.commentableType + this.entity.id + this.commentableKey
  };

  toggleClosed() {
    this.commentable.showComment = false;
  };

  useComment() {
    if (this.commentableIf == null && this.commentingService.globalUseCommentable == null) {
      return true;
    } else if (this.commentableIf != null) {
      return this.commentableIf
    }
    return this.commentingService.globalUseCommentable(this.commentable);
  };

  checkPrivilege(privilege) {
    return this.authService.loggedInUser.hasPrivilege(privilege);
  };

  useCommentInput() {
    return this.commentableForceInput || this.commentingService.globalShowInputCommentable();
  };

  send() {
    if (this.commentForm.invalid) {
      return;
    }
    var commentText = this.commentable.newComment;
    if (!commentText || commentText.length <= 0) {
      this.commentForm.setErrors({ required: true })
      this.commentForm.get('comment').setErrors({ required: true })
      return;
    }
    var comment: UserComment = {
      sourceAttribute: this.commentableKey,
      text: commentText,
      createdBy: this.authService.loggedInUser,
      createdTs: new Date(),
      originType: 'USER_COMMENT'
    }

    this.commentingService.globalSaveCommentable(this.entity, this.commentableRootEntity, comment)
    this.commentForm.markAsPristine()
    this.commentForm.get('comment').setValue('')
    this.commentable.newComment = null;
  };

  onInput(event: Event) {
    this.commentable.newComment = (event.target as HTMLInputElement).value;
  }
}
