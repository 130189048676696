import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoleLevel } from '@app/core/model/enums/user-role-level.enum';
import { Privilege } from "@app/core/model/enums/privilege.enum";
import { User } from '@core/model/user.model';

@Injectable({ providedIn: 'root' })
export class TabUtil {
  constructor(private router: Router, private route: ActivatedRoute, private location: Location) {}

  createTabs(tabs: TabDefinition[], user: User | undefined): Tabs {
    return new Tabs(this.route, this.router, this.location, user, tabs);
  }
}

export class Tabs {
  private tabMap: Map<string, Tab> = new Map();
  private tabs: Tab[] = [];
  selectedTab?: Tab = undefined;

  constructor(
    route: ActivatedRoute,
    router: Router,
    location: Location,
    public user: User | undefined,
    tabs: TabDefinition[]
  ) {
    tabs.forEach((t, i) => {
      t.key = t.key ?? t.name;
      const tab = new Tab(this, router, location, user as User, t);
      this.tabMap.set(t.key, tab);
      this.tabs.push(tab);
      if (i == 0 || t.default) {
        this.selectedTab = tab;
      }
    });

    //Default tab
    const paramTab = route.snapshot.queryParamMap.get('tab');
    if (paramTab) {
      var found = [...this.tabs.values()].filter(function (tab) {
        return tab.definition.tabParam ? paramTab.indexOf(tab.definition.tabParam) >= 0 : false;
      });
      if (found.length > 0) {
        this.selectedTab = found[0];
      }
    }
  }

  refreshCurrentTab() {
    this.selectedTab?.refresh();
  }

  getTabs(): Tab[] {
    return this.tabs.filter(function (tab) {
      return tab.isVisible();
    });
  }
  getSelectedIndex(): number {
    if (this.selectedTab) {
      return this.tabs.indexOf(this.selectedTab);
    }
    return -1;
  }

  getSelectedTab(): Tab | undefined {
    return this.selectedTab;
  }
}

export class TabDefinition {
  default?: boolean = false;
  key?: string = '';
  name: string = '';
  href?: string;
  opts?: TabOpts;
  onSwitch?: any;
  tabParam?: string;
  privileges?: Privilege[];
  role?: UserRoleLevel;

  constructor() {}
}

export interface TabOpts {
  location: TabOptsLocation[];
}
export interface TabOptsLocation {
  exactMatch?: string[] | string;
  like?: string[] | string;
}
@Injectable({ providedIn: 'root' })
export class Tab {
  constructor(
    private ref: Tabs,
    private router: Router,
    private location: Location,
    private user: User,
    public definition: TabDefinition
  ) {}

  href(): string | undefined {
    return this.definition.href;
  }

  name(): string {
    return this.definition.name;
  }

  isSelected(): boolean {
    if (this.definition.href) {
      return this.location.path().startsWith(this.definition.href);
    }
    return this.ref.selectedTab === this;
  }

  switch(additionalCtx = {}) {
    this.ref.selectedTab = this;
    this.refresh(additionalCtx);
  }

  refresh(additionalCtx: object = {}) {
    if (this.definition.href) {
      this.router.navigateByUrl(this.definition.href);
    } else if (this.definition.onSwitch) {
      this.definition.onSwitch(additionalCtx);
    } else if (this.definition.tabParam) {
      this.router.navigate(['.'], { queryParams: { tab: this.definition.tabParam } });
    }
  }

  isVisible(): boolean {
    var privileges = this.definition.privileges;
    var role = this.definition.role;
    var isVisible = true;

    if (privileges || role) {
      const user = this.user;
      if (user != null) {
        if (isVisible && privileges) {
          isVisible = privileges.some(function (privilege) {
            return user?.hasPrivilege(privilege) || user?.hasGroupPrivilege(privilege);
          });
        }
        if (isVisible && role) {
          isVisible = user.hasAccessRole(role);
        }
      }
    }

    return isVisible;
  }
}
