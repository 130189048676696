<ng-container [ngSwitch]="currentState">
  <div class="scope-tooltip" *ngSwitchCase="'DRAFT'">
    <span class='inner-tooltip'
      *ngIf="stateChecks.isDraft && !isReadyToSubmit && stateChecks.draftState.currentMember?.vote !== 'ACCEPT'">
      <button disabled matTooltip="Cannot submit {{ 'scope|l' | lang }}. The value of the {{ 'scope|l' | lang }} must be more than zero and not n/a.">
        Submit
      </button>
    </span>
    <span class='inner-tooltip'
      *ngIf="stateChecks.isDraft && !stateChecks.valuesRestrictedActionAllowed && isReadyToSubmit &&
        stateChecks.draftState.currentMember?.vote !== 'ACCEPT' &&
        (stateChecks.draftState.hasReviewers || stateChecks.draftState.hasApprovers)
      ">
      <button disabled matTooltip="Cannot submit {{ 'scope|l' | lang }} due to limitations in your permission settings (scope budgets). Contact your admin to increase your budget">
        Submit
      </button>
    </span>

    <span class='inner-tooltip'
      *ngIf="stateChecks.isDraft && isReadyToSubmit && stateChecks.draftState.currentMember?.vote !== 'ACCEPT' &&
        !(stateChecks.draftState.hasReviewers || stateChecks.draftState.hasApprovers) &&
        !stateChecks.valuesRestrictedActionAllowed
      ">
      <button disabled matTooltip="Cannot submit {{ 'scope|l' | lang }}. Please add at least one reviewer or approver.">
        Submit
      </button>
    </span>

    <span class='inner-tooltip lg' *ngIf="stateChecks.isDraft && stateChecks.draftState.currentMember?.vote === 'ACCEPT'">
      <span class="text"
        >You have submitted this {{ 'scope|l' | lang }}. Waiting on the all other collaborators to complete their
        work.</span
      >
      <button (click)="withdrawSubmission()" class="mt-2">Withdraw my submission</button>
    </span>
    <span class='inner-tooltip'
      *ngIf="isReadyToSubmit && stateChecks.draftState.currentMember?.vote !== 'ACCEPT' &&
        (stateChecks.draftState.hasReviewers || stateChecks.draftState.hasApprovers) &&
        stateChecks.valuesRestrictedActionAllowed">
      <button (click)="submitDraft()" *ngIf="stateChecks.draftState.currentMember?.vote === null">Submit</button>
    </span>
  </div>

  <!-- SUBMITTED -->
  <div class="scope-tooltip" *ngSwitchCase="'SUBMITTED'">
    <span class='inner-tooltip'
          *ngIf="stateChecks.isInReview &&
          (stateChecks.inReviewState.isAlreadyReviewed || (stateChecks.isCurrentVersion &&
          stateChecks.inReviewState.currentMember && !stateChecks.inReviewState.isAlreadyReviewed)) && !stateChecks.valuesRestrictedActionAllowed">
      <button disabled matTooltip="Cannot review {{ 'scope|l' | lang }} due to limitations in your permission settings (scope budgets). Contact your admin to increase your budget">
        Complete review
      </button>
    </span>
    <span class='inner-tooltip'
          [ngClass]="{ 'lg': stateChecks.inReviewState.isAlreadyReviewed }"
          *ngIf="stateChecks.isInReview &&
          (stateChecks.inReviewState.isAlreadyReviewed || (stateChecks.isCurrentVersion &&
          stateChecks.inReviewState.currentMember && !stateChecks.inReviewState.isAlreadyReviewed)) && stateChecks.valuesRestrictedActionAllowed">
      <span *ngIf="stateChecks.inReviewState.isAlreadyReviewed">
        You have reviewed this {{ 'scope' | langf }}. It is being reviewed currently by other Team {{'team_member.p|l' | lang }}.
      </span>

      <button
        *ngIf="
          stateChecks.isCurrentVersion &&
          stateChecks.inReviewState.currentMember &&
          !stateChecks.inReviewState.isAlreadyReviewed && stateChecks.valuesRestrictedActionAllowed
        "
        (click)="completeReviewSubmission()">
        Complete review
      </button>
    </span>
  </div>

  <!-- REVIEWED (APPROVED) -->
  <div class="scope-tooltip" *ngSwitchCase="'REVIEWED'">
    <span class='inner-tooltip lg' *ngIf="stateChecks.isStateReviewed && stateChecks.reviewedState.isAlreadyAgreed && stateChecks.valuesRestrictedActionAllowed">
      <span class="text"
        >You have accepted this {{ 'scope' | langf }}. It is being reviewed currently by other Team
        {{'team_member.p|l' | lang }}.</span
      >
    </span>
    <span class='inner-tooltip'
          *ngIf="
        stateChecks.isCurrentVersion &&
        stateChecks.isStateReviewed &&
        stateChecks.reviewedState.currentMember &&
        stateChecks.reviewedState.currentMember.vote !== 'ACCEPT'
        && !stateChecks.valuesRestrictedActionAllowed">
      <button disabled matTooltip="Cannot approve {{ 'scope|l' | lang }} due to limitations in your permission settings (scope budgets). Contact your admin to increase your budget">
         Agree | Decline
      </button>
    </span>

    <span class='inner-tooltip'
      *ngIf="
        stateChecks.isCurrentVersion &&
        stateChecks.isStateReviewed &&
        stateChecks.reviewedState.currentMember &&
        stateChecks.reviewedState.currentMember.vote !== 'ACCEPT' && stateChecks.valuesRestrictedActionAllowed
      ">
      <button (click)="agreeToScope()">Agree</button> |
      <button (click)="declineScope()">Decline</button>
    </span>
  </div>

  <div class="scope-tooltip" *ngSwitchCase="'AGENCY_APPROVED'">
    <span class='inner-tooltip'
      *ngIf="
        stateChecks.isCurrentVersion &&
        stateChecks.agencyApprovedState.currentMember && stateChecks.valuesRestrictedActionAllowed
      ">
      <span [has-setting]="'SCOPE__APPROVAL__STATUS_CLIENT'">
        <button (click)="acceptScope()">Accept</button> |
        <button (click)="rejectScope()">Reject</button>
      </span>
      <span *ngIf="!currentUser.company.hasApplicationSetting('SCOPE__APPROVAL__STATUS_CLIENT')">
        <button (click)="closeScope()">Close</button>
      </span>
    </span>
    <span class='inner-tooltip'
          *ngIf="
        stateChecks.isCurrentVersion &&
        stateChecks.agencyApprovedState.currentMember && !currentUser.company.hasApplicationSetting('SCOPE__APPROVAL__STATUS_CLIENT')
        && !stateChecks.valuesRestrictedActionAllowed">
      <button disabled matTooltip="Cannot close {{ 'scope|l' | lang }} due to limitations in your permission settings (scope budgets). Contact your admin to increase your budget">
         Close
      </button>
    </span>
  </div>

  <div class="scope-tooltip" *ngSwitchCase="'CLIENT_APPROVED'">
    <span class='inner-tooltip'
          *ngIf="stateChecks.isCurrentVersion &&
        stateChecks.clientApprovedState.currentMember &&
        currentUser.hasPrivilege('SCOPE__CLOSE')
        && !stateChecks.valuesRestrictedActionAllowed">
      <button disabled matTooltip="Cannot close {{ 'scope|l' | lang }} due to limitations in your permission settings (scope budgets). Contact your admin to increase your budget">
         Close
      </button>
    </span>
    <span class='inner-tooltip'
      *ngIf="
        stateChecks.isCurrentVersion &&
        stateChecks.clientApprovedState.currentMember &&
        currentUser.hasPrivilege('SCOPE__CLOSE') && stateChecks.valuesRestrictedActionAllowed
      ">
      <button (click)="closeScope()">Close</button>
    </span>
  </div>
</ng-container>
