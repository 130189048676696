import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgencySiteHeaderContentComponent } from './components/agency-site-header/agency-site-header.component';
import { ClientSiteHeaderContentComponent } from './components/client-site-header/client-site-header.component';
import { LegalSiteHeaderContentComponent } from './components/legal-site-header/legal-site-header.component';

export const routes: Routes = [
  {
    path: '',
    component: AgencySiteHeaderContentComponent,
  },
  {
    path: '',
    component: ClientSiteHeaderContentComponent,
  },
  {
    path: '',
    component: LegalSiteHeaderContentComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SiteHeaderRoutingModule {}
