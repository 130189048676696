import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { featureKey, reducers } from './store/reducers';
import { ScopeOverviewEffects } from './store/effects/scope-overview.effect';

const EFFECTS = [ScopeOverviewEffects];

@NgModule({
  imports: [StoreModule.forFeature(featureKey, reducers), EffectsModule.forFeature(EFFECTS)],
})
export class ScopeOverviewNgrxModule {}
