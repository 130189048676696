import { createReducer, Action, on } from '@ngrx/store';
import { SystemAdminState } from '../../models/system-admin.state.interface';

export const initialState: SystemAdminState = {};

export const SystemAdminReducer = createReducer(initialState);

export function reducer(state: SystemAdminState | undefined, action: Action): SystemAdminState {
  return SystemAdminReducer(state, action);
}
