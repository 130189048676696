<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>{{ data.title }}</h1>
    </div>

    <div class="mt-2" [ngClass]="{ 'w-450': data.limitModalWidth }">
      <span>
        {{ data.body }}
      </span>
    </div>

    <button class="close-button" mat-dialog-close>
      <i class="material-symbols-rounded">close</i>
    </button>
  </div>
</div>

<mat-dialog-actions class="export-scope-actions">
  <div>
    <button [has-privilege]="'SCOPE__EXPORT_CSV'" (click)="exportToCSV()" class="export-scope-button">
      <span class="export-scope-circle">CSV</span>
      <span class="export-scope-type">Export as CSV</span>
    </button>

    <button [has-privilege]="'SCOPE__EXPORT_DOCX'" (click)="exportToWordDoc()" class="export-scope-button">
      <span class="export-scope-circle">W</span>
      <span class="export-scope-type">Export as Word Document</span>
    </button>

    <button [has-privilege]="'SCOPE__EXPORT_PDF && SCOPE__VIEW_RATES'" (click)="exportScopeToPdf()" class="export-scope-button">
      <span class="export-scope-circle">PDF</span>
      <span class="export-scope-type">Export as PDF Document</span>
    </button>

    <button [has-privilege]="'SCOPE__EXPORT_XLSX'" (click)="exportScopeToExcel()" class="export-scope-button">
      <span class="export-scope-circle">X</span>
      <span class="export-scope-type">Export as Excel Document</span>
    </button>

    <button *ngIf="hasDocusignPermission && !data.currentScope.isScopeByRole()" [has-privilege]="'SCOPE__EXPORT_DOCU_SIGN'" (click)="showDocusignModal()" class="export-scope-button">
      <span class="export-scope-circle">DS</span>
      <span class="export-scope-type">Export to Docusign</span>
    </button>

    <!-- TODO: Add SOW export options as part of SOW implementation -->
  </div>
</mat-dialog-actions>
