import { Type } from 'class-transformer';
import { CompanySummary } from '@core/model/company-summary.model';
import { RatecardIdentity } from '@app/features/scope-overview/model/ratecard-identity.model';
import { CostPlus } from '@app/features/scope-overview/model/cost-plus.model';
import { SecondPartyBrand } from '@app/features/scope-overview/model/second-party-brand.model';

export class SecondPartyBrandRatecard {
  id!: number;
  @Type(() => CompanySummary) company!: CompanySummary;
  @Type(() => SecondPartyBrand) secondPartyBrand!: SecondPartyBrand;
  @Type(() => RatecardIdentity) ratecardIdentity!: RatecardIdentity;
  @Type(() => CostPlus) costPlus!: CostPlus;
}
