import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreNgrxStateModule } from '@core/core-ngrx.module';
import { PipesModule } from '@shared/pipe/index.module';
import { LoaderModalComponent } from './loader-modal.component';
import { LoaderModalService } from './service/loader-modal.service';

@NgModule({
  declarations: [LoaderModalComponent],
  imports: [CommonModule, CoreNgrxStateModule, PipesModule],
  providers: [LoaderModalService],
  exports: [LoaderModalComponent],
})
export class LoaderModalModule {}
