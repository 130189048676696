<div *ngIf="isStandardDropdown; then standardDropdown; else checkboxDropdown"></div>

<ng-template #standardDropdown>
  <mat-label *ngIf="customSelectIcon" [style.display]="controlLabel?.length ? 'block' : 'none'">{{ controlLabel }}</mat-label>
  <mat-form-field [class]='dropdownClass'>
    <mat-label *ngIf="!customSelectIcon" [style.display]="controlLabel?.length ? 'block' : 'none'">{{ controlLabel }}</mat-label>
    <mat-select disableRipple (selectionChange)="onSelection($event)" [(value)]="selectedValue" [compareWith]="compare" [disabled]="disabled || false">
      <mat-option [class]='dropdownClass' *ngFor="let option of dropdownOptions" [value]="option">
        {{ viewOption(option) }}
      </mat-option>
    </mat-select>
    <mat-icon class="material-symbols-rounded" *ngIf="customSelectIcon" matPrefix>{{ customSelectIcon }}</mat-icon>
    <mat-icon class="material-symbols-rounded {{matSelect?.panelOpen ? 'panel-open' : ''}}" *ngIf="!disabled" matSuffix>expand_more</mat-icon>
  </mat-form-field>
</ng-template>

<ng-template #checkboxDropdown>
  <mat-form-field [class]='dropdownClass'>
    <mat-label [style.display]="controlLabel?.length ? 'block' : 'none'">{{ controlLabel }} ({{ dropdownOptions?.length }})</mat-label>
    <mat-select (selectionChange)="onSelection($event)" [formControl]="selectControl" [(value)]="selectedValue" multiple>
      <mat-select-trigger>
        {{selectControl.value?.[0] || ''}}
        <span *ngIf="(selectControl.value?.length || 0) > 1">
          (+{{ (selectControl.value?.length || 0) - 1 }} {{ selectControl.value?.length === 2 ? 'other' : 'others' }})
        </span>
      </mat-select-trigger>
      <mat-option [class]='dropdownClass' *ngFor="let option of dropdownOptions" [value]="option">{{ option }}</mat-option>
    </mat-select>
    <mat-icon class="material-symbols-rounded" matSuffix>expand_more</mat-icon>
  </mat-form-field>
</ng-template>
