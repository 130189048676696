import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from '@shared/shared.module'
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms'
import { ScopeUiAutocompleteComponent } from '@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { CdnConfig } from '@core/model/cdn-config.model'
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service'
import { Deliverable, SelectedDeliverable } from '@app/features/scoping/models/deliverable.model'
import { SnackbarEventType, SNACKBAR_LENGTH_LONG, SnackbarService } from '@shared/utils/snackbar.service'
import {
  ScopeUiDatepickerComponent,
} from '@shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component';
import {
  ScopeUiDropdownComponent,
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { Observable } from 'rxjs'
import { User } from '@core/model/user.model';
import { getCurrencySymbol } from '@shared/utils/utils'
import { ScopeTraffickingService } from '@app/features/scope-overview/service/scope-trafficking.service'
import { Role } from '@app/features/scoping/models/role.model'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { MapRolesComponent } from '@app/features/scoping/components/map-roles/map-roles.component'
import { NgxMaskDirective } from 'ngx-mask'
import { ScopeVersion } from '@core/model/scope-version'
import { DeliverableType } from '@app/features/scope-overview/model/deliverable-type'
import { ScopeUiDuration } from '@shared/components/ui-components/scope-ui-duration/scope-ui-duration.component'

@Component({
  templateUrl: './create-deliverable-modal.component.html',
  styleUrls: ['./create-deliverable-modal.component.scss'],
  imports: [
    SharedModule,
    ScopeUiAutocompleteComponent,
    NgxDaterangepickerMd,
    ScopeUiInputComponent,
    MatDatepickerModule,
    CKEditorModule,
    ScopeUiDatepickerComponent,
    ScopeUiDropdownComponent,
    MapRolesComponent,
    NgxMaskDirective,
    ScopeUiDuration,
  ],
  standalone: true,
})
export class CreateDeliverableModalComponent {
  @Input() cdnConfig?: CdnConfig
  @Input() availableDeliverables$: Observable<Deliverable[]>
  @Input() availableOwners$: Observable<User[]>
  @Input() isFixedFee: boolean
  @Input() currentScope: ScopeVersion
  @Input() owner: User
  @Input() showMapping: boolean = false
  @Input() nextModalStep: boolean = false
  @Input() mappingRoles: Role[]
  @Input() targetRoles: any[]
  @Input() sourceRatecard: RatecardVersion
  @Input() targetRatecard: RatecardVersion
  @Input() showAgencyRates: boolean
  @Input() deliverableEntry: any
  @Input() distinctRolesMap: boolean
  @Input() set currencyCode(value: string) {
    this.symbol = getCurrencySymbol(value)
  }
  @Output() confirmCallback = new EventEmitter<any>()
  @Output() onSearchDeliverables = new EventEmitter<string>()
  @Output() onInsertWithRemapRolesSuccess = new EventEmitter<number>()
  modalForm: UntypedFormGroup
  selection: SelectedDeliverable = {}
  timeframe: any
  discipline: any
  itemType: any
  isNewCustomDeliverable: boolean = false
  editDescription: boolean = false
  title = 'customEditor'
  public Editor = Editor
  description = ''
  disciplines: any = []
  libraryItemTypes = [
    {
      name: 'Deliverable',
      option: 'DELIVERABLE',
    },
    {
      name: 'Fixed Cost',
      option: 'FIXED_COST',
    },
  ]
  deliverableName: string
  loading: boolean
  symbol: string
  trafficFields: any

  constructor(private scopeOverviewService: ScopeOverviewService,
              private snackbarService: SnackbarService,
              protected traffickingService: ScopeTraffickingService) {
    this.modalForm = new FormGroup([])
    this.modalForm.setControl('name', new FormControl(''))
    this.modalForm.setControl('budget', new FormControl(''))
    this.modalForm.setControl('discipline', new FormControl(''))
    this.modalForm.setControl('owner', new FormControl(''))
    this.modalForm.setControl('timeframe', new FormControl(''))
    this.modalForm.setControl('libraryItemType', new FormControl(''))
    this.newDeliverable = {}
    this.newDeliverable.startDate = new Date()
    this.newDeliverable.endDate = new Date()
    this.newDeliverable.endDate.setMonth(this.newDeliverable.startDate.getMonth() + 3)
  }

  ngOnInit() {
    this.scopeOverviewService.getDisciplines().subscribe({
      next: (d) => {
        this.disciplines = d
      },
      error: (error) => {
        this.snackbarService.showSnackbar(`Error during loading disciplines`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        console.error(error)
      },
    })
    this.trafficFields = this.traffickingService.getTrafficFields()
  }

  updateTrafficSystemMetadataField(uuid: any, event: any) {
    if(this.newDeliverable.trafficSystemEntityMetadata == undefined) {
      this.newDeliverable.trafficSystemEntityMetadata = {}
    }
    this.newDeliverable.trafficSystemEntityMetadata[uuid] = {
      name: event, value: event
    }
  }

  selectTrafficField($event: { event: any; componentId?: any }, uuid: string) {
    if(this.newDeliverable.trafficSystemEntityMetadata == undefined) {
      this.newDeliverable.trafficSystemEntityMetadata = {}
    }
    this.newDeliverable.trafficSystemEntityMetadata[uuid] = $event.event
  }

  newDeliverable: any
  selectedPeriod: { value: string, name: string } = { name: 'Custom', value: 'custom' };

  onSelectDiscipline(event: any) {
    this.modalForm.get('discipline').setValue(event)
  }

  onSelectOwner(event: any) {
    this.modalForm.get('owner').setValue(event.value)
  }

  onSelectType(event: any) {
    this.modalForm.get('libraryItemType').setValue(event.option)
  }

  searchDeliverables(event: { searchString: string }) {
    this.onSearchDeliverables.emit(event.searchString)
  }

  confirm(quickAdd = false) {
    this.deliverableName = this.modalForm.get('name')?.value
    this.selection.description = this.description
    this.selection.startDate = this.newDeliverable.startDate
    this.selection.endDate = this.newDeliverable.endDate
    this.selection.trafficSystemEntityMetadata = this.newDeliverable.trafficSystemEntityMetadata
    this.confirmCallback.emit({ form: this.modalForm, selection: this.selection, isNewCustomDeliverable: this.isNewCustomDeliverable, quickAdd })
  }

  showRemapDialog() {
    this.showMapping = true
  }

  insertWithRemapRoles() {
    this.loading = true;
    var mappingRoles = [];
    this.deliverableEntry.libraryComponents.forEach((libraryDeliverableEntryComponent) => {
      libraryDeliverableEntryComponent.roles.forEach((role) => {
        role.targetMappingRole = this.distinctRolesMap[role.rateCardRole.name.toLowerCase()].targetMappingRole;
        role.selectedMinutes = role.minutes;
        role.complexity = libraryDeliverableEntryComponent.complexity;
        mappingRoles.push(role)
      });
    });
    this.scopeOverviewService.convertAndInsertLibraryDeliverable(this.selection.deliverableType.id, this.deliverableEntry.id, this.currentScope.identity.id,
      mappingRoles, this.deliverableName, {
        description: this.description,
        budget: this.modalForm.get('budget')?.value,
        startDate: this.selection.startDate,
        endDate: this.selection.endDate
      }).subscribe({
        next: (deliverableId: number) => {
          this.loading = false
          this.onInsertWithRemapRolesSuccess.emit(deliverableId)
        },
        error: (error) => {
          this.loading = false
          this.snackbarService.showSnackbar(`Error during loading disciplines`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
          console.error(error)
        },
      })
  };

  onAutocompleteSelect(event: { event: DeliverableType }) {
    if (event.event) {
      this.modalForm.get('name').setValue(event.event.name)
      this.selection.deliverableType = event.event
    } else {
      this.selection = {}
    }
  }

  isFormValid() {
    if (this.isNewCustomDeliverable) {
      return this.modalForm.get('name')?.value.length > 0 &&
        this.modalForm.get('discipline')?.value && (this.isFixedFee || this.modalForm.get('libraryItemType')?.value)
    } else {
      return this.modalForm.get('name')?.value.length > 0 && this.selection?.deliverableType?.name.length > 0
    }
  }

  switchForm() {
    this.selection = {}
    this.newDeliverable.startDate = new Date()
    this.newDeliverable.endDate = new Date()
    this.newDeliverable.endDate.setMonth(this.newDeliverable.startDate.getMonth() + 3)
    this.selectedPeriod = { name: 'Custom', value: 'custom' }
  }
}
