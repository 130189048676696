import { inject } from '@angular/core';
import { ResolveFn, Router, UrlTree } from '@angular/router';
import { selectRouteData } from '@core/store/selectors/router.selector';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthSelectors } from '@core/store/selectors/auth.selector'
import { AuthService } from '@core/service/auth.service'

/**
 * The attempt to navigate a user to a specific page based on user's privileges.
 */

export const privilegesGuard: ResolveFn<Observable<boolean | UrlTree>> = () => {
  const store = inject(Store);
  const router = inject(Router);
  const authService = inject(AuthService);

  return store.pipe(
    select(selectRouteData),
    tap((routeData: any) => {
      const requiredPrivileges = routeData?.privileges;
      const hasPrivileges = requiredPrivileges.some((privilege: string) =>
        authService.loggedInUser?.privileges.includes(privilege)
      );
      return hasPrivileges ? true : router.parseUrl('/unauthorised');
    })
  );
};
