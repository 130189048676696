import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyType } from '@app/core/model/enums/company-type.enum';
import { BootstrapConfig } from '@core/model/bootstrap-config.model';
import { LanguageService } from '@core/service/language.service';
import { CoreState } from '@core/store';
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector';
import { select, Store } from '@ngrx/store';
import { untilDestroyed } from './utils';

@Injectable({ providedIn: 'root' })
export class RouteUtils {
 private readonly destroy$;
 private bootConfig?: BootstrapConfig | null;

  constructor(private store: Store<CoreState>, private router: Router, private lang: LanguageService) {
    this.destroy$ = untilDestroyed();
    this.store
      .pipe(
        this.destroy$(),
        select(BootstrapSelectors.selectBootstrapConfig)
        )
      .subscribe((bootConfig) => (this.bootConfig = bootConfig));
  }

  urlCreateScope() {
    switch (this.bootConfig?.companyBootstrap?.companyType) {
      case CompanyType.LEGAL:
        return '/create-matter/';
      default:
        return '/create-scope/';
    }
  }

  urlScopeOverview(identity?: number) {
    switch (this.bootConfig?.companyBootstrap?.companyType) {
      case CompanyType.LEGAL:
        return '/matters/matter-overview/' + (identity ? identity : '');
      default:
        return '/scopes/scope-overview/' + (identity ? identity : '');
    }
  }

  urlScopeOverviewDeliverable(identity?: number, deliverableId?: number) {
    return this.urlScopeOverview(identity) + '/' + this.lang.get('deliverable|l') + '/' + deliverableId;
  }

  urlSowOverview(identity?: number) {
    switch (this.bootConfig?.companyBootstrap?.companyType) {
      case CompanyType.LEGAL:
        return '/portfolio-of-matters-overview/' + (identity ? identity : '');
      default:
        return '/folder-overview/' + (identity ? identity : '');
    }
  }

  urlMyScopes() {
    switch (this.bootConfig?.companyBootstrap?.companyType) {
      case CompanyType?.LEGAL:
        return '/matters';
      default:
        return '/scopes';
    }
  }
}
