import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { effects, reducers } from './store';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    CommonModule,
    StoreModule.forFeature('core', reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [],
})
export class CoreNgrxStateModule {}
