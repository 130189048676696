import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from "@shared/shared.module";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgxMaskDirective } from "ngx-mask";

@Component({
  selector: 'scope-ui-counter',
  standalone: true,
  imports: [CommonModule, FormsModule, SharedModule, NgxMaskDirective],
  templateUrl: './scope-ui-counter.component.html',
  styleUrls: ['./scope-ui-counter.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        width: '200px'
      })),
      state('expanded', style({
        width: '400px'
      })),
      transition('collapsed <=> expanded', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})

export class ScopeUiCounterComponent {
  @Input() initialValue: number
  @Input() formClass?: string
  @Input() isDecimal?: boolean
  @Input() enableMinors?: boolean
  @Input() majorStep?: number
  @Input() minorStep?: number
  @Input() min?: number
  @Input() max?: number
  @Input() maxLength?: number
  _formatDisplayValue: (val: number) => string | number = (val: number) => val
  disableMask = false
  @Input() set formatDisplayValue (value: (val: number) => string | number) {
    if (value) {
      this.disableMask = true
      this._formatDisplayValue = value
    }
  }
  _parseDisplayValue:  (val: string | number) => number = (val: string | number) => parseFloat(val.toString())
  @Input() set parseDisplayValue (value: (val: string | number) => number) {
    if (value)
      this._parseDisplayValue = value
  }
  @Output() onUpdateValue = new EventEmitter<number>()

  onManualInput($event: string) {
    if ($event === "") return
    let newValue = this._parseDisplayValue($event)
    if (newValue != this.initialValue) {
      this.initialValue = this._parseDisplayValue($event)
      this.onUpdateValue.emit(this.initialValue)
    }
  }

  increaseMajor($event: MouseEvent) {
    $event.stopPropagation()
    let newValue = Math.floor(this.initialValue) + (this.majorStep || 1)
    newValue = Math.min(newValue, this.max || newValue)
    if (newValue != this.initialValue) {
      this.initialValue = newValue
      this.onUpdateValue.emit(this.initialValue)
    }
  }

  decreaseMajor($event: MouseEvent) {
    $event.stopPropagation()
    let newValue = Math.ceil(this.initialValue) - (this.majorStep || 1)
    newValue = Math.max(newValue, this.min || 0)
    if (newValue != this.initialValue) {
      this.initialValue = newValue
      this.onUpdateValue.emit(this.initialValue)
    }
  }

  increaseMinor($event: MouseEvent) {
    $event.stopPropagation()
    let newValue = this.initialValue + (this.minorStep || 1)
    newValue = Math.min(newValue, this.max || newValue)
    if (newValue != this.initialValue) {
      this.initialValue = newValue
      this.onUpdateValue.emit(this.initialValue)
    }
  }

  decreaseMinor($event: MouseEvent) {
    $event.stopPropagation()
    let newValue = this.initialValue - (this.minorStep || 1)
    newValue = Math.max(newValue, this.min || 0)
    if (newValue != this.initialValue) {
      this.initialValue = newValue
      this.onUpdateValue.emit(this.initialValue)
    }
  }
}
