import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {SharedModule} from "@shared/shared.module";
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import {ModalConfig} from "@core/model/modal-config.model";
import {
  ScopeUiAutocompleteComponent
} from "@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component";
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import {
  ScopeUiDropdownComponent
} from "@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { Discount } from '@core/model/discount.model'
import { formatMonetaryValue } from '@shared/utils/utils'
import { Money } from '@app/features/scope-overview/model/money.model'

@Component({
  selector: 'add-edit-discount-modal',
  templateUrl: './add-edit-discount-modal.component.html',
  styleUrls: ['./add-edit-discount-modal.component.scss'],
  imports: [
    SharedModule,
    ScopeUiAutocompleteComponent,
    NgxDaterangepickerMd,
    ScopeUiInputComponent,
    ScopeUiDropdownComponent,
    MatButtonToggleModule,
  ],
  standalone: true
})
export class AddEditDiscountModalComponent {
  @Input() discount?: Discount
  @Input() currencyCode: string
  @Input() maxDiscount: number
  @Output() onSubmit: EventEmitter<any>
  modalForm: UntypedFormGroup
  amountTypeControl = new FormControl('AMOUNT')
  newDiscount: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalConfig) {
    this.modalForm = new FormGroup({});
    this.onSubmit = new EventEmitter<any>()
  }

  ngOnInit() {
    if (this.discount) {
      this.newDiscount = {
        ...this.discount
      }
      this.amountTypeControl.setValue(this.discount.type)
    } else {
      this.newDiscount = {
        description: '',
        value: 0,
        type: 'AMOUNT'
      }
    }
  }

  confirm() {
    this.newDiscount.type = this.amountTypeControl.value
    this.onSubmit.emit(this.newDiscount)
  }

  protected readonly close = close;

  isFormValid() {
    return this.newDiscount.description && this.newDiscount.value && this.newDiscount.value > 0.01
  }

  getAmountLabel() {
    if (this.maxDiscount) {
      return this.amountTypeControl.value == 'AMOUNT' ?
        `Amount (${this.currencyCode}) = (${this.getCurrentPercent()}%)` :
        `Percent = (${formatMonetaryValue(new Money(this.getCurrentAmount(), this.currencyCode))})`
    } else {
      return this.amountTypeControl.value == 'AMOUNT' ? `Amount (${this.currencyCode})` : `Percent`
    }
  }

  getCurrentPercent() {
    return parseFloat(((this.newDiscount.value || 0) / this.maxDiscount * 100).toString()).toFixed(2);
  };

  getCurrentAmount() {
    return parseFloat(((this.newDiscount.value || 0) * this.maxDiscount / 100).toString());
  };
}
