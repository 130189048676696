import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromScopingReducer from './scoping.reducer';
import { ScopingState } from '../../models/scope.state.model';

export const featureKey = 'scoping';

export interface State {
  scoping: ScopingState;
}

export const reducers: ActionReducerMap<State> = {
  scoping: fromScopingReducer.reducer,
};

export const getScopesState = createFeatureSelector<State>(featureKey);
