import { Type } from 'class-transformer';
import { Money } from '@app/features/scope-overview/model/money.model';
import { ScopeComponent } from "@app/features/scoping/models/component.model";
import { ThirdPartyCost } from "@app/features/scoping/models/third-party-cost.model";
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { UserComment } from '@core/model/comment.model'

export class DeliverableSection {
  id!: number
  order!: number
  name!: string
  currencyUnit!: string
  @Type(() => ScopeComponent) components: ScopeComponent[]
  @Type(() => UserComment) comments!: UserComment[]
  @Type(() => ThirdPartyCost) thirdPartyCosts: ThirdPartyCost[] = []
  @Type(() => Date) startDate: Date
  @Type(() => Date) endDate: Date
  deliverableId?: number

  getTotalSellingPrice = (ctx: any = {}, deliverable: Deliverable) => {
    let total = new Money(0, this.currencyUnit);
    let components = this.getComponents(deliverable)
    for (var i = 0; i < components.length; i++) {
      let component = components[i];
      let totalUserSellingPrice = component.getTotalSellingPrice(ctx);
      if (totalUserSellingPrice == null) return null;
      total = total.add(totalUserSellingPrice);
    }
    for (var j = 0; j < this.thirdPartyCosts.length; j++) {
      let tpcPrice = this.thirdPartyCosts[j].calculateSellingPrice();
      total = total.add(tpcPrice);
    }
    return total;
  }

  getTotalRateCardHours = (deliverable: Deliverable) => {
    let components = this.getComponents(deliverable)
    return components.map((component) => component.rateCardTotalHours).reduce((acc, next) => acc + next, 0)
  }

  totalScopeMarkSellingPrice(deliverable: Deliverable) {
    var total = new Money(null, this.currencyUnit);
    let components = this.getComponents(deliverable)
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.totalScopeMarkSellingPrice() == null)
        return null;
      total = total.add(component.totalScopeMarkSellingPrice());
    }
    return total;
  }

  getComponents = (deliverable: Deliverable) => {
    return this.components || deliverable?.components?.filter((c) => c.section?.id === this.id) || []
  }

  calculateThirdPartyCosts(deliverable: Deliverable) {
    var total = new Money(null, this.currencyUnit)
    let components = this.getComponents(deliverable)
    for (var i = 0; i < components.length; i++) {
      var component = components[i]
      total = total.add(component.thirdPartyCostTotal())
    }
    for (var t = 0; t < this.thirdPartyCosts.length; t++) {
      var tpcPrice = this.thirdPartyCosts[t].calculateSellingPrice();
      total = total.add(tpcPrice);
    }
    return total;
  }

  getTpcValueTotal() {
    let total = new Money(0, this.currencyUnit)
    this.thirdPartyCosts?.forEach(function (tpc: any) {
      total = total.add(tpc.calculateCostPrice());
    });
    return total
  }
}
