import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "@envs/environment";
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class ScopeMetadataService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl;

  constructor(private httpClient: HttpClient) { }

  callScopeMetadataLookupUrl(uuid: string, secondPartyId: number, rateCardId: number, searchText: string): Observable<any[]> {
    const params = new HttpParams()
      .set('secondPartyId', secondPartyId)
      .set('rateCardId', rateCardId)
      .set('searchText', searchText)

    return this.httpClient.get<any[]>(`${this.BASE_API_URL}scope/traffic-system/metadata-field-lookup/${uuid}`,
      { params: params })
  }

  callDeliverableMetadataLookupUrl(uuid: string, scopeIdentityId: number, searchText: string): Observable<any[]> {
    const params = new HttpParams()
      .set('searchText', searchText)

    return this.httpClient.get<any[]>(`${this.BASE_API_URL}scope/${scopeIdentityId}/deliverable/traffic-system/metadata-field-lookup/${uuid}`,
      { params: params })
  }
}
