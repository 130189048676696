import { noop } from 'rxjs';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgForOf } from '@angular/common';

export interface RadioButtonItem {
  name: string;
  value: string | number;
}

let nextUniqueId = 0;

@Component({
  standalone: true,
  selector: 'scope-ui-radio',
  templateUrl: './scope-ui-radio.component.html',
  styleUrls: ['./scope-ui-radio.component.scss'],
  imports: [
    FormsModule,
    NgForOf,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScopeUiRadioComponent),
      multi: true,
    },
  ],
})
export class ScopeUiRadioComponent implements ControlValueAccessor, OnInit {

  @Input() items: Array<RadioButtonItem> = [];
  @Input() disabled: boolean = false;

  private innerValue: string | number | boolean = '';
  private _name: string = `switch-radio-group-${nextUniqueId++}`;

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get value(): string | number | boolean {
    return this.innerValue;
  }

  set value(v: string | number | boolean) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.change(v);
    }
  }

  onChange: Function = noop;

  onTouched: Function = noop;

  writeValue(value: string | number | boolean) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  change(value: string | number | boolean) {
    this.innerValue = value;
    this.onChange(value);
    this.onTouched(value);
  }

  ngOnInit(): void {
  }
}
