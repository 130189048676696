import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Pipe({
    name: 'safe',
})
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(value: string, isHtml?: boolean): SafeResourceUrl {
      if (isHtml) {
        return this.sanitizer.bypassSecurityTrustHtml(value)
      } else {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value)
      }
    }
}
