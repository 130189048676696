import {Company} from "@core/model/company.model";
import {Type} from "class-transformer";
import {User} from "@core/model/user.model";
import {Privilege} from "@core/model/enums/privilege.enum";
import {ScopeVersion} from "@core/model/scope-version";
import {FolderIdentity} from "@core/model/folder-identity";
import { Money } from "@app/features/scope-overview/model/money.model";

export class FolderVersion {
  id!: number;
  createdTs!: string;
  updatedTs!: string;
  @Type(() => User) createdBy!: User;
  @Type(() => User) updatedBy!: User;
  @Type(() => FolderIdentity) identity!: FolderIdentity;
  versionNumber!: number;
  startDate!: string;
  endDate!: string;
  @Type(() => User) owner!: User;
  @Type(() => ScopeVersion) scopeVersions!: ScopeVersion[];
  status!: string;
  name!: string;
  budgets?: any[];
  retainedTeams: any;
  userPrivilegeRestrictions?: any[];
  userAdditionalPrivileges?: any[];
  privilegeCategories?: string[];
  comments?: any[];
  @Type(() => Company) company!: Company;
  budgetBalances?: any[];
  @Type(() => Money) totalValues?: Money[];

  isStateDraft() {
    return this.status === 'DRAFT';
  };

  isArchived() {
    return this.identity.archived;
  };

  isTemplate() {
    return this.identity.isTemplate;
  };

  isPrivilegeRestricted(privilege: Privilege) {
    return this.userPrivilegeRestrictions?.indexOf(privilege) != -1;
  };

  hasPrivilege(privilege: Privilege, user: User) {
    var hasAdditional = this.hasAdditionalPrivilege(privilege);
    if (hasAdditional){
      return true;
    }

    var hasPrivilege = user.hasPrivilege(privilege);
    if (hasPrivilege){
      return !this.isPrivilegeRestricted(privilege);
    }
    return false;
  };

  hasAdditionalPrivilege(privilege: Privilege) {
    return this.userAdditionalPrivileges?.indexOf(privilege) != -1;
  };

  hasEnoughBudget() {
    var _this = this;
    var hasEnoughBudget = true;
    this.budgets?.forEach(budget => {
      var totalValue = _this.totalValues?.find(it => it.currency == budget.budget.currency);
      if (totalValue != null && budget.budget.amount < totalValue.amount) {
        hasEnoughBudget = false;
      }
    });
    return hasEnoughBudget;
  };
}

export type FolderStatus =
  | 'DRAFT'
  | 'IN_REVIEW'
  | 'SCOPING';

export type FolderStatusName = Record<FolderStatus, string>;

export const folderStatuses: FolderStatusName = {
  DRAFT: "DRAFT",
  IN_REVIEW: "IN_REVIEW",
  SCOPING: "SCOPING"
}
