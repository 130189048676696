import { Component, Input, OnDestroy } from '@angular/core'
import { SafePipe } from "@app/shared/pipe/safe.pipe";
import { HeaderStyleService } from "@app/shared/services/header-style.service";
import { EnvService } from "@envs/env-service";
import { Router } from "@angular/router";
import { AuthService } from '@core/service/auth.service'

@Component({
  selector: 'app-deliverable-builder',
  templateUrl: './deliverable-builder.component.html',
  styleUrls: ['./deliverable-builder.component.scss'],
  providers: [ SafePipe ],

})
export class DeliverableBuilderComponent {
  @Input() id!: number;
  @Input() deliverableId!: number;
  @Input() editLibDelEntryId!: number;
  constructor(private headerStyleService: HeaderStyleService, private envService: EnvService, private router: Router, private authService: AuthService) {
    window.addEventListener('message', (event) => {
      if (event.data.action === 'goToScope') {
        this.router.navigateByUrl('/scopes/scope-overview/' + this.id, { state: { skipWaitForLoad: true } })
      }
    })
  }

  public get baseJsUrl(): string {
    return `${this.envService.baseJsUrl}/scope-overview/${this.id}/deliverable/${this.deliverableId}?editLibDelEntryId=${this.editLibDelEntryId}&childCompany=${this.authService.loggedInUser?.company.id}`;
  }
}
