import { Component, EventEmitter, Input, Output } from '@angular/core'
import { User } from '@core/model/user.model'
import { CookieCategory } from '@core/model/enums/cookie-category.enum'

@Component({
  selector: 'cookie-footer',
  templateUrl: './cookie-footer.component.html',
  styleUrls: ['./cookie-footer.component.scss']
})
export class CookieFooterComponent {
  @Input() currentUser: User

  @Output() onSubmit = new EventEmitter<any[]>()

  tabsCounter: number
  showCookiesRow = false
  cookieCategories = [{
    key: CookieCategory.NECESSARY,
    label: "Necessary",
    description: "Necessary cookies help make the application usable by enabling basic functions like navigation and access to secure areas of the application. The application cannot function properly without these cookies",
    changeable: false,
    defaultValue: true,
    expanded: false
  },
  {
    key: CookieCategory.PREFERENCES,
    label: "Preferences",
    description: "",
    changeable: true,
    defaultValue: true,
    expanded: false
  },
  {
    key: CookieCategory.STATISTICS,
    label: "Statistics",
    description: "",
    changeable: true,
    defaultValue: true,
    expanded: false
  },
  {
    key: CookieCategory.MARKETING,
    label: "Marketing",
    description: "",
    changeable: true,
    defaultValue: true,
    expanded: false
  }]

  cookieVariable = [
    {
      key: "JSESSIONID",
      name: "JSESSIONID",
      provider: "scope.expert",
      purpose: "JSESSIONID is a cookie in J2EE web application which is used in session tracking. Since HTTP is a stateless protocol, we need to use any session to remember state.",
      expiry: "Session",
      type: "HTTP/HTTPS",
      category: CookieCategory.NECESSARY
    },
    {
      key: "CFDUID",
        name: "__cfduid",
        provider: "imgur.com",
        purpose: "The __cfduid cookie is used to identify individual secondParties behind a shared IP address and apply security settings on a per-client basis. It does not correspond to any user ID in the web application, and does not store any personally identifiable information.",
        expiry: "Session",
        type: "HTTP",
        category: CookieCategory.PREFERENCES
    },
    {
      key: "GA",
        name: "_ga",
        provider: "scope.expert",
        purpose: "The _ga cookie is asssociated with Google Universal Analytics. It is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports. This cookie doesn’t collect information that identifies a visitor.",
        expiry: "2 years",
        type: "HTTP/HTTPS",
        category: CookieCategory.STATISTICS
    },
    {
      key: "GAT",
        name: "_gat",
        provider: "scope.expert",
        purpose: "The _gad cookie is asssociated with Google Universal Analytics. It collects information about how visitors use the application, for instance which pages visitors go to most often, and if they get error messages from web pages. This cookie doesn’t collect information that identifies a visitor.",
        expiry: "1 day",
        type: "HTTP/HTTPS",
        category: CookieCategory.STATISTICS
    },
    {
      key: "GID",
        name: "_gid",
        provider: "scope.expert",
        purpose: "The _gid cookie is asssociated with Google Universal Analytics. It stores and updatea a unique value for each page visited. The _gid cookie collects information about how visitors use the application. This cookie doesn’t collect information that identifies a visitor.",
        expiry: "2 days",
        type: "HTTP/HTTPS",
        category: CookieCategory.STATISTICS
    },
    {
      key: "TOP_FILTER_SHOW",
        name: "ScopeApp.topFiltersShow",
        provider: "scope.expert",
        purpose: "The topFiltersShow cookie is used to store the settings of the top filters in the web application's Scope Home Page. It does not correspond to any user ID in the web application, and does not store any personally identifiable information.",
        expiry: "Never",
        type: "HTTP/HTTPS",
        category: CookieCategory.PREFERENCES
    },
    {
      key: "SCOPE_PAGE_FILTERS",
        name: "ScopeApp.scopePageFilters",
        provider: "scope.expert",
        purpose: "The scopePageFilters cookie is used to store the filters of scopes main page.",
        expiry: "Never",
        type: "HTTP/HTTPS",
        category: CookieCategory.PREFERENCES
    },
    {
      key: "GLOBAL_PARENT_CHILD_ACCOUNT_SWITCHER",
        name: "ScopeApp.globalParentChildAccountSwitcher",
        provider: "scope.expert",
        purpose: "The globalParentChildAccountSwitcher cookie is used to store the currently switched to child.",
        expiry: "Never",
        type: "HTTP/HTTPS",
        category: CookieCategory.NECESSARY
    },
    {
      key: "USER_COLUMNS",
        name: "ScopeApp.userColumns",
        provider: "scope.expert",
        purpose: "The ScopeApp.userColumns cookie is used to store the settings of the table columns in the web application pages. It does not correspond to any user ID in the web application, and does not store any personally identifiable information.",
        expiry: "Never",
        type: "HTTP/HTTPS",
        category: CookieCategory.PREFERENCES
    },
    {
      key: "DASHBOARD_USER_COLUMNS",
        name: "ScopeApp.dashboardUserColumns",
        provider: "scope.expert",
        purpose: "The ScopeApp.dashboardUserColumns cookie is used to store the settings of the filters in the web application's Dashboard page. It does not correspond to any user ID in the web application, and does not store any personally identifiable information.",
        expiry: "Never",
        type: "HTTP/HTTPS",
        category: CookieCategory.PREFERENCES
    },
    {
      key: "DELIVERABLE_USER_COLUMNS",
        name: "ScopeApp.userColumnsDeliverablesPage",
        provider: "scope.expert",
        purpose: "Page cookie is used to store the settings of the filters in the web application's Deliverable page. It does not correspond to any user ID in the web application, and does not store any personally identifiable information.",
        expiry: "Never",
        type: "HTTP/HTTPS",
        category: CookieCategory.PREFERENCES
    },
    {
      key: "LIBRARY_TOP_FILTER_SHOW",
        name: "ScopeApp.libraryTopFiltersShow",
        provider: "scope.expert",
        purpose: "The topFiltersShow cookie is used to store the settings of the top filters and table columns in the web application's Library Page. It does not correspond to any user ID in the web application, and does not store any personally identifiable information.",
        expiry: "Never",
        type: "HTTP/HTTPS",
        category: CookieCategory.PREFERENCES
    },
    {
      key: "LIBRARY_PAGE_COLUMNS",
        name: "ScopeApp.libraryPageColumns",
        provider: "scope.expert",
        purpose: "The ScopeApp.libraryPageColumns cookie is used to store the settings of the table columns in the web application pages. It does not correspond to any user ID in the web application, and does not store any personally identifiable information.",
        expiry: "Never",
        type: "HTTP/HTTPS",
        category: CookieCategory.PREFERENCES
    }]

  ngOnInit(){
    this.tabsCounter = 1
    if (this.currentUser.cookieSettings) {
      this.cookieCategories.forEach((category) => {
        category.defaultValue = this.currentUser.cookieSettings.allowedCookieCategories.indexOf(category.key) > -1
      });
    }
  }

  saveCookieSettings() {
    var selected = this.cookieCategories.filter(function (cat) {
      return cat.defaultValue == true;
    });
    var categories = [];
    selected.forEach(function (sel) {
      categories.push(sel.key);
    });
    this.onSubmit.emit(categories)
  };

  filterCookiesByCategory(category) {
    return this.cookieVariable.filter(function (cookie) {
      return cookie.category === category.key;
    })
  };
}
