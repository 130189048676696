import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { GroupedCommments, Mention, ActivityTradingStatus, ActivityComment, Item } from '../../model/activity.model'
import { ScopeOverviewSelectors } from '../../store/selectors/scope-overview.selector'
import { ScopeOverviewActions } from '../../store/actions/scope-overview.action'
import { ActivitiesService } from '../../service/activities.service'
import { MentionModule } from 'angular-mentions'
import { ScopeVersion } from '@app/core/model/scope-version'
import { User } from '@app/core/model/user.model'
import { FormsModule } from '@angular/forms'
import { CdnConfig } from '@app/core/model/cdn-config.model'
import { MatChipsModule } from '@angular/material/chips'
import { PipesModule } from '@app/shared/pipe/index.module'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { ActivityEventComponent } from './components/activity-event/activity-event.component'
import { ActivityStatus } from '@app/core/model/enums/activity-status.enum'
import { ActivityIconStatus } from '@app/core/model/definitions/activity-icon-status.interface'
import { Router } from '@angular/router'
import { DirectivesModule } from '@shared/directives/index.module'
import { trackById } from '@shared/utils/utils';

@Component({
  selector: 'scope-activity',
  standalone: true,
  imports: [
    CommonModule,
    MentionModule,
    FormsModule,
    MatChipsModule,
    PipesModule,
    MatIconModule,
    MatButtonModule,
    ActivityEventComponent,
    DirectivesModule,
    NgOptimizedImage,
  ],
  templateUrl: './scope-activity.component.html',
  styleUrls: ['./scope-activity.component.scss'],
})
export class ScopeActivityComponent implements OnInit {
  @Input() scopeId!: number

  @Input() currentUser!: User

  @Input() currentScope!: ScopeVersion

  @Input() cdnConfig!: CdnConfig

  @Output() onSelectTab = new EventEmitter<string>()

  activities$: Observable<GroupedCommments>

  collaboratorsMentions!: Mention[]

  collaborators!: User[]

  nonReachableUrls: string[] = []

  commentInput: string

  userProfileUri!: string

  canAddComment!: boolean

  activityStatusMap: { [key in ActivityStatus]: { icon: string; color: string } }

  constructor(private store: Store, public activitiesService: ActivitiesService, private router: Router) {
    this.commentInput = ''
    this.activities$ = this.activitiesService.groupComments(this.store.select(ScopeOverviewSelectors.selectActivities))
    this.activityStatusMap = this.activitiesService.activityStatusMap;
  }

  ngOnInit(): void {
    this.mapCollaborators(this.currentScope)
    this.store.dispatch(ScopeOverviewActions.getActivities({ scopeId: this.scopeId }))
    this.userProfileUri = this.currentUser.getPhotoUri(this.cdnConfig.userProfileUrl)
  }

  getActivityUserPhoto(comment: ActivityComment) {
    if (this.cdnConfig && comment.initiatedBy && comment.initiatedBy.photoUri) {
      return this.cdnConfig.getProfileImageUrl(comment.initiatedBy.photoUri);
    }
    return '';
  }

  getMentionUserPhoto(item: any) {
    if (this.cdnConfig && item && item.photoUri) {
      return this.cdnConfig.getProfileImageUrl(item.photoUri);
    }
    return '';
  }

  isImageReachable(url: string): boolean {
    if (this.nonReachableUrls.includes(url)) {
      return false
    }
    const img = new Image();
    img.src = this.cdnConfig.getProfileImageUrl(url);
    const reachable = img.complete && img.naturalHeight !== 0;
    if (!reachable) {
      this.nonReachableUrls.push(url)
    }
    return reachable;
  }

  getActivityIconStatus(comment: ActivityComment): ActivityIconStatus {
    const status = comment.action as ActivityStatus;
    return {
      status: status,
      ...this.activityStatusMap[status]
    };
  }

  checkTradingStatus(value: string): boolean {
    return Object.values(ActivityTradingStatus).includes(value as ActivityTradingStatus)
  }

  showHideComment() {
    this.canAddComment = !this.canAddComment
  }

  getFullName(user: User) {
    return `${user?.firstName} ${user?.lastName}`
  }

  getMemberHtmlText(user: User) {
    return '<user id="' + user.id + '">' + this.getFullName(user) + '</user>'
  }

  mapCollaborators(currentScope: ScopeVersion) {
    this.collaboratorsMentions = currentScope.collaborators
      .map((user) => ({ name: `${user.user?.firstName + ' ' + user.user?.lastName}`, email: user.user?.email, photoUri: user.user?.photoUri }))
    this.collaborators = currentScope.collaborators.map((collaborator) => collaborator.user)
  }

  submitComment() {
    this.store.dispatch(ScopeOverviewActions.submitComment({ scopeId: this.scopeId, comment: this.prepareComment() }))
    this.commentInput = ''
  }

  prepareComment() {
    let comment: string = JSON.parse(JSON.stringify(this.commentInput.replace(/@/g, '')))
    let mentionedMembers: User[] = []
    this.currentScope.collaborators.forEach((collaborator) => {
      if (comment.indexOf(this.getFullName(collaborator.user)) > -1) {
        mentionedMembers.push(collaborator.user)
        comment = comment.replaceAll(this.getFullName(collaborator.user), this.getMemberHtmlText(collaborator.user))
      }
    })
    return { text: comment, mentionedUsers: mentionedMembers }
  }

  // This handles the navigation of activity event links.
  navigateToActionSource(event: ActivityComment | Item) {
    switch (event.sourceType) {
      case 'SOW': {
        this.onSelectTab.emit('Scope')
        break
      }
      case 'SCOPE': {
        this.onSelectTab.emit('Scope')
        break
      }
      case 'DELIVERABLE': {
        this.router.navigateByUrl(`/scopes/scope-overview/${this.currentScope.identity.id}/deliverable/${(event as ActivityComment).source.id}`)
        break
      }
      case 'COMPONENT': {
        this.onSelectTab.emit('Scope')
        break
      }
      case 'SECTION': {
        this.onSelectTab.emit('Scope')
        break
      }
      case 'DEPARTMENT': {
        this.onSelectTab.emit('Scope')
        break
      }
      case 'ROLE': {
        this.onSelectTab.emit('Scope')
        break
      }
      case 'DELIVERABLE_TPC': {
        this.router.navigateByUrl(`/scopes/scope-overview/${this.currentScope.identity.id}/deliverable/${(event as ActivityComment).source.id}`)
        break
      }
      case 'SCOPE_TPC': {
        this.onSelectTab.emit('Scope')
        break
      }
      case 'COMPONENT_TPC': {
        this.onSelectTab.emit('Scope')
        break
      }
      default: {
        this.onSelectTab.emit('Scope')
        break
      }
    }
  }

  protected readonly trackById = trackById;
}
