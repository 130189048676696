import { EnvConfig } from './env-config';

export const environment: EnvConfig = {
  env: 'qa1',
  scopeApiUrl: 'https://coreapi.qa1.scope.expert/',
  baseUrl: 'https://qa1.scope.expert',
  baseJsUrl: '',
  production: false,
  baseHost: 'qa1.scope.expert'
};
