import { Type } from 'class-transformer';
import { CompanySummary } from '@core/model/company-summary.model';
import { SecondParty } from '@core/model/second-party.model';
import { RatecardIdentity } from '@app/features/scope-overview/model/ratecard-identity.model';
import { SecondPartyBrandRatecard } from '@app/features/scope-overview/model/second-party-brand-ratecard.model';

export class SecondPartyBrand {
  id!: number;
  @Type(() => CompanySummary) company!: CompanySummary;
  @Type(() => SecondParty) secondParty!: SecondParty;
  @Type(() => RatecardIdentity) rateCardIdentities!: RatecardIdentity[];
  @Type(() => SecondPartyBrandRatecard) ratecardAssociations!: SecondPartyBrandRatecard[];
}
