<div id='gantt' class="h-full"></div>
<button #dateEditorTrigger style='position: absolute; visibility: hidden' [matMenuTriggerFor]="dateEditor">btn</button>
<mat-menu #dateEditor id='dateEditor' (closed)='selectedTask = null'>
  <ng-container *ngIf='selectedTask'>
    <div class="flex flex-row">
      <scope-ui-input
        class="flex-1 p-4"
        [inputClass]="'date-input'"
        [inputLabel]="'Start Date'"
        [initialValue]="(selectedTask!.startDate || selectedTask!.minDate) | dateFormat: 'shortDate'"
        [dateFormat]="dateFormat"
        [type]="'text'"
        [isDate]="true"
        [minimumDate]="selectedTask!.minDate"
        [maximumDate]="selectedTask!.maxDate"
        (click)="$event.stopPropagation()"
        (onInputChange)="onSelectStartDate($event)"
        aria-label="Start Date"/>
      <scope-ui-input
        class="flex-1 p-4"
        [inputClass]="'date-input'"
        [inputLabel]="'End Date'"
        [initialValue]="(selectedTask!.endDate || selectedTask!.maxDate) | dateFormat: 'shortDate'"
        [dateFormat]="dateFormat"
        [type]="'text'"
        [isDate]="true"
        [minimumDate]="selectedTask!.startDate"
        [maximumDate]="selectedTask!.maxDate"
        (click)="$event.stopPropagation()"
        (onInputChange)="onSelectEndDate($event)"
        aria-label="End Date"/>
    </div>
    <scope-ui-datepicker
      class="timeline-datepicker"
      [initialValue]="{startDate: selectedTask!.startDate ||
        selectedTask!.minDate, endDate: selectedTask!.endDate || selectedTask!.maxDate}"
      [minDate]='selectedTask!.minDate'
      [maxDate]='selectedTask!.maxDate'
      [minDateClass]='selectedTask!.minDateClass'
      [maxDateClass]='selectedTask!.maxDateClass'
      (onSelect)="updateDate(selectedTask, $event.startDate, $event.endDate)">
    </scope-ui-datepicker>
  </ng-container>
</mat-menu>
