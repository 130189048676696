import { ChangeDetectorRef, Component, Input } from '@angular/core'
import { ScopeVersion } from '@core/model/scope-version';
import { trackById, untilDestroyed } from '@shared/utils/utils';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
import {
  BreakdownSummary,
  DepartmentSummary,
  RoleSummary,
} from '@app/features/scope-overview/model/breakdown-summary.model';
import { Role } from '@app/features/scoping/models/role.model';
import { Store } from '@ngrx/store'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { KeyValuePipe, NgClass, NgForOf, NgIf } from '@angular/common'
import { DirectivesModule } from '@shared/directives/index.module'

@Component({
  selector: 'app-scope-breakdown',
  templateUrl: './scope-breakdown.component.html',
  styleUrls: ['./scope-breakdown.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    NgClass,
    KeyValuePipe,
    NgForOf,
    NgIf,
    DirectivesModule,
  ],
})
export class ScopeBreakdownComponent {
  private readonly destroy$;

  @Input() currentScope!: ScopeVersion;
  @Input() currentRatecard!: RatecardVersion;

  selectedDeliverableId: number | string = 0;
  scopeIdentityId!: number;
  deliverables!: Deliverable[];
  breakdownSummaryList!: BreakdownSummary[];
  loadingSummary: boolean = true
  componentKeyPair!: any
  filterOpened: boolean = false

  constructor(private scopeService: ScopeOverviewService, private store: Store, private cdr: ChangeDetectorRef) {
    this.destroy$ = untilDestroyed();
  }

  ngOnInit() {
    this.deliverables = this.currentScope.deliverables;
    this.scopeIdentityId = this.currentScope.identity.id;
    this.loadScopeBreakdownSummary();
    this.loadComponentKeyPair();
  }

  sortedData(): BreakdownSummary[] {
    if (this.breakdownSummaryList) {
      return this.breakdownSummaryList.sort((a: BreakdownSummary, b: BreakdownSummary) => a.name.localeCompare(b.name));
    }
    return [];
  }

  loadScopeBreakdownSummary() {
    this.scopeService.getScopeBreakDownSummary(this.scopeIdentityId)
      .pipe(this.destroy$())
      .subscribe((summary: BreakdownSummary) => {
        this.breakdownSummaryList = [summary];
        this.breakdownSummaryList.forEach(bs => this.sortDeliverableDepartments(bs));
        this.initDepLocations();
        this.loadingSummary = false
        this.cdr.detectChanges()
      });
  }

  loadAllByDeliverablesSummary() {
    this.scopeService.getDeliverablesBreakdownSummaryList(this.scopeIdentityId)
      .pipe(this.destroy$())
      .subscribe((summary: BreakdownSummary[]) => {
        this.breakdownSummaryList = summary;
        this.breakdownSummaryList.forEach(bs => this.sortDeliverableDepartments(bs));
        this.initDepLocations();
        this.loadingSummary = false
        this.cdr.detectChanges()
      }
    );
  }

  loadByComponentSummaryAll() {
    this.scopeService.getComponentBreakdownSummaryList(this.scopeIdentityId)
      .pipe(this.destroy$())
      .subscribe((summary: BreakdownSummary[]) => {
        this.breakdownSummaryList = summary;
        this.breakdownSummaryList.forEach(bs => this.sortDeliverableDepartments(bs));
        this.initDepLocations();
        this.loadingSummary = false
        this.cdr.detectChanges()
      }
    );
  }

  loadDeliverableBreakdownSummary() {
    this.scopeService.getDeliverableBreakdownSummary(this.scopeIdentityId, this.selectedDeliverableId)
      .pipe(this.destroy$())
      .subscribe((summary: BreakdownSummary) => {
        this.breakdownSummaryList = [summary];
        this.breakdownSummaryList.forEach(bs => this.sortDeliverableDepartments(bs));
        this.initDepLocations();
        this.loadingSummary = false
        this.cdr.detectChanges()
      }
    );
  }

  loadByComponentSummarySingle(compId: number | string) {
    this.scopeService.getComponentBreakdownSummaryById(this.scopeIdentityId, compId)
      .pipe(this.destroy$())
      .subscribe((summary: BreakdownSummary[]) => {
        this.breakdownSummaryList = summary;
        this.breakdownSummaryList.forEach(bs => this.sortDeliverableDepartments(bs));
        this.initDepLocations();
        this.loadingSummary = false
        this.cdr.detectChanges()
      }
    );
  }

  loadComponentKeyPair() {
    this.scopeService.getComponentKeyPair(this.scopeIdentityId)
      .pipe(this.destroy$())
      .subscribe((keyPairs) => {
        this.componentKeyPair = keyPairs;
      });
  }

  sortDeliverableDepartments(breakdownSummary: BreakdownSummary) {
    breakdownSummary.departmentSummaries.forEach((department: DepartmentSummary) => {
      department.roles.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    });
  }

  initDepLocations() {
    this.breakdownSummaryList.forEach(bs => {
      bs.departmentSummaries.forEach((department: DepartmentSummary) => {
        department.locationName = "None";
        let roles = department.roles;
        if(roles.length > 0 ){
          roles.forEach((r: any) => this.initLocationName(r, this.currentRatecard))
          if(roles.every((r: any) => r.locationName === roles[0].locationName)){
            department.locationName = roles[0].locationName
          }
        }
      })
    });
  }

  initLocationName(role: RoleSummary, ratecard: RatecardVersion) {
    if (role.ratecardLocationRole) {
      let locationCard = { ...ratecard.locationCards?.find(lc => lc.roles.filter((r: Role) => r.id === role.ratecardLocationRole.id).length > 0) };
      role.locationName = (locationCard && locationCard.rateCardLocation) ? locationCard.rateCardLocation.name : "None";
    } else {
      role.locationName = "None";
    }
  };

  getDepartmentAvailableLocations(department: DepartmentSummary, ratecard: RatecardVersion) {
    let roles = department.roles;
    if (roles.length > 0) {
      let availableLocationsFirstRole = this.getAvailableLocations(roles[0], ratecard);
      roles.every((r: RoleSummary) => JSON.stringify(this.getAvailableLocations(r, ratecard)) === JSON.stringify(availableLocationsFirstRole))
      {
        return availableLocationsFirstRole
      }
    }
    return ["None"];
  };

  getAvailableLocations(role: any, ratecard: RatecardVersion): any[] {
    let locations = ["None"]
    if (!ratecard) {
      return locations
    }
    const roleLocations: string[] = ratecard.locationCards?.filter(lc =>
        lc.roles.filter((r: Role) => r.rateCardRole.name == role.name).length > 0)
      .map(lc => lc.rateCardLocation.name) || []
    locations.push(...(roleLocations));
    return locations
  };

  toTitleCase(phrase: string) {
    return phrase
      .toLowerCase()
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  onOptionSelect(selectedDeliverableId: any) {
    this.selectedDeliverableId = selectedDeliverableId;
    this.loadingSummary = true;
    if (this.selectedDeliverableId == 0) {
      this.loadScopeBreakdownSummary();
      // $scope.showMode = 'all';
    } else if (this.selectedDeliverableId == -1) {
      this.loadAllByDeliverablesSummary();
      // $scope.showMode = 'del_summary';
    } else if(this.selectedDeliverableId.toString().indexOf('component') !== -1) {
      if(this.selectedDeliverableId == 'component_all') {
        this.loadByComponentSummaryAll();
        // $scope.showMode = 'com_summary';
      } else {
        const componentId = this.selectedDeliverableId.toString().replace('component_', '');
        this.loadByComponentSummarySingle(componentId)
        // $scope.showMode = 'com_single';
      }
    } else {
      this.loadDeliverableBreakdownSummary();
      // $scope.showMode = 'del_single';
    }
  };

  changeDepartmentLocation(department: DepartmentSummary, location: any, breakdownSummary: BreakdownSummary) {
    if (location != "None") {
      let locationCard = { ...this.currentRatecard.locationCards?.find(lc => lc.rateCardLocation.name === location) };
      this.scopeService.applyLocationToDepartment(department, locationCard.id, this.currentScope.identity.id, breakdownSummary.deliverableId, breakdownSummary.componentId).pipe(this.destroy$())
        .subscribe(resp => {
          this.onOptionSelect(this.selectedDeliverableId);
      })
    } else {
      this.scopeService.applyLocationToDepartment(department, "", this.currentScope.identity.id, breakdownSummary.deliverableId, breakdownSummary.componentId).pipe(this.destroy$())
        .subscribe(resp => {
          this.onOptionSelect(this.selectedDeliverableId);
        })
    }
  };

  secondPartyFte!: number | undefined

  getRoleFte(breakdownSummary: BreakdownSummary) {
    if (this.secondPartyFte) {
      return this.secondPartyFte
    } else {
      this.breakdownSummaryList.forEach(bs => {
        if (bs.departmentSummaries) {
          bs.departmentSummaries.forEach(ds => {
            if (ds.roles) {
              this.secondPartyFte = ds.roles.find(r => r.secondPartyFTE != null)?.secondPartyFTE
              return this.secondPartyFte;
            } else {
              return breakdownSummary?.adjustedSecondPartyFTE != null ? breakdownSummary.adjustedSecondPartyFTE : breakdownSummary.adjustedCompanyFTE
            }
          })
        }
      })
    }
    return breakdownSummary?.adjustedSecondPartyFTE != null ? breakdownSummary.adjustedSecondPartyFTE : breakdownSummary.adjustedCompanyFTE
  }


  changeRoleLocation(role: RoleSummary, location: any, breakdownSummary: BreakdownSummary) {
    if (location != "None") {
      let locationRole = this.currentRatecard.locationCards?.find(ls => ls.rateCardLocation.name === location).roles.find((r: any) => r.rateCardRole.id == role.sourceId);
      if (locationRole) {

        this.scopeService.applyLocationToRole(role.roleId, locationRole.id, this.currentScope.identity.id, breakdownSummary.deliverableId, breakdownSummary.componentId).pipe(this.destroy$())
          .subscribe(resp => {})
        role.ratecardLocationRole = locationRole;
        role.locationName = location;
        role.rate = locationRole.rateAmount;
        role.estimatedTotal = (locationRole.rateAmount * role.estimatedHours).toString();
      }
    } else {
      this.scopeService.applyLocationToRole(role.roleId, undefined, this.currentScope.identity.id, breakdownSummary.deliverableId, breakdownSummary.componentId).pipe(this.destroy$())
        .subscribe(resp => {})
      let sourceRole = this.currentRatecard.departments.flatMap(d => d.roles).find(r => role.sourceId == r.id);
      if (sourceRole) {
        role.ratecardLocationRole = null
        if (sourceRole.rate.amount) {
          role.rate = sourceRole.rate.amount.toString();
          role.estimatedTotal = (sourceRole.rate.amount * role.estimatedHours).toString()
        }
      }
    }

  };

  toggleOpen(event: any) {
    this.filterOpened = event
  }

  breakdownSummaryIdentifier(index: any, breakdownSummary: any) {
    return breakdownSummary.deliverableId || breakdownSummary.componentId
  }

  roleIdentifier(index: any, breakdownSummary: any) {
    return breakdownSummary.roleId
  }

  protected readonly location = location;
  protected readonly trackById = trackById;
}
