import {ScopePlanType, ScopePlanTypeFeature, ScopePlanTypeMap} from './enums/scope-plan-type.enum';

export class ScopePlan {
  id!: number;
  name!: string;
  type!: ScopePlanType;

  hasFeature(feature: ScopePlanTypeFeature){
    return ScopePlanTypeMap[this.type].excluded_features?.[feature] == null
  };
}
