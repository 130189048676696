<div class='team-container'>
  <div class='team-wrapper flex flex-col'>
    <div>Filter by:
      <mat-form-field class="summary-type ml-2">
        <mat-select  [ngClass]="{'open': filterOpened}" [(value)]="selectedDeliverableId" (selectionChange)="onOptionSelect(selectedDeliverableId)" (openedChange)="toggleOpen($event)">
          <mat-option [value]="0">
            All
          </mat-option>
          <mat-option [value]="-1">
            All by deliverable
          </mat-option>
          <mat-option [value]="'component_all'">
            All by component
          </mat-option>
          <mat-option *ngFor="let deliverable of deliverables; trackBy: trackById" [value]="deliverable.id" [disabled]="deliverable.deliveryStatus === 'TRADED'">
            Deliverable: {{ deliverable.name }}
          </mat-option>
          <mat-option *ngFor="let componentPair of componentKeyPair | keyvalue" [value]="'component_' + componentPair.key">
            Component: {{ componentPair.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex flex-col" [hidden]="!loadingSummary">
      <div class="skeleton-container" *ngFor=" let x of [].constructor(2)">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title skeleton"></h3>
            <p class="card-intro skeleton"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col mb-3" *ngFor="let breakdownSummary of sortedData(); trackBy: breakdownSummaryIdentifier" [hidden]="loadingSummary">
      <div *ngIf="selectedDeliverableId != '0'" class='breakdown-deliverable-name'>
        <div class="deliverable-name-content font-newJuneBold">
          <div class="line-orange"></div>
          <span>{{ breakdownSummary.name }}</span>
          <div class="line-black"></div>
        </div>
      </div>
      <table class="all-table">
        <tr>
          <th colspan="2" class="text-left pl-2">
            FTE={{getRoleFte(breakdownSummary)}}
          </th>
          <th colspan="4">ESTIMATED RESOURCES PLAN</th>
        </tr>
        <tr>
          <th class="text-left">Department / Role</th>
          <th>Location</th>
          <th>Rate</th>
          <th>FTE (%)</th>
          <th>Hours</th>
          <th>Total</th>
        </tr>
        <tbody>
          <tr class="dep-role-row empty" *ngIf="breakdownSummary?.departmentSummaries?.length === 0">
            <td><span class="font-newJuneBold">No Department/Role added</span></td>
            <td *ngFor=" let x of [].constructor(5)"></td>
          </tr>
        </tbody>

        <tbody *ngFor="let dep of breakdownSummary?.departmentSummaries; trackBy: trackById">
          <tr class="dep-row-header">
            <td>{{dep.name}}</td>
            <td>
              <mat-select class="location-type" [(value)]="dep.locationName" (valueChange)="changeDepartmentLocation(dep, dep.locationName, breakdownSummary)">
                <mat-option *ngFor="let locationCard of getDepartmentAvailableLocations(dep, currentRatecard)" [value]="locationCard">
                  {{locationCard}}
                </mat-option>
              </mat-select>
            </td>
            <td>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr class="dep-role-row" *ngFor="let role of dep?.roles; trackBy: roleIdentifier">
            <td>{{role.name}}</td>
            <td>
              <mat-select class="location-type" [(value)]="role.locationName" (valueChange)="changeRoleLocation(role, role.locationName, breakdownSummary)">
                <mat-option *ngFor="let locationCard of getAvailableLocations(role, currentRatecard)" [value]="locationCard">
                  {{locationCard}}
                </mat-option>
              </mat-select>
            </td>
            <td>{{role?.rate}}</td>
            <td>{{role?.estimatedFte}}</td>
            <td>{{role?.estimatedHours}}</td>
            <td>{{role?.estimatedTotal}}</td>
          </tr>
          <tr class="dep-role-row totals" style="" *ngIf="breakdownSummary.agencyEstimatedTotal != null &&
          (breakdownSummary.departmentSummaries.indexOf(dep) === breakdownSummary.departmentSummaries.length - 1)">
            <td class="font-newJuneBold">TOTAL RESOURCES REQUIRED</td>
            <td></td>
            <td></td>
            <td></td>
            <td class="font-newJuneBold">{{breakdownSummary.agencyEstimatedHours}}</td>
            <td class="font-newJuneBold">{{breakdownSummary.agencyEstimatedTotal}}</td>
          </tr>
        </tbody>
      </table>


      <table class="tpc-table mt-3">
        <tr>
          <th class="text-left pl-2">
            THIRD PARTY COST
          </th>
          <th>Amount</th>
          <th>Unit</th>
          <th>Quantity</th>
          <th>Unit Type</th>
          <th>Unit Cost</th>
          <th>Overtime<br>Quantity</th>
          <th>Overtime Unit<br>Type</th>
          <th>Overtime Rate</th>
          <th>Overtime Total</th>
          <th>MarkUp (%)</th>
          <th><span>Total({{breakdownSummary?.currency}})</span></th>
        </tr>
        <tr class="dep-role-row" *ngFor="let costSummary of breakdownSummary?.partyCostSummaries">
          <td>{{costSummary.name}}</td>
          <td>{{costSummary.estimatedQuantity}}</td>
          <td>{{costSummary.subjectName}}</td>
          <td>{{costSummary.unitTypeQuantity}}</td>
          <td>{{costSummary.unitType}}</td>
          <td>{{costSummary.unitCost}}</td>
          <td>{{costSummary.overtimeUnitQuantity}}</td>
          <td>{{costSummary.overtimeUnitType ? toTitleCase(costSummary.overtimeUnitType) : '-'}}</td>
          <td>{{costSummary.overtimeRate}}</td>
          <td>{{costSummary.overtimeTotal}}</td>
          <td>{{costSummary.estimatedMarkup}}</td>
          <td><span>{{costSummary.estimatedTotal}}</span></td>
        </tr>
        <tr class="dep-role-row empty" *ngIf="breakdownSummary?.partyCostSummaries?.length === 0">
          <td><span class="font-newJuneBold">No Third Party Costs added</span></td>
          <td *ngFor=" let x of [].constructor(11)"></td>
        </tr>
        <tr class="dep-role-row" *ngIf="breakdownSummary?.partyCostSummaries?.length !== 0">
          <td><span class="font-newJuneBold">TOTAL THIRD PARTY COST</span></td>
          <td *ngFor=" let x of [].constructor(10)"></td>
          <td><span class="font-newJuneBold"> {{breakdownSummary.thirdPartyEstimatedTotal}}</span></td>
        </tr>
      </table>
    </div>
  </div>
</div>
