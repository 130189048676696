import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterState } from '@angular/router';

declare global {
  interface Window {
    gtag: any;
  }
}
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private gtag: any;

  constructor(private titleService: Title, private router: Router) {
    this.gtag = window.gtag || function () {};
  }

  handleRouteEvents() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
        this.event('page_view', {
          page_title: title,
          page_path: event.urlAfterRedirects,
          page_location: document.location.href,
        });
      }
    });
  }

  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }

  event(eventName: string, params?: { [key: string]: any }): void {
    this.gtag('event', eventName, params);
  }
}
