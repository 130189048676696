import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'scope-ui-go-back-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './scope-ui-go-back-breadcrumb.component.html',
  styleUrls: ['./scope-ui-go-back-breadcrumb.component.scss'],
})
export class ScopeUiGoBackBreadcrumbComponent {
  @Input() goBackToMessage!: string;
  @Input() goBackRouteLink!: string;
}
