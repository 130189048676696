import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@core/service/auth.service';

/**
 * The attempt to navigate to login after user is authenticated.
 */

export const isLoggedInGuard: ResolveFn<boolean | UrlTree> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  let isAuthenticated = authService.isAuthenticated()

  if (!isAuthenticated && !route.routeConfig.data?.disabledAuthorizationCheck) {
    return router.navigate(['/login']);
  } else if (isAuthenticated && route.routeConfig.data?.disabledAuthorizationCheck && route.routeConfig.data?.redirectToScopes) {
    return router.navigate(['/scopes']);
  }
  return true;
};
