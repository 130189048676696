import { Type } from "class-transformer";
import { CompanyType } from "./enums/company-type.enum";
import { IdpDiscovery } from "./definitions/idp-discovery.interface";


export class CompanySummary {
  id!: number;
  name!: string;
  logoUri!: string;
  captchaEnabled!: boolean;
  useSsoLoginOnly!: boolean;
  companyType!: CompanyType;
  samlDiscovery!: IdpDiscovery;
  @Type(() => CompanySummary) parentCompany?: CompanySummary;
}
