import {Exclude, Type} from "class-transformer";

export class Page<T> {
  @Type(options => {
    return (options!.newObject as Page<T>).type;
  }) content!: T[];
  first!: boolean;
  last!: boolean;
  number!: number;
  numberOfElements!: number;
  size!: number;
  totalElements!: number;
  totalPages!: number;

  @Exclude()
  private type: Function;

  constructor(type: Function) {
    this.type = type;
  }
}
