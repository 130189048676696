import { Component } from '@angular/core';
import { BootstrapConfig } from '@core/model/bootstrap-config.model';
import { CoreState } from '@core/store';
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent {
  bootstrapConfig$: Observable<BootstrapConfig | null>;

  constructor(private store: Store<CoreState>) {
    this.bootstrapConfig$ = this.store.pipe(
      select(BootstrapSelectors.selectBootstrapConfig)
    );
  }

  getClass(config: any) {
    return config?.getCompanyTypeString()?.toLowerCase();
  }
}

