import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import {MenuOptions} from "@core/model/definitions/menu-options.interface";

@Component({
  selector: 'scope-ui-options-menu',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './scope-ui-options-menu.component.html',
  styleUrls: ['./scope-ui-options-menu.component.scss'],
})
export class ScopeUiOptionsMenuComponent {
  @Input() menuOptions!: MenuOptions[];

  @Input() buttonText?: string;

  @Input() toggleButton?: boolean;

  @Input() element?: any;
}
