export class NewTask {
  description: string
  endDate: Date
  identificationType: string
    "TASK"
  name: string
  sourceType: string
    "NONE"
  startDate: Date
  quantity: number
}
