import { Type } from 'class-transformer';
import { CompanySummary } from '@core/model/company-summary.model';
import { SecondParty } from '@core/model/second-party.model';

export class SecondPartyLocation {
  id!: number
  location!: string
  @Type(() => CompanySummary) company!: CompanySummary;
  @Type(() => SecondParty) secondParty!: SecondParty;
  @Type(() => Date) deletedTs!: Date;
}
