<div class="container">
  <h2 class="welcome py-5 text-lg text-orange-500">Welcome to Scope UI Demo</h2>
  <div class="flex gap-10">
    <div>
      <ul>
        <li>
          <h2><a [routerLink]="['/ui-demo/breadcrumbs']">Breadcrumbs</a></h2>
          <h2><a [routerLink]="['/ui-demo/inputs']">Inputs</a></h2>
          <h2><a [routerLink]="['/ui-demo/cards']">Cards</a></h2>
        </li>
      </ul>
    </div>
  </div>

  <p>Page Breadcrumbs</p>
  <scope-ui-breadcrumbs [isParentBreadcrumb]="true" />

  <p>Scope Breadcrumbs</p>
  <scope-ui-breadcrumbs [isScopeBreadcrumb]="true" class="breadcrumb-scope-container" />
  <router-outlet></router-outlet>
</div>
