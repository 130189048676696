import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScopeVersion } from '@core/model/scope-version';
import { User } from '@app/core/model/user.model';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ScopeUiTableComponent } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { NgxMaskDirective } from 'ngx-mask'
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service'
import {
  DeliverableRowComponent
} from '@app/features/scope-overview/components/scope-tab/deliverable-row/deliverable-row.component'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { ToggleListComponent } from '@shared/components/ui-components/toggle-list/toggle-list.component'
import {
  SectionRowComponent
} from '@app/features/scope-overview/components/scope-tab/section-row/section-row.component'
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model';
import { Department } from '@app/features/scoping/models/department.model';
import { cloneDeep } from 'lodash';
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service';
import { Preference } from '@core/model/user-preferences.interface';
import { Store } from '@ngrx/store';
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action';
import { LanguageService } from '@core/service/language.service';
import { Role } from '@app/features/scoping/models/role.model';
import { Money } from '@app/features/scope-overview/model/money.model';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
import { formatHours, untilDestroyed } from '@shared/utils/utils';
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service';
import { Sort } from '@angular/material/sort';
import { plainToInstance } from 'class-transformer';
import { Privilege } from '@core/model/enums/privilege.enum';

@Component({
  selector: 'scope-by-role-table',
  imports: [CommonModule, SharedModule, ScopeUiTableComponent, NgxMaskDirective, DeliverableRowComponent, ScopeUiInputComponent, ToggleListComponent, SectionRowComponent],
  templateUrl: './scope-by-role-table.component.html',
  styleUrls: ['./scope-by-role-table.component.scss'],
  standalone: true,
})
export class ScopeByRoleTableComponent {
  private readonly destroy$;

  @Input() currentUser!: User;
  @Input() currentRatecard!: RatecardVersion;

  @Input() set scopeVersion(scope: ScopeVersion) {
    if (scope) {
      this.currentScope = scope
      if (scope.deliverables.length > 0) {
        if (!scope.deliverables[0]?.componentsInitialised) {
          this.store.dispatch(ScopeOverviewActions.getAllTasks({ scopeId: scope.identity.id }))
        } else if (scope.deliverables[0].components[0]?.departments) {
          this.mappedDepartmentsDataSource = this.mappingService.transformArray(
            cloneDeep(scope.deliverables[0]?.components[0]?.departments)
              .sort((a, b) => a.source.id - b.source.id),
            this.departmentColumns, 'roles')
        }
    }
    }
  }

  @Input() tableExpanded: boolean
  @Output() tableExpandedChange = new EventEmitter<boolean>()

  @Input() departmentSort: Sort
  @Output() departmentSortChange = new EventEmitter<Sort>()

  currentScope: ScopeVersion
  mappedDepartmentsDataSource!: MappedEntity<Department>[]
  departmentColumns: Preference[]

  constructor(private scopeOverviewService: ScopeOverviewService,
              private store: Store,
              private mappingService: DataMappingService,
              private lang: LanguageService,
              public scopeTabService: ScopeTabService,
              private scopeApprovalFlowService: ApprovalFlowService) {
    this.destroy$ = untilDestroyed();
    this.setColumns()
  }

  setColumns() {
    this.departmentColumns = [
      {
        key: 'NAME',
        name: `ROLES`,
        field: 'name',
        selected: true,
        noSort: true // Disabled for now due to multiple issues
        // (roles not sorting correctly, task tables not sorting consistently when sort is clicked multiple times)
        // Re-enable and fix issues in SE-5794 (Scope by Role Phase 2)
      },
      {
        key: 'LOCATION',
        name: `Location`,
        requiredSetting: 'RATECARD_LOCATION__ENABLE',
        value: (entity: Role | Department) => {
          if (entity instanceof Department) {
            return ''
          } else {
            if (entity.ratecardLocationRole && this.currentRatecard) {
              let locationCard = {...this.currentRatecard.locationCards?.find(lc =>
                  lc.roles.filter(r => r.id === entity.ratecardLocationRole.id).length > 0)
              };
              return locationCard?.rateCardLocation?.name || "None";
            } else {
              return "None";
            }
          }
        },
        isDisabled: () => {
          return !this.scopeApprovalFlowService.isScopeEditable(this.currentScope)
        },
        optionsFunction: (role: Role) => {
          if (!this.currentRatecard) return null
          return ['None'].concat(this.currentRatecard?.locationCards?.filter(lc =>
              lc.roles.filter(r => r.rateCardRole.id == role.source.id).length > 0)
            .map(lc => lc.rateCardLocation.name));
        },
        hideOptionsMenu: (entity: Role | Department) => {
          return entity instanceof Department;
        },
        optionDisplayFn: (option: any) => option,
        onChange: (event: { value: any; element: Role }) => {
          let role = event.element
          if (event.value != "None") {
            let locationRole = {...this.currentRatecard?.locationCards?.find(lc => lc.rateCardLocation.name === event.value).roles.find(r => r.rateCardRole.id == role.source.id)}
            if (locationRole) {
              this.store.dispatch(ScopeOverviewActions.applyLocationToRole({
                roleId: role.id,
                locationRoleId: locationRole.id,
                scopeId: this.currentScope.identity.id,
                sourceDepartmentId: role.departmentType.id,
                sourceRoleId: role.source.id,
                ratecardLocationRole: locationRole,
                locationName: event.value,
                rateCardRate: plainToInstance(Money, { ...role.rateCardRate, amount: locationRole.rateAmount || 0 }),
                rateCardCost: plainToInstance(Money, { ...role.rateCardCost, amount: locationRole.costAmount || 0 })
              }))
            }
          } else {
            let sourceRole = this.currentRatecard?.departments.flatMap(d => d.roles).find(r => role.source.id == r.id)
            this.store.dispatch(ScopeOverviewActions.applyLocationToRole({
              roleId: role.id,
              locationRoleId: undefined,
              scopeId: this.currentScope.identity.id,
              sourceDepartmentId: role.departmentType.id,
              sourceRoleId: role.source.id,
              ratecardLocationRole: null,
              locationName: null,
              rateCardRate: plainToInstance(Money, { ...role.rateCardRate, amount: sourceRole.rate.amount }),
              rateCardCost: plainToInstance(Money, { ...role.rateCardCost, amount: sourceRole.cost.amount })
            }))
          }
        },
        selected: true,
        noSort: true
      },
      {
        key: 'AGENCY_RATE',
        name: `Rate`,
        value: (entity: Role | Department) => {
          if (entity instanceof Department) {
            return ''
          } else {
            return entity.rateCardRate
          }
        },
        selected: true,
        noSort: true
      },
      {
        key: 'AGENCY_COST',
        name: `Cost`,
        value: (entity: Role | Department) => {
          if (entity instanceof Department) {
            return ''
          } else {
            return entity.rateCardCost
          }
        },
        selected: true,
        noSort: true,
        requiredPrivilege: Privilege.RATECARD_ROLE_COST__VIEW
      },
      {
        key: 'FTE',
        name: `Total FTE%`,
        value: (entity: Role | Department) => {
          let totalHours = 0;
          this.currentScope.deliverables.forEach(task => {
            if (task.components[0].departments) {
              let departmentId = (entity instanceof Department) ? entity.source.id : entity.departmentType.id
              let department = task.components[0].departments.find(d => d.source.id === departmentId)
              if (department) {
                if (entity instanceof Department) {
                  totalHours += department.getTotalRateCardHours() * task.quantity;
                } else {
                  let role = department.roles.find(r => r.source.id === entity.source.id)
                  if (role) {
                    totalHours += role.getRateCardHours() * task.quantity;
                  }
                }
              }
            }
          })
          if (totalHours === 0) {
            return 0;
          }
          return parseFloat((totalHours / this.currentScope.identity.getFteValue() * 100).toFixed(2));
        },
        selected: true,
        noSort: true
      },
      {
        key: 'HOURS',
        name: `Total Hours`,
        value: (entity: Role | Department) => {
          if (entity instanceof Department) {
            let totalHours = 0;
            this.currentScope.deliverables.forEach(task => {
              if (task.components[0].departments) {
                let dd = task.components[0].departments.find(d => d.source.id === entity.source.id)
                if (dd) {
                  totalHours += dd.getTotalRateCardHours() * task.quantity;
                }
              }
            })
            return formatHours(totalHours);
          } else {
            let totalRoleHours = 0;
            this.currentScope.deliverables.forEach(task => {
              if (task.components[0].departments) {
                let dd = task.components[0].departments.find(d => d.source.id === entity.departmentType.id)
                if (dd) {
                  let rr = dd.roles.find(r => r.source.id === entity.source.id)
                  if (rr) {
                    totalRoleHours += rr.getRateCardHours() * task.quantity;
                  }
                }
              }
            })
            return formatHours(totalRoleHours);
          }
        },
        selected: true,
        noSort: true
      },
      {
        key: 'AGENCY_PRICE',
        name: `${this.lang.get('agency')} Price`,
        value: (entity: Role | Department) => {
          if (entity instanceof Department) {
            let totalPrice = new Money(0, entity.currencyUnit);
            this.currentScope.deliverables.forEach(task => {
              if (task.components[0].departments) {
                let dd = task.components[0].departments.find(d => d.source.id === entity.source.id)
                if (dd) {
                  totalPrice = totalPrice.add(dd.getTotalSellingPrice().multiply(task.quantity))
                }
              }
            })
            return totalPrice;
          } else {
            let totalPrice = new Money(0, entity.rateCardRate.currency);
            this.currentScope.deliverables.forEach(task => {
              if (task.components[0].departments) {
                let dd = task.components[0].departments.find(d => d.source.id === entity.departmentType.id)
                if (dd) {
                  let rr = dd.roles.find(r => r.source.id === entity.source.id)
                  if (rr) {
                    totalPrice = totalPrice.add(rr.getUserSellingPrice().multiply(task.quantity))
                  }
                }
              }
            })
            return totalPrice;
          }
        },
        selected: true,
        noSort: true
      }
    ]
  }
}
