import { Type } from 'class-transformer';
import { Money } from '@app/features/scope-overview/model/money.model';
import { Company } from '@core/model/company.model';
import { ThirdPartyCostFormula } from './third-party-cost-formula.model';
import { UserComment } from '@core/model/comment.model'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'

export enum OvertimeUnitType {
  DAY = 'DAY',
  HOUR = 'HOUR',
  FLAT_FEE = 'FLAT_FEE'
}

enum ConvertBy {
  VALUE,
  SCOPE_FTE
}

export class ThirdPartyCostUnitType {
  id!: number
  name!: string
  @Type(() => Date) deletedTs!: Date
  @Type(() => Company) company!: Company
}

export class ScopeThirdPartyCostUnitTypeConverter {
  fromUnitType!: number
  toUnitType!: number
  value!: number
  name!: string
  convert!: ConvertBy
}

export class ThirdPartyCostFormulaDefinitionDetail {
  name!: string
  subjectName!: string
  @Type(() => ThirdPartyCostUnitType) unitTypes!: ThirdPartyCostUnitType[]
  @Type(() => ScopeThirdPartyCostUnitTypeConverter) unitTypeConvertors!: ScopeThirdPartyCostUnitTypeConverter[]
  includeAddAmount!: boolean
}


export class ThirdPartyCost {
  id!: number
  type!: {
    id: number,
    name: string
  }
  name!: string
  description!: string
  @Type(() => Money) cost!: Money
  quantity!: number
  markupPercentageDecimal!: number
  sourceType!: string
  @Type(() => UserComment) comments!: UserComment[]
  version!: number
  @Type(() => Date) createdTs!: Date
  @Type(() => ThirdPartyCostFormula) formula!: ThirdPartyCostFormula
  order!: number
  newSellingPrice!: number
  deliverable?: Deliverable

  setMarkUpFromSellingPrice(sellingPriceAmount: number) {
    if (!this.cost || !this.cost.amount || !this.quantity)
      return;

    let markup = this.markupValue(this.markupPercentageDecimal);
    if (this.isValidCost(this.cost)) {
      let formulaQuantity = this.formulaQuantityValue(this.formula);
      let addAmount = this.formulaAddAmountValue(this.formula);
      let costAmount = this.computeCost(formulaQuantity, this.quantity, this.cost.amount, addAmount);
      let computedMarkupPercentage = Math.abs(this.computeMarkupValue(sellingPriceAmount, costAmount));

      if (markup != computedMarkupPercentage)
        this.markupPercentageDecimal = (computedMarkupPercentage * 100);
    }
  }

  markupValue(markup: number): number {
    return markup >= 0 ? markup / 100 : 0;
  }

  isValidCost(cost: Money): boolean {
    return (cost?.amount || 0) >= 0;
  }

  formulaQuantityValue(formula: ThirdPartyCostFormula): number {
    return (formula && formula.unitTypeQuantity != null) ? formula.unitTypeQuantity : 1;
  }

  formulaAddAmountValue(formula: ThirdPartyCostFormula): number {
    return (formula && formula.overtimeRate && formula.quantity !== 0) ? formula.overtimeRate * formula.overtimeUnitQuantity : 0;
  }

  computeCost(formulaQuantity: number, quantity: number, costAmount: number, addAmount: number): number {
    let cost = formulaQuantity * quantity * costAmount;
    cost = cost !== 0 ? cost + addAmount : cost;
    return cost;
  };

  computeMarkupValue(sellingPriceAmount: number, costAmount: number) {
    return (sellingPriceAmount - costAmount) / costAmount;
  }

  calculateCostPrice(): Money {
    let cost = this.cost.multiply(this.quantity);
    if (this.formula && this.formula.unitTypeQuantity) {
      cost = cost.multiply(this.formula.unitTypeQuantity)
      if (this.formula.overtimeRate && this.formula.quantity !== 0) {
        cost = cost.addValue(this.formula.overtimeRate * this.formula.overtimeUnitQuantity);
      }
    }
    return cost;
  }

  getProfit(): Money {
    return new Money((this.calculateSellingPrice().amount || 0) - (this.calculateCostPrice().amount || 0), this.cost.getCurrencyCode())
  }

  calculateSellingPrice(): Money {
    var markup = this.markupValue(this.markupPercentageDecimal) + 1;
    if (this.isValidCost(this.cost)) {
      let formulaQuantity = this.formulaQuantityValue(this.formula);
      let addAmount = this.formulaAddAmountValue(this.formula);
      var val = markup * this.computeCost(formulaQuantity, this.quantity, (this.cost.amount || 0), addAmount);
      return new Money(Math.round(val * 100) / 100, this.cost.getCurrencyCode())
    }

    return new Money(0, this.cost.getCurrencyCode());
  };

  getCommentsCount(): number {
    return this.comments.length;
  }

  isNewSellingZeroMarkup(): boolean {
    const newSellingPrice = this.newSellingPrice;
    return newSellingPrice == null || newSellingPrice < ((this.cost.getValue() || 0) * this.quantity)
  }
}
