<div align="center" >
    <div class="modal-box preloader-main" [class.overlay]="overlay">

        <div class="loading">
			<img src="https://s3-eu-west-1.amazonaws.com/scopetrackermediastatic/main_load_spinner.gif" alt="">
        </div>

    </div>
</div>

