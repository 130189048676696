import { Type } from 'class-transformer';
import { OvertimeUnitType } from '@app/features/scoping/models/third-party-cost.model';
import { Money } from '@app/features/scope-overview/model/money.model';


export class DepartmentSummary {
  id!: number
  name!: string
  @Type(() => RoleSummary) roles!: RoleSummary[]
  order!: number
  unmappedDepartment!: boolean
  locationName!: string
}

export class RoleSummary {
  name!: string
  rate!: string
  estimatedFte!: string
  estimatedTotal!: string
  currencyUnit!: string

  estimatedHours!: number
  order!: number
  companyFTE!: number
  scopeFTE!: number
  secondPartyFTE!: number
  sourceId!: number
  roleId!: number
  ratecardLocationRole!: any
  @Type(() => Money) rateAsMoney!: Money
  @Type(() => Money) estimatedTotalAsMoney!: Money
  locationName!: string
}

export class ThirdPartyCostSummary {
  name!: string
  unitCost!: string
  estimatedMarkup!: string
  estimatedTotal!: string
  estimatedQuantity!: number
  unitTypeQuantity!: number

  actualMarkup!: number
  actualQuantity!: number
  actualTotal!: string
  subjectName!: string
  unitType!: string
  overtimeRate!: number
  overtimeUnitType!: OvertimeUnitType
  overtimeUnitQuantity!: number
  overtimeTotal!: number
}


export class BreakdownSummary {
  name!: string
  agencyEstimatedHours!: number
  deliverableId!: number
  componentId!: number
  agencyEstimatedTotal!: string
  thirdPartyEstimatedTotal!: string
  feeEstimate!: string
  thirdPartyCostExpenses!: string
  discount!: string
  agencyFeeEstimateProposed!: string
  currency!: string
  defaultCompanyFTE!: number
  defaultSecondPartyFTE!: number
  adjustedCompanyFTE!: number
  adjustedSecondPartyFTE!: number
  @Type(() => Date) startDate!: Date
  @Type(() => Date) endDate!: Date
  @Type(() => DepartmentSummary) departmentSummaries!: DepartmentSummary[]
  @Type(() => ThirdPartyCostSummary) partyCostSummaries!: ThirdPartyCostSummary[]
}
