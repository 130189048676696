import { Directive, ElementRef, HostListener } from '@angular/core'

@Directive({
  selector: '[isEllipsisActive]'
})
export class IsEllipsisActiveDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if (element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight){
        element.title = element.innerText;
      }
    }, 500);
  }
}
