import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionButton } from '@app/core/model/definitions/action-button.interface';
import { ScopeUiDropdownComponent } from '../scope-ui-dropdown/scope-ui-dropdown.component';
import { ScopeUiInputComponent } from '../scope-ui-input/scope-ui-input.component';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'scope-ui-card-master',
  standalone: true,
  imports: [CommonModule, SharedModule, ScopeUiDropdownComponent, ScopeUiInputComponent],
  templateUrl: './scope-ui-card-master.component.html',
  styleUrls: ['./scope-ui-card-master.component.scss'],
})
export class ScopeUiCardMasterComponent {
  @Input() actionButtons: ActionButton[] = [];

  @Input() userProfilePicUrl!: string;

  @Input() statusIcon!: string;

  @Input() statusIconClass: string | string[] = '';

  @Input() status!: string;

  @Input() cardTitle!: string;

  @Input() subTitle!: string;

  @Input() metaData!: string;

  @Input() inputLabel!: string;

  @Input() inputPlaceholder!: string;

  @Input() customSelectIcon!: string;

  @Input() controlLabel!: string;

  @Input() hasSubtitle: boolean;

  @Input() hasMetadata: boolean;

  @Input() hasDropDown: boolean;

  @Input() hasInputField: boolean;

  @Input() hasIcon: boolean;

  @Input() hasStatus: boolean;

  @Input() hasButtons: boolean;

  @Input() hasProfilePic: boolean;

  @Input() hasPlaceholder: boolean;

  @Input() isStandardDropdown!: boolean;

  @Input() isBold!: boolean;

  @Input() onClick!: boolean;

  @Output() buttonClicked;

  @Output() onSelectionChange;

  @Output() onInputChange;

  dropdownOptions: any;

  constructor() {
    this.buttonClicked = new EventEmitter<string>();

    this.onSelectionChange = new EventEmitter<any>();

    this.onInputChange = new EventEmitter<any>();

    this.hasDropDown = false;

    this.hasInputField = false;

    this.hasIcon = false;

    this.hasStatus = false;

    this.hasProfilePic = false;

    this.hasSubtitle = false;

    this.hasMetadata = false;

    this.hasButtons = false;

    this.hasPlaceholder = false;

    this.isBold = false;

    this.dropdownOptions = [
      {
        name: 'ACME Rates',
        id: 5627,
      },
      {
        name: 'tracey UK',
        id: 5497,
      },
      {
        name: 'Cheil',
        id: 535,
      },
      {
        name: 'tracey and jason',
        id: 5498,
      },
      {
        name: 'Task Type Rates',
        id: 5499,
      },
      {
        name: 'ScopeMark Ratecard - GBP',
        id: 5500,
      },
      {
        name: 'British Gas',
        id: 5501,
      },
      {
        name: 'Test',
        id: 5829,
      },
      {
        name: 'Oliver RC',
        id: 5503,
      },
      {
        name: 'tracey UK 2',
        id: 5504,
      },
      {
        name: 'T1',
        id: 5505,
      },
      {
        name: 'france',
        id: 5506,
      },
      {
        name: 'Jason',
        id: 5936,
      },
      {
        name: 'Lara Test',
        id: 5415,
      },
      {
        name: 'test2',
        id: 5593,
      },
      {
        name: 'Test 4773',
        id: 5805,
      },
      {
        name: 'Arqit UK 300 roles',
        id: 5831,
      },
    ];
  }

  handleButtonClick(btn: ActionButton): void {
    if (btn.callback) {
      const dataToSend = {
        /*... whatever data you want to send ...*/
      };
      btn.callback(dataToSend);
    } else if (btn.eventId) {
      this.buttonClicked.emit(btn.eventId);
    }
  }

  onSelection(value: any) {
    this.onSelectionChange.emit(value);
  }

  onInput(value: any) {
    this.onInputChange.emit(value);
  }
}
