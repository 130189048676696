import { Directive, ElementRef, Input } from '@angular/core';
import { AuthService } from '@core/service/auth.service'

@Directive({
  selector: '[has-setting]',
})
export class HasSettingDirective {
  @Input('has-setting') hasSetting!: string;
  @Input('has-setting-disabled-mask') hasSettingDisabledMask?: string;

  ngOnInit() {
    if (!this.hasSetting){
      throw new Error("Setting must be defined!");
    }

    if (this.hasSetting == "SKIP_RETURN_TRUE"){
      return;
    }

    let settings
    let andOperator = false
    let keepElement = false

    if (this.hasSetting.includes('&&')) {
      settings = this.hasSetting.split("&&");
      andOperator = true;
      keepElement = true;
    } else {
      settings = this.hasSetting.split("||");
    }

    settings.forEach((setting) => {
      setting = setting.trim();
      keepElement = andOperator ?
        keepElement && this.authService.loggedInUser?.company.hasApplicationSetting(setting) :
        keepElement || this.authService.loggedInUser?.company.hasApplicationSetting(setting);
    });
    if (!keepElement) {
      if (this.hasSettingDisabledMask){
        this.el.nativeElement.innerText = this.hasSettingDisabledMask
      } else {
        // Add hidden class as native element isn't always in the dom at this point
        this.el.nativeElement.classList.add("hidden")
        this.el.nativeElement.remove()
      }
    }
  }

  constructor(private el: ElementRef, private authService: AuthService) { }
}
