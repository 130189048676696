import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { untilDestroyed } from '@app/shared/utils/utils'
import { environment } from '@envs/environment'
import { Observable, catchError, map, throwError, tap } from 'rxjs'
import { ModalConfig } from '@app/core/model/modal-config.model'
import { ScopeUiModalComponent } from '@app/shared/components/ui-components/scope-ui-modal/scope-ui-modal.component'
import { ScopeVersion } from '@app/core/model/scope-version'
import { User } from '@app/core/model/user.model'
import { ApprovalFlowService } from './approval-flow.service'
import { ScopeOverviewService } from './scope-overview.service'
import { SecondParty } from '@app/core/model/second-party.model'
import { DocuSignSigner } from '../components/export-docusign/export-docusign.component'
import {
  SnackbarEventType,
  SNACKBAR_LENGTH_LONG,
  SnackbarService,
  SNACKBAR_LENGTH_SHORT,
} from '@shared/utils/snackbar.service'
import { FolderVersion } from '@core/model/folder-version.model'

@Injectable({
  providedIn: 'root',
})
export class ExportDocumentService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl

  private readonly destroy$

  public hasCustomXlsxTemplate: boolean

  public secondParty: SecondParty

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private approvalFlowService: ApprovalFlowService,
    private snackbarService: SnackbarService,
    private scopeOverviewService: ScopeOverviewService
  ) {
    this.destroy$ = untilDestroyed()
  }

  private closeExportDialog() {
    const exportCloseDialog: ModalConfig = {
      title: 'Export Scope',
      body: 'Your download will begin shortly.',
      confirmText: 'Close',
      confirmCallback: () => {
        dialog.close()
      },
      cancelCallback: undefined,
      inputs: [],
      isMemberModal: undefined,
      limitBodyWidth: true,
    }
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: exportCloseDialog,
    })
  }

  downloadFile(url: string, scopeName: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((blob: Blob) => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)

        a.href = objectUrl
        a.download = this.getFilenameFromUrl(url, scopeName) // Extract filename based on URL
        document.body.appendChild(a)
        a.click()

        URL.revokeObjectURL(objectUrl)
        document.body.removeChild(a)

        return blob
      }),
      catchError((error: any) => {
        console.error('Download error:', error)
        this.snackbarService.showSnackbar('Download error', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        return throwError(() => new Error('Failed to download file'))
      })
    )
  }

  setXlsxTemplateVisibility(element: ScopeVersion | FolderVersion) {
    this.hasCustomXlsxTemplate = element.identity.xlsxTemplate !== null && element.identity.xlsxTemplate !== undefined
  }

  makeDocuSignAvailableOutputNames(currentUser: User, currentScope: ScopeVersion) {
    let docusignOutputFiles: [{ name: string; selected: boolean }] = [{ name: 'Output Document', selected: false }]
    if (currentUser.hasPrivilege('SCOPE__EXPORT_XLSX')) {
      if (currentScope.identity.xlsxTemplate !== null && currentScope.identity.xlsxTemplate !== undefined) {
        docusignOutputFiles.push({ name: 'Output Spreadsheet', selected: false })
      }
    }
    return docusignOutputFiles
  }

  getSecondParty(id: number): Observable<SecondParty> {
    return this.scopeOverviewService.getSecondPartyByIdForScope(id).pipe(
      tap((secondParty: SecondParty) => {
        this.secondParty = secondParty
      })
    )
  }

  hasSetting(setting: string, currentUser: User) {
    return currentUser.company.hasApplicationSetting(setting)
  }

  getStatus(currentScope: ScopeVersion) {
    return this.approvalFlowService.isStateApproved(currentScope) &&
      (this.approvalFlowService.isStateAgencyApproved(currentScope.status) ||
        this.approvalFlowService.isStateClientApproval(currentScope.status))
      ? '__CLIENT_ACCEPTED'
      : currentScope.status
  }

  hasDocusignPermission(currentScope: ScopeVersion, currentUser: User): boolean {
    let status = this.getStatus(currentScope)

    var grants = {
      DRAFT: this.hasSetting('DOCUSIGN_OUTPUT_ON_DRAFT', currentUser),
      SUBMITTED: this.hasSetting('DOCUSIGN_OUTPUT_ON_REVIEW', currentUser),
      REVIEWED: this.hasSetting('DOCUSIGN_OUTPUT_ON_AGREEMENT', currentUser),
      AGENCY_APPROVED: this.hasSetting('DOCUSIGN_OUTPUT_ON_CLIENT_APPROVAL', currentUser),
      CLOSED: this.hasSetting('DOCUSIGN_OUTPUT_ON_CLOSED', currentUser),
      __CLIENT_ACCEPTED: this.hasSetting('DOCUSIGN_OUTPUT_ON_CLIENT_ACCEPTED', currentUser),
    }

    return grants[status]
  }

  exportToCSV(scopeId: number, scopeName: string) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/export/csv`
    this.downloadFile(url, scopeName)
      .pipe(
        this.destroy$(),
        tap(() => this.closeExportDialog())
      )
      .subscribe()
  }

  exportToDocusign(scopeId: number, contacts: DocuSignSigner[], outputOptions: string[]) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/docusign/export`

    this.snackbarService.showSnackbar('Sending document to DocuSign...', SNACKBAR_LENGTH_SHORT, SnackbarEventType.INFO)

    return this.http
      .post(url, { contacts, fileNames: outputOptions })
      .pipe(
        map((apiResponse) => {
          this.snackbarService.showSnackbar('Document was successfully sent to DocuSign.', SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
          return apiResponse
        }),
        catchError((error) => {
          this.snackbarService.showSnackbar('Failed to send document to DocuSign. Please check that DocuSign is correctly configured.', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
          return throwError(() => new Error(error))
        })
      )
      .pipe(this.destroy$())
      .subscribe()
  }

  exportToWordDoc(scopeId: number, scopeName: string, downloadFileName?: string) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/export/docx`

    if (downloadFileName) {
      url += `?downloadFileName=${downloadFileName}`
    }

    this.downloadFile(url, scopeName)
      .pipe(
        this.destroy$(),
        tap(() => this.closeExportDialog())
      )
      .subscribe()
  }

  exportToPdf(scopeId: number, scopeName: string) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/export/pdf`
    this.downloadFile(url, scopeName)
      .pipe(
        this.destroy$(),
        tap(() => this.closeExportDialog())
      )
      .subscribe()
  }

  exportScopeToXlsxTemplate(scopeId: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/export/xlsx-template`
    this.http.get(url)
      .pipe(
        this.destroy$(),
        tap(() => this.closeExportDialog())
      )
      .subscribe()
  }

  exportScopeToExcel(scopeId: number, scopeName: string) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/export/xlsx`
    this.downloadFile(url, scopeName)
      .pipe(
        this.destroy$(),
        tap(() => this.closeExportDialog())
      )
      .subscribe()
  }

  exportScopeToExcelSummary(scopeId: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/export/summary`
    this.http.get(url)
      .pipe(
        this.destroy$(),
        tap(() => this.closeExportDialog())
      )
      .subscribe()
  }

  exportScopeBreakdownXlsxTemplate(scopeId: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/export/scope-breakdown`
    this.http.get(url)
      .pipe(
        this.destroy$(),
        tap(() => this.closeExportDialog())
      )
      .subscribe()
  }

  exportScopeComponentToExcelSummary(scopeId: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/export/component-summary`
    this.http.get(url)
      .pipe(
        this.destroy$(),
        tap(() => this.closeExportDialog())
      )
      .subscribe()
  }

  exportScopeDepartmentsHoursXlsxTemplate(scopeId: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/export/scope-departments`
    this.http.get(url)
      .pipe(
        this.destroy$(),
        tap(() => this.closeExportDialog())
      )
      .subscribe()
  }

  private getFilenameFromUrl(url: string, scopeName: string): string {
    if (url.endsWith('/export/pdf')) {
      return `${scopeName}.pdf`
    } else if (url.endsWith('/export/docx')) {
      return `${scopeName}.docx`
    } else if (url.endsWith('/export/csv')) {
      return `${scopeName}.csv`
    } else if (
      url.endsWith('/export/xlsx') ||
      url.endsWith('/export/summary') ||
      url.endsWith('/export/scope-breakdown') ||
      url.endsWith('/export/component-summary') ||
      url.endsWith('/export/scope-departments')
    ) {
      return `${scopeName}.xlsx`
    } else {
      return 'downloadedfile'
    }
  }
}
