import { createSelector, createFeatureSelector, ActionReducerMap } from '@ngrx/store';

import * as fromAuth from './auth.reducer';
import * as fromCdn from './bootstrap.reducer';
import { getRouterSelectors, routerReducer } from '@ngrx/router-store';
import { RouterState } from '@angular/router';

export interface CoreState {
  auth: fromAuth.AuthState;
  bootstrapConfig: fromCdn.BootstrapState;
  router: RouterState;
}

export const reducers: ActionReducerMap<CoreState> = {
  auth: fromAuth.reducer,
  bootstrapConfig: fromCdn.reducer,
  router: routerReducer,
};

export const selectCoreState = createFeatureSelector<CoreState>('core');
export const selectCoreAuthState = createSelector(selectCoreState, (state: CoreState) => state.auth);
export const selectCoreBootstrapState = createSelector(selectCoreState, (state: CoreState) => state.bootstrapConfig);
export const selectRouterState = createSelector(selectCoreState, (state: CoreState) => state.router);
