import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { SharedModule } from '@app/shared/shared.module'
import { ScopeUiDatepickerComponent } from '@app/shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component'
import { ScopeUiAutocompleteComponent } from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { ScopeVersion } from '@app/core/model/scope-version'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { NgxMaskDirective } from 'ngx-mask'
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
import { ThirdPartyCost } from '@app/features/scoping/models/third-party-cost.model';
import { ThirdPartyCostFormula } from '@app/features/scoping/models/third-party-cost-formula.model';
import {
  ScopeUiDropdownComponent
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { FormControl } from '@angular/forms';
import { Money } from '@app/features/scope-overview/model/money.model';
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action';
import { Store } from '@ngrx/store';
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';

export interface AddTpcModalConfig {
  title: string
  formulas: ThirdPartyCostFormula[]
  currentScope: ScopeVersion
}

@Component({
  selector: 'add-tpc-modal',
  standalone: true,
  imports: [CommonModule, ScopeUiDatepickerComponent, ScopeUiAutocompleteComponent, SharedModule, MatDatepickerModule, CKEditorModule, ScopeUiInputComponent, NgxSliderModule, NgxMaskDirective, ScopeUiDropdownComponent],
  templateUrl: './add-tpc-modal.component.html',
  styleUrls: ['./add-tpc-modal.component.scss'],
})
export class AddTpcModalComponent implements OnInit {
  searchText: string
  editDescription: boolean = false
  newThirdPartyCost: ThirdPartyCost = new ThirdPartyCost()
  costControl = new FormControl()
  markupControl = new FormControl()
  priceControl = new FormControl()
  dropdownOptions: any[]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddTpcModalConfig,
    private scopeService: ScopeOverviewService,
    private store: Store,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
      this.dropdownOptions = [...this.data.formulas];
      this.dropdownOptions.unshift({name: 'None', id: null})
  }

  displayName(item: any): string {
    return item?.name || '';
  }

  onNameChange(event: string) {
    this.newThirdPartyCost.name = event
  }

  onCostChange(event: number) {
    if (this.costControl.getRawValue() == null) {
      this.priceControl.setValue(event);
    } else {
      let price = Number(this.costControl.getRawValue()) + Number(((this.markupControl.getRawValue() / 100) * this.costControl.getRawValue()));
      this.priceControl.setValue(Number(parseFloat((price).toString()).toFixed(2)));
    }
  }

  onMarkUpChange() {
    if (this.markupControl.getRawValue() == null) {
      this.priceControl.setValue(this.costControl.getRawValue());
    } else {
      let price = (this.costControl.getRawValue() == null) ?
        null : Number(this.costControl.getRawValue()) + Number((this.markupControl.getRawValue() / 100) * this.costControl.getRawValue())
      this.priceControl.setValue(Number(parseFloat((price).toString()).toFixed(2)));
    }
  }

  onPriceChange() {
    let sellingPrice = this.priceControl.getRawValue();
    let unitCost = this.costControl.getRawValue()
    let diff = sellingPrice - unitCost;
    this.markupControl.setValue(
    Number(parseFloat(((diff / unitCost) * 100).toString()).toFixed(2)));
  }

  onSelection(event: any) {
    if (!event.id) return
    this.newThirdPartyCost.formula = event
    this.newThirdPartyCost.formula.quantity = 1;
    this.newThirdPartyCost.formula.unitTypeQuantity = 1;
  }

  disableSubmit(): boolean {
    return !this.priceControl.getRawValue() || !this.costControl.getRawValue() || !this.markupControl.getRawValue() || !this.newThirdPartyCost.description
  }

  submit() {
    this.newThirdPartyCost.type = {
      id: 0,
      name: this.newThirdPartyCost.name
    }
    this.newThirdPartyCost.cost = Money.of(this.costControl.getRawValue(), this.data.currentScope.identity.rateCard.currencyCode)
    this.newThirdPartyCost.quantity = 1
    this.newThirdPartyCost.markupPercentageDecimal = this.markupControl.getRawValue()
    this.newThirdPartyCost.sourceType = 'SCOPE'
    this.scopeService.addScopeThirdPartyCost(this.data.currentScope.identity.id, this.newThirdPartyCost).subscribe({
      next: (resp) => {
        this.store.dispatch(ScopeOverviewActions.createScopeTpcSuccess({thirdPartyCost: resp}))
      }, error: () => this.snackbarService.showSnackbar("Error during saving Scope TPC", SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
    })
    this.dialog.closeAll()
  }
}
