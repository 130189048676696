import { ScopeReviewer } from "@app/features/scope-overview/model/scope-reviewer.model";
import {User} from "@core/model/user.model";
import {Type} from "class-transformer";
import { ScopeSubmitter } from "./scope-submitter.model";

export class ScopeTeam {
  @Type(() => ScopeTeamMember) collaborators!: ScopeTeamMember[]
  @Type(() => ScopeTeamMember) reviewers!: ScopeTeamMember[]
  @Type(() => ScopeTeamMember) approvers!: ScopeTeamMember[]
  @Type(() => ScopeTeamMember) traffickers!: ScopeTeamMember[]

  hasUser(userId: number) {
    return this.findCollaboratorByUserId(userId) || this.findReviewerByUserId(userId) || this.findApproverByUserId(userId) || this.findTraffickerByUserId(userId)
  }

  findCollaboratorByUserId(userId: number){
    for (var i = 0 ; i < this.collaborators.length ; i++) {
      if (this.collaborators[i].user.id == userId)
        return this.collaborators[i];
    }
    return null;
  }

  findReviewerByUserId(userId: number){
    for (var i = 0 ; i < this.reviewers.length ; i++) {
      if (this.reviewers[i].user.id == userId)
        return this.reviewers[i];
    }
    return null;
  }

  findApproverByUserId(userId: number){
    for (var i = 0 ; i < this.approvers.length ; i++) {
      if (this.approvers[i].user.id == userId)
        return this.approvers[i];
    }
    return null;
  }

  findTraffickerByUserId(userId: number){
    for (var i = 0 ; i < this.traffickers.length ; i++) {
      if (this.traffickers[i].user.id == userId)
        return this.traffickers[i];
    }
    return null;
  }
}

export class ScopeTeamMember {
  id!: number
  type!: string
  @Type(() => User) user!: User
  @Type(() => User) invitedBy?: User
  superVoter!: boolean
}

export interface ScopeStateMember {
  id: number;
  user: User;
  vote: string | null;
}

