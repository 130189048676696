import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { featureKey, reducers } from './store/reducers';
import { ScopingEffects } from './store/effects/scoping.effects';

const EFFECTS = [ScopingEffects];

@NgModule({
  imports: [StoreModule.forFeature(featureKey, reducers), EffectsModule.forFeature(EFFECTS)],
})
export class ScopingNgrxModule {}
