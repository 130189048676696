import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { environment } from '@envs/environment';
import { map, of } from 'rxjs';
import { CompanyType } from '@app/core/model/enums/company-type.enum';
import { enumToString } from '@shared/utils/utils';


@Injectable({ providedIn: "root" })
export class LanguageService {
  private readonly BASE_URL: string = environment.scopeApiUrl;
  private companyTypeLangData?: CompanyLang;
  private requested$?: RequestedCompanyLang

  constructor(private http: HttpClient) {
  }

  get(keys: string): string {
    let key = keys.split('|')
    if (!this.companyTypeLangData) {
      return "[?]"
    }
    let val = this.companyTypeLangData?.data[key[0]]
    if (key == null) {
      throw new Error("LANG Key: " + key[0] + " not found")
    }
    if (key.length > 1) {
      switch (key[1].trim()) {
        case 'l': return val.toLowerCase()
        case 'u': return val.toUpperCase()
      }
    }
    return val;
  }

  setLangDataByCompanyType(companyType: CompanyType): Promise<CompanyLang> {
    if (this.companyTypeLangData && this.companyTypeLangData?.type == companyType) {
      return new Promise(res => res(this.companyTypeLangData!));
    }
    if (this.requested$?.type == companyType) {
      return this.requested$.request;
    }

    this.requested$ = {
      type: companyType,
      request:
        new Promise(res => {
          this.http.get<any>(this.BASE_URL + "lang/" + enumToString(companyType, CompanyType))
            .pipe(map((lang => this.companyTypeLangData = { data: lang, type: companyType })))
            .subscribe(res)
        })
    };
    return this.requested$.request;
  }
}

export interface CompanyLang {
  type: CompanyType
  data: any
}
export interface RequestedCompanyLang {
  type: CompanyType
  request: Promise<CompanyLang>
}
