import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { GoogleAnalyticsService } from '@core/service/google-analytics.service';
import { CoreState } from '@core/store';
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector';
import { Store, select } from '@ngrx/store';
import { HeaderStyleService } from '@shared/services/header-style.service';
import { Observable } from 'rxjs';
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector'
import { NavigationEnd, Router } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  companyType$: Observable<any>;
  isScopeLoading$: Observable<boolean>;
  mainClass$ = this.headerStyleService.getMainSectionClass()
  isMyScopes: boolean = false

  constructor(
    public headerStyleService: HeaderStyleService,
    private store: Store<CoreState>,
    private googleAnalytics: GoogleAnalyticsService,
    private router: Router
  ) {
    this.companyType$ = this.store.pipe(select(BootstrapSelectors.selectCompanyType));
    this.googleAnalytics.handleRouteEvents();
    this.isScopeLoading$ = this.store.select(ScopeOverviewSelectors.selectCurrentScopeLoading);

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.isMyScopes = event.url === '/scopes';
      }
    });
  }
}
