import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@plugins/material/material.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { CompanyManagementRoutingModule } from './company-management-routing.module';
import { ManageComponent } from './components/manage/manage.component';
import { CompanyManagementNgrxModule } from './company-management-ngrx.module';
import { ScopeUiCardMasterComponent } from '@app/shared/components/ui-components/scope-ui-card-master/scope-ui-card-master.component';
import { ScopeUiAutocompleteComponent } from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component';
import {
  AccountSetupComponent
} from "@app/features/company-management/components/account-setup/account-setup.component";
import { InvitationSetupComponent } from './components/invitation-setup/invitation-setup.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    CompanyManagementRoutingModule,
    CompanyManagementNgrxModule,
    ScopeUiCardMasterComponent,
    ScopeUiAutocompleteComponent,
  ],
  declarations: [ManageComponent, AccountSetupComponent, InvitationSetupComponent],
  providers: [],
  exports: [RouterModule],
})
export class CompanyManagementModule {}
