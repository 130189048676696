<form name="counter" class="input-counter {{formClass}}" [ngClass]="{'disabled': !edit && confirm}">
  <div *ngIf="confirm && !edit" class="flex justify-center">
    {{ viewValue }}
  </div>
  <span class="counter-wrap" *ngIf="edit || !confirm">
    <input
      type="text"
      class="input-count"
      name="count"
      [disabled]="disabled"
      [maxLength]="9"
      [ngModel]="_formatDisplayValue(initialValue)"
      (ngModelChange)="onManualInput($event)"
      mask="{{disableMask ? null : (isDecimal ? 'separator.2' : '0*')}}"
      (click)='$event.stopPropagation()'/>
  </span>
</form>
<mat-icon *ngIf="confirm && !edit && !disabled" class="material-symbols-rounded component-description-icon"
          (click)="edit = true">edit</mat-icon>
<mat-icon *ngIf="confirm && edit && !disabled" class="material-symbols-rounded component-description-icon"
          (click)="edit = false; onConfirmInput()">check</mat-icon>
