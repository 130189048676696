import { Component, Inject } from '@angular/core'
import { SharedModule } from '@shared/shared.module'
import {
  ComponentsFilterValue,
  DateFilterValue,
  FilterModalConfig,
  FilterOption,
  GenericFilterValue,
  RangeFilterValue
} from '@core/model/filter-option.interface'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { MatRadioModule } from '@angular/material/radio'
import { ScopeUiDropdownComponent } from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component'
import {
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule,
} from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { ScopeUiAutocompleteComponent } from '@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { plainToInstance } from 'class-transformer'
import { isEqual } from 'lodash'
import { KeyValue } from '@angular/common'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { ChosenDate } from 'ngx-daterangepicker-material/daterangepicker.component'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { ScopeUiDatepickerComponent } from '@shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component'

@Component({
  selector: 'scope-ui-filter-modal',
  templateUrl: './scope-ui-filter-modal.component.html',
  styleUrls: ['./scope-ui-filter-modal.component.scss'],
  imports: [
    SharedModule,
    MatRadioModule,
    ScopeUiDropdownComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    ScopeUiInputComponent,
    ScopeUiAutocompleteComponent,
    MatCheckboxModule,
    ScopeUiDatepickerComponent,
  ],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
  standalone: true,
})
export class ScopeUiFilterModalComponent {
  selectedOption?: FilterOption<any>
  timeframeOptions: any[] = [
    { name: 'Today', value: 'today' },
    { name: 'Yesterday', value: 'yesterday' },
    { name: 'Last week', value: 'week' },
    { name: 'Last month', value: 'month' },
    { name: 'Custom', value: 'custom' },
  ]
  sizeOptions: any[] = [
    { name: 'All', value: undefined },
    { name: 'S', value: 0 },
    { name: 'M', value: 1 },
    { name: 'L', value: 2 },
    { name: 'XL', value: 3 },
  ]
  selectedDateRange?: ChosenDate

  constructor(@Inject(MAT_DIALOG_DATA) public data: FilterModalConfig, public dialog: MatDialog) {}

  onSeeResults() {
    if (!this.isValidValue(this.data.options['date'].value)) {
      this.data.options['date'].value = this.data.filterDefaults['date']
    }
    this.data.seeResults(this.data.options)
  }

  onReset() {
    this.data.reset()
  }

  selectOption(option: FilterOption<any>) {
    if (option === this.selectedOption) {
      this.selectedOption = undefined
    } else {
      this.selectedOption = option
    }
  }

  onSelection({ event }: { event: MatAutocompleteSelectedEvent }) {
    if (!this.selectedOption!.value) {
      this.selectedOption!.value = []
    }
    if (this.selectedOption!.value?.some((value: any) => isEqual(value, event))) {
      return
    }
    this.selectedOption!.value.push(event)
  }

  onSelectAction(event: any) {
    if (!this.selectedOption!.value) {
      this.selectedOption!.value = new DateFilterValue()
    }
    this.selectedOption!.value.action = event
  }

  onSelectTimeframe(event: any) {
    if (!this.selectedOption!.value) {
      this.selectedOption!.value = new DateFilterValue()
    }
    this.selectedOption!.value.timeframe = event
    let date = new Date()
    if (event.value === 'today') {
      const now = date.getTime()
      this.selectedOption!.value.start = new Date(now - (now % 86400000))
    } else if (event.value === 'yesterday') {
      date.setDate(date.getDate() - 1)
      const now = date.getTime()
      this.selectedOption!.value.start = new Date(now - (now % 86400000))
    } else if (event.value === 'week') {
      date.setDate(date.getDate() - 7)
      this.selectedOption!.value.start = date
    } else if (event.value === 'month') {
      date.setDate(date.getDate() - 31)
      this.selectedOption!.value.start = date
    }
  }

  protected readonly Object = Object

  onChangeMinimum(event: any) {
    if (!this.selectedOption!.value) {
      this.selectedOption!.value = new RangeFilterValue()
    }
    this.selectedOption!.value.minimum = event
  }

  onChangeMaximum(event: any) {
    if (!this.selectedOption!.value) {
      this.selectedOption!.value = new RangeFilterValue()
    }
    this.selectedOption!.value.maximum = event
  }

  onSelectComponentSize(event: any) {
    this.selectedOption!.value = plainToInstance(ComponentsFilterValue, {
      ...(this.selectedOption!.value || new ComponentsFilterValue()),
      complexity: event,
    })
  }

  onSelectComponentName({ event }: { event: MatAutocompleteSelectedEvent }) {
    if (this.selectedOption && this.selectedOption.value?.components?.some((value: any) => isEqual(value, event))) {
      return
    }
    this.selectedOption!.value = plainToInstance(ComponentsFilterValue, {
      ...(this.selectedOption!.value || new ComponentsFilterValue()),
      components: [...(this.selectedOption!.value.components || []), event],
    })
  }

  removeValue(index: number) {
    this.selectedOption!.value.splice(index, 1)
  }

  removeComponent(index: number) {
    let components = this.selectedOption!.value.components.toSpliced(index, 1)
    this.selectedOption!.value = plainToInstance(ComponentsFilterValue, {
      ...this.selectedOption!.value,
      components: [...components],
    })
  }

  protected readonly isEqual = isEqual
  unsorted(a: KeyValue<string, any>, b: KeyValue<string, any>): any {
    return a
  }

  filterCount() {
    let count = Object.entries(this.data.options).filter(
      ([key, value]) => !isEqual(value.value, this.data.filterDefaults[key])
    ).length
    return count ? ` (${count})` : ''
  }

  _onSelectedChange(date: ChosenDate): void {
    this.selectedDateRange = date
    if (this.data.options['date'].value?.timeframe?.value === 'custom') {
      let startDate = this.selectedDateRange?.startDate.toDate()
      if (startDate?.getHours() && startDate?.getHours() != 0 || startDate?.getMinutes() && startDate?.getMinutes() != 0) {
        startDate?.setMinutes(startDate?.getMinutes() + startDate?.getTimezoneOffset())
      }
      this.data.options['date'].value.start = startDate
      let endDate = this.selectedDateRange?.endDate.toDate()
      // The datepicker library selects the end of the day UTC. Offset this to fix the date changing when reopening the filter
      if (endDate?.getHours() && endDate?.getHours() != 23 || endDate?.getMinutes() && endDate?.getMinutes() != 59) {
        endDate?.setMinutes(endDate?.getMinutes() + endDate?.getTimezoneOffset())
      }
      this.data.options['date'].value.end = endDate
    }
  }

  isValidValue(value: any) {
    if (value instanceof DateFilterValue) {
      return value.action && value.timeframe && (value.timeframe.value !== 'custom' || (value.start && value.end))
    }
    return true
  }

  isValid() {
    const errors = Object.values(this.data.options).filter(opt => opt.type === 'range' && !this.validateRange(opt)).length;
    return errors <= 0;
  }

  validateRange(option: FilterOption<RangeFilterValue>) {
    let valid = this.validateMinMax(option)
    if (option.value?.maximum != undefined && option.value?.maximum.toString() != "") {
      valid = valid && (option.minimum == undefined || Number(option.value?.maximum) >= option.minimum) &&
        (option.maximum == undefined || Number(option.value?.maximum) <= option.maximum)
    }
    if (option.value?.minimum != undefined && option.value?.minimum.toString() != "") {
      valid = valid && (option.minimum == undefined || Number(option.value?.minimum) >= option.minimum) &&
        (option.maximum == undefined || Number(option.value?.minimum) <= option.maximum)
    }
    return valid
  }

  validateMinMax(option: FilterOption<RangeFilterValue>) {
    let valid = true
    if (option.value?.maximum != undefined && option.value?.minimum != undefined &&
      option.value?.maximum.toString() != "" && option.value?.minimum.toString() != "") {
      valid = Number(option.value.maximum) >= Number(option.value.minimum)
    }
    return valid
  }
}
