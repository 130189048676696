import { Action, createReducer, on } from '@ngrx/store'
import { ScopingState } from '../../models/scope.state.model'
import {
  FilterScopesPageInfoAction,
  FilterScopesSortAction,
  FilterScopesAction,
  GetMyScopesAction,
  GetMyScopesSuccessAction,
  GetMyScopesColumnsAction,
  GetMyScopesColumnsSuccessAction,
  GetScopeColumnsSuccessAction,
  FilterFoldersPageInfoAction,
  FilterFoldersSortAction,
  FilterFoldersAction,
  GetFoldersAction,
  GetFoldersSuccessAction,
  GetHighlightsAction,
  GetHighlightsSuccessAction,
  GetDeliverablesAction,
  GetDeliverablesSuccessAction,
  GetScopesByFolderAction,
  GetScopesByFolderSuccessAction,
  GetAllFoldersSuccessAction,
  GetSecondPartiesSuccessAction,
  SearchDeliverablesSuccessAction,
  SearchComponentsSuccessAction,
  GetDeliverableSummariesAction,
  GetDeliverableSummariesSuccessAction,
  GetArchivedScopesAction,
  GetArchivedScopesSuccessAction,
  FilterArchivedScopesPageInfoAction,
  FilterArchivedScopesSortAction,
  GetArchivedFoldersAction,
  GetArchivedFoldersSuccessAction,
  FilterArchivedFoldersPageInfoAction, FilterArchivedFoldersSortAction
} from '../model/scoping.action.interface';
import { ScopeActions } from '../actions/scoping.actions';
import { deliverableColumnBaseConfig, scopeColumnBaseConfig } from '../../models/scope-columns.model';
import { BootstrapActions } from '@app/core/store';
import { ScopeFilterDefaults } from "@core/model/scope-filter.model";
import { ScopeVersion } from "@core/model/scope-version";
import { Page } from "@app/features/scoping/models/page.model";
import { FolderVersion } from "@core/model/folder-version.model";
import { plainToClassFromExist, plainToInstance } from "class-transformer";
import { ScopeOverviewFilterDefaults } from "@core/model/scope-overview-filter.model";

export const initialState: ScopingState = {
  columns: scopeColumnBaseConfig,
  deliverablesByScope: {},
  scopesByFolder: [],
  deliverablesColumns: deliverableColumnBaseConfig,
  pagination: {
    totalCount: 0,
    page: 0,
  },
  paginationArchived: {
    totalCount: 0,
    page: 0,
  },
  sort: {
    active: 'LAST_EDITED',
    direction: 'desc',
  },
  sortArchived: {
    active: 'LAST_EDITED',
    direction: 'desc',
  },
  filters: ScopeFilterDefaults,
  overviewFilters: ScopeOverviewFilterDefaults,
  scopes: new Page<ScopeVersion>(ScopeVersion),
  archivedScopes: new Page<ScopeVersion>(ScopeVersion),
  folders: new Page<FolderVersion>(FolderVersion),
  archivedFolders: new Page<FolderVersion>(FolderVersion),
  allFolders: [],
  highlights: undefined,
  availableRoles: [],
  filteredRoles: [],
  availableDeliverables: [],
  availableDepartments: [],
  availableComponents: [],
  searchParams: undefined,
  selectedReplaceRole: undefined,
  selectedReplaceWithRole: undefined,
  selectedRole: undefined,
  replaceSummarySuccess: false,
  revertSummarySuccess: false,
  replacedRolesResponse: [],
  revertSummaryResponse: [],
  ratecard: undefined,
  loading: false,
  loadingArchive: false,
  loadingColumns: false,
  loaded: true,
  loadedArchive: true,
}
function  getMyScopesHandler(state: ScopingState, action: GetMyScopesAction) {
  return {
    ...state,
    loading: true,
    loaded: false,
  }
}

function getMyScopesSuccessHandler(state: ScopingState, action: GetMyScopesSuccessAction) {
  return {
    ...state,
    scopes: action.scopes,
    pagination: {
      ...state.pagination,
      totalCount: action.scopes.totalElements,
    },
    loading: false,
    loaded: true,
  }
}

function getArchivedScopesHandler(state: ScopingState, action: GetArchivedScopesAction) {
  return {
    ...state,
    loadingArchive: true,
    loadedArchive: false
  }
}

function getArchivedScopesSuccessHandler(state: ScopingState, action: GetArchivedScopesSuccessAction) {
  return {
    ...state,
    archivedScopes: action.scopes,
    paginationArchived: {
      ...state.paginationArchived,
      totalCount: action.scopes.totalElements,
    },
    loadingArchive: false,
    loadedArchive: true
  }
}

function getFoldersHandler(state: ScopingState, action: GetFoldersAction) {
  return {
    ...state,
    loading: true,
    loaded: false,
  }
}

function getFoldersSuccessHandler(state: ScopingState, action: GetFoldersSuccessAction) {
  return {
    ...state,
    folders: action.folders,
    pagination: {
      ...state.pagination,
      totalCount: action.folders.totalElements,
    },
    loading: false,
    loaded: true,
  }
}

function getFoldersFailHandler(state: ScopingState) {
  return {
    ...state,
    loading: false,
    loaded: true,
  }
}

function getArchivedFoldersHandler(state: ScopingState, action: GetArchivedFoldersAction) {
  return {
    ...state,
    loadingArchive: true,
    loadedArchive: false
  }
}

function getArchivedFoldersSuccessHandler(state: ScopingState, action: GetArchivedFoldersSuccessAction) {
  return {
    ...state,
    archivedFolders: action.folders,
    paginationArchived: {
      ...state.paginationArchived,
      totalCount: action.folders.totalElements,
    },
    loadingArchive: false,
    loadedArchive: true
  }
}

function getAllFoldersSuccessHandler(state: ScopingState, action: GetAllFoldersSuccessAction) {
  return {
    ...state,
    allFolders: action.folders,
  }
}

function getHighlightsHandler(state: ScopingState, action: GetHighlightsAction) {
  return {
    ...state,
    loadingHighlights: true,
  }
}

function getHighlightsSuccessHandler(state: ScopingState, action: GetHighlightsSuccessAction) {
  return {
    ...state,
    highlights: action.highlights,
    loadingHighlights: false,
  }
}

function searchDeliverablesSuccessHandler(state: ScopingState, action: SearchDeliverablesSuccessAction) {
  return {
    ...state,
    deliverables: action.deliverables,
  };
}

function searchComponentsSuccessHandler(state: ScopingState, action: SearchComponentsSuccessAction) {
  return {
    ...state,
    components: action.components.filter((item, i, arr) => arr.findIndex((t) => t.name === item.name && t.originalName === item.originalName) === i),
  };
}

function getMyScopesMappedColumnsHandler(state: ScopingState, action: GetMyScopesColumnsAction) {
  return {
    ...state,
    loadingColumns: true
  }
}

function getMyScopesMappedColumnsSuccessHandler(state: ScopingState, action: GetMyScopesColumnsSuccessAction) {
  return {
    ...state,
    columns: {
      ...state.columns,
      ...action.preferences
    },
    loadingColumns: false
  }
}

function getScopeMappedColumnsSuccessHandler(state: ScopingState, action: GetScopeColumnsSuccessAction) {
  let columns = Object.keys(action.preferences).length !== 0 ? action.preferences : state.deliverablesColumns;
  return {
    ...state,
    deliverablesColumns: {
      ...columns
    },
    loadingColumns: false
  }
}

function updateMyScopesColumnsHandler(state: ScopingState, action: any) {
  const { updatedPrefs } = action

  if (updatedPrefs.key === 'SCOPE_NUMBER') {
    const updatedColumns = { ...state.columns }

    updatedColumns['SCOPE_NUMBER'] = updatedPrefs

    return {
      ...state,
      columns: updatedColumns,
    }
  } else {
    return {
      ...state,
      columns: {
        ...state.columns,
        [updatedPrefs.key]: updatedPrefs,
      },
    }
  }
}

function updateScopeOverviewColumnsHandler(state: ScopingState, action: any) {
  const { updatedPrefs } = action

  return {
    ...state,
    deliverablesColumns: {
      ...state.deliverablesColumns,
      [updatedPrefs.key]: updatedPrefs,
    },
  }
}

function filterScopesPageInfoHandler(state: ScopingState, action: FilterScopesPageInfoAction) {
  const { page, pageSize } = action.pageInfo
  return {
    ...state,
    pagination: {
      ...state.pagination,
      page,
      pageSize,
    },
    loading: true
  }
}

function filterArchivedScopesPageInfoHandler(state: ScopingState, action: FilterArchivedScopesPageInfoAction) {
  const { page, pageSize } = action.pageInfo
  return {
    ...state,
    paginationArchived: {
      ...state.paginationArchived,
      page,
      pageSize,
    },
    loadingArchive: true
  }
}

function filterScopesSortHandler(state: ScopingState, action: FilterScopesSortAction) {
  return {
    ...state,
    pagination: {
      totalCount: 0,
      page: 0,
      pageSize: state.pagination.pageSize,
    },
    sort: action.sort
  }
}

function filterArchivedScopesSortHandler(state: ScopingState, action: FilterArchivedScopesSortAction) {
  return {
    ...state,
    paginationArchived: {
      totalCount: state.paginationArchived.totalCount,
      page: 0,
      pageSize: state.paginationArchived.pageSize,
    },
    sortArchived: action.sort
  }
}

function filterFoldersPageInfoHandler(state: ScopingState, action: FilterFoldersPageInfoAction) {
  const { page, pageSize } = action.pageInfo
  return {
    ...state,
    pagination: {
      ...state.pagination,
      page,
      pageSize,
    },
    loading: true
  }
}

function filterArchivedFoldersPageInfoHandler(state: ScopingState, action: FilterArchivedFoldersPageInfoAction) {
  const { page, pageSize } = action.pageInfo
  return {
    ...state,
    paginationArchived: {
      ...state.paginationArchived,
      page,
      pageSize,
    },
    loadingArchive: true
  }
}

function filterFoldersSortHandler(state: ScopingState, action: FilterFoldersSortAction) {
  return {
    ...state,
    pagination: {
      totalCount: 0,
      page: 0,
      pageSize: state.pagination.pageSize,
    },
    sort: action.sort
  }
}

function filterArchivedFoldersSortHandler(state: ScopingState, action: FilterArchivedFoldersSortAction) {
  return {
    ...state,
    paginationArchived: {
      totalCount: state.paginationArchived.totalCount,
      page: 0,
      pageSize: state.paginationArchived.pageSize,
    },
    sortArchived: action.sort
  }
}

function filterScopesHandler(state: ScopingState, action: FilterScopesAction) {
  return {
    ...state,
    pagination: {
      ...state.pagination,
      totalCount: 0,
      page: action.page != undefined ? action.page : state.pagination.page
    },
    paginationArchived: {
      ...state.paginationArchived,
      totalCount: 0,
      page: action.pageArchived != undefined ? action.pageArchived : state.paginationArchived.page
    },
    filters: {
      ...state.filters,
      ...action.filters,
    },
    loading: true,
    loadingArchive: true,
    loaded: false,
    loadedArchive: false,
  }
}

function filterFoldersHandler(state: ScopingState, action: FilterFoldersAction) {
  return {
    ...state,
    pagination: {
      ...state.pagination,
      totalCount: 0,
      page: action.page != undefined ? action.page : state.pagination.page
    },
    paginationArchived: {
      ...state.paginationArchived,
      totalCount: 0,
      page: action.pageArchived != undefined ? action.pageArchived : state.paginationArchived.page
    },
    filters: {
      ...state.filters,
      ...action.filters,
    },
    loading: true,
    loadingArchive: true,
    loaded: false,
    loadedArchive: false
  }
}

function getDeliverablesHandler(state: ScopingState, action: GetDeliverablesAction) {
  return {
    ...state,
    loading: true,
  }
}

function getDeliverablesSuccessHandler(state: ScopingState, action: GetDeliverablesSuccessAction) {
  return {
    ...state,
    deliverablesByScope: {
      ...state.deliverablesByScope,
      [action.scopeId]: action.deliverables,
    },
    loading: false,
  }
}

function getDeliverableSummariesHandler(state: ScopingState, action: GetDeliverableSummariesAction) {
  return {
    ...state
  }
}

function getDeliverableSummariesSuccessHandler(state: ScopingState, action: GetDeliverableSummariesSuccessAction) {
  return {
    ...state,
    deliverablesByScope: {
      ...state.deliverablesByScope,
      [action.scopeId]: action.deliverables,
    },
    loading: false,
  }
}

function getScopesByFolderHandler(state: ScopingState, action: GetScopesByFolderAction) {
  return {
    ...state
  }
}

function getScopesByFolderSuccessHandler(state: ScopingState, action: GetScopesByFolderSuccessAction) {
  return {
    ...state,
    scopesByFolder: {
      ...state.scopesByFolder,
      [action.folderId]: action.scopes,
    },
    loading: false,
  }
}

function archiveScopeHandler(state: ScopingState) {
  return state
}

function archiveScopeSuccessHandler(state: ScopingState) {
  return state
}

function archiveScopeFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error?.error,
  }
}

function unarchiveScopeHandler(state: ScopingState) {
  return state
}

function unarchiveScopeSuccessHandler(state: ScopingState) {
  return state
}

function unarchiveScopeFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error?.error,
  }
}

function archiveSowHandler(state: ScopingState) {
  return state
}

function archiveSowSuccessHandler(state: ScopingState) {
  return state
}

function archiveSowFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error?.error,
  }
}

function unarchiveSowHandler(state: ScopingState) {
  return state
}

function unarchiveSowSuccessHandler(state: ScopingState) {
  return state
}

function unarchiveSowFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error?.error,
  }
}

function closeScopeHandler(state: ScopingState) {
  return state
}

function closeScopeSuccessHandler(state: ScopingState, action: any) {
  return {
    ...state,
    scopes: plainToClassFromExist(new Page<ScopeVersion>(ScopeVersion), {
      ...state.scopes,
      content: state.scopes.content.map((scope: ScopeVersion) => {
        if (scope.identity.id === action.scopeId) {
          scope = plainToInstance(ScopeVersion, {
            ...scope,
            status: 'CLOSED',
          })
        }
        return scope
      }),
    }),
  }
}

function closeScopeFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error?.error,
  }
}

function deleteScopeHandler(state: ScopingState) {
  return {
    ...state,
    loading: true
  }
}

function deleteScopeSuccessHandler(state: ScopingState) {
  return {
    ...state,
    loading: false
  }
}

function deleteScopeFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error?.error,
    loading: false
  }
}

function deleteFolderHandler(state: ScopingState) {
  return {
    ...state,
    loading: true
  }
}

function deleteFolderSuccessHandler(state: ScopingState) {
  return {
    ...state,
    loading: false
  }
}

function deleteFolderFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error?.error,
    loading: false
  }
}

function getSecondPartiesSuccessHandler(state: ScopingState, action: GetSecondPartiesSuccessAction) {
  return {
    ...state,
    secondParties: action.secondParties,
    loading: false,
    loaded: true,
  }
}

function assignScopeToFolderSuccessHandler(state: ScopingState, action: any) {
  return {
    ...state,
    scopes: plainToClassFromExist(new Page<ScopeVersion>(ScopeVersion), {
      ...state.scopes,
      content: state.scopes.content.map((scope: ScopeVersion) => {
        if (scope.identity.id === action.scopeId) {
          scope = plainToInstance(ScopeVersion, {
            ...scope,
            scopeOfWorkVersion: action.folder,
          })
        }
        return scope
      }),
    }),
  }
}

function assignScopeToFolderFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error?.error,
  }
}

function unassignScopeFromFolderSuccessHandler(state: ScopingState, action: any) {
  return {
    ...state,
    scopes: plainToClassFromExist(new Page<ScopeVersion>(ScopeVersion), {
      ...state.scopes,
      content: state.scopes.content.map((scope: ScopeVersion) => {
        if (scope.identity.id === action.scopeId) {
          scope = plainToInstance(ScopeVersion, {
            ...scope,
            scopeOfWorkVersion: null,
          })
        }
        return scope
      }),
    }),
    successMessage: action.message,
  }
}

function unassignScopeFromFolderFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error?.error,
  }
}

function findRolesHandler(state: ScopingState, action: any) {
  return {
    ...state,
    searchParams: action.searchParams
  }
}

function findRolesSuccessHandler(state: ScopingState, action: any) {
  const uniqueDeliverables: { name?: string; id?: string }[] = []
  const uniqueDepartments: { name?: string; id?: string }[] = []
  const uniqueComponents: { name?: string; id?: string }[] = []

  action.roles.forEach((role: any) => {
    if (!uniqueDeliverables.some((item) => item.id === role.deliverable.id)) {
      uniqueDeliverables.push(role.deliverable)
    }

    if (!uniqueDepartments.some((item) => item.id === role.department.id)) {
      uniqueDepartments.push(role.department)
    }

    if (!uniqueComponents.some((item) => item.id === role.component.id)) {
      uniqueComponents.push(role.component)
    }
  })

  const availableDeliverables = Object.values(uniqueDeliverables)
  const availableDepartments = Object.values(uniqueDepartments)
  const availableComponents = Object.values(uniqueComponents)

  return {
    ...state,
    availableRoles: action.roles,
    availableDeliverables,
    availableDepartments,
    availableComponents,
  }
}

function findRolesFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error,
  }
}

function selectedReplaceRoleHandler(state: ScopingState, action: any) {
  return {
    ...state,
    selectedReplaceRole: action.role,
  }
}

function selectedReplaceWithRoleHandler(state: ScopingState, action: any) {
  return {
    ...state,
    selectedReplaceRoleWith: action.role,
  }
}

function replaceRolesHandler(state: ScopingState, action: any) {
  return {
    ...state,
    loading: true,
    loaded: false,
  }
}

function replaceRoleSuccessHandler(state: ScopingState, action: any) {
  return {
    ...state,
    replacedRolesResponse: action.response,
    replaceSummarySuccess: true,
    loading: false,
    loaded: true,
  }
}

function replaceRoleFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error,
    replaceSummarySuccess: false,
    loading: false,
    loaded: true,
  }
}

function closeFindReplaceHandler(state: ScopingState) {
  return {
    ...state,
    replaceSummarySuccess: false,
    revertSummarySuccess: false
  }
}

function getRateCardByScopeHandler(state: ScopingState, action: any) {
  return {
    ...state,
    loading: true,
    loaded: false,
  }
}

function getRateCardByScopeHandlerSuccess(state: ScopingState, action: any) {
  return {
    ...state,
    ratecard: action.ratecard,
    loading: false,
    loaded: true,
  }
}

function getRateCardByScopeHandlerFail(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error,
    loading: false,
    loaded: true,
  }
}

function getRateCardHandler(state: ScopingState, action: any) {
  return {
    ...state,
    loading: true,
    loaded: false,
  }
}

function getRateCardHandlerSuccess(state: ScopingState, action: any) {
  return {
    ...state,
    ratecard: action.ratecard,
    loading: false,
    loaded: true,
  }
}

function getRateCardHandlerFail(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error,
    loading: false,
    loaded: true,
  }
}

function getOfficeLocationsHandlerSuccess(state: ScopingState, action: any) {
  return {
    ...state,
    officeLocations: action.locations
  }
}

function getOfficeLocationsHandlerFail(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error
  }
}

function getScopeTemplatesSuccessHandler(state: ScopingState, action: any) {
  return {
    ...state,
    scopeTemplates: action.scopeTemplates
  }
}

function getScopeTemplatesFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error
  }
}

function getWordTemplatesSuccessHandler(state: ScopingState, action: any) {
  return {
    ...state,
    wordTemplates: action.wordTemplates
  }
}

function getWordTemplatesFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error
  }
}

function getXlsxTemplatesSuccessHandler(state: ScopingState, action: any) {
  return {
    ...state,
    xlsxTemplates: action.xlsxTemplates
  }
}

function getXlsxTemplatesFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error
  }
}

function getScopeTypesSuccessHandler(state: ScopingState, action: any) {
  return {
    ...state,
    scopeTypes: action.scopeTypes
  }
}

function getScopeTypesFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error
  }
}

function getCustomFieldsSuccessHandler(state: ScopingState, action: any) {
  return {
    ...state,
    customFields: action.customFields
  }
}

function getCustomFieldsFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error
  }
}

function getTrafficSystemSuccessHandler(state: ScopingState, action: any) {
  return {
    ...state,
    trafficSystem: action.trafficSystem
  }
}

function getTrafficSystemFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error
  }
}

function revertReplacementsHandler(state: ScopingState, action: any) {
  return {
    ...state,
    loading: true,
    loaded: false,
  }
}

function revertReplacementsHandlerSuccess(state: ScopingState, action: any) {
  return {
    ...state,
    revertSummaryResponse: action.revertResponse,
    revertSummarySuccess: true,
    replaceSummarySuccess: false,
    loading: false,
    loaded: true,
  }
}

function revertReplacementsHandlerFail(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error,
    loading: false,
    loaded: true,
  }
}

function getAllFeeItemsSuccessHandler(state: ScopingState, action: any) {
  return {
    ...state,
    feeItems: action.feeItems
  }
}

function getAllFeeItemsFailHandler(state: ScopingState, action: any) {
  return {
    ...state,
    error: action.error
  }
}

const ScopingReducer = createReducer(
  initialState,
  on(ScopeActions.getMyScopes, getMyScopesHandler),
  on(ScopeActions.getMyScopesSuccess, getMyScopesSuccessHandler),
  on(ScopeActions.getArchivedScopes, getArchivedScopesHandler),
  on(ScopeActions.getArchivedScopesSuccess, getArchivedScopesSuccessHandler),
  on(ScopeActions.getMyScopesMappedColumns, getMyScopesMappedColumnsHandler),
  on(ScopeActions.getMyScopesMappedColumnsSuccess, getMyScopesMappedColumnsSuccessHandler),
  on(ScopeActions.getScopeMappedColumnsSuccess, getScopeMappedColumnsSuccessHandler),
  on(ScopeActions.getFolders, getFoldersHandler),
  on(ScopeActions.getFoldersSuccess, getFoldersSuccessHandler),
  on(ScopeActions.getFoldersFail, getFoldersFailHandler),
  on(ScopeActions.getArchivedFolders, getArchivedFoldersHandler),
  on(ScopeActions.getArchivedFoldersSuccess, getArchivedFoldersSuccessHandler),
  on(ScopeActions.filterScopesPageInfo, filterScopesPageInfoHandler),
  on(ScopeActions.filterArchivedScopesPageInfo, filterArchivedScopesPageInfoHandler),
  on(ScopeActions.filterScopesSort, filterScopesSortHandler),
  on(ScopeActions.filterArchivedScopesSort, filterArchivedScopesSortHandler),
  on(ScopeActions.filterScopes, filterScopesHandler),
  on(BootstrapActions.updateScopeColumnPreferences, updateMyScopesColumnsHandler),
  on(BootstrapActions.updateScopeOverviewColumnPreferences, updateScopeOverviewColumnsHandler),
  on(ScopeActions.filterFoldersPageInfo, filterFoldersPageInfoHandler),
  on(ScopeActions.filterArchivedFoldersPageInfo, filterArchivedFoldersPageInfoHandler),
  on(ScopeActions.filterFoldersSort, filterFoldersSortHandler),
  on(ScopeActions.filterArchivedFoldersSort, filterArchivedFoldersSortHandler),
  on(ScopeActions.filterFolders, filterFoldersHandler),
  on(ScopeActions.getHighlights, getHighlightsHandler),
  on(ScopeActions.getHighlightsSuccess, getHighlightsSuccessHandler),
  on(ScopeActions.searchDeliverablesSuccess, searchDeliverablesSuccessHandler),
  on(ScopeActions.searchComponentsSuccess, searchComponentsSuccessHandler),
  on(ScopeActions.getDeliverables, getDeliverablesHandler),
  on(ScopeActions.getDeliverablesSuccess, getDeliverablesSuccessHandler),
  on(ScopeActions.getDeliverableSummaries, getDeliverableSummariesHandler),
  on(ScopeActions.getDeliverableSummariesSuccess, getDeliverableSummariesSuccessHandler),
  on(ScopeActions.getScopesByFolder, getScopesByFolderHandler),
  on(ScopeActions.getScopesByFolderSuccess, getScopesByFolderSuccessHandler),
  on(ScopeActions.archiveScope, archiveScopeHandler),
  on(ScopeActions.archiveScopeSuccess, archiveScopeSuccessHandler),
  on(ScopeActions.archiveScopeFail, archiveScopeFailHandler),
  on(ScopeActions.unarchiveScope, unarchiveScopeHandler),
  on(ScopeActions.unarchiveScopeSuccess, unarchiveScopeSuccessHandler),
  on(ScopeActions.unarchiveScopeFail, unarchiveScopeFailHandler),
  on(ScopeActions.closeScope, closeScopeHandler),
  on(ScopeActions.closeScopeSuccess, closeScopeSuccessHandler),
  on(ScopeActions.closeScopeFail, closeScopeFailHandler),
  on(ScopeActions.deleteScope, deleteScopeHandler),
  on(ScopeActions.deleteScopeSuccess, deleteScopeSuccessHandler),
  on(ScopeActions.deleteScopeFail, deleteScopeFailHandler),
  on(ScopeActions.deleteFolder, deleteFolderHandler),
  on(ScopeActions.deleteFolderSuccess, deleteFolderSuccessHandler),
  on(ScopeActions.deleteFolderFail, deleteFolderFailHandler),
  on(ScopeActions.getAllFoldersSuccess, getAllFoldersSuccessHandler),
  on(ScopeActions.getSecondPartiesSuccess, getSecondPartiesSuccessHandler),
  on(ScopeActions.assignScopeToFolderSuccess, assignScopeToFolderSuccessHandler),
  on(ScopeActions.assignScopeToFolderFail, assignScopeToFolderFailHandler),
  on(ScopeActions.unassignScopeFromFolderSuccess, unassignScopeFromFolderSuccessHandler),
  on(ScopeActions.unassignScopeFromFolderFail, unassignScopeFromFolderFailHandler),
  on(ScopeActions.findRoles, findRolesHandler),
  on(ScopeActions.findRolesSuccess, findRolesSuccessHandler),
  on(ScopeActions.findRolesFail, findRolesFailHandler),
  on(ScopeActions.setSelectedReplaceRole, selectedReplaceRoleHandler),
  on(ScopeActions.setSelectedReplaceWithRole, selectedReplaceWithRoleHandler),
  on(ScopeActions.replaceRolesSuccess, replaceRoleSuccessHandler),
  on(ScopeActions.replaceRolesFail, replaceRoleFailHandler),
  on(ScopeActions.closeFindReplace, closeFindReplaceHandler),
  on(ScopeActions.replaceRoles, replaceRolesHandler),
  on(ScopeActions.getRateCardForScope, getRateCardByScopeHandler),
  on(ScopeActions.getRateCardForScopeSuccess, getRateCardByScopeHandlerSuccess),
  on(ScopeActions.getRateCardForScopeFail, getRateCardByScopeHandlerFail),
  on(ScopeActions.getRateCard, getRateCardHandler),
  on(ScopeActions.getRateCardSuccess, getRateCardHandlerSuccess),
  on(ScopeActions.getRateCardFail, getRateCardHandlerFail),
  on(ScopeActions.getOfficeLocationsSuccess, getOfficeLocationsHandlerSuccess),
  on(ScopeActions.getOfficeLocationsFail, getOfficeLocationsHandlerFail),
  on(ScopeActions.getScopeTemplatesSuccess, getScopeTemplatesSuccessHandler),
  on(ScopeActions.getScopeTemplatesFail, getScopeTemplatesFailHandler),
  on(ScopeActions.getWordOutputTemplatesSuccess, getWordTemplatesSuccessHandler),
  on(ScopeActions.getWordOutputTemplatesFail, getWordTemplatesFailHandler),
  on(ScopeActions.getXlsxOutputTemplatesSuccess, getXlsxTemplatesSuccessHandler),
  on(ScopeActions.getXlsxOutputTemplatesFail, getXlsxTemplatesFailHandler),
  on(ScopeActions.getScopeTypesSuccess, getScopeTypesSuccessHandler),
  on(ScopeActions.getScopeTypesFail, getScopeTypesFailHandler),
  on(ScopeActions.getCustomFieldsSuccess, getCustomFieldsSuccessHandler),
  on(ScopeActions.getCustomFieldsFail, getCustomFieldsFailHandler),
  on(ScopeActions.getTrafficSystemSuccess, getTrafficSystemSuccessHandler),
  on(ScopeActions.getTrafficSystemFail, getTrafficSystemFailHandler),
  on(ScopeActions.revertReplacementRoles, revertReplacementsHandler),
  on(ScopeActions.revertReplacementRolesSuccess, revertReplacementsHandlerSuccess),
  on(ScopeActions.revertReplacementRolesFail, revertReplacementsHandlerFail),
  on(ScopeActions.getAllFeeItemsSuccess, getAllFeeItemsSuccessHandler),
  on(ScopeActions.getAllFeeItemsFail, getAllFeeItemsFailHandler)
)

export function reducer(state: ScopingState | undefined, action: Action): ScopingState {
  return ScopingReducer(state, action)
}
