<div class="sidebar" [ngClass]="{'active': visible}">
  <div class="sidebar-title font-newJuneBold">
    <span class="text-lg">Notifications</span>
    <span class="text-sm cursor-pointer" (click)="markAllAsRead()">Mark all as read</span>
  </div>
  <div class="notibox cursor-pointer" [ngClass]="{'greyed-out': notification.read}" *ngFor="let notification of userNotifications$ | async; trackBy: trackById">
    <div class="circle {{getIcon(notification.eventType)}}">
      <mat-icon class="material-symbols-rounded">{{getIcon(notification.eventType)}}</mat-icon>
      <div class="inner-circle">{{notification.initiatedBy ? notification.initiatedBy.firstName.charAt(0).toUpperCase() : "S"}}</div>
    </div>
    <div class="entity-content" (click)="onClickNotification(notification)">
      <div class="notif-title">
        <span *ngIf="notification.initiatedBy" class="font-newJuneBold blue-violet">{{notification.initiatedBy.firstName}} {{notification.initiatedBy.lastName}}</span>
        <div class="unread-badge" *ngIf="!notification.read"></div>
      </div>
      <div class="notif-body">
        <span [innerHTML]="getNotificationBody(notification).message"></span>
      </div>
      <div>
        <span class="blue-violet">{{getTimeAgo(notification)}}</span>
      </div>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="visible" (click)="onCloseNotifications.emit()"></div>


