<div class='flex' *ngIf="!hidden">
  <button class="main-action-button" mat-button (click)="onClick()">
    {{ buttonText }}
  </button>
  <mat-divider [vertical]="true"></mat-divider>
  <button class="action-button" mat-button [matMenuTriggerFor]="menu">
     <mat-icon class="material-symbols-rounded">expand_more</mat-icon>
  </button>
  <mat-menu #menu="matMenu" backdropClass="options-menu">
    <ng-container *ngFor="let option of menuOptions">
      <button [hidden]="option.isHidden?.()" (click)="onOptionClick(option)" class="menu-button" mat-menu-item>
        <i class="material-symbols-rounded">
          {{ option.icon?.() }}
        </i>
        <span> {{ option.name?.() }}</span>
      </button>
    </ng-container>
    <mat-divider class='mx-2'></mat-divider>
    <ng-container *ngFor="let option of secondaryMenuOptions">
      <button [hidden]="option.isHidden?.()" (click)="onOptionClick(option)" class="menu-button" mat-menu-item>
        <i class="material-symbols-rounded">
          {{ option.icon?.() }}
        </i>
        <span> {{ option.name?.() }}</span>
      </button>
    </ng-container>
  </mat-menu>
</div>
