<button *ngIf="!toggleButton" class="action-button" mat-button [matMenuTriggerFor]="menu">
  {{ buttonText }} <mat-icon class="material-symbols-rounded" iconPositionEnd>expand_more</mat-icon>
</button>
<button
  *ngIf="toggleButton"
  class="toggle-button float-right ml-1"
  (click)="$event.stopPropagation()"
  mat-mini-fab
  [matMenuTriggerFor]="menu">
  <mat-icon class="material-symbols-rounded">more_horiz</mat-icon>
</button>
<mat-menu #menu="matMenu" backdropClass="options-menu">
  <ng-container *ngFor="let option of menuOptions">
    <button [hidden]="option.isHidden?.(element)" [has-privilege]="option.hasPrivilege?.(element)"
            [has-privilege-entity]="option.hasPrivilegeEntity?.(element)" (click)="option?.callback(this.element)" class="menu-button" mat-menu-item>
      <i class="material-symbols-rounded">
        {{ option.icon(element) }}
      </i>
      <span> {{ option.name(element) }}</span>
    </button>
  </ng-container>
</mat-menu>
