import { Type } from 'class-transformer';
import { Money } from '@app/features/scope-overview/model/money.model';
import { ScopeComponent } from '@app/features/scoping/models/component.model';
import { Role } from '@app/features/scoping/models/role.model';
import { UserComment } from '@core/model/comment.model'


export class Department {
  id!:number
  @Type(() => UserComment) comments!: UserComment[]
  @Type(() => ScopeComponent) component!: ScopeComponent;
  currencyUnit!: string
  name!: string
  rateCardTotalHours!: number
  rateCardTotalMinutes!: number
  @Type(() => Money) rateCardTotalSellingPrice!: Money;
  @Type(() => Money) rateCardTotalSpentPrice!: Money;
  scopeMarkTotalHours!: number
  @Type(() => Money) scopeMarkTotalSellingPrice!: Money;
  source!:
    {
      description: string
      foreignSource: any
      id: number
      name: string
      nonTranslatedDescription: string
      nonTranslatedName: string
      parentId: number
      scopeType:string
    }
  sourceType!: string
  @Type(() => Role) roles!: Role[];
  trafficSyncStatus!: string
  selected!: boolean
  breadcrumb?: string

  totalScopeMarkHours() {
    let hours = 0;
    this.roles.forEach((role) => {
      hours += role.getScopeMarkHours();
    });
    return hours != null ? hours : 0;
  };

  getTotalRateCardHours() {
    let hours = 0;
    this.roles.forEach((role) => {
      hours += role.getRateCardHours();
    });
    return hours != null ? hours : 0;
  };

  getTotalSellingPrice(ctx: { markup: number } = { markup: null }) {
    let price = new Money(0, this.currencyUnit);
    for (let i = 0 ; i < this.roles.length ; i++) {
      let role = this.roles[i];
      let userPrice = role.getUserSellingPrice(ctx)
      if (userPrice == null) return null
      price = price.add(userPrice)
    }
    return price
  }

  getTotalCostPrice() {
    let price = new Money(0, this.currencyUnit);
    for (let i = 0 ; i < this.roles.length ; i++) {
      let role = this.roles[i];
      let cost = role.getRateCardCost();
      if (cost == null) {
        return null;
      }
      price = price.add(cost);
    }
    return price
  }

  totalScopeMarkSellingPrice() {
    let price = new Money(0, this.currencyUnit);
    this.roles.forEach((role) => {
      const scopeMarkSellingPrice = role.getScopeMarkSellingPrice();
      if (scopeMarkSellingPrice != null) {
        price = price.addValue(scopeMarkSellingPrice.amount);
      }
    });
    return price;
  };

  getBreadcrumb() {
    return this.breadcrumb
  }

  getFtePercentage(fteHours) {
    if (this.getTotalRateCardHours() === 0) {
      return 0;
    }
    return parseFloat((this.getTotalRateCardHours() / fteHours * 100).toFixed(2));
  }

  getCommentsCount() {
    let ownCommentsCount = this.comments.length;
    for (let i = 0; i < this.roles.length; i++) {
      ownCommentsCount += this.roles[i].getCommentsCount();
    }
    return ownCommentsCount;
  }
}
