<div class="cookies-container">
  <div class="cookies-wrap">
    <p class="title">This application uses cookies</p>
    <p class="subtitle">SCOPE uses cookies to deliver the best functionality to users and improve the experience of the application. You consent to our cookies if you continue to use the application.</p>
    <div class="btn-wrap">
      <a (click)="saveCookieSettings()" class="btn btn--medium btn--inline">Agree</a>
    </div>

    <div class="checkbox-row">
      <mat-checkbox class="mr-2" *ngFor="let category of cookieCategories" [(ngModel)]="category.defaultValue" [disabled]="!category.changeable">{{category.label}}</mat-checkbox>
    </div>

    <div class="main-container" [ngClass]="{'give-me-shadow': showCookiesRow}">
      <div class="row" (click)="showCookiesRow =! showCookiesRow">
        <mat-icon class="material-symbols-rounded orange" [ngClass]="{'open' : showCookiesRow}">keyboard_arrow_down</mat-icon>
        <p class="row-title">Details</p>
      </div>
      <div [hidden]="!showCookiesRow" class="details-inside">
        <div class="row">
          <div class="select" [ngClass]="{'active': tabsCounter == 1}" (click)="tabsCounter = 1">Cookies declaration</div>
          <div class="select" [ngClass]="{'active': tabsCounter == 2}" (click)="tabsCounter = 2">About Cookies</div>
        </div>

        <div class="show-wrapper costume-scroll" [hidden]="tabsCounter !== 1">

          <div *ngFor="let category of cookieCategories">
            <div class="show-line" (click)="category.expanded = !category.expanded">
              <mat-icon class="material-symbols-rounded orange" [ngClass]="{'open' : category.expanded}">keyboard_arrow_down</mat-icon>
              <div class="show-title">{{category.label}}</div>
            </div>
            <div class="show-inside" [hidden]="!category.expanded">
              <p>{{category.description}}</p>
              <div class="table" [hidden]="!filterCookiesByCategory(category).length">
                <div class="table-header">
                  <div class="col">Name</div>
                  <div class="col">Provider</div>
                  <div class="col">Purpose</div>
                  <div class="col">Expiry</div>
                  <div class="col">Type</div>
                </div>
                <div class="table-row" *ngFor="let cookie of filterCookiesByCategory(category)">
                  <div class="col">{{cookie.name}}</div>
                  <div class="col">{{cookie.provider}}</div>
                  <div class="col" title="{{cookie.purpose}}">{{cookie.purpose}}</div>
                  <div class="col">{{cookie.expiry}}</div>
                  <div class="col">{{cookie.type}}</div>
                </div>
              </div>
              <div [hidden]="filterCookiesByCategory(category).length">
                <span>We do not use cookies of this category</span>
              </div>
            </div>
          </div>

        </div>

        <div class="show-wrapper costume-scroll" [hidden]="tabsCounter !== 2">
          <p>
            Cookies are small text files that can be used by applications to make a user's experience more efficient. The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission. This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages. These third party services are plugins to enable functionality in all cases.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
