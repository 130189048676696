import { Type } from "class-transformer";
import { CompanySize } from "./enums/company-size.enum";
import { CompanyType } from "./enums/company-type.enum";
import { CompanySummary } from "./company-summary.model";
import { ScopePlan } from "./scope-plan.model";
import { TrafficSystemSettings } from '@app/features/scope-overview/model/traffic-system-settings.model';

export class Company {
  id!: number;
  name!: string;
  subdomain!: string;
  companyType!: CompanyType;
  size!: CompanySize;
  locationId!: number;
  logoUri!: string;
  enabled!: boolean;
  demoAccount!: boolean;
  fteValue!: number;
  estimates!: number;
  defaultThirdPartyCostMarkUp!: number;
  language!: string;
  currency!: string;
  @Type(() => Company) parentCompany!: Company;
  @Type(() => ScopePlan) scopePlan!: ScopePlan;
  @Type(() => Date) createdTs!: Date;
  @Type(() => Date) updatedTs!: Date;
  @Type(() => TrafficSystemSettings) trafficSystemSettings!: TrafficSystemSettings;
  applicationSettings!: ApplicationSetting[];
  companyDateFormat!: string;
  currencyUnit?: string;
  hasWorkatoIntegration!: boolean;
  workatoIntegrationSystemNames?: string;

  enabledMappedApplicationSettings = new Map<String, ApplicationSetting>();
  editEnabledMappedApplicationSettings = new Map<String, ApplicationSetting>();

  public mapApplicationSettings(){
    if (this.applicationSettings){
      let mapped = this.enabledMappedApplicationSettings = new Map<String, ApplicationSetting>();
      let editEnabled = this.editEnabledMappedApplicationSettings = new Map<String, ApplicationSetting>();
      this.applicationSettings.forEach(function(setting){
        if (setting.isEnabled){
          mapped.set(setting.applicationSettingType, setting);
        }
        if (setting.isEditEnabled) {
          editEnabled.set(setting.applicationSettingType, setting);
        }
      })
    }
  };

  public hasApplicationSetting(setting: string) {
    return this.enabledMappedApplicationSettings.get(setting) != null;
  };
}

export class ApplicationSetting {
  applicationSettingType!: string;
  id!: number;
  isEditEnabled!: boolean;
  isEnabled!: boolean;
}
