
export class CdnConfig {

  agencyLogoUrl!: string;
  userProfileUrl!: string;
  staticMediaUrl!: string;
  captchaEnabled!: boolean;
  googleRecaptchaPublicKey?: string;

  getStaticMediaUrl(path: string) {
    return `${this.staticMediaUrl}/${path}`;
  }

  getCompanyLogoUrl(uri: string) {
    return `${this.agencyLogoUrl}/${uri}`;
  }
  getProfileImageUrl(uri: string) {
    return `${this.userProfileUrl}/${uri}`;
  }
}
