import { Type } from 'class-transformer';
import { User } from '@core/model/user.model';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { Money } from '@app/features/scope-overview/model/money.model';
import { ScopeComponent } from '@app/features/scoping/models/component.model';
import { Department } from '@app/features/scoping/models/department.model';
import { UserComment } from '@core/model/comment.model'
import { DepartmentType } from '@app/features/scoping/models/department-type.model';


export class Role {
  id!:number
  @Type(() => UserComment) comments!: UserComment[]
  substituteSources!: any[]
  @Type(() => Department) department!: Department;
  name!: string
  distributableScopeMarkHours!: number
  rateCardMinutes!: number
  ratecardLocationRole!: any
  rateCardRole!: any
  @Type(() => Money) rateCardTotalSellingPrice!: Money;
  @Type(() => Money) rateCardTotalSpentPrice!: Money;
  @Type(() => Money) scopeMarkRate!: Money;
  scopeMarkTotalHours!: number
  @Type(() => Money) scopeMarkTotalSellingPrice!: Money;
  @Type(() => Money) rateCardRate!: Money;
  @Type(() => Money) rateCardCost!: Money;
  order!: number
  source!:
    {
      description: string
      foreignSource: any
      id: number
      name: string
      nonTranslatedDescription: string
      nonTranslatedName: string
      parentId: number
      scopeType:string
    }
  sourceType!: string
  trafficSyncStatus!: string
  fte: any
  breadcrumb?: string
  locationName!: string
  rate!: any
  cost: any
  departmentType: DepartmentType
  editFte?: boolean

  getScopeMarkHours() {
    let hours = 0;
    if (this.sourceType == "SCOPE"){
      return this.distributableScopeMarkHours;
    }
    else if (this.sourceType == "RATE_CARD"){
      this.substituteSources.forEach(function(subRoles){
        hours += subRoles?.distributedScopeMarkHours;
      });
    }
    return hours;
  };

  getRateCardHours () {
    return this.rateCardMinutes / 60;
  };

  getUserSellingPrice(ctx: { markup: number } = { markup: null }) {
    let rate: Money;
    if (ctx.markup != null && this.rateCardCost) {
      rate = new Money(this.rateCardCost.getValue() + (this.rateCardCost.getValue() * ctx.markup / 100), this.rateCardCost.getCurrencyCode());
    } else {
      rate = this.rateCardRate;
    }
    if (rate != null) {
      return rate.multiply(this.getRateCardHours());
    }
    return null;
  };

  getRateCardCost() {
    if (!this.source || this.sourceType != "RATE_CARD"){
      return null;
    }
    return this.rateCardCost.multiply(this.getRateCardHours());
  };

  getScopeMarkRate() {
    if (this.sourceType == "SCOPE"){
      return this.scopeMarkRate
    }
    else if (this.sourceType == "RATE_CARD"){
      if (this.substituteSources.length == 0){
        return null;
      }

      var weightedRates = [];
      var weightedByHours = 0;
      var currency;
      for (var i = 0; i < this.substituteSources.length; i++){
        var source = this.substituteSources[i];
        if (source.scopeMarkRate == null){
          //Rates not available on model - Break returning NULL
          return null;
        } else {
          currency = source.scopeMarkRate.currency;
          weightedRates.push(source.scopeMarkRate.amount * source.scopeMarkHours);
          weightedByHours += source.scopeMarkHours;
        }
      }

      var summedRate = 0;
      weightedRates.forEach(function(rate){
        summedRate += rate;
      });

      if (!summedRate && !weightedByHours){
        return new Money(0, currency);
      }
      return new Money((summedRate / weightedByHours), currency);
    }
    return null;
  };

  getScopeMarkSellingPrice() {
    let scopeMarkRate = this.getScopeMarkRate();
    if (scopeMarkRate == null) {
      return null;
    }
    return scopeMarkRate.multiply(this.getScopeMarkHours());
  };

  getFtePercentage(fteHours: number) {
    if (this.getRateCardHours() === 0) {
      return 0;
    }
    let fte = this.getRateCardHours() / fteHours * 100;
    if (Number.isInteger(fte)) {
      return fte;
    } else {
      return parseFloat(fte.toFixed(2));
    }
  }

  getBreadcrumb() {
    return this.breadcrumb
  }

  hasAttributeAlterationComment() {
    return this.comments.some(nextComment =>
      nextComment.sourceAttribute == 'rateCardHours' && nextComment.originType == 'ATTRIBUTE_ALTERATION');
  }

  setRateCardMinutes(minutes) {
    if (minutes%15!==0) throw "Minutes must be in 15 minute increments";

    this.rateCardMinutes = minutes
  }

  setRateCardHours(hours) {
    return this.setRateCardMinutes(hours*60)
  };

  getCommentsCount() {
    return this.comments.length;
  }
}
