import { Injectable } from "@angular/core";
import { User } from "@app/core/model/user.model";
import { UserService } from "@app/features/user-account/services/user.service";
import {environment} from "@envs/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl

  constructor(private userService: UserService, private http: HttpClient) {}

//  TODO - define TrafficMetadataField type
  getTrafficMetadataFields(user: User): any[] {
    return user
      ?.company
      ?.trafficSystemSettings
      ?.trafficSystem
      ?.externalTrafficSystemConfig
      ?.scopeMetadataLookupConfig
      ?.definitions ?? [];
  }

  getCompanyIntegrations() {
    let url = `${this.BASE_API_URL}/company/api-integrations`
    return this.http.get(url)
  }

}
