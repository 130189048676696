import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { SafePipe } from "@app/shared/pipe/safe.pipe";
import { EnvService } from "@envs/env-service";
import { Router } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { HeaderStyleService, HIDE_HEADER } from '@shared/services/header-style.service';
import { AuthService } from '@core/service/auth.service'

@Component({
  selector: 'app-folder-overview',
  templateUrl: './folder-overview.component.html',
  styleUrls: ['./folder-overview.component.scss'],
  providers: [ SafePipe ],

})
export class FolderOverviewComponent {
  @Input() id!: number;
  constructor(private envService: EnvService, private cdr: ChangeDetectorRef, private router: Router, private titleService: Title, private headerStyleService: HeaderStyleService, private authService: AuthService) {
    this.titleService.setTitle('Folder Overview | Scope')
    window.addEventListener('message', event => {
      if (event.data.action === 'goToScope') {
        this.router.navigateByUrl('/scopes/scope-overview/' + event.data.id)
      } else if (event.data.action === 'goToScopeByRole') {
        this.router.navigateByUrl('/scopes/scope-by-role-overview/' + event.data.id)
      } else if (event.data.action === 'goToRetainedTeam') {
        this.headerStyleService.setHeaderClass(HIDE_HEADER)
        this.cdr.markForCheck()
      } else if (event.data.action === 'goOutRetainedTeam') {
        this.headerStyleService.setHeaderClass(null)
        this.cdr.markForCheck()
      }
    })
  }

  public get baseJsUrl(): string {
    return `${this.envService.baseJsUrl}/folder-overview/${this.id}?childCompany=${this.authService.loggedInUser?.company.id}`;
  }
}
