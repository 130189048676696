import {
  ComponentsFilterValue,
  DateFilterValue, GenericFilterValue,
  RangeFilterValue
} from "@core/model/filter-option.interface";
import {Filter} from "@core/model/scope-filter.model";

export interface ScopeOverviewFilter extends Filter {
  [key: string]: any;
  name: string;
  teamMembers?: GenericFilterValue[];
  comments: string;
  date?: DateFilterValue;
  disciplines?: GenericFilterValue[];
  deliverables?: GenericFilterValue[];
  stages?: GenericFilterValue[];
  components?: ComponentsFilterValue;
  departments?: GenericFilterValue[];
  roles?: GenericFilterValue[];
  thirdPartyCost?: RangeFilterValue;
  budget?: RangeFilterValue;
  margin?: RangeFilterValue;
  markUp?: RangeFilterValue;
  profit?: RangeFilterValue;
  agencyPrice?: RangeFilterValue;
}

export const ScopeOverviewFilterDefaults: ScopeOverviewFilter = {
  name: "",
  teamMembers: [],
  comments: 'ALL',
  date: new DateFilterValue(),
  disciplines: [],
  deliverables: [],
  stages: [],
  components: new ComponentsFilterValue(),
  departments: [],
  roles: [],
  thirdPartyCost: new RangeFilterValue(),
  budget: new RangeFilterValue(),
  margin: new RangeFilterValue(),
  markUp: new RangeFilterValue(),
  profit: new RangeFilterValue(),
  agencyPrice: new RangeFilterValue()
}
