import { UserSummary } from "./user.model";
import { EventType } from "./enums/event-type.enum";
import { ClassificationType } from "./classification-type.model";
import { Type } from "class-transformer";

export class UserNotification {
    id!: number;
    eventType!: EventType;
    read!: boolean;
    message: string;
    action!: any;
    properties!: any;
    entitySourceType!: string;
    @Type(() => ClassificationType) entity!: ClassificationType;
    @Type(() => UserSummary) user!: UserSummary;
    @Type(() => UserSummary) initiatedBy!: UserSummary;
    @Type(() => Date) createdTs!: Date;
}

export interface ScopeNotificationUpdate {
    archived: boolean;
    readyToTraffic: boolean;
    scopeApprovedWebHookInfo: any;
    status: string;
    members: any[];
    collaborators: any[];
    reviewers: any[];
    approvers: any[];
}

export type NotificationScopeUpdate = {
    [key: string]: any;
}


