<div class="activity-comment-container">
  <button
    (click)="showHideComment()"
    class="add-comment"
    mat-mini-fab
    color="primary"
    aria-label="Example icon button with a menu icon">
    <i class="material-symbols-rounded">add_comment</i>
  </button>

  <div *ngIf="canAddComment" class="add-activity-comment polygon">
    <div class="add-activity-input-container">
      <form (ngSubmit)="submitComment()" #commentForm="ngForm">
        <input
          required
          class="form-control"
          id="message"
          name="message"
          type="text"
          [(ngModel)]="commentInput"
          [mentionConfig]="{ items: collaboratorsMentions, labelKey: 'name' }"
          [mentionListTemplate]="mentionListTemplate"
          placeholder="Add your message. Add @ to tag a team member" />
        <button type="submit" [disabled]="!commentForm.form.valid"><i class="material-symbols-rounded">send</i></button>
      </form>
    </div>

    <ng-template #mentionListTemplate let-item="item">
      <div class="activity-mention">
        <div class="user-avatar-placeholder">
          <img class="user-avatar" height="40" width="40" *ngIf="item?.photoUri && isImageReachable(item?.photoUri)"
               alt="{{item?.name?.charAt(0)}}" ngSrc="{{ getMentionUserPhoto(item) }}" />
          <p *ngIf="!item?.photoUri || !isImageReachable(item?.photoUri)">{{ item.name.charAt(0) }}</p>
        </div>
        <div class="activity-mention-content">
          <span>{{ item.name }}</span>
          <span>{{ item.email }}</span>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="(activities$ | async)?.today?.length" class="activities-container">
  <!-- Today's comments -->
  <div class="activity-group">
    <div class="activity-timeline-header">
      <p class="activity-timeline-date">Today</p>
    </div>

    <ng-container *ngFor="let activity of (activities$ | async)?.today | orderBy : 'createdTs':'desc'; trackBy: trackById">
      <div class="activity">
        <div class="activity-user">
          <div class="user-avatar-placeholder">
            <img class="user-avatar" height="40" width="40" *ngIf="activity?.initiatedBy?.photoUri && isImageReachable(activity?.initiatedBy?.photoUri)"
                 alt="{{activity.initiatedBy?.firstName?.charAt(0)}}" ngSrc="{{ getActivityUserPhoto(activity) }}" />
            <div [style.background-color]="getActivityIconStatus(activity).color" class="activity-icon-status">
              <i class="material-symbols-rounded">{{getActivityIconStatus(activity).icon}}</i>
            </div>
            <p *ngIf="!activity?.initiatedBy?.photoUri || !isImageReachable(activity?.initiatedBy?.photoUri)">{{ activity.initiatedBy?.firstName?.charAt(0) }}</p>
          </div>
        </div>

        <ng-container *ngIf="checkTradingStatus(activity.action); else standardComment">
          <activity-event
            [comment]="activity"
            [title]="activity | commentTag"
            [action]="activity.action"
            [attributes]="activity.attributes"
            (navigateTo)="onSelectTab.emit('Scope')" />
        </ng-container>

        <ng-template #standardComment>
          <div class="activity-content">
            <div class="activity-text">
              <p class="activity-user-name">{{ activity.initiatedBy?.fullName }}</p>
              <p class="activity-comment-action">{{ activity | commentTag }}</p>

              <mat-chip-listbox aria-label="User selection">
                <ng-container *ngFor="let item of activity.comment?.text! | userToChipArray">
                    <span *ngIf="item.type === 'text'" class="activity-comment">{{ item.value }}</span>

                    <mat-chip-row *ngIf="item.type === 'user'" disabled="true">
                      {{ item.name }}
                    </mat-chip-row>
                </ng-container>
              </mat-chip-listbox>
              <a class="activity-comment-link" (click)="navigateToActionSource(activity)">
                <span class="activity-source-type">{{ activitiesService.getCommentTypeTag(activity) }}</span>
                <i class="material-symbols-rounded">chevron_right</i> {{ activitiesService.getSourceName(activity) }}</a
              >
            </div>

            <div class="activity-date">
              <p>{{ activity.createdTs | date }}</p>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="(activities$ | async)?.yesterday?.length" class="activities-container">
  <div class="activity-group">
    <div class="activity-timeline-header">
      <p class="activity-timeline-date">Yesterday</p>
    </div>

    <ng-container *ngFor="let activity of (activities$ | async)?.yesterday | orderBy : 'createdTs':'desc'; trackBy: trackById">
      <div class="activity">
        <div class="activity-user">
          <div class="activity-user">
            <div class="user-avatar-placeholder">
              <img class="user-avatar" height="40" width="40" *ngIf="activity?.initiatedBy?.photoUri && isImageReachable(activity?.initiatedBy?.photoUri)"
                   alt="{{activity.initiatedBy?.firstName?.charAt(0)}}" ngSrc="{{ getActivityUserPhoto(activity) }}" />
              <div [style.background-color]="getActivityIconStatus(activity).color" class="activity-icon-status">
                <i class="material-symbols-rounded">{{getActivityIconStatus(activity).icon}}</i>
              </div>
              <p *ngIf="!activity?.initiatedBy?.photoUri || !isImageReachable(activity?.initiatedBy?.photoUri)">{{ activity.initiatedBy?.firstName?.charAt(0) }}</p>
            </div>
          </div>
        </div>

        <ng-container *ngIf="checkTradingStatus(activity.action); else standardComment">
          <activity-event
            [comment]="activity"
            [title]="activity | commentTag"
            [action]="activity.action"
            [attributes]="activity.attributes"
            (navigateTo)="onSelectTab.emit('Scope')" />
        </ng-container>

        <ng-template #standardComment>
          <div class="activity-content">
            <div class="activity-text">
              <p class="activity-user-name">{{ activity.initiatedBy?.fullName }}</p>
              <p class="activity-comment-action">{{ activity | commentTag }}</p>
              <mat-chip-listbox aria-label="User selection">
                <ng-container *ngFor="let item of activity.comment?.text! | userToChipArray">
                  <span *ngIf="item.type === 'text'" class="activity-comment">{{ item.value }}</span>

                  <mat-chip-row *ngIf="item.type === 'user'" disabled="true">
                    {{ item.name }}
                  </mat-chip-row>
                </ng-container>
              </mat-chip-listbox>
              <a class="activity-comment-link" (click)="navigateToActionSource(activity)">
                <span class="activity-source-type">{{ activitiesService.getCommentTypeTag(activity) }}</span>
                <i class="material-symbols-rounded">chevron_right</i> {{ activitiesService.getSourceName(activity) }}</a
              >
            </div>

            <div class="activity-date">
              <p>{{ activity.createdTs | date }}</p>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="(activities$ | async)?.current?.length" class="activities-container">
  <div class="activity-group">
    <div class="activity-timeline-header">
      <p class="activity-timeline-date">Current</p>
    </div>

    <ng-container *ngFor="let activity of (activities$ | async)?.current | orderBy : 'createdTs':'desc'; trackBy: trackById">
      <div class="activity">
        <div class="activity-user">
          <div class="activity-user">
            <div class="user-avatar-placeholder">
              <img class="user-avatar" height="40" width="40" *ngIf="activity?.initiatedBy?.photoUri && isImageReachable(activity?.initiatedBy?.photoUri)"
                   alt="{{activity.initiatedBy?.firstName?.charAt(0)}}" ngSrc="{{ getActivityUserPhoto(activity) }}" />
              <div [style.background-color]="getActivityIconStatus(activity).color" class="activity-icon-status">
                <i class="material-symbols-rounded">{{getActivityIconStatus(activity).icon}}</i>
              </div>
              <p *ngIf="!activity?.initiatedBy?.photoUri || !isImageReachable(activity?.initiatedBy?.photoUri)">{{ activity.initiatedBy?.firstName?.charAt(0) }}</p>
            </div>
          </div>
        </div>

        <ng-container *ngIf="checkTradingStatus(activity.action); else standardComment">
          <activity-event
            [comment]="activity"
            [title]="activity | commentTag"
            [action]="activity.action"
            [attributes]="activity.attributes"
            (navigateTo)="onSelectTab.emit('Scope')" />
        </ng-container>

        <ng-template #standardComment>
          <div class="activity-content">
            <div class="activity-text">
              <p class="activity-user-name">{{ activity.initiatedBy?.fullName }}</p>
              <p class="activity-comment-action">{{ activity | commentTag }}</p>
              <mat-chip-listbox aria-label="User selection">
                <ng-container *ngFor="let item of activity.comment?.text! | userToChipArray">
                  <span *ngIf="item.type === 'text'" class="activity-comment">{{ item.value }}</span>

                  <mat-chip-row *ngIf="item.type === 'user'" disabled="true">
                    {{ item.name }}
                  </mat-chip-row>
                </ng-container>
              </mat-chip-listbox>
              <a class="activity-comment-link" (click)="navigateToActionSource(activity)">
                <span class="activity-source-type">{{ activitiesService.getCommentTypeTag(activity) }}</span>
                <i class="material-symbols-rounded">chevron_right</i> {{ activitiesService.getSourceName(activity) }}</a>
            </div>

            <div class="activity-date">
              <p>{{ activity.createdTs | date }}</p>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>
