<div class="library-roles-mapping builder-modal-columns">
  <div class="library-roles-mapping-row">
    <div class="title-main">
      <div class="title-div"><div class="orange">Rate Card:</div> {{sourceRateCard.name}}</div>
      <div class="title-div"><div class="orange">Version:</div> {{sourceRateCard.version}}</div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="title fc">Role</div>
        <div class="title sc">{{'agency' | lang }}<br>Rate</div>
      </div>
      <ul>
        <li *ngFor="let role of mappingRoles | orderBy: 'hierarchyIndex'">
          <div class="left">{{role.name}}</div>
          <div class="right">{{role.rate.amount | currency: role.rate.currency}}</div>
        </li>
      </ul>
    </div>
  </div>
  <div class="library-roles-mapping-row">

    <div class="title-main">
      <div class="title-div"><div class="orange">Rate Card:</div> {{ratecard!.name}}</div>
      <div class="title-div"><div class="orange">Version:</div> {{ratecard!.version}}</div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="title fc">Role</div>
        <div class="title sc">{{'agency' | lang }}<br>Rate</div>
      </div>
      <ul>
        <li  *ngFor="let role of mappingRoles | orderBy: 'hierarchyIndex'">
          <div class="left">
            <scope-ui-dropdown
              [dropdownOptions]="targetRoles"
              [isStandardDropdown]="true"
              [selectedValue]='role.targetMappingRole'
              (onSelectionChange)="selectTargetRole($event, role)" />
          </div>
          <div class="right">
            <span *ngIf="role.targetMappingRole?.id">{{role.targetMappingRole.rate.amount | currency: role.targetMappingRole.rate.currency}}</span>
            <span *ngIf="!role.targetMappingRole?.id" class="orange">--</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
