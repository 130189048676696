import { Pipe, PipeTransform } from '@angular/core'
import { formatHours } from '@shared/utils/utils'

@Pipe({ name: 'formatHours', pure: true })
export class FormatHoursPipe implements PipeTransform {
    transform(hours: number | string) {
      try {
        let format = formatHours(hours, " hrs");
        if (format != null){
          return format;
        }
      } catch (ignore) {}
      return "--"
    }
}
