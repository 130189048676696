<div class='switch-radio-container'>
  <label
    class='radio-button'
    *ngFor='let item of items'
    [class.checked]='value === item.value'
  >
    <input
      type='radio'
      [value]='item.value'
      [ngModel]='value'
      (ngModelChange)='change($event)'
      [name]='name'
      [disabled]='disabled'
    />
    <span class='radio-visualization'>
      <span class='radio-visualization__inner'></span>
    </span>
    <span class='radio-button__label'>{{item.name}}</span>
  </label>
</div>
