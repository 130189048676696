<div class="flex flex-row gap-4">
  <scope-ui-dropdown
    class="{{ level && level.value !== 'scope' ? 'dropdown-sm' : 'flex-1' }}"
    [selectedValue]="level"
    [controlLabel]="'Level'"
    [dropdownOptions]="levels"
    [isStandardDropdown]="true"
    (onSelectionChange)="onSelectLevel($event)" />
  <scope-ui-autocomplete
    class="flex-1"
    *ngIf="level?.value === 'section'"
    [label]="'Name'"
    [initialValue]='linkTo'
    [options]="currentScope.sections"
    [hasIcon]="true"
    [iconName]="'search'"
    [hideToggleIcon]="false"
    (onSelectionChange)="onSelectLinkTo($event)" />
  <scope-ui-autocomplete
    class="flex-1"
    *ngIf="level?.value === 'deliverable'"
    [label]="'Name'"
    [initialValue]='linkTo'
    [options]="currentScope.deliverables"
    [hasIcon]="true"
    [iconName]="'search'"
    [hideToggleIcon]="false"
    [showBreadcrumbs]="true"
    (onSelectionChange)="onSelectLinkTo($event)" />
  <scope-ui-autocomplete
    class="flex-1"
    *ngIf="level?.value === 'component'"
    [label]="'Name'"
    [initialValue]='linkTo'
    [options]="(components$ | async)!"
    [filterByAction]="false"
    [hasIcon]="true"
    [iconName]="'search'"
    [hideToggleIcon]="false"
    [showBreadcrumbs]="true"
    (onSelectionChange)="onSelectLinkTo($event)" />
</div>
