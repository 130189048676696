<svg width="12" height="12" viewBox="0 0 12 12" fill="#FF6B00" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M6 11L3 8.00001L3.7125 7.28751L6 9.57501L8.2875 7.28751L9 8.00001L6 11Z"
    [attr.fill]="
      column === sortedColumn && sortDirection === 'desc'
        ? '#FF6B00'
        : column === sortedColumn && sortDirection === 'asc'
        ? '#333844'
        : '#FF6B00'
    " />
  <path
    d="M3.725 4.7L3 4L6 1L9 4L8.275 4.7L6 2.425L3.725 4.7Z"
    [attr.fill]="
      column === sortedColumn && sortDirection === 'asc'
        ? '#FF6B00'
        : column === sortedColumn && sortDirection === 'desc'
        ? '#333844'
        : '#FF6B00'
    " />
</svg>
