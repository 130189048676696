import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import {
  ScopeUiAutocompleteComponent
} from '@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component';
import {
  ScopeUiDropdownComponent
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { User } from '@core/model/user.model';
import { LanguageService } from '@core/service/language.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { filter, Observable } from 'rxjs';
import { ScopeComponent } from '@app/features/scoping/models/component.model';
import { ScopeVersion } from '@core/model/scope-version';
import { Store } from '@ngrx/store';
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector';

@Component({
  selector: 'link-to-selector',
  imports: [CommonModule, SharedModule, ScopeUiAutocompleteComponent, ScopeUiDropdownComponent],
  templateUrl: './link-to-selector.component.html',
  styleUrls: ['./link-to-selector.component.scss'],
  standalone: true,
})
export class LinkToSelectorComponent implements OnInit {
  @Input() currentUser: User
  @Input() level: { name: string, value: string }
  @Input() linkTo: any
  @Output() levelChange = new EventEmitter<{ name: string, value: string }>()
  @Output() linkToChange = new EventEmitter<any>()

  levels = []
  currentScope: ScopeVersion
  components$: Observable<ScopeComponent[]>

  constructor(private lang: LanguageService, private store: Store) {
    this.components$ = this.store.select(ScopeOverviewSelectors.selectSearchedNonDistinctComponentsForScope);
  }

  ngOnInit() {
    this.setLevels()
    this.store
      .select(ScopeOverviewSelectors.selectCurrentScope)
      .pipe(
        filter((currentScope) => !!currentScope)
      )
      .subscribe((currentScope) => {
        this.currentScope = currentScope
      });
  }

  setLevels() {
    if (this.currentUser.company.hasApplicationSetting('SCOPE__ENABLE_FEES')) {
      this.levels.push({ name: this.lang.get('scope'), value: 'scope' })
    }
    if (this.currentUser.company.hasApplicationSetting('SCOPE__ENABLE_SECTION_FEES')) {
      this.levels.push({ name: this.lang.get('stage'), value: 'section' })
    }
    if (this.currentUser.company.hasApplicationSetting('DELIVERABLE__ENABLE_FEES')) {
      this.levels.push({ name: this.lang.get('deliverable'), value: 'deliverable' })
    }
    if (this.currentUser.company.hasApplicationSetting('COMPONENT__ENABLE_FEES')) {
      this.levels.push({ name: this.lang.get('component'), value: 'component' })
    }
  }

  onSelectLevel($event) {
    this.level = $event
    this.linkTo = null
    this.levelChange.emit($event)
  }

  onSelectLinkTo({ event }: { event: MatAutocompleteSelectedEvent }) {
    if (this.linkTo === event) {
      return
    }
    if (event) {
      this.linkTo = event
      this.linkToChange.emit(event)
    }
  }
}
