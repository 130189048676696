import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { featureKey, reducers } from './store/reducers';
import { CompanyManagementEffects } from './store/effects/company-management.effects';

const EFFECTS = [CompanyManagementEffects];

@NgModule({
  imports: [StoreModule.forFeature(featureKey, reducers, {}), EffectsModule.forFeature(EFFECTS)],
})
export class CompanyManagementNgrxModule {}
