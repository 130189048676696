import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs'
import { catchError, concatMap, exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { AuthRequest } from '@app/core/model/definitions/auth-request.interface';
import { AuthService } from '../../service/auth.service';
import { AuthActionTypes, AuthActions } from '@core/store';
import { LoaderModalService } from '@shared/components/loader-modal/service/loader-modal.service';
import { AuthError } from '@app/core/model/definitions/auth-error.interface';
import { select, Store } from '@ngrx/store'
import { BootstrapSelectors } from '../selectors/bootstrap.selector';
import { BootstrapActions } from '../actions';
import { User } from '@core/model/user.model';
import { LoginService } from '@app/features/user-account/services/login.service';
import { Company } from '@app/core/model/company.model';
import { UserService } from '@app/features/user-account/services/user.service';
import { EnvService } from '@envs/env-service';
import {
  SnackbarEventType,
  SNACKBAR_LENGTH_LONG,
  SnackbarService,
  SNACKBAR_LENGTH_SHORT,
} from '@shared/utils/snackbar.service'
import { AuthSelectors } from '@core/store/selectors/auth.selector'
import { AuthenticationSuccessAction } from '@core/store/models/auth.action.interface'
import { authorisedAsChildCompany } from '@shared/utils/utils'

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private loginService: LoginService,
    private userService: UserService,
    private router: Router,
    private loader: LoaderModalService,
    private snackbarService: SnackbarService,
    private envService: EnvService,
    private store: Store
  ) { }

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActionTypes.LOGIN),
      map((action: { authenticatedUser: AuthRequest }) => action.authenticatedUser),
      switchMap((authParams: AuthRequest) => {
        return this.authService.login(authParams).pipe(
          mergeMap((user: User) => {
            user.mapUserPrivileges();
            user.mapGroupPrivileges();
            user.company.mapApplicationSettings();
            return [AuthActions.loginSuccess({ user: user })];
          }),
          tap(() => this.loader.hide()),
          catchError((response: { error: AuthError }) => {
            return of(AuthActions.loginFail({ error: response.error }));
          })
        );
      })
    );
  });

  authSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActionTypes.LOGIN_SUCCESS),
        tap((action: AuthenticationSuccessAction) => {
          this.store.select(BootstrapSelectors.selectBootstrapConfig).subscribe(
            bsConfig => {
              this.redirectToSubdomain(bsConfig?.host, action.user.company, true);
            }
          )
        })
      );
    },
    { dispatch: false }
  );

  authFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActionTypes.LOGIN_FAIL),
        withLatestFrom(this.store.pipe(select(AuthSelectors.selectUsername))),
        tap(([response, username]: [{error: AuthError}, string]) => {
          let error: string
          if (response.error.companyDisabled) {
            error = "Account has been disabled. Please contact your administrator.";
          } else if (response.error.emailVerificationNeeded) {
            this.router.navigate(['/account-verification-needed'], { queryParams: { email: username } });
          } else if (response.error.accountDisabled) {
            error = "Account has been disabled. Please contact your administrator.";
          } else if (response.error.blackListed) {
            error = response.error.errorMessage;
          } else if (response.error.redirectUrl) {
            this.router.navigateByUrl(response.error.redirectUrl)
            error = "Your password is expired. Please change your password."
          } else {
            error = "Invalid username or password. Please double-check your credentials and try again.";
          }
          this.snackbarService.showPrioritisedSnackbar(error, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        })
      );
    },
    { dispatch: false }
  );

  myAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActionTypes.MY_ACCOUNT),
      map((action: { payload: any }) => action.payload),
      concatMap(() => {
        return this.authService.getAccount().pipe(
          switchMap((user: User) => {
            user.mapUserPrivileges();
            user.mapGroupPrivileges();
            user.company.mapApplicationSettings();
            return [AuthActions.myAccountSuccess({ user: user }), BootstrapActions.loadUserPreferences()];
          }),
          catchError(() => {
            this.snackbarService.showSnackbar('Error during getting account details', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(AuthActions.myAccountFail())
          })
        );
      })
    );
  });

  myAccountSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActionTypes.MY_ACCOUNT_SUCCESS),
        tap((action: AuthenticationSuccessAction) => {
          this.store.select(BootstrapSelectors.selectBootstrapConfig).subscribe(
            bsConfig => {
              let company = authorisedAsChildCompany() ? action.user.company.parentCompany : action.user.company;
              this.redirectToSubdomain(bsConfig?.host, company, false);
            }
          )
        })
      );
    },
    { dispatch: false }
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.FORGOT_PASSWORD),
      map((action: { username: string, captchaToken: string }) => ({
        username: action.username,
        captchaToken: action.captchaToken,
      })),
      exhaustMap((payload: { username: string; captchaToken: string }) =>
        this.authService.forgotPassword(payload.username, payload.captchaToken).pipe(
          map(() => AuthActions.forgotPasswordSuccess({ forgotSuccess: true })),
          catchError((error) => {
            this.snackbarService.showSnackbar('Error during password reset request', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(AuthActions.forgotPasswordFail({ error }))
          })
        )
      )
    )
  );

  forgotPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActionTypes.FORGOT_PASSWORD_SUCCESS),
        tap(() => {
          this.router.navigate(['/auth/forgot-password-success']);
        })
      );
    },
    { dispatch: false }
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.RESET_PASSWORD),
      map((action: { password: string; token: string }) => ({ password: action.password, token: action.token })),
      exhaustMap((payload: { password: string; token: string }) =>
        this.authService.resetPassword({ password: payload.password, token: payload.token }).pipe(
          map(() => AuthActions.resetPasswordSuccess()),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              this.snackbarService.showSnackbar('Token invalid. It`s outdated or already used', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
              console.log('%c Token Invalid', 'background: red; color: white; font-weight:bold; font-size:16px');
            }
            return of(AuthActions.resetPasswordFail({ error }));
          })
        )
      )
    )
  );

  resetPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActionTypes.RESET_PASSWORD_SUCCESS),
        tap(() => {
          this.snackbarService.showSnackbar('Password successfully updated', SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
          this.router.navigate(['/login']);
        })
      );
    },
    { dispatch: false }
  );

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActionTypes.LOGOUT),
      switchMap(() => {
        return this.authService.logout().pipe(
          map(() => AuthActions.logOutSuccess())
        );
      })
    );
  });

  logoutSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActionTypes.LOGOUT_SUCCESS),
        tap(() => {
          this.router.navigateByUrl('/login');
        })
      );
    },
    { dispatch: false }
  );

  getChildCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActionTypes.GET_CHILD_COMPANIES),
      switchMap(() => {
        return this.userService.getChildCompanies().pipe(
          map((childCompanies) => AuthActions.getChildCompaniesSuccess({childCompanies: childCompanies})),
          catchError((error) => {
            this.snackbarService.showSnackbar('Error during loading child accounts', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(AuthActions.getChildCompaniesFail({error}))
          })
        );
      })
    );
  });

  getChildCompaniesAccessRules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActionTypes.GET_CHILD_COMPANIES_ACCESS_RULES),
      switchMap(() => {
        return this.userService.getUserChildCompanyAccessRules().pipe(
          map((rules) => AuthActions.getChildCompaniesAccessRulesSuccess({childCompaniesAccessRules: rules})),
          catchError((error) => {
            this.snackbarService.showSnackbar('Error during loading child accounts access rules', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(AuthActions.getChildCompaniesAccessRulesFail({error}))
          })
        );
      })
    );
  });

  getUserNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActionTypes.GET_USER_NOTIFICATIONS),
      switchMap(() => {
        return this.userService.getUserNotifications(50, 0).pipe(
          map((userNotifications) => AuthActions.getUserNotificationsSuccess({notifications: userNotifications.notifications, unreadNotificationsCount: userNotifications.unreadCount})),
          catchError((error) => {
            this.snackbarService.showSnackbar('Error during loading user notifications', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            return of(AuthActions.getUserNotificationsFail({error}))
          })
        );
      })
    );
  });

  saveCookieSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActionTypes.SAVE_COOKIE_SETTINGS),
      map((action: { categories: any }) => ({ categories: action.categories })),
      exhaustMap((payload: { categories: any }) =>
        this.userService.saveCookieSettings(payload.categories).pipe(
          map((data) => AuthActions.saveCookieSettingsSuccess({cookieSettings: data})),
          catchError((error) => {
            this.snackbarService.showSnackbar(`An error occurred during update cookie settings`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.ERROR)
            return of(AuthActions.saveCookieSettingsFail({error}))
          })
        )
      )
    );
  });

  redirectToSubdomain(host: any, company: Company, isLogin: boolean): void {
    if (company) {
      const hostname = `${company.subdomain}.${host}`;
      if (window.location.hostname !== hostname) {
        this.router.navigate([], { queryParamsHandling: 'preserve', replaceUrl: true, skipLocationChange: true });
        const redirectTo = `${window.location.protocol}//${hostname}:${window.location.port}`
        window.location.replace(`${redirectTo}/scopes`);
        this.envService.baseUrl = `${redirectTo}`;
        return;
      }
    }

    if (isLogin) {
      this.router.navigateByUrl('/scopes');
    }
  }
}
