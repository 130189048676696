import { User } from '@app/core/model/user.model'
import { Discipline } from '@app/features/scope-overview/model/discipline.model'

interface ActivityUser {
  id: number
  firstName: string
  lastName: string
  fullName: string
  email: string
  photoUri: string
  enabled: boolean
  company: ActivityUserCompany
}

interface ActivityUserCompany {
  id: number
  name: string
}

export interface Source {
  id: number
  name: string
  description: null | string
  nonTranslatedName: null | string
  nonTranslatedDescription: null | string
  parentId: null | number
  foreignSource: null | any
  scopeType: null | any
  translated: boolean
}

export interface Comment {
  id: number
  sourceType: string
  source: Source
  text: string | undefined
  createdBy: ActivityUser
  createdTs: string
  mentionedUsers: any[]
  originType: string
  scopeVersion: {
    id: number
    name: string
  }
  cleanText: string | undefined
}

export interface ActivityComment {
  discriminator_type: string
  id: number
  sourceType: string
  source: Source
  comment?: Comment
  initiatedBy: ActivityUser
  createdTs: string
  attributes: Attributes
  action: string
}

export interface GroupedCommments {
  today: ActivityComment[]
  yesterday: ActivityComment[]
  current: ActivityComment[]
}

export interface PostComment {
  mentionedUsers: any[]
  text: string
}

export interface Collaborators {
  id: string
  user: User
}

export interface Mention {
  name: string
  email: string
  photoUri?: string
}

export enum ActivityTradingStatus {
  DELIVERABLE_TRADED = 'DELIVERABLE_TRADED',
  DELIVERABLE_TRADING_IN_PROGRESS = 'DELIVERABLE_TRADING_IN_PROGRESS',
}

export interface ActivityActionConfigItem {
  title: string
  key: string
  specialHandling?: boolean
}

export const activityActionConfig: Record<string, ActivityActionConfigItem[]> = {
  COMPONENT_UPDATE: [
    { title: 'Description', key: 'description' },
    { title: 'Quantity', key: 'quantity' },
  ],
  SCOPE_MSA_UPDATED: [
    { title: 'Amount', key: 'value' },
    { title: 'Name', key: 'name' },
    { title: 'Type', key: 'type' },
    { title: 'Charge Type', key: 'chargetype' },
  ],
  DOCX_CREATION_ON_APPROVAL: [{ title: 'DOCX download', key: 'docx_securelink_id', specialHandling: true }],
  DELIVERABLE_TRADING_CANCELLED: [
    { title: 'Trading Cancelled', key: 'traded_to', specialHandling: true }, // you can add more keys if needed
    { title: 'Trading Cancelled', key: 'traded_from', specialHandling: true },
  ],
  DELIVERABLE_TRADING_IN_PROGRESS: [
    { title: 'Trading In Progress', key: 'traded_to', specialHandling: true },
    { title: 'Trading In Progress', key: 'traded_from', specialHandling: true },
    { title: 'Trading In Progress', key: 'added', specialHandling: true },
  ],
  DELIVERABLE_TRADED: [
    { title: 'Deliverable Traded', key: 'traded_to', specialHandling: true },
    { title: 'Deliverable Traded', key: 'traded_from', specialHandling: true },
    { title: 'Deliverable Traded', key: 'added', specialHandling: true },
  ],
}

export interface Budget {
  amount: number | undefined
  currency: string
}

export interface Item {
  id: number
  name: string | null
  description: null
  nonTranslatedName: null
  nonTranslatedDescription: null
  parentId: null
  foreignSource: null
  scopeType: null
  discipline: Discipline
  sourceType?: string
  budget: Budget
  translated: boolean
}

export interface Attributes {
  added?: Item
  traded_to: Item[]
  traded_from: Item
  // createdTs: string
  // discriminator_type: string
  // id: number
}

export type Activities = ActivityComment[]
