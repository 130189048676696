import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router'
import { catchError } from 'rxjs/operators';
import { LoginService } from "@app/features/user-account/services/login.service";
import { SnackbarEventType, SNACKBAR_LENGTH_LONG, SnackbarService } from '@shared/utils/snackbar.service'
import { PrivilegeStringMap } from '@core/model/definitions/privilege.builder';
import { Store } from '@ngrx/store'

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private loginService: LoginService, private snackbarService: SnackbarService, private store: Store) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: any) => {
        if (response instanceof HttpErrorResponse) {
          this.handleError(response)
        } else {
          this.snackbarService.showDefaultErrorSnackbar()
        }
        return throwError(response);
      })
    );
  }

  handleError(response: any) {
    const status = response.status
    if (status == 403) {
      const errorMessage = 'You do not have permission to perform the requested action. Please contact your company administrator.'
      this.snackbarService.showPrioritisedSnackbar(`${errorMessage} ${this.makeRequiresPrivilegeMessage(response)}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
    } else if (status == 402) {
      const errorMessage = 'You need to upgrade your plan to perform this action.'
      this.snackbarService.showPrioritisedSnackbar(errorMessage, SNACKBAR_LENGTH_LONG, SnackbarEventType.WARNING)
    } else if (status == 404) {
      this.snackbarService.showPrioritisedSnackbar(`An error occurred. ${response.error.message}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
      setTimeout(() => {
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        this.router.navigateByUrl('/scopes')
      }, 2000)
    } else if (status == 401) {
      const errorMessage = 'No user was authorised for this request, please re-login'
      this.snackbarService.showSnackbar(errorMessage, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
    } else if (status == 502) {
      const errorMessage = 'Scope is currently down for maintenance. We expect to be back shortly. Thanks for your patience.'
      this.snackbarService.showPermanentSnackbar(errorMessage, SnackbarEventType.WARNING)
      this.router.navigateByUrl('/error');
    } else if (status.toString().startsWith('5')) {
      this.snackbarService.showDefaultErrorSnackbar()
    }
  }

  makeRequiresPrivilegeMessage(response: any) {
    if (!response.error || !response.error.message) {
      return null;
    }
    const parts = response.error.message.split(':');
    if (parts.length > 1) {
      let requiredPrivilege = PrivilegeStringMap.get(parts[1].trim());
      if (requiredPrivilege && requiredPrivilege.getDescription()) {
        return "Required: " + requiredPrivilege.getDescription();
      } else return null;
    } else {
      return null;
    }
  }
}
