import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Injectable } from '@angular/core'
import { LoaderModalComponent } from '../loader-modal.component'

@Injectable()
export class LoaderModalService {
    // TODO: Refactor into re-usable component.
    private overlayRef: OverlayRef | null = null

    constructor(private overlay: Overlay) {}

    public show(ctx = { overlay: false }) {
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create()
        }

        const spinnerOverlayPortal = new ComponentPortal(LoaderModalComponent)
        const component = this.overlayRef.attach(spinnerOverlayPortal)
        component.instance.overlay = ctx.overlay
    }

    public hide() {
        if (!!this.overlayRef) {
            this.overlayRef.detach()
        }
    }
}
