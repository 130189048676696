<div class="container">
  <div class="flex gap-10">
    <div>
      <ul>
        <li>
          <h2><a style="color: black" [routerLink]="['/ui-demo/']">UI Demo</a></h2>
          <h2><a style="color: black" [routerLink]="['/ui-demo/breadcrumbs/']">Breadcrumbs</a></h2>
          <h2><a style="color: black" [routerLink]="['/ui-demo/breadcrumbs/level-1']">First Breadcrumb</a></h2>
          <h2><a style="color: black" [routerLink]="['/ui-demo/breadcrumbs/level-2']">Second Breadcrumb</a></h2>
          <h2><a style="color: black" [routerLink]="['/ui-demo/breadcrumbs/level-2/level-3']">Third Breadcrumb</a></h2>
        </li>
      </ul>
    </div>
  </div>

  <div class="p-5">
    <scope-ui-go-back-breadcrumb [goBackRouteLink]="'/scopes'" [goBackToMessage]="'Back to Templates gallery'" />
  </div>

  <router-outlet></router-outlet>
</div>
