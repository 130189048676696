<div class="main-login-wrapper">
  <section class="page-header">
    <div class="scope-row">
      <div class="four--columns">
        <h2 class="page-header__title">Sign in</h2>
      </div>
    </div>
  </section>

  <section class="login-container">
    <div class="login-content scope-pod">
      <h4 class="scope-pod__title">Sign in</h4>
      <div class="login-form-content scope-pod__form">
        <h4 *ngIf="accountVerification && isAccountVerified" class="scope-pod__title">Account-verified, sign in to your Scope account</h4>
        <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">
          <mat-form-field [hidden]="hideUsernamePasswordFields" class="form-group">
            <input
              matInput
              type="email"
              name="username"
              placeholder="Email"
              formControlName="username"
              class="login-input"
              required
              (input)="idpDiscovery()" />
          </mat-form-field>

          <mat-form-field [hidden]="hideUsernamePasswordFields" class="form-group">
            <input
              matInput
              name="password"
              placeholder="Password"
              type="password"
              formControlName="password"
              required />
          </mat-form-field>

          <ng-container *ngIf="captchaEnabled">
            <re-captcha
              class="mb-2"
              formControlName="recaptcha"
              (resolved)="resolved($event)"
              [siteKey]="(companyBootstrap$ | async)?.cdnConfig.googleRecaptchaPublicKey"></re-captcha>
          </ng-container>

          <div *ngIf="isFormTouched && loginForm.invalid && (loginForm.dirty || loginForm.touched)">
            <div class="warning warning--broken login-warning">
              <mat-error *ngIf="username?.errors?.['pattern']"> Invalid email </mat-error>

              <mat-error *ngIf="username?.errors?.['required']"> Please enter your email </mat-error>

              <mat-error *ngIf="password?.errors?.['required']"> Please enter your password </mat-error>

              <mat-error *ngIf="password?.errors?.['minlength']?.requiredLength">
                Must be at least 8 characters!
              </mat-error>

              <mat-error *ngIf="password?.errors?.['maxlength']"> Cannot exceed 100 characters! </mat-error>

              <mat-error *ngIf="loginForm?.hasError('badCreds')">
                Invalid credentials - Please check and try again
              </mat-error>

              <mat-error *ngIf="loginForm?.hasError('accountDisabled')">
                Company Account Locked - Please contact your administrator
              </mat-error>

              <mat-error *ngIf="loginForm?.hasError('userDisabled')">
                User Account Locked - Please contact your administrator
              </mat-error>

              <mat-error *ngIf="loginForm.get('recaptcha')?.hasError('required')">
                Please complete the reCAPTCHA verification to proceed.
              </mat-error>
            </div>
          </div>

          <button [disabled]="loginForm.invalid" [ngClass]="{ 'btn--disabled': loginForm.invalid }" [hidden]="hideUsernamePasswordFields" class="btn--action">
            Sign in
          </button>
          <div class="fine-print" [hidden]="hideUsernamePasswordFields">
            <a class="right forgot-password" routerLink="/auth/forgot-password">Forgotten password?</a>
          </div>

          <sso-login-form
            [isFormInvalid]="false"
            (onLoginSaml)="onLoginSaml()"
            [isSubdomainIdpAvailable]="companyBootstrap$ | async"
            [isUsernameIdpAvailable]="isIdpAvailable$ | async" />
        </form>
      </div>
    </div>
  </section>
</div>
