import { Component, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Attributes, Item } from '@app/features/scope-overview/model/activity.model'

@Component({
  selector: 'from-to-component',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './from-to-component.component.html',
  styleUrls: ['./from-to-component.component.scss'],
})
export class FromToComponentComponent implements OnInit {
  @Input() title!: string
  @Input() attributes!: Attributes | undefined

  tradedTo: Item | undefined

  constructor() {}

  ngOnInit(): void {
    if (this.attributes?.traded_to && this.attributes.traded_to.length) {
      this.tradedTo = this.attributes?.traded_to[0]
    }
  }
}
