<ng-container
  *ngIf="isUsernameIdpAvailable?.isAvailable || isSubdomainIdpAvailable?.companyBootstrap?.samlDiscovery?.idp || isSubdomainIdpAvailable?.companyBootstrap?.useSsoLoginOnly">
  <div class="creds-signin available-idp" [ngClass]="{'sso-only': isSubdomainIdpAvailable?.companyBootstrap?.useSsoLoginOnly}">
    <span *ngIf="!isSubdomainIdpAvailable?.companyBootstrap?.useSsoLoginOnly" class="signin-via-scope-msg">
      Or via your company's SSO
    </span>
    <button
      [disabled]="isFormInvalid"
      [ngClass]="{ 'btn--disabled': isFormInvalid }"
      class="btn btn--inline btn--action btn--large btn--full"
      (click)="onLoginSamlProvider()">
      Sign in via
      {{ isUsernameIdpAvailable?.name || isSubdomainIdpAvailable?.companyBootstrap?.samlDiscovery?.name }}
    </button>
  </div>
</ng-container>
