import { Action, createReducer, on } from '@ngrx/store';
import { DashboardOverviewState } from '../../model/dashboard-overview.state.interface';

export const initialState: DashboardOverviewState = {};

export const DashboardOverviewReducer = createReducer(initialState);

export function reducer(state: DashboardOverviewState | undefined, action: Action): DashboardOverviewState {
  return DashboardOverviewReducer(state, action);
}
