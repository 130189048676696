import { Component, Input, OnInit } from '@angular/core'
import { LoginService } from '@app/features/user-account/services/login.service';
import { UserRoleLevel } from '@app/core/model/enums/user-role-level.enum';
import { Privilege } from "@app/core/model/enums/privilege.enum";
import { User } from '@core/model/user.model';
import { AuthActions, CoreState } from '@core/store'
import { Store } from '@ngrx/store';
import { RouteUtils } from '@shared/utils/route-util.service';
import { Tab, Tabs, TabUtil } from '@shared/utils/tabs-util.service';
import { isArrayOfStrings } from '@shared/utils/utils'
import { LanguageService } from "@core/service/language.service";
import { CookieFooterComponent } from '@app/features/user-account/components/cookie-footer/cookie-footer.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'main-nav',
  templateUrl: 'main-nav.component.html',
})
export class MainNavComponent implements OnInit {
  constructor(
    private store: Store<CoreState>,
    private loginService: LoginService,
    private routeUtils: RouteUtils,
    private tabUtils: TabUtil,
    private lang: LanguageService,
    private dialog: MatDialog
  ) { }

  @Input() loggedInUser!: User;
  user: any;

  tabs!: Tabs;

  ngOnInit(): void {
    this.createTabs();
    this.showCookieFooter();
  }

  private createTabs() {
    this.tabs = this.tabUtils.createTabs(
      [
        {
          name: `My ${this.lang.get('scope.p')}`,
          default: true,
          href: this.routeUtils.urlMyScopes(),
          opts: {
            location: [
              { exactMatch: '/' },
              {
                like: [
                  this.routeUtils.urlMyScopes(),
                  this.routeUtils.urlScopeOverview(),
                  this.routeUtils.urlSowOverview(),
                ],
              },
            ],
          },
        },
        {
          name: 'Manage',
          href: '/manage',
          privileges: [
            Privilege.COMPANY_MEMBERS__VIEW,
            Privilege.SECOND_PARTY__VIEW,
            Privilege.RATECARD__VIEW,
            Privilege.COMPANY_TRAFFIC_SYSTEM__VIEW,
            Privilege.COMPANY_DETAILS__VIEW,
          ],
        },
        {
          name: 'Library',
          href: '/library',
          privileges: [
            Privilege.LIBRARY_COMPONENT__VIEW,
            Privilege.LIBRARY_DELIVERABLE__VIEW,
            Privilege.LIBRARY_THIRD_PARTY_COST__VIEW,
          ],
        },
        {
          name: 'Dashboards',
          href: '/dashboard/quantity',
          opts: {
            location: [
              { exactMatch: '/dashboard' },
              {
                like: [
                  '/dashboard/quantity',
                  '/dashboard/downloads',
                  '/dashboard/finance',
                  '/dashboard/scopes_by_value',
                  '/dashboard/scopes_by_role',
                ],
              },
            ],
          },
          privileges: [Privilege.DASHBOARD__VIEW],
        },
        {
          name: 'System Admin',
          href: '/system-management',
          role: UserRoleLevel.SYSTEM,
        },
      ],
      this.loggedInUser
    );
  }

  showCookieFooter() {
    if (!this.loggedInUser.cookieSettings || !this.loggedInUser?.cookieSettings.valid) {
      let dialog = this.dialog.open(CookieFooterComponent)
      dialog.componentInstance.currentUser = this.loggedInUser
      dialog.componentInstance.onSubmit.subscribe((categories) => {
        dialog.close()
        this.store.dispatch(AuthActions.saveCookieSettings({ categories }))
      })
    }
  }

  isTabActive(tab: Tab) {
    return tab.isSelected() || this.tabHasCurrentLocation(tab);
  }

  tabHasCurrentLocation(tab: Tab) {
    if (tab.definition.opts?.location != null) {
      return (
        tab.definition.opts?.location.filter((l) => {
          if (l.exactMatch) {
            if (isArrayOfStrings(l.like)) {
              return (l.like as string[]).some((l) => location.pathname == l);
            }
            return location.pathname == (l.exactMatch as string);
          }
          if (l.like) {
            if (isArrayOfStrings(l.like)) {
              return (l.like as string[]).some((l) => location.pathname.indexOf(l) != -1);
            }
            return location.pathname.indexOf(l.like as string) != -1;
          }
          return false;
        }).length > 0
      );
    }
    return false;
  }
}
