import { createReducer, Action } from '@ngrx/store';
import { LibraryManagementState } from '../../models/library-management.state.interface';

export const initialState: LibraryManagementState = {};

export const LibraryManagementReducer = createReducer(initialState);

export function reducer(state: LibraryManagementState | undefined, action: Action): LibraryManagementState {
  return LibraryManagementReducer(state, action);
}
