import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, Validators } from '@angular/forms';
import { SharedModule } from "@shared/shared.module";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgxMaskDirective } from "ngx-mask";
import { MatInput } from '@angular/material/input'
import { getCurrencySymbol } from '@shared/utils/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(utc)
dayjs.extend(customParseFormat)

@Component({
  selector: 'scope-ui-input',
  standalone: true,
  imports: [CommonModule, FormsModule, SharedModule, NgxMaskDirective],
  templateUrl: './scope-ui-input.component.html',
  styleUrls: ['./scope-ui-input.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        width: '200px'
      })),
      state('expanded', style({
        width: '400px'
      })),
      transition('collapsed <=> expanded', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})

export class ScopeUiInputComponent implements AfterViewInit {
  @Input() inputLabel!: string;

  @Input() inputPlaceholder!: string;

  @Input() hasPlaceholder!: boolean;

  @Input() icon!: string;

  @Input() cancelIcon!: string;

  @Input() resultsCount?: number;

  @Input() set initialValue(value: string | number | null) {
    this.value = value
    this.control.setValue(value)
    this.cdr.detectChanges()
  }

  @Input() type?: string;

  @Input() set isDisabled(value: boolean) {
    if (value) {
      this.control.disable()
    } else {
      this.control.enable()
    }
  }

  @Input() required = false;

  @Input() minimum?: number;

  @Input() maximum?: number;

  @Input() minimumLength?: number;

  @Input() maximumLength?: number;

  @Input() minimumDate?: Date;

  @Input() maximumDate?: Date;

  @Input() parseNumber: boolean = false;

  @Input() preventPaste: boolean = false;

  @Input() dateFormat: string;

  value: any
  _isCurrency: boolean = false
  _isPercentage: boolean = false
  _isDate: boolean = false
  mask: string
  suffix: string
  prefix: string
  @Input() set isCurrency(value: boolean) {
    this._isCurrency = value
    if (value) {
      this.mask = 'separator.2'
      this.prefix = this.currencySymbol
      this.suffix = ''
    }
  }
  @Input() set isPercentage(value: boolean) {
    this._isPercentage = value
    if (value) {
      this.mask = 'separator.2'
      this.prefix = ''
      this.suffix = '%'
    }
  }
  @Input() set isDate(value: boolean) {
    this._isDate = value
    if (value) {
      this.mask = '99/99/9999'
      this.prefix = ''
      this.suffix = ''
    }
  }
  @Input() set isInteger(value: boolean) {
    if (value) {
      this.mask = '0*'
    }
  }

  @Input() set currency (value: string) {
    this.currencySymbol = getCurrencySymbol(value)
    this.prefix = this._isCurrency ? this.currencySymbol : ''
  }

  @Input() isTextarea: boolean = false;

  @Input() textareaRows?: number;

  @Input() inputClass?: string;

  @Input() autofocus?: boolean;

  @Output() onInputChange = new EventEmitter<any>();

  @Output() onInputBlurChange = new EventEmitter<any>();

  @Output() onEnter = new EventEmitter<any>();

  @Output() onCancel = new EventEmitter<void>();

  @ViewChild(MatInput) input: MatInput

  @Input() control: FormControl = new FormControl()

  private readonly BACKSPACE_KEY_CODE: number = 8

  currencySymbol: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.minimum != undefined) {
      this.control.addValidators(Validators.min(this.minimum));
    }
    if (this.maximum) {
      this.control.addValidators(Validators.max(this.maximum));
    }
    if (this.minimumDate && dayjs.utc(this.value, this.dateFormat).format('YYYY-MM-DD') < dayjs.utc(this.minimumDate).format('YYYY-MM-DD')) {
      this.value = dayjs.utc(this.minimumDate).format(this.dateFormat)
      this.control.setValue(this.value)
    }
    if (this.maximumDate && dayjs.utc(this.value, this.dateFormat).format('YYYY-MM-DD') > dayjs.utc(this.maximumDate).format('YYYY-MM-DD')) {
      this.value = dayjs.utc(this.maximumDate).format(this.dateFormat)
      this.control.setValue(this.value)
    }
  }

  ngAfterViewInit() {
    if (this.autofocus) setTimeout(() => this.input.focus())
  }

  onInput(event: Event) {
    let inputValue = (event.target as HTMLInputElement).value;
    if (this.parseNumber) {
      if (this._isCurrency && this.currencySymbol != '') inputValue = inputValue.replace(this.currencySymbol, '')
      let value = parseFloat(inputValue.replace(/,/g, ''))
      this.onInputChange.emit(!isNaN(value) ? value : undefined);
    } else if (this._isDate) {
      let date = dayjs.utc(inputValue, this.dateFormat);
      if (date.isValid()) {
        if (!(date.format('YYYY-MM-DD') >= dayjs.utc(this.minimumDate).format('YYYY-MM-DD') &&
            date.format('YYYY-MM-DD') <= dayjs.utc(this.maximumDate).format('YYYY-MM-DD'))) {
          this.control.setValue(this.value)
        } else {
          this.value = inputValue
          this.onInputChange.emit(date.toDate())
        }
      }
    } else {
      if (this.maximumLength && inputValue.length > this.maximumLength) {
        inputValue = inputValue.slice(0, this.maximumLength);
        (event.target as HTMLInputElement).value = inputValue
      }
      this.onInputChange.emit(inputValue);
    }
  }

  onKeydown(event: KeyboardEvent) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.onKeydownCheck(inputValue, event);
    if (event.key === 'Enter' || event.which === 13) {
      if (this.parseNumber) {
        this.onEnter.emit(!isNaN(parseFloat(inputValue)) ? parseFloat(inputValue) : undefined);
      } else if (this._isDate) {
        let date = dayjs.utc(inputValue, this.dateFormat);
        if (date.isValid()) {
          if (!(date.format('YYYY-MM-DD') >= dayjs.utc(this.minimumDate).format('YYYY-MM-DD') &&
            date.format('YYYY-MM-DD') <= dayjs.utc(this.maximumDate).format('YYYY-MM-DD'))) {
            this.control.setValue(this.value)
          } else {
            this.value = inputValue
            this.onEnter.emit(date.toDate())
          }
        }
      } else {
        this.onEnter.emit(inputValue);
      }
      (event.target as HTMLInputElement).blur()
    }
  }

  onKeydownCheck(inputValue: string, event: KeyboardEvent) {
    if (this.maximumLength && inputValue.length > this.maximumLength && event.which !== this.BACKSPACE_KEY_CODE) {
      event.preventDefault();
    }
    if (this.minimum === 0 && event.key === '-') {
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  onInputBlur(event: Event) {
    let inputValue = (event.target as HTMLInputElement).value;
    if (this.parseNumber) {
      if (this._isCurrency && this.currencySymbol != '') inputValue = inputValue.replace(this.currencySymbol, '')
      let value = parseFloat(inputValue.replace(/,/g, ''))
      this.onInputBlurChange.emit(!isNaN(value) ? value : undefined);
    } else if (this._isDate) {
      let date = dayjs.utc(inputValue, this.dateFormat);
      if (date.isValid()) {
        if (!(date.format('YYYY-MM-DD') >= dayjs.utc(this.minimumDate).format('YYYY-MM-DD') &&
          date.format('YYYY-MM-DD') <= dayjs.utc(this.maximumDate).format('YYYY-MM-DD'))) {
          this.control.setValue(this.value)
        } else {
          this.value = inputValue
          this.onInputBlurChange.emit(date.toDate())
        }
      }
    } else {
      this.onInputBlurChange.emit(inputValue);
    }
  }

  cancel() {
    this.control.reset();
    this.onCancel.emit();
  }

  moveDate(increment: number) {
    let date = dayjs.utc(this.value, this.dateFormat).add(increment, 'day')
    if (date.format('YYYY-MM-DD') >= dayjs.utc(this.minimumDate).format('YYYY-MM-DD') &&
      date.format('YYYY-MM-DD') <= dayjs.utc(this.maximumDate).format('YYYY-MM-DD')) {
      this.value = date.format(this.dateFormat)
      this.control.setValue(this.value)
      this.onInputChange.emit(date.toDate())
    }
  }
}
