<form *ngIf="singleDatePicker && !inlineDatePicker">
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>{{ inputLabel }}</mat-label>
    <input
      type="text"
      ngxDaterangepickerMd
      matInput
      [formControl]="dateControl"
      [disabled]="disabled"
      [autoApply]="true"
      [lockStartDate]="false"
      [locale]="locale"
      [singleDatePicker]="true"
      [minDate]="_minDateValue"
      [maxDate]="_maxDateValue"
      (startDateChanged)="onChange($event)"
      [opens]="opens"/>
  </mat-form-field>
</form>
<ngx-daterangepicker-material
  *ngIf="singleDatePicker && inlineDatePicker"
  [autoApply]="true"
  [locale]="locale"
  [minDate]='_minDateValue'
  [maxDate]="_maxDateValue"
  [singleDatePicker]="true"
  (choosedDate)="onChange($event)">
</ngx-daterangepicker-material>
<ngx-daterangepicker-material
  *ngIf="!singleDatePicker"
  [autoApply]="true"
  [locale]="locale"
  [minDate]='_minDateValue'
  [maxDate]="_maxDateValue"
  [isCustomDate]="isCustomDate"
  (choosedDate)="_onSelectedChange($event)">
</ngx-daterangepicker-material>
