import { createAction, emptyProps, props } from '@ngrx/store';
import { AuthRequest } from '@app/core/model/definitions/auth-request.interface';
import { AuthError } from '@app/core/model/definitions/auth-error.interface';
import { User } from '../../model/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Company } from '@core/model/company.model';
import { UserNotification } from '@core/model/user-notification.model';

export enum AuthActionTypes {
  LOGIN = '[AUTH] Login',
  LOGIN_SUCCESS = '[AUTH] Login Success',
  LOGIN_FAIL = '[AUTH] Login Failure',
  INIT_ACCOUNT = '[AUTH] Init Account',
  MY_ACCOUNT = '[AUTH] My Account',
  MY_ACCOUNT_SUCCESS = '[AUTH] My Account Success',
  MY_ACCOUNT_FAIL = '[AUTH] My Account Fail',
  LOGOUT = '[AUTH] Logout',
  LOGOUT_SUCCESS = '[AUTH] Logout Success',
  FORGOT_PASSWORD = '[AUTH] Forgot Password',
  FORGOT_PASSWORD_SUCCESS = '[AUTH] Forgot Password Success',
  FORGOT_PASSWORD_FAIL = '[AUTH] Forgot Password Fail',
  RESET_PASSWORD = '[AUTH] Reset Password',
  RESET_PASSWORD_SUCCESS = '[AUTH] Reset Password Success',
  RESET_PASSWORD_FAIL = '[AUTH] Reset Password Fail',
  GET_CHILD_COMPANIES = '[Header] Get Child Companies',
  GET_CHILD_COMPANIES_SUCCESS = '[Header] Get Child Companies Success',
  GET_CHILD_COMPANIES_FAIL = '[Header] Get Child Companies Fail',
  GET_CHILD_COMPANIES_ACCESS_RULES = '[Header] Get Child Companies Access Rules',
  GET_CHILD_COMPANIES_ACCESS_RULES_SUCCESS = '[Header] Get Child Companies Access Rules Success',
  GET_CHILD_COMPANIES_ACCESS_RULES_FAIL = '[Header] Get Child Companies Access Rules Fail',
  GET_USER_NOTIFICATIONS = '[Header] Get User Notifications',
  GET_USER_NOTIFICATIONS_SUCCESS = '[Header] Get User Notifications Success',
  GET_USER_NOTIFICATIONS_FAIL = '[Header] Get User Notifications Fail',
  SAVE_COOKIE_SETTINGS = '[AUTH] Save User Cookie Settings',
  SAVE_COOKIE_SETTINGS_SUCCESS = '[AUTH] Save User Cookie Settings Success',
  SAVE_COOKIE_SETTINGS_FAIL = '[AUTH] Save User Cookie Settings Fail'
}

const login = createAction(AuthActionTypes.LOGIN, props<{ authenticatedUser: AuthRequest | null }>());
const loginSuccess = createAction(AuthActionTypes.LOGIN_SUCCESS, props<{ user: User }>());
const loginFail = createAction(AuthActionTypes.LOGIN_FAIL, props<{ error: AuthError }>());

const logOut = createAction(AuthActionTypes.LOGOUT);
const logOutSuccess = createAction(AuthActionTypes.LOGOUT_SUCCESS);

const initAccount = createAction(AuthActionTypes.INIT_ACCOUNT, props<{ userInitAccount: User }>());

const myAccount = createAction(AuthActionTypes.MY_ACCOUNT, (payload: any = null) => payload);
const myAccountSuccess = createAction(AuthActionTypes.MY_ACCOUNT_SUCCESS, props<{ user: User }>());
const myAccountFail = createAction(AuthActionTypes.MY_ACCOUNT_FAIL, emptyProps);

const forgotPassword = createAction(AuthActionTypes.FORGOT_PASSWORD, props<{ username: string, captchaToken: string }>());

const forgotPasswordSuccess = createAction(
  AuthActionTypes.FORGOT_PASSWORD_SUCCESS,
  props<{ forgotSuccess: boolean }>()
);

const forgotPasswordFail = createAction(AuthActionTypes.FORGOT_PASSWORD_FAIL, props<{ error: Error }>());

const resetPassword = createAction(AuthActionTypes.RESET_PASSWORD, props<{ password: string; token: string }>());

const resetPasswordSuccess = createAction(AuthActionTypes.RESET_PASSWORD_SUCCESS);

const resetPasswordFail = createAction(AuthActionTypes.RESET_PASSWORD_FAIL, props<{ error: HttpErrorResponse }>());

const getChildCompanies = createAction(AuthActionTypes.GET_CHILD_COMPANIES, emptyProps)
const getChildCompaniesSuccess = createAction(AuthActionTypes.GET_CHILD_COMPANIES_SUCCESS, props<{ childCompanies: Company[] }>())
const getChildCompaniesFail = createAction(AuthActionTypes.GET_CHILD_COMPANIES_FAIL, props<{ error: Error }>())

const getChildCompaniesAccessRules = createAction(AuthActionTypes.GET_CHILD_COMPANIES_ACCESS_RULES, emptyProps)
const getChildCompaniesAccessRulesSuccess = createAction(AuthActionTypes.GET_CHILD_COMPANIES_ACCESS_RULES_SUCCESS, props<{ childCompaniesAccessRules: any[] }>())
const getChildCompaniesAccessRulesFail = createAction(AuthActionTypes.GET_CHILD_COMPANIES_ACCESS_RULES_FAIL, props<{ error: Error }>())

const getUserNotifications = createAction(AuthActionTypes.GET_USER_NOTIFICATIONS, emptyProps)
const getUserNotificationsSuccess = createAction(AuthActionTypes.GET_USER_NOTIFICATIONS_SUCCESS, props<{ notifications: UserNotification[], unreadNotificationsCount: number }>())
const getUserNotificationsFail = createAction(AuthActionTypes.GET_USER_NOTIFICATIONS_FAIL, props<{ error: Error }>())

const saveCookieSettings = createAction(AuthActionTypes.SAVE_COOKIE_SETTINGS, props<{ categories: any[] }>());
const saveCookieSettingsSuccess = createAction(AuthActionTypes.SAVE_COOKIE_SETTINGS_SUCCESS, props<{ cookieSettings: any }>())
const saveCookieSettingsFail = createAction(AuthActionTypes.SAVE_COOKIE_SETTINGS_FAIL, props<{ error: Error }>())

export const AuthActions = {
  login,
  loginSuccess,
  loginFail,
  initAccount,
  myAccount,
  myAccountSuccess,
  myAccountFail,
  logOut,
  logOutSuccess,
  forgotPassword,
  forgotPasswordFail,
  forgotPasswordSuccess,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFail,
  getChildCompanies,
  getChildCompaniesSuccess,
  getChildCompaniesFail,
  getChildCompaniesAccessRules,
  getChildCompaniesAccessRulesSuccess,
  getChildCompaniesAccessRulesFail,
  getUserNotifications,
  getUserNotificationsSuccess,
  getUserNotificationsFail,
  saveCookieSettings,
  saveCookieSettingsSuccess,
  saveCookieSettingsFail
};
