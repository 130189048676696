import { Money } from '@app/features/scope-overview/model/money.model'
import { Type } from 'class-transformer'
import { Discipline } from '@app/features/scope-overview/model/discipline.model'

export class DeliverableType {
  id: number
  name: string
  description?: string
  internalNote?: string
  sourcePath?: any
  isTrialRestricted?: boolean
  sourceType?: string
  isFixedCost?: boolean
  ratecardVersionId?: number
  @Type(() => Money) complexityPriceS?: Money
  @Type(() => Money) complexityPriceM?: Money
  @Type(() => Money) complexityPriceL?: Money
  @Type(() => Money) complexityPriceXL?: Money
  discipline?: Discipline
  scopeMarkComponentsOverridden?: boolean
  parentId?: number
  companyId?: number

  getHtml(searchText: string) {
    let search = new RegExp(searchText, 'gi');
    let result = `<div class='flex flex-col'><span class='item-title ${this.isTrialRestricted ? 'trial' : ''}'>
        ${(this.name)?.replace(search, "<b>$&</b>")} ${this.discipline ? '(' + this.discipline.name + ')' : ''} ${this.isTrialRestricted ? ' - Premium Only' : ''}</span>`
    if (this.sourcePath) {
      result += `<span class='path'><small>${this.sourcePath}</small></span>`
    }
    result += `<span class="item-description">
        ${!this.description?.length ? "No description available": this.description}</span>
    </div>`
    if (this.sourceType == 'LIBRARY') {
      result += `<div class='flex flex-col'><i class='material-symbols-rounded orange'>${this.isFixedCost ? 'book' : 'description'}</i></div>`
    }
    return result
  };
}
