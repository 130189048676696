import { Injectable } from '@angular/core';
import { ScopeActions } from '@app/features/scoping/store/actions/scoping.actions';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { FindReplaceRole } from '../model/find-repace-roles.model';
import { FindRolesSearchParams } from '@core/model/definitions/findRoleSearchParams.interface'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { Role } from '@app/features/scoping/models/role.model'

@Injectable({
  providedIn: 'root',
})
export class FindReplaceService {
  constructor(private store: Store) {}

  private filteredRolesSource = new BehaviorSubject<Role[]>([]);

  filteredRoles$ = this.filteredRolesSource.asObservable();

  selectedRole: Partial<FindReplaceRole>

  onSearchReplaceRole(searchString: string, ratecard: RatecardVersion) {
    this.filteredRolesSource.next(
      ratecard.departments
        .map(it => it.roles).flat()
        .filter(it => it.name.toLowerCase().includes(searchString.toLowerCase())).filter(it => it.name != this.selectedRole?.name)
    );
  }

  removeDuplicates(arr: any[]) {
    const uniqueRoles = new Map();
    return arr.filter((item) => {
      if (!uniqueRoles.has(item.roleId)) {
        uniqueRoles.set(item.roleId, true);
        return true;
      }
      return false;
    });
  }

  onSearch(scopeId: number, searchData: FindRolesSearchParams) {
    this.store.dispatch(ScopeActions.findRoles({ scopeId: scopeId, searchParams: searchData }))
  }

  onSelectionChange(selectionEvent: any) {
    if (selectionEvent.componentId === 'roleName') {
      this.selectedRole = selectionEvent.event;
      this.store.dispatch(ScopeActions.setSelectedReplaceRole({ role: selectionEvent.event }));
    } else if (selectionEvent.componentId === 'roleReplaceName') {
      this.store.dispatch(ScopeActions.setSelectedReplaceWithRole({ role: selectionEvent.event }));
    }
  }
}
