<div *ngIf="isLoading" style="position: relative; overflow: hidden;">
  <div class="p-5 loading-container">
    <div class="loading-header">
      <h1 class="loading-header-title">Find and Replace Role</h1>
    </div>

    <div class="skeleton-loader">
      <div class="skeleton-item"></div>
      <div class="skeleton-item"></div>
      <div class="skeleton-item"></div>

      <div class="flex justify-between">
        <div class="skeleton-item thinner"></div>
        <div class="skeleton-item thinner small"></div>
      </div>

      <div class="flex justify-between">
        <div class="skeleton-item thinner"></div>
        <div class="skeleton-item thinner small"></div>
      </div>

      <div class="flex justify-between">
        <div class="skeleton-item thinner"></div>
        <div class="skeleton-item thinner small"></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!revertSummarySuccess && !replaceSummarySuccess && !isLoading" style="position: relative; overflow: hidden;">
  <div class="content-container p-5">
    <div class="find-replace-header">
      <h1 class="find-replace-title">Find and Replace Role</h1>
      <span (click)="closeDrawer()">
        Close
        <i class="material-symbols-rounded">close</i>
      </span>
    </div>

    <div class="find-replace-find-roles">
      <scope-ui-autocomplete
        [hasIcon]="true"
        [iconName]="'search'"
        [componentId]="'roleName'"
        [label]="'Find role'"
        [filterByAction]="true"
        [options]="availableRoles"
        (onSelectionChange)="onSelectedValue($event)"
        (onSearch)="onSearchAutoComplete($event)" />
      <scope-ui-input
        class="rate-card-field"
        [hasPlaceholder]="false"
        [inputPlaceholder]="''"
        [icon]="''"
        [initialValue]="selectedRole?.rate?.amount"
        [inputLabel]="'Rate'"
        [isDisabled]="true"
        [isCurrency]='true'
        [currency]="currencyCode"
        (onInputChange)="onSearchKeyPressed($event)" />
    </div>

    <div class="find-replace-filter-more">
      <span (click)="onExpandFilter()">
        <p>Filter More</p>
        <i class="material-icons material-symbols-rounded">{{ showMoreFilters ? 'expand_less' : 'expand_more' }}</i>
      </span>

      <div *ngIf="showMoreFilters">
        <div class="find-replace-filter-inputs">
          <scope-ui-autocomplete [filterByAction]="false" [label]="'Deliverable'" [options]="availableDeliverables"
                                 (onSelectionChange)='onSelectDeliverableFilter($event)' />
          <scope-ui-autocomplete [filterByAction]="false" [label]="'Component'" [options]="availableComponents"
                                 (onSelectionChange)='onSelectComponentFilter($event)' />
          <scope-ui-autocomplete [filterByAction]="false" [label]="'Department'" [options]="availableDepartments"
                                 (onSelectionChange)='onSelectDepartmentFilter($event)' />
        </div>
      </div>
    </div>

    <div *ngIf="showFilters" class="find-replace-roles-with">
      <scope-ui-autocomplete
        [hasIcon]="true"
        [iconName]="'search'"
        [filterByAction]="true"
        [label]="'Replace With'"
        [componentId]="'roleReplaceName'"
        [options]="filteredRoles"
        (onSearch)="onSearchReplace($event)"
        (onSelectionChange)="selectReplacement($event)" />
      <scope-ui-input
        [hasPlaceholder]="false"
        [inputPlaceholder]="''"
        [icon]="''"
        [inputLabel]="'Rate'"
        [isDisabled]="true"
        [initialValue]="selectedReplaceRole?.rate?.amount"
        [isCurrency]='true'
        [currency]="currencyCode"
        (onInputChange)="onSearchKeyPressed($event)"
        class="rate-card-field" />
    </div>

    <form [formGroup]="breadcrumbsForm">
      <ng-container formArrayName="breadcrumbs" *ngIf="showFilters" class="breadcrumbs">
        <span class="find-replace-results"
          >Found <span>{{ deliverablesCount }}</span> result<span *ngIf="deliverablesCount > 1">(s)</span>
        </span>

        <div *ngFor="let deliverableBreadcrumb of breadcrumbs; let i = index">
          <section class="breadcrumb-section">
            <ng-container [formGroupName]="i">
              <span class="breadcrumb-panel">
                <input type="checkbox" formControlName="isChecked" name="replaceSelected" />

                <button [matMenuTriggerFor]="menu" class="flex breadcrumb-menu">
                  <i class="material-icons material-symbols-rounded">more_vert</i>
                </button>

                <!-- BREADCRUMBS MENU -->
                <mat-menu #menu="matMenu">
                  <button class="deliverable-breadcrumb-name" mat-menu-item>
                    {{ deliverableBreadcrumb.deliverable.name }}
                  </button>
                  <button mat-menu-item>{{ deliverableBreadcrumb.component.name }}</button>
                  <button mat-menu-item>{{ deliverableBreadcrumb.department.name }}</button>
                  <button mat-menu-item>{{ deliverableBreadcrumb.name }}</button>
                </mat-menu>

                <span class="find-replace-deliverable">
                  {{ deliverableBreadcrumb.deliverable.name }}
                </span>
              </span>

              <span *ngIf="!deliverableBreadcrumb.showHideHoursInput" class="deliverable-breadcrumb-input">
                <p>{{ minutesToHours(deliverableBreadcrumb.minutes) }} hrs</p>
                <i
                  (click)="onHoursInputChange(deliverableBreadcrumb, i)"
                  class="material-symbols-rounded fill"
                  >mode_edit</i>
              </span>
              <span *ngIf="deliverableBreadcrumb.showHideHoursInput" class="deliverable-breadcrumb-input">
                <mat-form-field class="breadcrumb-hours-input">
                  <input
                    (input)="onInput($event)"
                    type="text"
                    matInput
                    formControlName="minutes"
                    [value]="minutesToHours(deliverableBreadcrumb.minutes)"
                    mask="0*" />
                </mat-form-field>
                hrs
                <i
                  (click)="onHoursInputChange(deliverableBreadcrumb, i)"
                  class="material-symbols-rounded"
                  >done</i>
              </span>
            </ng-container>
          </section>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="find-replace-footer">
    <div class="find-replace-action-buttons">
      <button (click)="closeDrawer()" class="find-replace-cancel">Cancel</button>
      <button
        class="find-replace-complete"
        [ngClass]="{
          'find-replace-complete-active': selectedReplaceRole,
          'find-replace-selected-active': isReplaceSelected
        }"
        [disabled]="!selectedReplaceRole || selectedReplaceRole.length === 0"
        (click)="submitFindReplace()">
        {{ isReplaceSelected ? 'Replace Selected' : 'Replace All' }}
      </button>
    </div>
  </div>
</div>

<!-- REPLACE SUMMARY CONFIRMATION -->
<div *ngIf="replaceSummarySuccess && !isLoading" style="position: relative; overflow: hidden;">
  <div class="content-container p-5">
    <div class="find-replace-header">
      <h1 class="find-replace-title">Information Replacement Successful!</h1>
    </div>

    <p class="replaced-summary-text">The role and associated deliverables have been successfully updated</p>
    <h2 class="replaced-summary-header">Summary</h2>

    <div class="replaced-summary"></div>

    <div class="find-replace-find-roles"></div>

    <div *ngIf="replaceSummaryResponse">
      <div class="replaced-roles">
        <span>{{ replaceSummaryResponse.oldRole.name }}</span>
        <i class="material-symbols-rounded"> arrow_forward</i>
        <span>{{ replaceSummaryResponse.newRole.name }}</span>
      </div>
    </div>
  </div>
  <div class="find-replace-footer">
    <div class="find-replace-action-buttons">
      <button (click)="revertReplacements(replaceSummaryResponse?.id)" class="find-replace-revert">Revert</button>
      <button (click)="closeDrawer()" class="find-replace-done">Done</button>
    </div>
  </div>
</div>

<!-- REVERT SUMMARY CONFIRMATION -->
<div *ngIf="revertSummarySuccess && !isLoading" style="position: relative; overflow: hidden;">
  <div class="content-container p-5">
    <div class="find-replace-header">
      <h1 class="find-replace-title">Information Replacement Reverted!</h1>
    </div>

    <p class="replaced-summary-text">The role and associated deliverables have been successfully reverted</p>
    <h2 class="replaced-summary-header">Summary</h2>

    <div class="replaced-summary"></div>

    <div class="find-replace-find-roles"></div>

    {{ revertSummaryResponse.newRole | json }}

    <div *ngFor="let response of revertSummaryResponse">
      <div class="replaced-roles">
        <span>{{ response.newRole.name }}</span>
        <i class="material-symbols-rounded"> arrow_forward</i>
        <span>{{ response.oldRole.name }}</span>
      </div>
    </div>
  </div>
  <div class="find-replace-footer">
    <div class="find-replace-action-buttons">
      <button (click)="closeDrawer()" class="find-replace-done">Done</button>
    </div>
  </div>
</div>
