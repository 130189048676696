import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core'
import { BootstrapConfig } from '@core/model/bootstrap-config.model'
import { CoreState } from '@core/store'
import { select, Store } from '@ngrx/store'
import { map, Observable } from 'rxjs'
import { AsyncPipe } from '@angular/common'
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector'

@Pipe({ name: 'urlStaticMedia', pure: false })
export class UrlStaticMediaPipe implements PipeTransform {
    private bootstrap$: Observable<BootstrapConfig | null>
    private asyncPipe: AsyncPipe


    constructor(private store: Store<CoreState>, private cdr: ChangeDetectorRef) {
        this.bootstrap$ = this.store.pipe(select(BootstrapSelectors.selectBootstrapConfig))
        this.asyncPipe = new AsyncPipe(this.cdr)
    }

    transform(path: string) {
        return this.asyncPipe.transform(this.bootstrap$.pipe(map((bs) => bs?.cdnConfig.getStaticMediaUrl(path))))
    }
}
