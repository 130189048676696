<form name="counter" class="input-counter {{formClass}}">
  <div class="incrementers">
    <span class="increment-major" (click)="decreaseMajor($event)">-</span>
    <span *ngIf="enableMinors" class="increment-minor" (click)="decreaseMinor($event)">-</span>
  </div>
  <span class="counter-wrap">
    <input
      type="text"
      class="input-count"
      name="count"
      [maxLength]="maxLength ? maxLength : 9999"
      [ngModel]="_formatDisplayValue(initialValue)"
      (ngModelChange)="onManualInput($event)"
      mask="{{disableMask ? null : (isDecimal ? 'separator.2' : '0*')}}"
      (click)='$event.stopPropagation()'/>
  </span>
  <div class="incrementers">
    <span class="increment-major" (click)="increaseMajor($event)">+</span>
    <span *ngIf="enableMinors" class="increment-minor" (click)="increaseMinor($event)">+</span>
  </div>
</form>
