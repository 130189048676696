import { Directive, ElementRef, HostBinding, Input } from '@angular/core'

@Directive({
    selector: '[ttlclass]',
})
export class TTLClassDirective {
    @Input() ttlclass: string = ''
    @Input('ttlclass-expire') expire: number = 1000
    @Input('ttlclass-cond')
    set show(newValue: boolean) {
        if (this.show != newValue) {
            this.className = this.ttlclass;
            setTimeout(() => {
                this.className = ''
            }, this.expire)
        }
    }

    @HostBinding('class')
    className = ''
}
