import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@plugins/material/material.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { DashboardOverviewRoutingModule } from './dashboard-overview-routing.module';
import { DashboardOverviewComponent } from './components/dashboard-overview/dashboard-overview.component';
import { DashboardOverviewNgrxModule } from './dashboard-overview-ngrx.module';

@NgModule({
  imports: [CommonModule, MaterialModule, SharedModule, DashboardOverviewRoutingModule, DashboardOverviewNgrxModule],
  declarations: [DashboardOverviewComponent],
  providers: [],
  exports: [RouterModule],
})
export class DashboardOverviewModule {}
