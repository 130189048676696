import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dummy-component-level3',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dummy-component-level3.component.html',
  styleUrls: ['./dummy-component-level3.component.scss']
})
export class DummyComponentLevel3Component {

}
