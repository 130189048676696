import { Component, Input } from '@angular/core';
import {SharedModule} from "@shared/shared.module";
import {
  ScopeUiAutocompleteComponent
} from "@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component";
import { RatecardVersion } from "@app/features/scope-overview/model/ratecard-version.model";
import {
  ScopeUiDropdownComponent
} from "@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component";

@Component({
  selector: 'map-roles',
  templateUrl: './map-roles.component.html',
  styleUrls: ['./map-roles.component.scss'],
  imports: [
    SharedModule,
    ScopeUiAutocompleteComponent,
    ScopeUiDropdownComponent
  ],
  standalone: true
})
export class MapRolesComponent {
  @Input() sourceRateCard?: RatecardVersion
  @Input() ratecard?: RatecardVersion
  @Input() mappingRoles: any[]
  @Input() targetRoles: any[]

  selectTargetRole($event: any, role: any) {
    role.targetMappingRole = $event.id != null ? $event : null
  }
}
