<div mat-dialog-title>
  <div class="modal__header" [class.mw-992]='isMapStep && !mappingIsEmpty'>
    <div class="modal__title" *ngIf="isTemplate">
      <h1 *ngIf="!isMapStep">Create {{'scope|l' | lang }} with a template</h1>
      <h1 *ngIf="isMapStep">Map roles from {{'scope.p|l' | lang }} '{{ scopeTemplate.name }}'</h1>
    </div>
    <div class="modal__title" *ngIf="!isTemplate">
      <h1 *ngIf="!isMapStep">Duplicate {{'scope' | lang }}</h1>
      <h1 *ngIf="isMapStep">Duplicate {{'scope|l' | lang }} '{{ scopeToDuplicate.name }}'</h1>
    </div>

    <div class="mt-2" *ngIf="isMapStep && !mappingIsEmpty">
      <span>Please select {{'agency|l' | lang }} resources from '{{ratecard!.name}} (v{{ratecard!.version}})' rate-card for roles which do not exist in '{{sourceRateCard.name}} (v{{sourceRateCard.version}})' rate-card</span>
    </div>

    <button class="close-button" mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content aria-label="Generic modal" class="modal-container md-dialog-container" [class.mw-992]='isMapStep && !mappingIsEmpty'>
  <div class="modal__body pb-7">
    <div class="mt-2" *ngIf="!isMapStep">
      <p *ngIf="isTemplate">The rate card of the {{'scope|l' | lang }} template which you selected to use is different.</p>
      <p *ngIf="!isTemplate">The rate card of the {{'scope|l' | lang }} which you selected to use is different.</p>
      <p class="pt-1">This means you will need to resolve the inconsistency between '{{sourceRateCard.name}} (v{{sourceRateCard.version}})' and '{{myNewScope.ratecard.name}} (v{{myNewScope.ratecard.version}})' rate cards. Would you like to try?
      </p>
    </div>

    <div class="mt-2" *ngIf="isMapStep && mappingIsEmpty">
      <span>There are no roles to map, you may insert this scope.</span>
    </div>

    <map-roles
      *ngIf="isMapStep && !mappingIsEmpty"
      [sourceRateCard]='sourceRateCard'
      [ratecard]='ratecard'
      [targetRoles]='targetRoles'
      [mappingRoles]='mappingRoles'>
    </map-roles>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flex" style="justify-content: flex-end;">
  <button *ngIf="!isMapStep" [disabled]="!deliverables || !ratecard" mat-flat-button class="ml-8" (click)="mapRoles()">
    {{!deliverables || !ratecard ? '...Loading' : 'Continue'}}
  </button>
  <button *ngIf="isMapStep" mat-flat-button class="ml-8 mt-8" (click)="confirmConvertAndInsert()">Create</button>
</mat-dialog-actions>
