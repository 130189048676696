import { Component, EventEmitter, Input, Output } from '@angular/core'
import Editor from 'ckeditor5-custom-build'
import { CommentingService } from '@app/features/scope-overview/service/commenting.service'

@Component({
  selector: 'description-editor',
  templateUrl: './description-editor.component.html',
  styleUrls: ['./description-editor.component.scss'],
})
export class DescriptionEditorComponent {
  @Input() model!: any

  @Input() isScope?: boolean = false

  @Input() status!: string

  @Input() editable?: boolean = true

  @Input() visible!: boolean

  @Input() hideNote?: boolean = false

  @Input() showNoteBelow?: boolean = false

  @Input() descriptionRequired?: boolean = false

  @Input() descriptionDirty?: boolean = false

  @Output() visibleChange = new EventEmitter<boolean>()

  @Input() disableDragging!: boolean

  @Output() disableDraggingChange = new EventEmitter<boolean>()

  @Output() onSaveDescription = new EventEmitter<string>()

  @Output() onSaveNote = new EventEmitter<string>()

  protected readonly Editor = Editor

  description!: string

  internalNote!: string

  originalDescription!: string

  originalInternalNote!: string

  editingDescription: boolean = false

  editingNote: boolean = false

  commenting = false

  commentable: any = null

  constructor(public commentingService: CommentingService) { }

  ngOnInit() {
    this.description = this.model.description || ''
    this.internalNote = this.model.internalNote
    this.originalDescription = this.model.description
    this.originalInternalNote = this.model.internalNote
  }

  showDescription(){
    this.visible = true;
    this.visibleChange.emit(this.visible);
    if (this.status === 'DRAFT' && !this.description) {
      this.editDescription();
    }
  }

  hideDescription(){
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  editDescription(){
    this.visible = true;
    this.originalDescription = this.description;
    this.editingDescription = true;
  }

  saveDescription() {
    this.onSaveDescription.emit(this.description);
    this.originalDescription = this.description;
    this.cancelDescription();
  }

  saveNote() {
    this.onSaveNote.emit(this.internalNote);
    this.originalInternalNote = this.internalNote;
    this.cancelNote()
  }

  editNote(){
    this.originalInternalNote = this.internalNote;
    this.editingNote = true;
  }

  cancelNote() {
    this.internalNote = this.originalInternalNote
    this.editingNote = false;
  }

  cancelDescription() {
    this.editingDescription = false
    this.description = this.originalDescription
    if (!this.description) {
      this.hideDescription();
    }
  }

  toggleCommenting() {
    this.commenting = !this.commenting;
  }

  setCommentable(commentable) {
    this.commentable = commentable;
  }
}
