import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { SharedModule } from '@app/shared/shared.module'
import { PipesModule } from '@app/shared/pipe/index.module'
import { ScopeTraffickingService } from '../../service/scope-trafficking.service'
import {IntegrationModalConfig} from "@app/features/scope-overview/model/integration-modal-config";

@Component({
  selector: 'integration-traffic',
  standalone: true,
  imports: [CommonModule, PipesModule, MatDialogModule, SharedModule],
  templateUrl: './integration-traffic.component.html',
  styleUrls: ['./integration-traffic.component.scss'],
})
export class IntegrationTrafficModal {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IntegrationModalConfig,
    public dialogRef: MatDialogRef<IntegrationTrafficModal>,
    public dialog: MatDialog,
    public traffickingService: ScopeTraffickingService
  ) {}

  getSelectedIntegrations() {
    return this.data.integrations.filter(int => int.selected).map(int => int.apiIntegration.name)
  }

  trafficScope() {
    const selectedIntegrationNames = this.getSelectedIntegrations()
    this.traffickingService.trafficToIntegration(this.data.scopeId, selectedIntegrationNames);
  }
}
