import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ScopeVersion, StatusType } from '@app/core/model/scope-version';
import { PipesModule } from '@app/shared/pipe/index.module';
import { ApprovalChecks } from '@app/features/scope-overview/model/approval-checks.interface'
import { SharedModule } from '@shared/shared.module'
import { User } from '@core/model/user.model'

@Component({
  selector: 'scope-tooltip',
  standalone: true,
  imports: [CommonModule, PipesModule, SharedModule],
  templateUrl: './scope-tooltip.component.html',
  styleUrls: ['./scope-tooltip.component.scss'],
})

export class ScopeTooltipComponent {
  @Input() currentState!: StatusType

  @Input() currentUser!: User

  @Input() stateChecks!: ApprovalChecks;

  @Input() set currentScope(value: ScopeVersion) {
     if (value) {
       this.isReadyToSubmit = value.status === StatusType.DRAFT &&
         value.identity.currentVersionNumber === value.version && !value.containsUnmappedScopeMarkRoles &&
         value.deliverables.length > 0 && value.getTotalSellingPrice()?.amount > 0
      }
  }

  @Output() onSubmitDraft: EventEmitter<void>;

  @Output() onWithdrawSubmission: EventEmitter<void>;

  @Output() onCompleteReview: EventEmitter<void>;

  @Output() onAgreeToScope: EventEmitter<void>;

  @Output() onDeclineScope: EventEmitter<void>;

  @Output() onAcceptScope!: EventEmitter<void>

  @Output() onRejectScope!: EventEmitter<void>

  @Output() onCloseScope!: EventEmitter<void>

  isReadyToSubmit: boolean

  constructor() {
    this.onSubmitDraft = new EventEmitter<void>();
    this.onWithdrawSubmission = new EventEmitter<void>();
    this.onCompleteReview = new EventEmitter<void>();
    this.onAgreeToScope = new EventEmitter<void>();
    this.onDeclineScope = new EventEmitter<void>();
    this.onAcceptScope = new EventEmitter<void>();
    this.onRejectScope = new EventEmitter<void>();
    this.onCloseScope = new EventEmitter<void>();
  }

  submitDraft() {
    this.onSubmitDraft.emit();
  }

  withdrawSubmission() {
    this.onWithdrawSubmission.emit();
  }

  completeReviewSubmission() {
    this.onCompleteReview.emit()
  }

  agreeToScope() {
    this.onAgreeToScope.emit();
  }

  declineScope() {
    this.onDeclineScope.emit();
  }

  acceptScope() {
    this.onAcceptScope.emit();
  }

  rejectScope() {
    this.onRejectScope.emit();
  }

  closeScope() {
    this.onCloseScope.emit();
  }
}
