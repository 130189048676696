<header
  #siteHeader
  class="site-header"
  [ngClass]="getClass(bootstrapConfig$ | async)"
  [ngSwitch]="(bootstrapConfig$ | async)?.getCompanyTypeString()">
  <agency-site-header-content *ngSwitchDefault></agency-site-header-content>
  <agency-site-header-content class="agency" *ngSwitchCase="'AGENCY'"></agency-site-header-content>
  <client-site-header-content class="client" *ngSwitchCase="'CLIENT'"></client-site-header-content>
  <legal-site-header-content class="legal" *ngSwitchCase="'LEGAL'"></legal-site-header-content>
</header>
