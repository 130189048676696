<a class="no-hover notifications" title="Notifications"
  (click)="notificationStatus.showNotifications = !notificationStatus.showNotifications;">
  <span *ngIf="unreadyNotificationsCount$ | async as unreadyNotificationsCount" class="unread-counter">{{
    unreadyNotificationsCount
    }}</span>
  <i class="material-symbols-rounded"
    [ngClass]="{'fill': notificationStatus.showNotifications || (unreadyNotificationsCount$ | async) != 0}"
    ttlclass="has-notification" [ttlclass-cond]="(unreadyNotificationsCount$ | async) != 0">{{
    notificationStatus.showNotifications || (unreadyNotificationsCount$ | async) == 0
    ? 'notifications'
    : 'notifications_active'
    }}</i>
</a>
<app-notification-sidebar (onNotificationClick)="onNotificationClick($event)" (onMarkAllAsRead)="onMarkAllAsRead()"
  (onCloseNotifications)="onCloseNotifications()" [visible]="notificationStatus.showNotifications">
</app-notification-sidebar>
