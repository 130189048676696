import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { authorisedAsChildCompany } from '@app/shared/utils/utils'
import { Observable } from 'rxjs';

@Injectable({providedIn : 'root'})
export class ParentChildInterceptor implements HttpInterceptor {
  private excludedUrls: string[] = ['api/company/child-companies'];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isExcluded(request.url) && authorisedAsChildCompany()) {
      request = request.clone({
        setHeaders: {
          'scope-parent-auth-as-child': authorisedAsChildCompany(),
        },
      });
    }

    return next.handle(request);
  }

  private isExcluded(url: string): boolean {
    return this.excludedUrls.some(excludedUrl => url.includes(excludedUrl));
  }

}
