import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ScopeOverviewRoutingModule } from './scope-overview.routing.module'
import { ScopeOverviewComponent } from '@app/features/scope-overview/components/scope-overview/scope-overview.component'
import { SharedModule } from '@shared/shared.module'
import { ScopeUiTabsComponent } from '@shared/components/ui-components/scope-ui-tabs/scope-ui-tabs.component'
import { ScopeUiTabComponent } from '@shared/components/ui-components/scope-ui-tab/scope-ui-tab.component'
import { DetailsOverviewComponent } from '@shared/components/ui-components/details-overview/details-overview/details-overview.component'
import { FindReplaceComponent } from '@app/shared/components/find-replace/find-replace.component'
import { ScopeUiAutocompleteComponent } from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { ScopeUiInputComponent } from '@app/shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { DeliverableRowComponent } from './components/scope-tab/deliverable-row/deliverable-row.component';
import { ScopeTabComponent } from './components/scope-tab/scope-tab.component';
import { ScopeUiOptionsMenuComponent } from "@shared/components/scope-ui-options-menu/scope-ui-options-menu.component";
import { TeamComponent } from "@app/features/scope-overview/components/team/team.component";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { OutputEditorComponent } from '@app/shared/components/output-editor/output-editor.component'
import { ScopeOutputComponent } from './components/scope-output/scope-output.component'
import { ScopeDetailsComponent } from "@app/features/scope-overview/components/scope-details/scope-details.component";
import { ScopeOverviewNgrxModule } from './scoping-overview-ngrx.module'
import { ScopeActivityComponent } from './components/scope-activity/scope-activity.component'
import { ToggleListComponent } from '@shared/components/ui-components/toggle-list/toggle-list.component';
import { MatDividerModule } from "@angular/material/divider";
import { ScopeUiTableComponent } from "@shared/components/ui-components/scope-ui-table/scope-ui-table.component";
import { ScopeBreakdownComponent } from './components/scope-breakdown/scope-breakdown.component'
import { ScopeApprovalFlowComponent } from './approval-flow/scope-approval-flow/scope-approval-flow.component'
import { ExportDocumentService } from './service/export-document-service'
import {
  DeliverableBuilderComponent
} from "@app/features/scoping/components/deliverable-builder/deliverable-builder.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ScopeUiSnackbarComponent } from '@shared/components/scope-ui-snackbar/scope-ui-snackbar.component';
import { DuplicateScopeTemplateComponent } from './components/scope-tab/duplicate-scope-template/duplicate-scope-template.component';


@NgModule({
  declarations: [DeliverableBuilderComponent, DuplicateScopeTemplateComponent],
  imports: [
    CommonModule,
    ScopeOverviewNgrxModule,
    ScopeActivityComponent,
    ScopeOverviewRoutingModule,
    SharedModule,
    ScopeUiAutocompleteComponent,
    ScopeApprovalFlowComponent,
    DetailsOverviewComponent,
    FindReplaceComponent,
    ScopeUiOptionsMenuComponent,
    TeamComponent,
    ScopeBreakdownComponent,
    ScopeOutputComponent,
    OutputEditorComponent,
    CKEditorModule,
    ScopeDetailsComponent,
    ScopeDetailsComponent,
    MatDividerModule,
    ScopeUiTableComponent,
    MatTooltipModule,
    ScopeUiSnackbarComponent,
  ],
  providers: [ExportDocumentService],
})
export class ScopeOverviewModule {}
