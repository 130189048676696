import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service'
import { ScopeVersion } from '@core/model/scope-version'
import { Privilege } from '@core/model/enums/privilege.enum'
import { authorisedAsChildCompany, untilDestroyed } from '@shared/utils/utils'
import { User } from '@core/model/user.model'
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component'
import { ScopeTeamMember } from '@core/model/scope-team.model'
import { UserRoleLevel } from '@core/model/enums/user-role-level.enum'
import { MatDialog } from '@angular/material/dialog'
import { ModalConfig } from '@core/model/modal-config.model'
import { FormControl } from '@angular/forms'
import { CdnConfig } from '@core/model/cdn-config.model'
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action'
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector'
import { filter } from 'rxjs'
import { AuthService } from '@core/service/auth.service'

@Injectable({
  providedIn: 'root',
})
export class ScopeTeamService {
  private readonly destroy$

  constructor(private store: Store, private scopeOverviewService: ScopeOverviewService, private dialog: MatDialog, private authService: AuthService) {
    this.destroy$ = untilDestroyed()

    this.store.select(ScopeOverviewSelectors.selectTeamError)
      .pipe(
        filter((error) => !!error)
      )
      .subscribe((error) => {
        console.error(error)
      })
  }

  filterMembers(agencyUser: User[], existingTeamMembers: ScopeTeamMember[], accessRoleRequired: UserRoleLevel) {
    return agencyUser.filter((user) => {
      let existingMember = existingTeamMembers.find((m) => {
        return m.user.id === user.id
      })
      return !existingMember && user.hasAccessRole(accessRoleRequired)
    })
  }

  filterAvailableMembers(availableMembers: User[], existingMembers: ScopeTeamMember[]) {
    let allMembers = availableMembers
    if (authorisedAsChildCompany()) {
      if (!allMembers.find((i) => i.id == this.authService.loggedInUser!.id)) {
        allMembers.push(this.authService.loggedInUser!)
      }
    }

    return this.filterMembers(availableMembers, existingMembers, UserRoleLevel.MEMBER)
  }

  showAddCollaboratorModal(currentScope: ScopeVersion, cdnConfig: CdnConfig) {
    this.scopeOverviewService
      .getAvailableScopeTeamMembersWithPrivilege(currentScope.identity.id, Privilege.SCOPE__SUBMIT)
      .pipe(this.destroy$())
      .subscribe((members: User[]) => {
        let availableMembers = this.filterAvailableMembers(members, currentScope.identity.team.collaborators)
        let dialog = this.dialog.open(ScopeUiModalComponent, {
          data: new ModalConfig(
            `Add Collaborator`,
            `Invite to ${currentScope.name}`,
            'Add Users',
            undefined,
            (form: FormControl, selections: User[]) => {
              dialog.close()
              const memberIds = selections.map(function (m) {
                return m.id
              })

              this.store.dispatch(ScopeOverviewActions.addScopeCollaborator({ scopeId: currentScope.identity.id, memberIds }))
            },
            undefined,
            [
              {
                name: 'members',
                control: new FormControl(''),
                type: 'autocomplete',
                label: 'Member',
                options: availableMembers,
                placeholder: 'Find member',
                multiselect: true
              },
            ],
            true,
            undefined,
            undefined,
            (form: FormControl, selections: any[]) => {
              return !!selections.length
            }
          ),
        })
        dialog.componentInstance.cdnConfig = cdnConfig!
      })
  }

  showAddReviewerModal(currentScope: ScopeVersion, cdnConfig: CdnConfig) {
    this.scopeOverviewService
      .getAvailableScopeTeamMembersWithPrivilege(currentScope.identity.id, Privilege.SCOPE__REVIEW)
      .pipe(this.destroy$())
      .subscribe((members: User[]) => {
        let availableMembers = this.filterAvailableMembers(members, currentScope.identity.team.reviewers)
        let dialog = this.dialog.open(ScopeUiModalComponent, {
          data: new ModalConfig(
            `Add Reviewer`,
            `Invite to ${currentScope.name}`,
            'Add Users',
            undefined,
            (form: FormControl, selections: User[]) => {
              dialog.close()
              const memberIds = selections.map(function (m) {
                return m.id
              })
              this.store.dispatch(ScopeOverviewActions.addScopeReviewer({ scopeId: currentScope.identity.id, memberIds }))
            },
            undefined,
            [
              {
                name: 'members',
                control: new FormControl(''),
                type: 'autocomplete',
                label: 'Member',
                options: availableMembers,
                placeholder: 'Find member',
                multiselect: true
              },
            ],
            true,
            undefined,
            undefined,
            (form: FormControl, selections: any[]) => {
              return !!selections.length
            }
          ),
        })
        dialog.componentInstance.cdnConfig = cdnConfig!
      })
  }

  showAddApproverModal(currentScope: ScopeVersion, cdnConfig: CdnConfig) {
    this.scopeOverviewService
      .getAvailableScopeTeamMembersWithPrivilege(currentScope.identity.id, Privilege.SCOPE__APPROVE)
      .pipe(this.destroy$())
      .subscribe((members: User[]) => {
        let availableMembers = this.filterAvailableMembers(members, currentScope.identity.team.approvers)
        let dialog = this.dialog.open(ScopeUiModalComponent, {
          data: new ModalConfig(
            `Add Approver`,
            `Invite to ${currentScope.name}`,
            'Add Users',
            undefined,
            (form: FormControl, selections: User[]) => {
              dialog.close()
              const memberIds = selections.map(function (m) {
                return m.id
              })

              this.store.dispatch(ScopeOverviewActions.addScopeApprover({ scopeId: currentScope.identity.id, memberIds }))
            },
            undefined,
            [
              {
                name: 'members',
                control: new FormControl(''),
                type: 'autocomplete',
                label: 'Member',
                options: availableMembers,
                placeholder: 'Find member',
                multiselect: true
              },
            ],
            true,
            undefined,
            undefined,
            (form: FormControl, selections: any[]) => {
              return !!selections.length
            }
          ),
        })
        dialog.componentInstance.cdnConfig = cdnConfig!
      })
  }

  showAddTraffickerModal(currentScope: ScopeVersion, cdnConfig: CdnConfig) {
    this.scopeOverviewService
      .getAvailableScopeTeamMembersWithPrivilege(currentScope.identity.id, Privilege.SCOPE__TRAFFIC)
      .pipe(this.destroy$())
      .subscribe((members: User[]) => {
        let availableMembers = this.filterAvailableMembers(members, currentScope.identity.team.traffickers)
        let dialog = this.dialog.open(ScopeUiModalComponent, {
          data: new ModalConfig(
            `Add Trafficker`,
            `Invite to ${currentScope.name}`,
            'Add Users',
            undefined,
            (form: FormControl, selections: User[]) => {
              dialog.close()
              const memberIds = selections.map(function (m) {
                return m.id
              })

              this.store.dispatch(ScopeOverviewActions.addScopeTrafficker({ scopeId: currentScope.identity.id, memberIds }))
            },
            undefined,
            [
              {
                name: 'members',
                control: new FormControl(''),
                type: 'autocomplete',
                label: 'Member',
                options: availableMembers,
                placeholder: 'Find member',
                multiselect: true
              },
            ],
            true,
            undefined,
            undefined,
            (form: FormControl, selections: any[]) => {
              return !!selections.length
            }
          ),
        })
        dialog.componentInstance.cdnConfig = cdnConfig!
      })
  }

  updateTeamMember(member: ScopeTeamMember, currentScope: ScopeVersion) {
    this.store.dispatch(ScopeOverviewActions.updateTeamMember({ scopeId: currentScope.identity.id, member }))
  }

  deleteCollaborator(member: ScopeTeamMember, currentScope: ScopeVersion) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete Collaborator`,
        `Are you sure want to delete ${member.user.getFullname()} as a collaborator?`,
        'Delete',
        undefined,
        () => {
          dialog.close()

          this.store.dispatch(ScopeOverviewActions.removeScopeCollaborator({ scopeId: currentScope.identity.id, member: member }))
        },
        undefined
      ),
    })
  }

  deleteReviewer(member: ScopeTeamMember, currentScope: ScopeVersion) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete Reviewer`,
        `Are you sure want to delete ${member.user.getFullname()} as a reviewer?`,
        'Delete',
        undefined,
        () => {
          dialog.close()

          this.store.dispatch(ScopeOverviewActions.removeScopeReviewer({ scopeId: currentScope.identity.id, member: member }))
        },
        undefined
      ),
    })
  }

  deleteApprover(member: ScopeTeamMember, currentScope: ScopeVersion) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete Approver`,
        `Are you sure want to delete ${member.user.getFullname()} as an approver?`,
        'Delete',
        undefined,
        () => {
          dialog.close()

          this.store.dispatch(ScopeOverviewActions.removeScopeApprover({ scopeId: currentScope.identity.id, member: member }))
        },
        undefined
      ),
    })
  }

  deleteTrafficker(member: ScopeTeamMember, currentScope: ScopeVersion) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete Trafficker`,
        `Are you sure want to delete ${member.user.getFullname()} as a trafficker?`,
        'Delete',
        undefined,
        () => {
          dialog.close()

          this.store.dispatch(ScopeOverviewActions.removeScopeTrafficker({ scopeId: currentScope.identity.id, member: member }))
        },
        undefined
      ),
    })
  }
}
