import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderStyleService {
  private headerClass: string = '';
  private mainPositionClass = new BehaviorSubject('absolute')

  getHeaderClass(): string {
    return this.headerClass;
  }

  getMainSectionClass(): Observable<string> {
    return this.mainPositionClass
  }

  setHeaderClass(className: string) {
    this.headerClass = className;
    if (className == HIDE_HEADER) {
      this.mainPositionClass.next('inherit')
    } else {
      this.mainPositionClass.next('absolute')
    }
  }

  setMainSectionClass(className: string) {
    this.mainPositionClass.next(className)
  }
}

export const HIDE_HEADER = 'hide-header'
export const DEFAULT_POSITION = 'absolute'
export const INHERIT_POSITION = 'inherit'
