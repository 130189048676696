<div
  class="section-deliverable-droplist w-full"
  [ngClass]="{ 'drag-target': scopeTabService.deliverableDragging }"
  id="list-{{ section.id }}"
  [cdkDropListConnectedTo]="sectionDrops"
  cdkDropList
  [cdkDropListData]="section.deliverables"
  [cdkDropListDisabled]="!scopeApprovalFlowService.isScopeEditable(scopeVersion)"
  (cdkDropListDropped)="dropDeliverable($event, section)">
  <div class="w-full h-fit">
    <div class="flex-col w-full">
      <div
        class="flex flex-row w-full items-center h-10 py-2"
        (click)="scopeTabService.toggleSection(section.id)">
        <div
          class="column level-1"
          [ngClass]="mainColumnClass">
          <div class="flex items-center">
            <mat-icon
              class="arrow orange material-symbols-rounded"
              [ngClass]="{ active: scopeTabService.sectionSelectedStates[section.id] || section.id == scopeTabService.dragTargetId }"
            >expand_more</mat-icon>
            <i class="item-icon material-symbols-rounded">flag</i>
            <span *ngIf="editSectionName !== section.id">{{section.name}}</span>
            <mat-icon (click)="editSectionName = section.id; $event.stopPropagation()" *ngIf="editSectionName !== section.id && scopeApprovalFlowService.isScopeEditable(scopeVersion)"
                      class="material-symbols-rounded fill orange text-sm ml-1.5">mode_edit</mat-icon>
            <scope-ui-input
              *ngIf="editSectionName === section.id"
              class="edit-name"
              (click)='$event.stopPropagation()'
              [initialValue]="section.name"
              [hasPlaceholder]="false"
              [autofocus]='true'
              (onInputBlurChange)="updateSectionName($event, section)"
            />
          </div>
        </div>
        <div
          class="column one--column"
          [hidden]="!userColumns.DELIVERY_STATUS?.isVisible()"></div>
        <div
          class="column one--column"
          [hidden]="!userColumns.CREATED_BY?.selected"></div>
        <div
          class="column one--column"
          [hidden]="!userColumns.OWNER?.selected">
          &nbsp;
        </div>
        <div
          class="column one--column"
          [hidden]="!userColumns.DATE_CREATED?.selected"></div>
        <div
          class="column one--column"
          [hidden]="!userColumns.QUANTITY?.selected"></div>
        <div
          class="column one--column"
          [hidden]="!(userColumns.START_DATE?.selected && userColumns.START_DATE?.isVisibleInList())">
          <div class="inline-flex items-center">
            {{ section.startDate || scopeVersion.startDate | dateFormat: 'shortDate' }}
            <mat-icon #startDateMenuTrigger [matMenuTriggerFor]="startDateEditor"
                      class="material-symbols-rounded fill orange text-sm"
                      (click)='$event.stopPropagation()'>mode_edit</mat-icon>
            <mat-menu #startDateEditor id='startDateEditor'>
              <scope-ui-datepicker
                [initialValue]="section.startDate || scopeVersion.startDate"
                [singleDatePicker]='true'
                [inlineDatePicker]='true'
                [minDate]='scopeVersion.startDate'
                [maxDate]='scopeVersion.endDate'
                (onSelect)="updateStartDate($event)">
              </scope-ui-datepicker>
            </mat-menu>
          </div>
        </div>
        <div
          class="column one--column"
          [hidden]="!userColumns.LAST_EDITED?.selected"></div>
        <div
          class="column one--column"
          [hidden]="!(userColumns.END_DATE?.selected && userColumns.END_DATE?.isVisibleInList())">
          <div class="inline-flex items-center">
            {{ section.endDate || scopeVersion.endDate | dateFormat: 'shortDate' }}
            <mat-icon #endDateMenuTrigger [matMenuTriggerFor]="endDateEditor"
                      class="material-symbols-rounded fill orange text-sm"
                      (click)='$event.stopPropagation()'>mode_edit</mat-icon>
            <mat-menu #endDateEditor id='endDateEditor'>
              <scope-ui-datepicker
                [initialValue]="section.endDate || scopeVersion.endDate"
                [singleDatePicker]='true'
                [inlineDatePicker]='true'
                [minDate]='section.startDate || scopeVersion.startDate'
                [maxDate]='scopeVersion.endDate'
                (onSelect)="updateEndDate($event)">
              </scope-ui-datepicker>
            </mat-menu>
          </div>
        </div>
        <div
          class="column one--column"
          [hidden]="!userColumns.MARK_UP?.selected"
          [has-privilege]="'SCOPE_MARKUP__VIEW'"
          [has-privilege-entity]="scopeVersion">
          {{
          scopeVersion.getAvgMarginOfSection(section.id)
            ? scopeVersion.getAvgMarginOfSection(section.id) + '%'
            : '0%'
          }}
        </div>
        <div
          class="column one--column"
          [hidden]="!userColumns.PROFIT?.selected"
          [has-privilege]="'SCOPE_MARKUP__VIEW'"
          [has-privilege-entity]="scopeVersion">
          {{ getProfitOfSection(section) }}
        </div>
        <div
          class="column one--column"
          [hidden]="!userColumns.SCOPEMARK_HOURS?.selected">
          {{ section.scopemarkHours(scopeVersion) | formatHours}}
        </div>
        <div
          class="column one--column"
          [hidden]="!userColumns.SCOPEMARK_PRICE?.selected">
          {{ section.getScopemarkPrice(scopeVersion).amount | currency: scopeVersion.getRateCardCurrency()}}
        </div>
        <div
          class="column {{isTimeline ? 'three--columns' : 'one--column'}}"
          [hidden]="!isTimeline && !userColumns.AGENCY_HOURS?.selected">
          {{ section.getAgencyHours(scopeVersion) | formatHours }}
        </div>
        <div
          class="column one--column"
          [hidden]="!userColumns.BUDGET?.selected"></div>
        <div
          class="column one--column"
          [hidden]="!userColumns.BALANCE?.selected"></div>
        <div
          class="column {{isTimeline ? 'three--columns' : 'one--column'}}"
          [hidden]="!isTimeline && !userColumns.AGENCY_PRICE?.selected">
          {{ section.getTotalSellingPrice(scopeVersion).amount | currency : scopeVersion.getRateCardCurrency() }}
        </div>
        <div class="column one--column">
          <button
            *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion)"
            class="toggle-button float-right {{isTimeline ? 'mr-1' : 'mr-2'}}"
            (click)="$event.stopPropagation()"
            #menuTrigger="matMenuTrigger"
            mat-mini-fab
            [matMenuTriggerFor]="sectionMenu">
            <mat-icon class="material-symbols-rounded">more_horiz</mat-icon>
          </button>
        </div>
        <mat-menu #sectionMenu="matMenu" backdropClass="customize">
          <button
            mat-menu-item
            *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion) &&
                        currentUser.company.hasApplicationSetting('FEE_ITEM__ENABLE') &&
                        currentUser.company.hasApplicationSetting('SCOPE__ENABLE_SECTION_FEES')"
            (click)="addSectionFee()">
            <mat-icon class="material-symbols-rounded orange">attach_money</mat-icon>
            <span>Add fee</span>
          </button>
          <button
            mat-menu-item
            (click)="deleteSection()">
            <mat-icon class="material-symbols-rounded orange">delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </div>
      <div
        *ngIf='!isTimeline'
        class="flex flex-col w-full description-container"
        (click)="$event.stopPropagation()">
        <description-editor
          class="level-1"
          [(visible)]="scopeTabService.sectionDescriptionStates[section.id]"
          [model]="section"
          [isScope]="false"
          [status]='scopeVersion.status'
          [editable]='scopeApprovalFlowService.isScopeEditable(scopeVersion)'
          [(disableDragging)]="scopeTabService.disableDragging"
          (onSaveDescription)="updateSectionDescription($event, section)"
          (onSaveNote)="updateSectionNote($event, section)">
        </description-editor>
      </div>
    </div>
  </div>

  <div
    class="text-for-empty"
    [hidden]="!(scopeTabService.sectionSelectedStates[section.id] || section.id == scopeTabService.dragTargetId) || section.deliverables.length > 0">
    <p class="text">Drag &amp; Drop {{ 'deliverable.p' | lang }}</p>
    <p class="undertext">between sections or from the unsectioned {{ 'deliverable.p' | lang }} below</p>
  </div>

  <scope-deliverable-row
    [hidden]="!(scopeTabService.sectionSelectedStates[section.id] || section.id == scopeTabService.dragTargetId)"
    class="deliverable-row flex-col"
    *ngFor="let deliverable of section.deliverables; trackBy: trackById"
    cdkDrag
    [isTimeline]='isTimeline'
    [currentUser]="currentUser"
    [cdkDragData]="deliverable"
    [cdkDragDisabled]="scopeTabService.disableDragging"
    [scopeVersion]="scopeVersion"
    [deliverable]="deliverable"
    [userColumns]="userColumns"
    [mainColumnClass]='mainColumnClass'
    [componentMainColumnClass]='componentMainColumnClass'
    [deliverableColumns]="scopeTabService.deliverableColumns"
    [deliverableFeeColumns]="scopeTabService.deliverableFeeColumns"
    [componentFeeColumns]="scopeTabService.componentFeeColumns"
    [feeMenuOptions]="feeMenuOptions"
    [currentRatecard]="currentRatecard"
    [level]="2"
    [deliverableSelectedStates]="scopeTabService.deliverableSelectedStates$ | async"
    (onFetchDeliverable)="fetchDeliverable($event)"
    (onUpdateDeliverable)="updateDeliverable($event)"
    (onUpdateComponent)="updateComponent($event)"
    (cdkDragStarted)="scopeTabService.deliverableDragging = true"
    (cdkDragEnded)="scopeTabService.deliverableDragging = false; scopeTabService.dragTargetId = null;"
    (onDuplicateDeliverable)="duplicateDeliverable($event)"
    (onDeleteDeliverable)="deleteDeliverable($event)"
    (onAcceptDeliverable)="acceptDeliverable(deliverable)"
    (onRejectDeliverable)="rejectDeliverable(deliverable)"
    (onAddDeliverableFee)="addDeliverableFee($event)"
    (onAddComponentFee)="addComponentFee($event)"
    (onEditDeliverable)="editDeliverable($event)"
    (onCompleteTrade)="completeTrade(deliverable)"
    (onTradeDeliverable)="tradeDeliverable(deliverable)"
    (onStopProgressTrade)="stopProgressTrade(deliverable)"
    (onReopenTrade)="reopenTrade(deliverable)"
    (onCancelTrade)="cancelTrade(deliverable)"
    (onConfirmTrade)="confirmTrade(deliverable)"
    (onAddDeliverableToTrade)="addDeliverableToTrade(deliverable)"
    (onShowDeliverableApplyLocationCardDialog)="showDeliverableApplyLocationCardDialog($event)"
    (onUpdateFeeItem)="updateFeeItem($event.feeItem, undefined, $event.deliverable, $event.component)"
    (onOverrideQuantity)="overrideQuantity($event)"
    (onOverrideRateCardRoleHours)="overrideRateCardRoleHours($event)" >
  </scope-deliverable-row>
</div>
<div
  *ngIf="section.feeItems?.length"
  [hidden]="!(scopeTabService.sectionSelectedStates[section.id] || section.id == scopeTabService.dragTargetId) || isTimeline">
  <scope-ui-table
    [has-setting]="'SCOPE__ENABLE_SECTION_FEES'"
    [has-privilege]="'SCOPE__VIEW_FEES'"
    [loggedInUser]="currentUser"
    [tableClass]="'fees-table nested'"
    [headerExpandable]="true"
    [unmappedDataSource]='section.feeItems'
    [displayedColumns]="scopeTabService.sectionFeeColumns"
    [noSort]="true"
    [noPagination]="true"
    [noPreferences]="true"
    [expandable]="false"
    [showToggleMenu]="feeMenuOptions.length > 0"
    [menuOptions]="feeMenuOptions"
    (updateFeeItem)="updateFeeItem($event, section)"
    [showFooterRow]="true"
    [parentEntity]="section"
    [level]="2"
    [(tableExpanded)]="scopeTabService.sectionFeeTableStates[section.id]"
    [(disableDragging)]="scopeTabService.disableDragging">
  </scope-ui-table>
</div>
