import { enumToString } from "@shared/utils/utils"
import { CompanyType } from "./enums/company-type.enum"
import { CdnConfig } from "./cdn-config.model"
import { CompanySummary } from "./company-summary.model"
import { Type } from "class-transformer"

export class BootstrapConfig {

  host!: string

  @Type(() => CdnConfig) cdnConfig!: CdnConfig
  @Type(() => CompanySummary) companyBootstrap?: CompanySummary

  getCompanyTypeString(): string | undefined {
    return this.companyBootstrap ? enumToString(this.companyBootstrap.companyType, CompanyType) : undefined
  }
}


