import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@plugins/material/material.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { LibraryManagementRoutingModule } from './library-management-routing.module';
import { LibraryHomeComponent } from './components/library-home/library-home.component';
import { LibraryManagementNgrxModule } from './library-management-ngrx.module';

@NgModule({
  imports: [CommonModule, MaterialModule, SharedModule, LibraryManagementRoutingModule, LibraryManagementNgrxModule],
  declarations: [LibraryHomeComponent],
  providers: [],
  exports: [RouterModule],
})
export class LibraryManagementModule {}
