import { Component, Input } from '@angular/core'

@Component({
    selector: 'loader-modal',
    templateUrl: './loader-modal.component.html',
    styleUrls: ['./loader-modal.component.scss'],
})
export class LoaderModalComponent {
    constructor() {}

    @Input() overlay: boolean = false
}
