import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipe/index.module';
import { ScopeVersion } from '@core/model/scope-version';
import { ScopeRetainedHoursStats } from '@app/features/scope-overview/model/scope-retained-hours-stats.model';
import { SowRetainedHoursStats } from '@app/features/scope-overview/model/sow-retained-hours-stats.model';
import { FormsModule } from '@angular/forms';
import { Money } from '@app/features/scope-overview/model/money.model';
import {
  ScopeUiDropdownComponent
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component'

@Component({
  selector: 'details-overview',
  standalone: true,
  imports: [CommonModule, PipesModule, FormsModule, ScopeUiDropdownComponent],
  templateUrl: './details-overview.component.html',
  styleUrls: ['./details-overview.component.scss'],
})
export class DetailsOverviewComponent implements OnChanges {
  expandedMode: boolean = false;

  versionNumbers: number[] = [];

  selectedVersion: number;

  @Input() scopeVersion!: ScopeVersion;

  @Input() scopeRetainedHoursStats!: ScopeRetainedHoursStats;

  @Input() sowRetainedHoursStats!: SowRetainedHoursStats;

  @Input() trafficMetadataLookupFields!: any;

  @Input() thirdPartyCostBalance!: Money;

  @Output() onVersionChange = new EventEmitter<number>();

  ngOnChanges(): void {
    this.generateVersionNumbers();
    this.selectedVersion = this.scopeVersion?.version;
  }

  changeVersion() {
    this.onVersionChange.emit(this.selectedVersion);
  }

  versionDisplayFn = (version: number) => {
    return version.toString()
  }

  generateVersionNumbers() {
    const currentVersionNumber = this.scopeVersion.identity.currentVersionNumber;
    this.versionNumbers = Array.from({ length: currentVersionNumber }, (_, i) => i + 1);
  }
}
