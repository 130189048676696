import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { DialogEventsService } from '../../service/dialog-events.service'
import { SharedModule } from '@app/shared/shared.module'
import { PipesModule } from '@app/shared/pipe/index.module'
import { ScopeTraffickingService } from '../../service/scope-trafficking.service'
import { ExportModalConfig } from '../../model/export-modal-config'

@Component({
  selector: 'export-traffic',
  standalone: true,
  imports: [CommonModule, PipesModule, MatDialogModule, SharedModule],
  templateUrl: './export-traffic.component.html',
  styleUrls: ['./export-traffic.component.scss'],
})
export class ExportTrafficModal {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ExportModalConfig,
    public dialogRef: MatDialogRef<ExportTrafficModal>,
    public dialog: MatDialog,
    private dialogEventService: DialogEventsService,
    public traffickingService: ScopeTraffickingService
  ) {}

  exportTrafficThroughAgencyTrafficSystem() {
    this.dialogEventService.emitEvent({ key: 'exportTrafficThroughAgencyTrafficSystem' })
    this.traffickingService.exportStatusChanges.subscribe(status => {
      if (status._showExportComplete || status._showExportAborted) {
        this.dialogRef.close();
      }
    });
  }

  exportTrafficThroughWorkato() {
    this.traffickingService.exportStatus.setPendingStart();
    this.dialogEventService.emitEvent({ key: 'exportTrafficThroughWorkato' })
    this.traffickingService.exportStatusChanges.subscribe(status => {
      if (status._showExportComplete || status._showExportAborted) {
        this.dialogRef.close();
      }
    });
  }

  exportData(exportReference: string) {
    this.traffickingService.exportDiagnosticData(this.data.scopeId, exportReference);
  }
}
