import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ScopeVersion } from '@app/core/model/scope-version'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { environment } from '@envs/environment'
import { plainToInstance } from 'class-transformer'
import { map } from 'rxjs'

interface SubmitScopeReviewPayload {
  text?: string
  overrideCollaborators?: boolean
  deliverableIds?: number[]
  userId?: number
}

@Injectable({
  providedIn: 'root',
})
export class ApprovalsService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl

  constructor(private http: HttpClient) {}

  submitScope(scopeId: number, isOverideCollabarators?: boolean, text?: string) {
    const requestBody: { text: string } = { text }
    let url = `${this.BASE_API_URL}scope/${scopeId}/submit`

    if (isOverideCollabarators) {
      url = `${url}?overrideCollaborators=${isOverideCollabarators}`
    }
    return this.http.put(url, requestBody).pipe(map((api) => plainToInstance(ScopeVersion, api)))
  }

  agreeToScope(scopeId: number, isOverrideApprovers?: boolean, text?: string) {
    const requestBody: { text: string } = { text }
    let url = `${this.BASE_API_URL}scope/${scopeId}/approve/accept`

    if (isOverrideApprovers) {
      url = `${url}?overrideApprovers=${isOverrideApprovers}`
    }
    return this.http.put(url, requestBody).pipe(map((api) => plainToInstance(ScopeVersion, api)))
  }

  rejectScope(scopeId: number, text?: string) {
    const requestBody: { text: string } = { text }
    let url = `${this.BASE_API_URL}scope/${scopeId}/approve/reject`

    return this.http.put(url, requestBody).pipe(map((api) => plainToInstance(ScopeVersion, api)))
  }

  clientAcceptScope(scopeId: number, text?: string) {
    const requestBody: { text: string } = { text }
    let url = `${this.BASE_API_URL}scope/${scopeId}/client-approve/accept`

    return this.http.put(url, requestBody).pipe(map((api) => plainToInstance(ScopeVersion, api)))
  }

  clientRejectScope(scopeId: number, text?: string, closeScope?: boolean) {
    const requestBody: { text: string } = { text }
    let url = `${this.BASE_API_URL}scope/${scopeId}/client-approve/reject?closeScope=${closeScope}`

    return this.http.put(url, requestBody).pipe(map((api) => plainToInstance(ScopeVersion, api)))
  }

  closeScope(scopeId: number, text?: string) {
    return this.http.put<any>(`${this.BASE_API_URL}scope/${scopeId}/close`, { text: text })
  }

  submitScopeReview(scopeId: number, isOverrideReviewers?: boolean, text?: string) {
    const requestBody: SubmitScopeReviewPayload = { text }
    let url = `${this.BASE_API_URL}scope/${scopeId}/review/submit`

    if (isOverrideReviewers) {
      url = `${url}?overrideReviewers=${isOverrideReviewers}`
    }
    return this.http.put(url, requestBody).pipe(map((api) => plainToInstance(ScopeVersion, api)))
  }

  withdrawSubmission(scopeId: number, text?: string) {
    const requestBody: SubmitScopeReviewPayload = { text }
    let url = `${this.BASE_API_URL}scope/${scopeId}/withdraw-submission`

    return this.http.put(url, requestBody).pipe(map((api) => plainToInstance(ScopeVersion, api)))
  }

  acceptDeliverableReview(scopeId: number, acceptComment = '', deliverableId?: number) {
    const requestBody: SubmitScopeReviewPayload = { text: acceptComment }

    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/review/accept`

    return this.http.put(url, requestBody).pipe(map((api) => plainToInstance(Deliverable, api)))
  }

  rejectDeliverableReview(scopeId: number, acceptComment = '', deliverableId?: number) {
    const requestBody: SubmitScopeReviewPayload = { text: acceptComment }

    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverable/${deliverableId}/review/reject`

    return this.http.put(url, requestBody).pipe(map((api) => plainToInstance(Deliverable, api)))
  }

  acceptAllDeliverableReview(scopeId: number, acceptComment = '', deliverableIds?: number[]) {
    const requestBody = { deliverableIds, comment: { text: acceptComment } }
    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverable/review/accept`

    return this.http.put(url, requestBody).pipe(map((api) => plainToInstance(Deliverable, api)))
  }

  rejectAllDeliverableReview(scopeId: number, acceptComment = '', deliverableIds?: number[]) {
    const requestBody = { deliverableIds, comment: { text: acceptComment } }
    let url = `${this.BASE_API_URL}scope/${scopeId}/deliverable/review/reject`

    return this.http.put(url, requestBody).pipe(map((api) => plainToInstance(Deliverable, api)))
  }

  sendApprovalNotification(scopeId: number, acceptComment = '', userId?: number) {
    let url = `${this.BASE_API_URL}scope/${scopeId}/send-notification/${userId}`

    return this.http.post(url, { text: acceptComment }).pipe(map((api) => plainToInstance(Deliverable, api)))
  }
}
