import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({providedIn : 'root'})
export class AuthInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        AuthSource: 'ScopeApi'
      },
      withCredentials: true
    });

    let subdomain = window.location.href.split(".")[0].split("//")[1]
    if (subdomain) {
      request = request.clone({
        setHeaders: {
          'X-SCOPE-DOMAIN': subdomain,
        }
      });
    }

    return next.handle(request);
  }

}
