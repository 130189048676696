import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogEventsService {

  dialogEventEmitter = new EventEmitter<{ key: string, optionalData?: {"optionalData": string, "hasOverride": boolean} }>();

  constructor() { }

  emitEvent(data: any, optionalData?: any, hasOverride?: boolean) {
    this.dialogEventEmitter.emit({key: data, optionalData: {optionalData , hasOverride}});
  }
}
