import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromLibraryManagementReducer from './library-management.reducer';
import { LibraryManagementState } from '../../models/library-management.state.interface';

export const featureKey = 'libraryManagement';

export interface State {
  libraryManagement: LibraryManagementState;
}

export const reducers: ActionReducerMap<State> = {
  libraryManagement: fromLibraryManagementReducer.reducer,
};

export const getLibraryManagementState = createFeatureSelector<State>(featureKey);
