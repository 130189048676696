import { Pipe, PipeTransform } from '@angular/core'
import { ActivityComment } from '@app/features/scope-overview/model/activity.model'
import { ActivitiesService } from '@app/features/scope-overview/service/activities.service'

@Pipe({
  name: 'commentTag',
})
export class CommentTagPipe implements PipeTransform {
  constructor(private activitiesService: ActivitiesService) {}

  transform(comment: ActivityComment): any {
    return this.activitiesService.getCommentTag(comment)
  }
}
