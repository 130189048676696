import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-demo',
  templateUrl: './ui-demo.component.html',
  styleUrls: ['./ui-demo.component.scss']
})
export class UiDemoComponent {

}
