import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@plugins/material/material.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { SystemAdminRoutingModule } from './system-admin-routing.module';
import { SystemAdminNgrxModule } from './system-admin-ngrx.module';
import { SystemAdminOverviewComponent } from './components/system-admin-overview/system-admin-overview.component';

@NgModule({
  imports: [CommonModule, MaterialModule, SharedModule, SystemAdminRoutingModule, SystemAdminNgrxModule],
  declarations: [SystemAdminOverviewComponent],
  providers: [],
  exports: [RouterModule],
})
export class SystemAdminModule {}
