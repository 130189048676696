import { EventType } from '@core/model/enums/event-type.enum';
import { UserNotification } from '@core/model/user-notification.model';


export function getCircleIcon(type: EventType): string {
  switch (type.toString()) {
    case EventType[EventType.SCOPE_COMMENT]: {
      return 'chat'
    }
    case EventType[EventType.SCOPE_UPDATED]: {
      return 'assignment'
    }
    case EventType[EventType.SCOPE_VERSIONED]: {
      return 'assignment'
    }
    case EventType[EventType.SCOPE_APPROVAL_ACCEPTED]: {
      return 'check'
    }
    case EventType[EventType.SCOPE_SUBMITTED]: {
      return 'check'
    }
    case EventType[EventType.SCOPE_SUBMITTED_FOR_REVIEW]: {
      return 'check'
    }
    case EventType[EventType.SCOPE_REVIEW_SUBMITTED]: {
      return 'check'
    }
    case EventType[EventType.SCOPE_REVIEW_REJECTED]: {
      return 'close'
    }
    case EventType[EventType.LIBRARY_ENTRY_MIGRATION_SUCCESS]: {
      return 'book'
    }
    case EventType[EventType.SCOPE_APPROVAL_REJECTED]: {
      return 'close'
    }
    case EventType[EventType.SCOPE_CLIENT_APPROVAL_REJECTED]: {
      return 'close'
    }
    case EventType[EventType.SCOPE_CLIENT_APPROVAL_ACCEPTED]: {
      return 'check'
    }
    case EventType[EventType.SCOPE_APPROVER_REMOVED]: {
      return 'person_remove'
    }
    case EventType[EventType.SCOPE_COLLABORATOR_REMOVED]: {
      return 'person_remove'
    }
    case EventType[EventType.SCOPE_REVIEWED]: {
      return 'rate_review'
    }
    case EventType[EventType.SCOPE_COLLABORATOR_ADDED]: {
      return 'person_add'
    }
    case EventType[EventType.DATA_EXPORT_SUCCESS]: {
      return 'download'
    }
  }
  return 'feedback'
}

export function getNotificationBody(notif: UserNotification) {
  return mapUserNotification(notif);
}

export function createNotification(notif: UserNotification, c: any) {
  let entityTypeName = c.entityTypeName ? c.entityTypeName : "";
  let actionText = c.nameOfAction ? entityTypeName + " has been " + c.nameOfAction : entityTypeName + "";
  let preEntityText = c.preEntityText ? " " + c.preEntityText + " " : "";
  let initiatedUserRole = c.initiatedUserRole ? c.initiatedUserRole : "";
  let postEntityText = c.postEntityText ?  " " + c.postEntityText + " " : actionText + " by " + initiatedUserRole;
  let initiator = c.hideInitiator ? "" : " <span class='font-newJuneBold'>" + notif.initiatedBy.firstName + notif.initiatedBy.lastName + "</span>";
  let postInitiatorText = c.postInitiatorText ? " " + c.postInitiatorText : "";

  let entityText = preEntityText + "<span class='font-newJuneBold'>" + notif.entity.name + "</span> " + postEntityText;
  let initiatorText = initiator + postInitiatorText;
  if (c.initiatorTextFirst){
    notif = { ...notif, message: initiatorText + entityText}
    return notif;
  } else {
    notif = { ...notif, message: entityText + initiatorText};
    return notif;
  }
}

export function scopeNotification(notif: UserNotification, c: any) {
  c.entityTypeName = "scope";
  notif = createNotification(notif, c);
  notif = {...notif, action: c.action ? c.action : function () {
      // TODO suggestion set action in this file instead of notification-sidebar (Router importing breaking app build right now)
  }};
  return notif
}

export function sowNotification(notif: UserNotification, c: any) {
  c.entityTypeName = "scope of work";
  notif = createNotification(notif, c);
  notif = {...notif, action: c.action ? c.action : function () {
    // TODO set action
  }};
  return notif
}

export function companyGroupNotification(notif: UserNotification, c: any) {
  c.entityTypeName = "group";
  notif = createNotification(notif, c);
  notif = {...notif, action: function () {
    // TODO set action
  }};
  return notif
}

export function mapUserNotification(notif: UserNotification) {
  let type = notif.eventType;
  switch (type.toString()) {
    case EventType[EventType.DATA_EXPORT_SUCCESS]: {
      if (notif.properties){
        const type = notif.properties.type
        const format = notif.properties.format
        const uuid = notif.properties.uuid
        const exportName = notif.properties.exportName
        if (exportName){
          notif = {...notif, message: "File is available to download</br><small><i>" + exportName + `.${format}` + "</i></small>"}
        } else {
          notif = {...notif, message: `${type} ${format} is available to download`}
        }
        return notif
      } else {
        return notif = {...notif, message: `File downloaded`}
      }
    } case EventType[EventType.SOW_REVIEW_REJECTED]: {
      return sowNotification(notif, {
        nameOfAction: "rejected",
        initiatedUserRole: "reviewer",
        icon: "thumb_down",
        action: function () {
          //UiUtils.redirectToSowActivity(notif.entity.id)
        }
      });
    }
    case EventType[EventType.LIBRARY_ENTRY_MIGRATION_SUCCESS]: {
      notif = {...notif, message: `Library Entries Migrated Successfully`}
      return notif
    }
    case EventType[EventType.SOW_REVIEW_ACCEPTED]: {
      return sowNotification(notif, {
        nameOfAction: "accepted",
        initiatedUserRole: "reviewer",
        icon: "thumb_up",
        action: function () {
          //UiUtils.redirectToSowActivity(notif.entity.id)
        }
      });
    }
    case EventType[EventType.SOW_IN_REVIEW]: {
      return sowNotification(notif, {
        preEntityText: "has published",
        postEntityText: "Scope of Work and is now ready for your review",
        initiatorTextFirst: true,
        icon: "assignment",
        action: function () {
          //UiUtils.redirectToSowActivity(notif.entity.id)
        }
      });
    }
    case EventType[EventType.SCOPE_COLLABORATOR_ADDED]: {
      return scopeNotification(notif, {
        preEntityText: "You have been added as a collaborator on",
        icon: "add"
      });
    }
    case EventType[EventType.SCOPE_REVIEWER_ADDED]: {
      return scopeNotification(notif, {
        preEntityText: "You have been added as a reviewer on",
        icon: "add"
      });
    }
    case EventType[EventType.SCOPE_REVIEWER_REMOVED]: {
      return scopeNotification(notif, {
        preEntityText: "You have been removed as a reviewer from",
        icon: "remove"
      });
    }
    case EventType[EventType.SCOPE_APPROVER_ADDED]: {
      return scopeNotification(notif, {
        preEntityText: "You have been added as an approver on",
        icon: "add"
      });
    }
    case EventType[EventType.SCOPE_APPROVER_REMOVED]: {
      return scopeNotification(notif, {
        preEntityText: "You have been removed as an approver from",
        icon: "remove"
      });
    }

    case EventType[EventType.SCOPE_TRAFFICKER_ADDED]: {
      return scopeNotification(notif, {
        preEntityText: "You have been added as a trafficker on",
        icon: "add"
      });
    }
    case EventType[EventType.SCOPE_TRAFFICKER_REMOVED]: {
      return scopeNotification(notif, {
        preEntityText: "You have been removed as a trafficker from",
        icon: "remove"
      });

    }
    case EventType[EventType.SCOPE_SUBMITTED]: {
      return scopeNotification(notif, {
        nameOfAction: "submitted",
        icon: "lock",
        action: function () {
          //UiUtils.redirectToScopeActivity(notif.entity.id, notif.entity.scopeType)
        }
      });
    }
    case EventType[EventType.SCOPE_SUBMISSION_WITHDREW]: {
      return scopeNotification(notif, {
        postInitiatorText: "has withdrew their submission",
        preEntityText: "on",
        postEntityText: "scope",
        initiatorTextFirst: true,
        icon: "lock_open",
        action: function () {
          //UiUtils.redirectToScopeActivity(notif.entity.id, notif.entity.scopeType)
        }
      });
    }
    case EventType[EventType.SCOPE_SUBMITTED_FOR_REVIEW]: {
      return scopeNotification(notif, {
        postEntityText: "Scope has been submitted and is now ready for your review",
        hideInitiator: true,
        icon: "assignment",
      });
    }

    case EventType[EventType.SCOPE_REVIEW_SUBMITTED]: {
      return scopeNotification(notif, {
        postEntityText: "Scope has been submitted by",
        icon: "assignment"
      });
    }
    case EventType[EventType.SCOPE_REVIEW_ACCEPTED]: {
      return scopeNotification(notif, {
        nameOfAction: "accepted",
        initiatedUserRole: "reviewer",
        icon: "thumb_up",
        action: function () {
          ////UiUtils.redirectToScopeActivity(notif.entity.id, notif.entity.scopeType)
        }
      });
    }
    case EventType[EventType.SCOPE_REVIEW_REJECTED]: {
      return scopeNotification(notif, {
        nameOfAction: "rejected",
        initiatedUserRole: "reviewer",
        icon: "thumb_down",
        action: function () {
          //UiUtils.redirectToScopeActivity(notif.entity.id, notif.entity.scopeType)
        }
      });
    }
    case EventType[EventType.SCOPE_REVIEWED]: {
      return scopeNotification(notif, {
        postEntityText: "Scope has been reviewed and is now ready for your approval",
        hideInitiator: true,
        icon: "assignment"
      });
    }
    case EventType[EventType.SCOPE_MENTION]: {
      return scopeNotification(notif, {
        preEntityText: "You have been mentioned in comment on",
        icon: "add"
      });
    }
    case EventType[EventType.SCOPE_APPROVAL_ACCEPTED]: {
      return scopeNotification(notif, {
        nameOfAction: "approved",
        initiatedUserRole: "approver",
        icon: "done_all",
        action: function () {
          //UiUtils.redirectToScopeActivity(notif.entity.id, notif.entity.scopeType)
        }
      });
    }
    case EventType[EventType.SCOPE_APPROVAL_REJECTED]: {
      return scopeNotification(notif, {
        nameOfAction: "rejected",
        initiatedUserRole: "approver",
        icon: "thumb_down",
        action: function () {
          //UiUtils.redirectToScopeActivity(notif.entity.id, notif.entity.scopeType)
        }
      });
    }
    case EventType[EventType.SCOPE_CLIENT_APPROVAL_ACCEPTED]: {
      return scopeNotification(notif, {
        postEntityText: "Scope has been approved by the client",
        hideInitiator: true,
        icon: "done_all",
        action: function () {
          //UiUtils.redirectToScopeActivity(notif.entity.id, notif.entity.scopeType)
        }
      });
    }
    case EventType[EventType.SCOPE_CLIENT_APPROVAL_REJECTED]: {
      return scopeNotification(notif, {
        postEntityText: "Scope has been rejected by the client",
        hideInitiator: true,
        icon: "thumb_down",
        action: function () {
          //UiUtils.redirectToScopeActivity(notif.entity.id, notif.entity.scopeType)
        }
      });
    }
    case EventType[EventType.SCOPE_READY_FOR_TRAFFICKING]: {
      return scopeNotification(notif, {
        postEntityText: "Scope has been approved and is now ready for trafficking",
        hideInitiator: true,
        icon: "flight_takeoff"
      });
    }
    case EventType[EventType.SCOPE_APPROVAL_REMINDER]: {
      return scopeNotification(notif, {
        postEntityText: "Scope is waiting for your approval. Requested by",
        icon: "flight_takeoff",
        action: function () {
          //UiUtils.redirectToScopeActivity(notif.entity.id, notif.entity.scopeType)
        }
      });
    }
    case EventType[EventType.SCOPE_TRAFFICKED]: {
      return scopeNotification(notif, {
        nameOfAction: "trafficked",
        icon: "swap_horiz"
      });

    }
    case EventType[EventType.SCOPE_CLOSED]: {
      return scopeNotification(notif, {
        nameOfAction: "closed",
        initiatedUserRole: "initiated",
        icon: "assignment_turned_in"
      });
    }
    case EventType[EventType.SCOPE_COLLABORATOR_REMOVED]: {
      return scopeNotification(notif, {
        preEntityText: "You have been removed as a collaborator from",
        icon: "remove"
      });
    }
    case EventType[EventType.SCOPE_COMMENT]: {
      return scopeNotification(notif, {
        preEntityText: "A new comment has been added on",
        icon: "comment",
        action: function () {
          //UiUtils.redirectToScopeActivity(notif.entity.id, notif.entity.scopeType)
        }
      });
    }
    case EventType[EventType.GROUP_MEMBER_ADDED]: {
      return companyGroupNotification(notif, {
        preEntityText: "You have been added as a member to",
        icon: "add"
      });
    }
    case EventType[EventType.GROUP_MEMBER_REMOVED]: {
      return companyGroupNotification(notif, {
        preEntityText: "You have been removed from",
        icon: "remove"
      });
    }
  }
  return notif;
}

export function getTimeAgo(date: Date) {
  let today = new Date();
  if (!date) {
    return ''
  }
  let hoursOld = ((today).getTime() - date.getTime()) / 3600000;
  if (isSameDay(date, today)) {
    if (hoursOld < 1) {
      let minutes = hoursOld * 60;
      if (minutes < 1) {
        let seconds = Math.round(minutes * 60);
        if (seconds < 15) {
          return "just now"
        }
        return seconds + " seconds ago"
      }
      return Math.round(minutes) + ' minutes ago'
    }
    return Math.round(hoursOld) + ' hours ago';
  }

  let yesterdaysDate = calculateYesterdaysDate();
  if (isSameDay(date, yesterdaysDate)) {
    return 'yesterday';
  }

  if (hoursOld < 168) {
    let days = Math.round(hoursOld / 24);
    if(days === 1) {
      return days + ' day ago';
    }
    return days + ' days ago';
  } else if (hoursOld < 730) {
    let weeks = Math.round(hoursOld / 168);
    if(weeks === 1) {
      return weeks + ' week ago';
    }
    return weeks + ' weeks ago';
  } else if (hoursOld < 8760) {
    let months = Math.round(hoursOld / 730);
    if(months === 1) {
      return months + ' month ago';
    }
    return months + ' months ago';
  } else {
    let years = Math.round(hoursOld / 8760);
    if(years === 1) {
      return years + ' year ago';
    }
    return years + ' years ago';
  }
};

export function calculateYesterdaysDate() {
  let yesterdaysDate = new Date();
  yesterdaysDate.setDate(yesterdaysDate.getDate() - 1);
  return yesterdaysDate
}

export function isSameDay(thisDate: Date, thatDate: Date) {
  return thisDate.getFullYear() == thatDate.getFullYear() &&
    thisDate.getMonth() == thatDate.getMonth() &&
    thisDate.getDate() == thatDate.getDate();
}


