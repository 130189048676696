import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotPasswordSuccessComponent } from './components/forgot-password-success/forgot-password-success.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { isLoggedInGuard } from '@core/guard/is-logged-in-guard'

export const routes: Routes = [
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [isLoggedInGuard],
    data: {
      disabledAuthorizationCheck: true,
      redirectToScopes: true
    }
  },
  {
    path: 'auth/forgot-password-success',
    component: ForgotPasswordSuccessComponent,
    canActivate: [isLoggedInGuard],
    data: {
      disabledAuthorizationCheck: true,
      redirectToScopes: true
    }
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    canActivate: [isLoggedInGuard],
    data: {
      disabledAuthorizationCheck: true,
      redirectToScopes: true
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserAccountRoutingModule {}
