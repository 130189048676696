import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { AuthSelectors } from '@core/store/selectors/auth.selector';
import { Store } from '@ngrx/store';
import { AuthActions } from '@core/store';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { EventType } from '@core/model/enums/event-type.enum';
import {
  getCircleIcon,
  getNotificationBody,
  getTimeAgo,
} from '@shared/components/notification-sidebar/utils/notification-utils';
import { UserNotification } from '@core/model/user-notification.model';
import { RouterLink } from '@angular/router';
import { UserService } from '@app/features/user-account/services/user.service';
import { WebsocketService } from '@app/shared/services/websocket.service';
import { DirectivesModule } from '@shared/directives/index.module'
import { trackById } from '@shared/utils/utils';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss'],
  imports: [
    NgClass,
    ReactiveFormsModule,
    AsyncPipe,
    NgForOf,
    MatIconModule,
    NgIf,
    RouterLink,
    DirectivesModule,
  ],
  standalone: true,
})
export class NotificationSidebarComponent {
  @Input() visible!: boolean
  @Output() onNotificationClick: EventEmitter<any>
  @Output() onMarkAllAsRead: EventEmitter<any>
  @Output() onCloseNotifications: EventEmitter<any>

  userNotifications$ = this.store.select(AuthSelectors.selectUserNotifications)

  constructor(private store: Store, private userService: UserService, private webSocketService: WebsocketService) {
    this.onNotificationClick = new EventEmitter<any>()
    this.onMarkAllAsRead = new EventEmitter<any>()
    this.onCloseNotifications = new EventEmitter<any>()
  }

  ngOnInit() {
    this.webSocketService.connect()
    this.webSocketService.registerRequiredListeners()
    this.store.dispatch(AuthActions.getUserNotifications())
  }

  getIcon(type: EventType) {
    return getCircleIcon(type)
  }

  getNotificationBody(notif: UserNotification) {
    return getNotificationBody(notif)
  }

  onClickNotification(notif: UserNotification) {
    this.userService.markNotificationAsRead(notif.id).subscribe(() => {
      this.store.dispatch(AuthActions.getUserNotifications())
      this.onNotificationClick.emit(notif)
    })
  }

  getTimeAgo(notif: UserNotification) {
    return getTimeAgo(notif.createdTs)
  }

  markAllAsRead() {
    this.userService.markAllNotificationsAsRead().subscribe(() => {
      this.store.dispatch(AuthActions.getUserNotifications())
      this.onMarkAllAsRead.emit()
    })
  }

  protected readonly trackById = trackById;
}
