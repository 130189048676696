import { User } from '@core/model/user.model'
import { Type } from 'class-transformer'

export class UserComment {
  id?: number
  text: string
  sourceAttribute: any
  @Type(() => User) createdBy: User
  @Type(() => Date) createdTs: Date
  source?: any
  sourceType?: string
  originType: string
}
