import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@envs/environment';
import { BootstrapConfig } from '../model/bootstrap-config.model';

@Injectable({
  providedIn: 'root',
})
export class BootstrapService {
  constructor(private http: HttpClient) {}

  loadBootstrapConfig() {
    return this.http.get<BootstrapConfig>(`${environment.scopeApiUrl}bootstrap`);
  }

  samlIdpDiscovery(username: string) {
    const idpParams = new HttpParams().set('email', username)
    return this.http.get<any>(`${environment.scopeApiUrl}saml/idp-discovery`, { params: idpParams })
  }

  idpSamlLogin(username: string) {
    const idpLoginParams = new HttpParams().set('idp', username)
    return this.http.get<any>(`${environment.scopeApiUrl}saml/login`, { params: idpLoginParams })
  }
}
