import { Component, OnInit } from '@angular/core';
import { CoreState } from '@core/store';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { UserNotification } from '@core/model/user-notification.model';
import { AuthSelectors } from '@core/store/selectors/auth.selector';
import { HttpResponse } from '@angular/common/http';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';

@Component({
  selector: 'notification-status',
  templateUrl: './notification-status.component.html',
})
export class NotificationStatusComponent implements OnInit {
  constructor(private store: Store<CoreState>, private scopeOverviewService: ScopeOverviewService, private router: Router) { }

  unreadyNotificationsCount!: number
  notificationStatus: NotificationStatus = new NotificationStatus();

  unreadyNotificationsCount$ = this.store.select(AuthSelectors.selectUnreadUserNotifications)

  ngOnInit(): void {

  }

  onNotificationClick(notif: UserNotification) {
    this.notificationStatus.showNotifications = false;
    if (notif.entitySourceType === EntitySourceType.SCOPE) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
      this.router.navigateByUrl('scopes/scope-overview/' + notif.entity.id)
    } else if (notif.entitySourceType === EntitySourceType.SCOPE_OF_WORK) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
      this.router.navigateByUrl('scopes/folder-overview/' + notif.entity.id)
    } else if (notif.entitySourceType === EntitySourceType.EXTERNAL_FILE) {
      if (notif.properties && notif.properties.uuid) {
        this.scopeOverviewService.downloadDataExport(notif.properties.uuid, null).subscribe(resp => {
          this.downloadFile(resp);
        })
      }
    }
  }

  downloadFile(res: HttpResponse<Blob>) {
    if (res.body != null) {
      const a = document.createElement("a");
      const fileName = res.headers.get('content-disposition');
      a.href = URL.createObjectURL(res.body);
      if (fileName != null) {
        a.download = fileName.split('filename=')[1].split(';')[0];
      }
      a.click();
    }
  }

  onCloseNotifications() {
    this.notificationStatus.showNotifications = false;
  }

  onMarkAllAsRead() {
    this.notificationStatus.showNotifications = false;
  }
}

class NotificationStatus {
  showNotifications: boolean = false;
}

export enum EntitySourceType {
  SCOPE = 'SCOPE',
  SCOPE_OF_WORK = 'SCOPE_OF_WORK',
  EXTERNAL_FILE = 'EXTERNAL_FILE',
}
