export class Money {
  amount!: number | null
  currency!: string

  constructor(amount: number | null, currency: string) {
    this.amount = amount;
    this.currency = currency;
  }

  static of(amount: number | null, currency: string) {
    return new Money(amount, currency);
  }

  static addValues(a: Money, b: Money) {
    if (a.currency !== b.currency) {
      throw new Error("Cannot add two amounts with different currencies");
    }
    const sum = (a.amount || 0) + (b.amount || 0);
    return Money.of(sum, a.currency);
  }

  subtractValue(value: number): Money {
    const subtraction = (this.amount || 0) - value;
    return Money.of(subtraction, this.currency);
  }

  getValue() {
    return this.amount;
  }

  getCurrencyCode() {
    return this.currency;
  }

  add(a?: Money) {
    if (a && a.currency !== this.currency) {
      throw new Error("Cannot add two amounts with different currencies");
    }
    const sum = (a?.amount || 0) + (this.amount || 0);
    return Money.of(sum, this.currency);
  }

  addValue(a: number | null) {
    const sum = (a || 0) + (this.amount || 0);
    return Money.of(sum, this.currency);
  }

  multiply(num: number | null) {
    return new Money((this.amount || 0) * (num || 0), this.currency);
  }
}
