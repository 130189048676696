<section class="page-header">
  <div class="scope-row">
    <div class="four--columns">
      <h2 class="page-header__title">Reset Password</h2>
    </div>
    <div class="eight--columns"></div>
  </div>
</section>

<section class="login-container container container--grey">
  <div class="login-content scope-pod">
    <h4 class="scope-pod__title">Set new password</h4>

    <div class="login-form-content scope-pod__form">
      <div class="five--columns--responsive">
        <form [formGroup]="passwordResetForm" (ngSubmit)="resetPassword()" class="login-form">
          <div class="reset-info">
            <label>
              Passwords must be a minimum of {{ minLength }}
              characters and must contain at least three of the following:
            </label>

            <ul>
              <li>Alphabetical lower case letters: a-z</li>
              <li>Alphabetical upper case letters: A-Z</li>
              <li>Digits: 0-9</li>
              <li>Special characters: ! $*&</li>
            </ul>

            <p class="text-sm">Please do not use dictionary words in any language, names, addresses, or dates.</p>
          </div>

          <mat-form-field class="form-group">
            <input
              matInput
              type="password"
              name="username"
              placeholder="Password"
              formControlName="password"
              class="login-input"
              required />
          </mat-form-field>

          <div *ngIf="passwordResetForm.invalid && (passwordResetForm.dirty || passwordResetForm.touched)" style="transition: 0.5s">
            <div class="warning warning--broken">
              <mat-error *ngIf="password?.errors?.['required']"> Please enter a new password </mat-error>
              <mat-error *ngIf="password?.errors?.['minlength']"> Minimum Length is {{ minLength }} </mat-error>
              <mat-error *ngIf="password?.errors?.['hasLowerCase']"> Must contain an lowercase letter </mat-error>
              <mat-error *ngIf="password?.errors?.['hasUpperCase']"> Must contain an uppercase letter </mat-error>
              <mat-error *ngIf="password?.errors?.['hasSpecialChar']"> Must contain a special character </mat-error>
              <mat-error *ngIf="password?.errors?.['hasDigit']"> Must contain a number </mat-error>
              <mat-error *ngIf="password?.errors?.['containsDictionaryWord']"
                >Passwords cannot contain dictionary words in any language, names, addresses, or dates
              </mat-error>
              <mat-error *ngIf="password?.errors?.['containsInPasswordHistory']">
                Five most recent passwords cannot be reused
              </mat-error>
              <mat-error *ngIf="password?.errors?.['containsUserId']"> Passwords cannot contain a user's ID </mat-error>
            </div>
          </div>

          <button
            [disabled]="passwordResetForm.invalid"
            [ngClass]="{ 'btn--disabled': passwordResetForm.invalid }"
            class="btn btn--inline btn--action btn--large"
            type="submit">
            Save Password
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
