import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScopeUiBreadcrumbsComponent } from '@app/shared/components/scope-ui-breadcrumbs/scope-ui-breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { ScopeUiGoBackBreadcrumbComponent } from '@app/shared/components/scope-ui-go-back-breadcrumb/scope-ui-go-back-breadcrumb.component';

@Component({
  selector: 'breadcrumbs-demo',
  standalone: true,
  imports: [CommonModule, ScopeUiBreadcrumbsComponent, ScopeUiGoBackBreadcrumbComponent, RouterModule],
  templateUrl: './breadcrumbs-demo.component.html',
  styleUrls: ['./breadcrumbs-demo.component.scss'],
})
export class BreadcrumbsDemoComponent {}
