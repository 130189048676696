import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from "@shared/shared.module";
import { ScopeVersion } from "@core/model/scope-version";

@Component({
  selector: 'duplicate-different-ratecard-version',
  templateUrl: './duplicate-different-ratecard-version.component.html',
  styleUrls: ['./duplicate-different-ratecard-version.component.scss'],
  imports: [
    SharedModule
  ],
  standalone: true
})
export class DuplicateDifferentRatecardVersionComponent {
  @Input() scopeToDuplicate: ScopeVersion

  @Output() onUseLatest = new EventEmitter<any>();
  @Output() onUseOld = new EventEmitter<any>();

  useLatest() {
    this.onUseLatest.emit()
  }

  useOld() {
    this.onUseOld.emit()
  }
}
