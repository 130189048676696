import { Type } from 'class-transformer';
import { CompanySummary } from '@core/model/company-summary.model';
import { SecondParty } from '@core/model/second-party.model';
import { RatecardIdentity } from '@app/features/scope-overview/model/ratecard-identity.model';
import { CostPlus } from '@app/features/scope-overview/model/cost-plus.model';

export class SecondPartyRatecard {
  id!: number;
  @Type(() => CompanySummary) company!: CompanySummary;
  @Type(() => SecondParty) secondParty!: SecondParty;
  @Type(() => RatecardIdentity) ratecardIdentity!: RatecardIdentity;
  @Type(() => CostPlus) costPlus!: CostPlus;
}
