import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { SystemAdminState } from '../../models/system-admin.state.interface';
import * as fromSystemAdminReducer from './system-admin.reducer';

export const featureKey = 'systemAdmin';

export interface State {
  systemAdmin: SystemAdminState;
}

export const reducers: ActionReducerMap<State> = {
  systemAdmin: fromSystemAdminReducer.reducer,
};

export const getSystemAdminState = createFeatureSelector<State>(featureKey);
