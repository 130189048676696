

export class ClassificationType {
    id!: number;
    name!: string;
    description!: string;
    nonTranslatedName!: string;
    nonTranslatedDescription!: string;
    parentId!: number;
    foreignSource!: boolean;
    scopeType!: string;
}
