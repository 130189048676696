<span *ngIf="!useComment()" isEllipsisActive style='-webkit-line-clamp: var(--line-clamp, 2)'>
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</span>

<ng-container *ngIf="useComment()">
  <div (click)="$event.stopPropagation()">
    <div class="commentable" [class.has-comments]="commentable?.comments?.length > 0" [class.has-unread-comment]="commentable?.hasUnreadComment"
         [matMenuTriggerFor]="menu" (menuClosed)="toggleClosed()" (menuOpened)="toggleOpened()">
      <div class="comment-subject" isEllipsisActive>
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
      <div class="comment-indicator">
        <div class="comments-number" *ngIf="commentable?.comments?.length > 0">
          <mat-icon class="material-symbols-rounded">chat_bubble</mat-icon>
          <span>{{commentable?.comments?.length}}</span>
        </div>
        <mat-icon class="material-symbols-rounded -scale-x-100" title="Add comment" *ngIf="!commentable?.comments?.length && useCommentInput() && !commentableUseIcon">comment</mat-icon>
        <mat-icon class="material-symbols-rounded" title="Add comment" *ngIf="!commentable?.comments?.length && useCommentInput() && commentableUseIcon">rate_review</mat-icon>
      </div>
    </div>
    <mat-menu #menu="matMenu" [class]="disableAnimation ? 'comment-wrapper no-animate' : 'comment-wrapper'" (click)="$event.stopPropagation()">
      <div class="comment-content" [hidden]="!commentable?.comments?.length">
        <ul>
          <li *ngFor="let comment of commentable?.comments | orderBy: 'createdTs'">
            <span>
              <small class="orange bold">{{comment.createdBy.fullName}}</small>
              <small class="ultra-grey right">{{comment.createdTs | dateFormat: 'shortDate'}}</small>
            </span>
            <p class="comment">{{comment.text}}</p>
          </li>
        </ul>
      </div>
      <div class="comment-input" *ngIf="useCommentInput()" (click)="$event.stopPropagation()">
        <form [formGroup]="commentForm">
          <mat-form-field>
            <textarea
              matInput
              class='resize-none'
              (input)="onInput($event)"
              (keydown.enter)='$event.preventDefault()'
              (keyup.enter)="send(); $event.stopPropagation()"
              formControlName="comment"
              maxlength="250"
              placeholder="Comment:"
              rows='1'
              autofocus></textarea>
            <mat-hint align="end">{{commentable?.newComment?.length || 0}} / 250</mat-hint>
          </mat-form-field>
          <mat-error *ngIf="commentForm.hasError('required')">
            You must enter a comment
          </mat-error>
          <mat-error *ngIf="commentForm.hasError('maxlength')">
            Your comment is too long.
          </mat-error>
          <div class="flex justify-between">
            <span>
              <span *ngIf="commentableAction && checkPrivilege(commentableActionPrivilege)" class="orange left pointer" (click)="callAction()">{{commentableActionText}}</span>
            </span>
            <mat-icon class="material-symbols-rounded orange pointer" (click)="send()">send</mat-icon>
          </div>
        </form>
      </div>
    </mat-menu>
  </div>
</ng-container>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
