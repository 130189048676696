export class Discount {
    description: string;
    value: number;
    type: string;

    constructor(description: string, value: number, type: string) {
        this.description = description;
        this.value = value;
        this.type = type;
    }

    getDescription(): string {
        return this.description;
    }

    getValue(): number | string {
        return this.value;
    }

    getValueFormatted(): number {
        return Number((this.value).toFixed(2))
    }

    getValueByPercentage(scopePrice: number): number {
        if (this.type == 'PERCENTAGE') {
            return Number((scopePrice * this.value / 100).toFixed(2))
        }
        return this.value
    }

    getType(): string {
        return this.type;
    }

    setType(type: string): void {
        this.type = type;
    }

    setDescription(description: string): void {
        this.description = description;
    }

    setValue(value: number): void {
        this.value = value;
    }
}
