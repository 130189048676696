<div class="output-info">
  <div class="text-container">
    Edit your documents to match your needs. Preview your documents with ease, ensuring they look just right before
    finalising. <br>

    <span *ngIf="showMoreInfo">
      Download your creations in various formats such as CSV, Word document, PDF, or Excel document. <br>
      You can even bring your own templates to the table, making every project a true reflection of your individuality.
      <br>
    </span>
    <span class="show-more-output orange pointer" (click)="showMoreInfo = !showMoreInfo">{{!showMoreInfo ? 'Show more' :
      'Hide'}}</span>
  </div>
</div>
<div class='scope-page pt-2'>
  <scope-ui-tabs [fontSize]="'16px'">
    <scope-ui-tab tabTitle="Document">
      <div class="output-container" *ngIf='outputTemplate'>
        <div class="container__header controls output">
          <div class="left output-left">
            <div class="def-output flex flex-row">
              <span>
                <i class="material-icons material-symbols-rounded orange pointer mr-1 text-sm"
                   matTooltip="By default file name corresponds to the Scope name ({{currentScope.name}}). You can change the name of current template. Leave blank form to return it by default">help_outline</i>
              </span>
              <span class="font-newJuneBold text-sm" *ngIf="!editName">{{outputTemplate.exportName ?
                outputTemplate.exportName : currentScope.name}}</span>
              <i (click)="editName = true" *ngIf="!editName"
                 class="material-icons material-symbols-rounded fill orange text-sm ml-1.5 cursor-pointer">mode_edit</i>
              <div *ngIf="editName"
                   style="width:300px;margin: 0;height: 40px; font-family: NewJune, Verdana, Geneva, sans-serif;">
                <scope-ui-input
                  *ngIf="editName"
                  [initialValue]="outputTemplate.exportName ? outputTemplate.exportName : currentScope.name"
                  [hasPlaceholder]="false"
                  (onInputBlurChange)="outputTemplate.exportName = $event"
                />
              </div>
              <mat-icon (click)="editName = false;updateTemplate();" *ngIf="editName" class="material-symbols-rounded orange cursor-pointer">check</mat-icon>
            </div>
          </div>
          <div class="float-right">
            <div class="flex items-center relative">
              <div class='flex items-center' *ngIf="outputTemplate?.documentTemplate == 'BLANK'">
                <input [(ngModel)]="outputTemplate.pageBreakable" (ngModelChange)="updateTemplate()" type='checkbox' />
                <label class='text-sm'>Include page breaks by section</label>
              </div>
              <div class='flex items-center'>
                <input [(ngModel)]="outputTemplate.enableTemplateBorders" (ngModelChange)="updateTemplate()"
                       type='checkbox' />
                <label class='text-sm'>Enable borders for whole document</label>
              </div>
            </div>
          </div>
        </div>

        <app-output-editor *ngIf='outputTemplate' (addBlock)="onAddBlock($event)" (myEvent)='updateTemp($event)' [outputTemplate]='outputTemplate'
                           [currentScope]='currentScope' [currentUser]='loggedInUser' (onPreviewExport)="previewExport()"></app-output-editor>
      </div>

    </scope-ui-tab>
    <scope-ui-tab tabTitle="Spreadsheet">
      <div class="output-container" *ngIf="xlsxTemplate != null">
        <div class="members-container__header clients output">
          <div class="left output-left">
            <div class="def-output">
              <span class="title orange">Template: </span>{{xlsxTemplate.name}}
            </div>
          </div>
          <div class="right">
            <a (click)="previewXlsxExport()" class="brows-btn">Preview</a>
          </div>
        </div>
      </div>
    </scope-ui-tab>
  </scope-ui-tabs>
</div>
