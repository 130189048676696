<div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{ data.title }}</h1>
      </div>

      <div class="mt-2" [ngClass]="{ 'w-450': data.limitModalWidth }">
        <span *ngIf="traffickingService.exportStatus._confirmationPending">{{ data.body }}</span>

        <span *ngIf="traffickingService.exportStatus._pendingStart">Export should start shortly...</span>

        <span *ngIf="traffickingService.exportStatus._showExportAborted">{{traffickingService.abortedErrorMessage}} <br> Please contact your administrator.</span>

        <span *ngIf="traffickingService.exportStatus._showExportComplete">Successfully synced with traffic system. You can now close the window.</span>
      </div>

      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>

    <div *ngIf="traffickingService.exportStatus._showExportAborted || (traffickingService.exportStatus._showExportComplete && traffickingService.deliverableFailCount > 0)">
      <div>
        <a *ngIf="!traffickingService.showAbortErrorDiagnostic" style="color: black;" (click)="traffickingService.showAbortErrorDiagnostic = true">Show System Diagnostic</a>
        <a *ngIf="traffickingService.showAbortErrorDiagnostic" style="color: black;" (click)="traffickingService.showAbortErrorDiagnostic = false">Hide System Diagnostic</a>
      </div>

      <div style="max-height: 300px; overflow-y: scroll;">
        <small *ngIf="traffickingService.showAbortErrorDiagnostic" style="color: grey;">
          <span *ngIf="traffickingService.exportReference">
            <code>Export Reference: {{ traffickingService.exportReference }}</code><br>
            <a *ngIf="traffickingService.errorType != 'INTERNAL'" (click)="exportData(traffickingService.exportReference)" style="font-family: monospace, monospace; font-size: 1em; color: #ff6b00;">Download error log</a><br>
          </span>
          <code>{{ traffickingService.abortedErrorMessage }}</code>
          <code *ngFor="let e of traffickingService.deliverableErrors">[{{ e.id }} - {{ e.name }}]: {{ e.error }}<br></code>
          <code *ngIf="!traffickingService.abortedErrorMessage && traffickingService.deliverableErrors.length == 0">
            Please contact Scope support
          </code>
        </small>
      </div>
    </div>

  </div>


  <mat-dialog-content aria-label="Generic modal" class="modal-container md-dialog-container">
    <div class="export-traffic-actions">
      <mat-dialog-actions class="flex gap-2">

        <button *ngIf="traffickingService.exportStatus._confirmationPending && data.currentUser.company.trafficSystemSettings?.trafficSystem" (click)="exportTrafficThroughAgencyTrafficSystem()" class="scope-ui-modal-flat-button">
          <!-- TODO: remove the system check when generic integration work is completed -->
          Traffic to {{ data.currentUser.company.trafficSystemSettings.trafficSystem.name == 'Workato' ? 'Workday' : data.currentUser.company.trafficSystemSettings.trafficSystem.name }}
        </button>
        <button *ngIf="traffickingService.exportStatus._confirmationPending && data.currentUser.company.hasWorkatoIntegration" (click)="exportTrafficThroughWorkato()" class="scope-ui-modal-flat-button">
          Traffic to {{ data.currentUser.company.workatoIntegrationSystemNames }}
        </button>
        <button (click)="dialogRef.close(); traffickingService.exportStatus.reset()" *ngIf="traffickingService.exportStatus._showExportComplete || traffickingService.exportStatus._showExportAborted" class="scope-ui-modal-flat-button">
            Close
        </button>

      </mat-dialog-actions>
    </div>
  </mat-dialog-content>
