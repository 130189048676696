import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'scope-ui-filter-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './scope-ui-filter-card.component.html',
  styleUrls: ['./scope-ui-filter-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScopeUiFilterCardComponent {
  @Input() heading!: string;
  @Input() sub_heading!: string;
  @Input() icon_name!: string;
  @Input() card_data: any;
  @Input() card_type!: string;
  @Input() cardActive!: boolean;

  @Output() cardClicked = new EventEmitter<string>();

  onCardClick() {
    this.cardClicked.emit(this.card_type);
  }
}
