import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SnackbarEventType, SnackbarEvent, SnackbarService } from '@shared/utils/snackbar.service'
import { PipesModule } from '@shared/pipe/index.module'

@Component({
  selector: 'scope-ui-snackbar',
  standalone: true,
  imports: [CommonModule, PipesModule],
  templateUrl: './scope-ui-snackbar.component.html',
  styleUrls: ['./scope-ui-snackbar.component.scss'],
})
export class ScopeUiSnackbarComponent implements OnInit {
  visible: boolean = false
  htmlClass: string
  actionIcon: string
  snackbarEvent: SnackbarEvent

  constructor(private snackbarService: SnackbarService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.snackbarService.getEvent().subscribe((newEvent: SnackbarEvent) => {
      if (this.snackbarEvent == null || (!this.snackbarEvent.prioritised && newEvent.prioritised)) {
        this.snackbarEvent = newEvent;
        this.cdr.detectChanges();
        this.getClass();
        this.getIcon();
        this.setVisible(this.snackbarEvent.timeout, this.snackbarEvent.permanent);
      }
    })
    this.snackbarService.getHideEvent().subscribe((hide: boolean) => {
      this.visible = !hide
      this.cdr.detectChanges();
    })
  }

  setVisible(timeout: number, permanent: boolean) {
    this.visible = true
    this.cdr.detectChanges();
    if (!permanent) {
      setTimeout(() => {
        this.visible = false
        this.snackbarEvent = null
        this.cdr.detectChanges();
      }, timeout + 500)
    }
  }

  close() {
    this.visible = false
    this.snackbarEvent = null
  }

  getClass(): string {
    switch (this.snackbarEvent.eventType) {
      case SnackbarEventType.ERROR:
        return this.htmlClass = 'red'
      case SnackbarEventType.WARNING:
        return this.htmlClass = 'yellow'
      case SnackbarEventType.SUCCESS:
        return this.htmlClass = 'green'
      case SnackbarEventType.INFO:
        return this.htmlClass = 'teal'
    }
  }

  getIcon(): string {
    switch (this.snackbarEvent.eventType) {
      case SnackbarEventType.ERROR:
        return this.actionIcon = 'cancel'
      case SnackbarEventType.WARNING:
        return this.actionIcon = 'error'
      case SnackbarEventType.SUCCESS:
        return this.actionIcon = 'check_circle'
      case SnackbarEventType.INFO:
        return this.actionIcon = 'info'
    }
  }
}


