import { Type } from 'class-transformer';
import { RatecardIdentity } from '@app/features/scope-overview/model/ratecard-identity.model';
import { Department } from '@app/features/scoping/models/department.model';

export class RatecardVersion {
  id!: number
  name!: string
  version!: number
  currencyCode!: string
  locationCards?: any[]
  @Type(() => RatecardIdentity) rateCardIdentity!: RatecardIdentity;
  @Type(() => Department) departments!: Department[];

  getTotalRolesCount() {
    return this.departments.flatMap(d => d.roles).length;
  }

  isCostPlus(): boolean {
    return this.rateCardIdentity.costPlus;
  }
}
