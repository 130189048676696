import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UiDemoComponent } from './ui-demo/ui-demo.component';
import { BreadcrumbRoutingModule } from '@app/ui-demo/breadcrumbs-demo/breadcrumbs-routing.module';
import { DummyComponentLevel1Component } from '@app/ui-demo/dummy-component-level1/dummy-component-level1.component';
import { DummyComponentLevel2Component } from '@app/ui-demo/dummy-component-level2/dummy-component-level2.component';
import { DummyComponentLevel3Component } from '@app/ui-demo/dummy-component-level3/dummy-component-level3.component';

const routes: Routes = [
  {
    path: '',
    component: UiDemoComponent,
    children: [
      {
        path: 'breadcrumbs',
        loadComponent: () =>
          import('../breadcrumbs-demo/breadcrumbs-demo.component').then((m) => m.BreadcrumbsDemoComponent),
        data: {
          breadcrumb: 'Breadcrumbs Component',
        },
        children: [
          {
            path: 'level-1',
            component: DummyComponentLevel1Component,
            data: {
              breadcrumb: 'Level 1',
            },
          },
          {
            path: 'level-2',
            component: DummyComponentLevel2Component,
            data: {
              breadcrumb: 'Level 2',
            },
            children: [
              {
                path: 'level-3',
                component: DummyComponentLevel3Component,
                data: {
                  breadcrumb: 'Level 3',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'inputs',
        loadComponent: () =>
          import('../input-fields-demo/input-fields-demo.component').then((mod) => mod.InputFieldsDemoComponent),
        data: {
          breadcrumb: 'Inputs',
        },
      },
      {
        path: 'cards',
        loadComponent: () => import('../demo-cards/demo-cards.component').then((mod) => mod.DemoCardsComponent),
        data: {
          breadcrumb: 'Cards',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), BreadcrumbRoutingModule],
  exports: [RouterModule],
})
export class UiDemoRoutingModule {}
