import { Type } from 'class-transformer';
import { CompanySummary } from '@core/model/company-summary.model';
import { SecondParty } from '@core/model/second-party.model';
import { OutputField } from '@app/features/scope-overview/model/output-field.model';
import { User } from '@core/model/user.model';

export class OutputTemplate {
  id!: number
  templateType!: string
  name!: string
  isDefault!: boolean
  thumbnailUri!: string
  documentTemplate!: string
  templateLevelType!: string
  exportName!: string
  shared!: boolean
  inherited!: boolean
  pageBreakable!: boolean
  enableTemplateBorders!: boolean
  locked!: boolean
  userPrivilegeRestrictions!: string[]
  userAdditionalPrivileges!: string[]
  privilegeCategories!: string[]
  templateSowType!: boolean
  language!: string
  templateScopeType!: boolean
  parentTemplate!: number
  @Type(() => Date) updatedTs!: Date;
  @Type(() => User) updatedBy!: User;
  outputFields!: Array<OutputField>
  @Type(() => CompanySummary) company!: CompanySummary;
  @Type(() => SecondParty) secondParty!: SecondParty;
  @Type(() => Date) deletedTs!: Date;
  @Type(() => OutputBorders) outputTemplateBorders!: OutputBorders;
}

export class OutputBorders {
  borderStyle!: string
  borderWidth!: number
  fromTop!: number
  fromBottom!: number
  fromLeft!: number
  fromRight!: number
  borderType!: string
}
