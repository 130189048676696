import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScopeOverviewComponent } from '@app/features/scope-overview/components/scope-overview/scope-overview.component';

const routes: Routes = [
  {
    path: '',
    component: ScopeOverviewComponent,
    children: [
    ]
  }
  // add child routes here .... most likely this will be "Activity , Details, Team etc"
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ScopeOverviewRoutingModule {}
