<!-- Dynamic component generation based on action -->
<div *ngIf="activityActionConfig[action]">
  <div class='flex'>
    <div class='flex-1'>
      <p class='activity-user-name'>{{ comment.initiatedBy.fullName }}</p>
    </div>
    <div class='activity-date'>
      <p>{{ comment.createdTs | date }}</p>
    </div>
  </div>
  <ng-container *ngIf="preparedAttributes">
    <from-to-component
      [title]="title"
      [attributes]="preparedAttributes">
      <a
        activity-content
        class="activity-comment-link"
        (click)="navigateToActionSource()">
        <span class="activity-source-type">{{ comment.attributes?.traded_from?.name | titlecase }}</span>
      </a>

      <a class="activity-comment-link" (click)="navigateToActionSource()">
        <span class="activity-source-type">{{ comment.attributes?.traded_to[0]?.name | titlecase }}</span>
      </a>
    </from-to-component>
  </ng-container>
</div>
