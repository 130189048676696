import { User } from '../model/user.model';
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/internal/Observable';
import { AuthRequest } from '@app/core/model/definitions/auth-request.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@envs/environment';
import { map } from 'rxjs'
import { PasswordParams } from '@app/core/model/definitions/password-params.interface';
import { AuthResponse } from '@app/core/model/definitions/auth-response.interface';
import { plainToInstance } from 'class-transformer';
import { LoginService } from '@app/features/user-account/services/login.service'
import { AuthActions } from '@core/store'
import { AuthSelectors } from '@core/store/selectors/auth.selector'
import { Store } from '@ngrx/store'
import { untilDestroyed } from '@shared/utils/utils'

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl;
  loggedInUser: User;
  private readonly destroy$

  constructor(private http: HttpClient, private loginService: LoginService, private store: Store) {
    this.destroy$ = untilDestroyed()
    this.store.select(AuthSelectors.selectUser).pipe(this.destroy$()).subscribe((user: User) => this.loggedInUser = user);
  }

  login(auth: AuthRequest): Observable<User> {
    return this.http.post<AuthResponse>(`${this.BASE_API_URL}login`, auth).pipe(map((api) => plainToInstance(User, api.user)));
  }

  logout(): Observable<void> {
    return this.http.get<void>(`${this.BASE_API_URL}logout`);
  }

  loadAccount() {
    return new Promise<void>((resolve) => {
      this.store.dispatch(AuthActions.myAccount());
      this.store.select(AuthSelectors.selectLoaded)
        .pipe(
          this.destroy$()
        ).subscribe((loaded) => {
          if (loaded) {
            resolve()
          }
        })
    });
  }

  getAccount(): Observable<User> {
    return this.http.get<User>(`${this.BASE_API_URL}user/account`).pipe(map((api) => plainToInstance(User, api)));
  }

  forgotPassword(username: string, recaptcha: string): Observable<any> {
    const params = new HttpParams().set('email', username).set('captchaToken', recaptcha)
    return this.http.post(`${this.BASE_API_URL}user/reset-password/request`, params)
  }

  resetPassword(passwordParams: PasswordParams): Observable<any> {
    const params = new HttpParams().set('password', passwordParams.password).set('key', passwordParams.token);
    return this.http.post(`${this.BASE_API_URL}user/reset-password`, {}, { params });
  }

  isAuthenticated(): boolean {
    return !!this.loggedInUser;
  }
}
