import { Component } from "@angular/core";
import { SafePipe } from "@app/shared/pipe/safe.pipe";
import { HeaderStyleService } from "@app/shared/services/header-style.service";
import { EnvService } from "@envs/env-service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss'],
  providers: [ SafePipe ],

})
export class CreateFolderComponent {
  constructor(private headerStyleService: HeaderStyleService, private envService: EnvService, private router: Router) {
    window.addEventListener('message', event => {
      if (event.data.action === 'goToFolder') {
        this.router.navigateByUrl('/scopes/folder-overview/' + event.data.id)
      }
      if (event.data === 'goToScopes') {
        this.router.navigateByUrl('/scopes')
      }
    })
  }

  public get baseJsUrl(): string {
    return `${this.envService.baseJsUrl}/create-folder`;
  }
}
