import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import { LoaderModalService } from '@shared/components/loader-modal/service/loader-modal.service';
import { Observable } from 'rxjs';
import { AuthSelectors } from '@core/store/selectors/auth.selector';
import { untilDestroyed } from '@shared/utils/utils';
import { IdpDiscovery } from '@app/core/model/definitions/idp-discovery.interface';
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector';
import { BootstrapConfig } from '@core/model/bootstrap-config.model';
import { conditionalRequiredValidator } from '@core/validators/captchaRequired.validator';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Input() accountVerification: boolean = false

  @Input() key: string

  private readonly destroy$;

  loginForm: UntypedFormGroup;

  loginLoading$: Observable<boolean>;

  isIdpAvailable$: Observable<IdpDiscovery | null>;

  companyBootstrap$: Observable<BootstrapConfig | null>;

  isFormTouched: boolean;

  hideUsernamePasswordFields: boolean = false;

  captchaEnabled: boolean = false;

  isAccountVerified: boolean;

  responseToken: string;

  constructor(
    private store: Store<fromStore.CoreState>,
    private loader: LoaderModalService,
    private loginService: LoginService
  ) {
    this.isFormTouched = false;
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.pattern(/^.+@.+\..+/)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]),
      recaptcha: new FormControl(null, [conditionalRequiredValidator(this.store)]),
    });
    this.destroy$ = untilDestroyed();
    this.loginLoading$ = this.store.pipe(select(AuthSelectors.selectAuthenticationLoading));
    this.isIdpAvailable$ = this.store.pipe(select(BootstrapSelectors.selectIdpDiscovery));
    this.companyBootstrap$ = this.store.pipe(select(BootstrapSelectors.selectBootstrapConfig));
    this.responseToken = '';
  }

  ngOnInit(): void {
    this.subscribeToAuthErrors();
    this.isLoginLoading();
    this.companyBootstrap$.subscribe(b => {
      this.captchaEnabled = b.cdnConfig.captchaEnabled && (!b.companyBootstrap || b.companyBootstrap.captchaEnabled)
      this.hideUsernamePasswordFields = b.companyBootstrap?.useSsoLoginOnly
    })
    this.verifyEmail(this.key)
  }

  get username() {
    return this.loginForm.get('username');
  }
  get password() {
    return this.loginForm.get('password');
  }

  verifyEmail(key: string) {
    if (this.accountVerification && key !=  null) {
      this.loginService.verifyEmail(key).subscribe({
        next: () => {
          this.isAccountVerified = true
        },
        error: (error) => {
          console.debug(error);
        }
      })
    }
  }

  isLoginLoading(): void {
    this.loginLoading$.pipe(this.destroy$()).subscribe((loading) => {
      loading ? this.loader.show() : this.loader.hide();
    });
  }

  resolved(captchaResponse: string) {
    this.responseToken = captchaResponse;
  }

  subscribeToAuthErrors(): void {
    this.loginService.setFormErrors(this.loginForm).pipe(this.destroy$()).subscribe((formWithErrors: UntypedFormGroup) => {
      this.resetCaptcha()
      this.loginForm = formWithErrors
    })
  }

  onInputChange() {
    this.isFormTouched = true;
  }

  login() {
    let email = this.loginForm.get('username')?.value;
    let password = this.loginForm.get('password')?.value;
    const authenticatedUser = { username: email, password, captchaToken: this.responseToken };
    this.store.dispatch(fromStore.AuthActions.login({ authenticatedUser }));
  }

  resetCaptcha(): void {
    this.loginForm.get('recaptcha').reset();
  }

  idpDiscovery(): void {
    if (this.username?.value.length)
      this.store.dispatch(fromStore.BootstrapActions.idpDiscovery({ username: this.username?.value }));
  }

  onLoginSaml() {
    this.store.dispatch(fromStore.BootstrapActions.idpSamlLogin());
  }
}
