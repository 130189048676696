import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BootstrapConfig } from '@core/model/bootstrap-config.model';
import { IdpDiscovery } from '@app/core/model/definitions/idp-discovery.interface';

@Component({
  selector: 'sso-login-form',
  templateUrl: './sso-login-form.component.html',
  styleUrls: ['./sso-login-form.component.scss'],
})
export class SsoLoginFormComponent {
  @Input() isUsernameIdpAvailable!: IdpDiscovery | null;

  @Input() isFormInvalid!: boolean;

  @Input() isSubdomainIdpAvailable!: BootstrapConfig | null;

  @Output() onLoginSaml = new EventEmitter<void>();

  onLoginSamlProvider() {
    this.onLoginSaml.emit();
  }
}
