import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromScopeOverviewReducer from './scope-overview.reducer';
import { ScopeOverviewState } from '../../model/scope-overview.state.model';

export const featureKey = 'scope-overview';

export interface State {
  scopeOverview: ScopeOverviewState;
}

export const reducers: ActionReducerMap<State> = {
  scopeOverview: fromScopeOverviewReducer.reducer,
};

export const getScopeOverviewState = createFeatureSelector<State>(featureKey);
