import { Component, HostListener, Input } from "@angular/core";
import { SafePipe } from "@app/shared/pipe/safe.pipe";
import { HeaderStyleService, HIDE_HEADER } from '@app/shared/services/header-style.service';
import { EnvService } from "@envs/env-service";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import _ from "lodash";
import { AuthService } from '@core/service/auth.service'

@Component({
  selector: 'app-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.scss'],
  providers: [ SafePipe ],

})
export class AccountSetupComponent {
  @Input() id!: number;
  @Input() tab!: string;
  baseJsUrl: string

  constructor(private headerStyleService: HeaderStyleService, private envService: EnvService, private router: Router, private route: ActivatedRoute, private titleService: Title, private authService: AuthService) {
    this.headerStyleService.setHeaderClass(HIDE_HEADER)
  }

  ngOnInit() {
    this.baseJsUrl = `${this.envService.baseJsUrl}/account-setup/${this.id}?tab=${this.tab}&childCompany=${this.authService.loggedInUser?.company.id}`
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data.action === 'adminNavigate') {
      this.headerStyleService.setHeaderClass(null)
      this.titleService.setTitle(_.startCase(_.camelCase(event.data.location)) + ' | Admin | Scope')
      this.router.navigate(
        [`/manage`],
        {
          queryParams: { 'tab': event.data.location }
        }
      )
    } else if (event.data.action === 'accountSetupNavigate') {
      this.titleService.setTitle(_.startCase(_.camelCase(event.data.location)) + ' | Company Setup | Scope')
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { 'tab': event.data.location }
        }
      )
    }
  }
}
