import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { SharedModule } from '@app/shared/shared.module'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ScopeUiDatepickerComponent } from '@app/shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component'
import {
  Deliverable,
  SelectedDeliverable,
} from '@app/features/scoping/models/deliverable.model'
import { ScopeUiAutocompleteComponent } from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { ScopeVersion } from '@app/core/model/scope-version'
import { Store } from '@ngrx/store'
import { ScopeOverviewActions } from '../../store/actions/scope-overview.action'
import { Observable } from 'rxjs'
import { displayCurrencyValueNearestThousand, formatMonetaryValue, getCurrencySymbol } from '@shared/utils/utils'
import Editor from 'ckeditor5-custom-build'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { ScopeTraffickingService } from '@app/features/scope-overview/service/scope-trafficking.service'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { NgxMaskDirective } from 'ngx-mask'
import { DeliverableType } from '@app/features/scope-overview/model/deliverable-type'
import { Money } from '@app/features/scope-overview/model/money.model'

export interface TrackTradeModalConfig {
  title: string
  deliverable: Deliverable
  deliverables$: Observable<Deliverable[]>
  currencyCode: string
  currentScope: ScopeVersion
  onSearch: (searchText: string) => void
}

export interface DeliverableTradePayload {
  name: string
  budget: { currency: string; amount: number }
  description: string
  source: any
  sections: any[]
  feeItems: any[]
  thirdPartyCosts: any[]
  sourceType: any
  startDate: string
  endDate: string
  userPrivilegeRestrictions: any[]
  userAdditionalPrivileges: any[]
  trafficSystemEntityMetadata: any
}

@Component({
  selector: 'track-trade-modal',
  standalone: true,
  imports: [CommonModule, ScopeUiDatepickerComponent, ScopeUiAutocompleteComponent, SharedModule, MatDatepickerModule, CKEditorModule, ScopeUiInputComponent, NgxSliderModule, NgxMaskDirective],
  templateUrl: './track-trade-modal.component.html',
  styleUrls: ['./track-trade-modal.component.scss'],
})
export class TrackTradeModalComponent implements OnInit {
  @ViewChild(ScopeUiAutocompleteComponent) scopeUiAutocompleteComponent: ScopeUiAutocompleteComponent

  trackTradeFormGroup: FormGroup

  deliverableType: DeliverableType

  complexityPrices: number[]

  hasSlider: boolean = false

  symbol: string

  selectedDeliverableTypes: SelectedDeliverable[]

  formInvalid: boolean = false

  maxComplexityPrice: number

  budgetValue: number

  selectedDeliverableIndex: number | null = null

  startDate = this.data.currentScope.startDate ? this.data.currentScope.startDate : new Date()
  endDate = this.data.currentScope.endDate ? this.data.currentScope.endDate : new Date(new Date().setMonth(new Date().getMonth()+3))

  selection = {
    startDate: this.startDate,
    endDate: this.endDate
  }

  trafficSystemEntityMetadata: any = {}

  isEditing: boolean = false
  editDescription: boolean = false
  trafficFields: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TrackTradeModalConfig,
    public dialogRef: MatDialogRef<TrackTradeModalComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private store: Store,
    protected traffickingService: ScopeTraffickingService
  ) {
    this.selectedDeliverableTypes = []
    this.maxComplexityPrice = 100
    this.complexityPrices = []
  }

  ngOnInit(): void {
    this.trackTradeFormGroup = this.fb.group({
      name: new FormControl('', Validators.required),
      deliverableType: new FormControl('', Validators.required),
      budget: new FormControl(''),
      description: new FormControl('')
    })

    this.symbol = getCurrencySymbol(this.data.currentScope.currencyUnit) || this.data.currentScope.currencyUnit
    this.trafficFields = this.traffickingService.getTrafficFields()
  }

  onSearch(event: { searchString: string }) {
    this.data.onSearch(event.searchString)
  }

  onSelectionChange($event) {
    if ($event.event === undefined) {
      this.budgetValue = 0
      this.hasSlider = false
      this.deliverableType = null
      this.trackTradeFormGroup.get('deliverableType').setErrors({required: true})
    } else {
      if (
        this.selection.startDate === null &&
        this.selection.endDate === null
      ) {
        this.selection.startDate = this.startDate
        this.selection.endDate = this.endDate
      }

      this.trackTradeFormGroup.get('description').setValue($event.event.description)

      this.deliverableType = $event.event
      this.setDeliverableType()
    }

    this.trackTradeFormGroup.get('budget').setValue(this.budgetValue.toFixed(2))
  }

  setDeliverableType() {
    this.trackTradeFormGroup.get('deliverableType').markAsTouched()
    this.trackTradeFormGroup.get('deliverableType').setErrors(null)

    this.applyDeliverableTypeTickOptions(this.deliverableType)

    if (this.complexityPrices.length) {
      this.hasSlider = true
      this.budgetValue = this.complexityPrices[2]
    } else {
      this.hasSlider = false
      this.budgetValue = 0
    }
  }

  onSliderChange() {
    this.trackTradeFormGroup.get('budget').setValue(this.budgetValue.toFixed(2))
  }

  onStartDateChange(event: Date) {
    this.selection.startDate = event
  }

  onEndDateChange(event: Date) {
    this.selection.endDate = event
  }

  onResetDatePickers() {
    this.selection.startDate = this.startDate
    this.selection.endDate = this.endDate
  }

  loadDeliverable(index: number) {
    const selectedDeliverable = this.selectedDeliverableTypes[index]
    this.trackTradeFormGroup.get('name').setValue(selectedDeliverable.name)
    this.trackTradeFormGroup.get('budget').setValue(selectedDeliverable.budget.amount)
    this.trackTradeFormGroup.get('description').setValue(selectedDeliverable.description)
    this.selection.startDate = selectedDeliverable.startDate
    this.selection.endDate = selectedDeliverable.endDate
    this.trafficSystemEntityMetadata = selectedDeliverable.trafficSystemEntityMetadata

    this.deliverableType = selectedDeliverable.deliverableType
    this.setDeliverableType()

    this.selectedDeliverableIndex = index
    this.isEditing = true
  }

  submitTradeForm() {
    const selectedDeliverable = {
      name: this.trackTradeFormGroup.value.name,
      deliverableType: this.deliverableType,
      budget: new Money(this.trackTradeFormGroup.value.budget, this.data.currencyCode),
      startDate: this.selection.startDate,
      endDate: this.selection.endDate,
      description: this.trackTradeFormGroup.value.description,
      trafficSystemEntityMetadata: this.trafficSystemEntityMetadata
    }

    if (this.trackTradeFormGroup.valid && this.deliverableType) {
      if (this.selectedDeliverableIndex !== null) {
        // Update the selected deliverable
        this.selectedDeliverableTypes[this.selectedDeliverableIndex] = selectedDeliverable
        this.selectedDeliverableIndex = null
      } else {
        // Add a new deliverable
        this.selectedDeliverableTypes.push(selectedDeliverable)
      }

      this.resetForm()
    } else {
      this.formInvalid = true
    }
  }

  cancelUpdate() {
    this.selectedDeliverableIndex = null
    this.resetForm()
  }

  removeSelectedDeliverable(index: number) {
    this.selectedDeliverableTypes.splice(index, 1)
    if (!this.selectedDeliverableTypes.length) {
      this.resetForm()
    }
  }

  initiateTrade() {

    const deliverablesToTrade: DeliverableTradePayload[] = this.selectedDeliverableTypes.map(
      (selectedDeliverable: SelectedDeliverable) => {
        return {
          name: selectedDeliverable.name,
          budget: selectedDeliverable.budget,
          description: selectedDeliverable.description,
          source: selectedDeliverable.deliverableType,
          sections: [],
          feeItems: [],
          thirdPartyCosts: [],
          sourceType: selectedDeliverable.deliverableType.sourceType,
          startDate: selectedDeliverable.startDate?.toISOString(),
          endDate: selectedDeliverable.endDate?.toISOString(),
          userPrivilegeRestrictions: [],
          userAdditionalPrivileges: [],
          trafficSystemEntityMetadata: selectedDeliverable.trafficSystemEntityMetadata,
        }
      }
    )

    this.store.dispatch(ScopeOverviewActions.initiateTrade({ scopeId: this.data.currentScope.identity.id, deliverableId: this.data.deliverable.id, deliverables: deliverablesToTrade }))

    this.dialogRef.close()
  }

  resetForm() {
    this.scopeUiAutocompleteComponent.resetAutoComplete()
    this.trackTradeFormGroup.reset()
    this.deliverableType = null
    this.hasSlider = false
    this.formInvalid = false
    this.isEditing = false
    this.trafficSystemEntityMetadata = {}
  }

  applyDeliverableTypeTickOptions(deliverableType: DeliverableType) {
    this.complexityPrices = []
    if (deliverableType.complexityPriceS.amount) {
      if (deliverableType.sourceType === 'SCOPE') {
        this.complexityPrices = [
          deliverableType.complexityPriceS.amount * 2,
          deliverableType.complexityPriceM.amount * 2,
          deliverableType.complexityPriceL.amount * 1.5,
          deliverableType.complexityPriceXL.amount * 1.2,
        ]
      } else {
        this.complexityPrices = [
          deliverableType.complexityPriceS.amount * 0.1,
          deliverableType.complexityPriceM.amount * 0.6,
          deliverableType.complexityPriceL.amount * 1.2,
          deliverableType.complexityPriceXL.amount * 2.5,
        ]
      }
    }
    this.maxComplexityPrice = parseFloat((this.complexityPrices[3] * 1.1).toFixed(2))
  }

  updateTrafficSystemMetadataField(uuid: any, event: any) {
    if(this.trafficSystemEntityMetadata == undefined) {
      this.trafficSystemEntityMetadata = {}
    }
    this.trafficSystemEntityMetadata[uuid] = {
      name: event, value: event
    }
  }

  selectTrafficField($event: { event: any; componentId?: any }, uuid: string) {
    if(this.trafficSystemEntityMetadata == undefined) {
      this.trafficSystemEntityMetadata = {}
    }
    this.trafficSystemEntityMetadata[uuid] = $event.event
  }

  translate = () => ''

  getSliderLegend = (value: number) => {
    var index = this.complexityPrices.findIndex((tick) => tick === value);
    var complex;
    switch (index) {
      case 0:
        complex = 'S';
        break;
      case 1:
        complex = 'M';
        break;
      case 2:
        complex = 'L';
        break;
      case 3:
        complex = 'XL';
        break;
    }
    return `<div><b>${complex}</b></div> (${this.symbol}${displayCurrencyValueNearestThousand(value)})`;
  }

  customValueToPosition(val, minVal, maxVal) {
    val = Math.sqrt(val);
    minVal = Math.sqrt(minVal);
    maxVal = Math.sqrt(maxVal);
    var range = maxVal - minVal;
    return (val - minVal) / range;
  }

  customPositionToValue(percent, minVal, maxVal) {
    minVal = Math.sqrt(minVal);
    maxVal = Math.sqrt(maxVal);
    var value = percent * (maxVal - minVal) + minVal;
    return Math.pow(value, 2);
  }

  protected readonly formatMonetaryValue = formatMonetaryValue
  protected readonly Editor = Editor
}
