<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>{{ discount ? 'Edit discount' : 'Add discount' }}</h1>
    </div>

    <button class="close-button" mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content aria-label="Generic modal" class="modal-container md-dialog-container">
  <div class="w-full">
    <form [formGroup]="modalForm">
      <div class="flex flex-row">
        <scope-ui-input
          class="flex-1"
          [inputLabel]="'Discount Name'"
          [initialValue]="newDiscount.description"
          [required]="true"
          [maximumLength]='100'
          (onInputChange)="newDiscount.description = $event" />
      </div>
      <div class="flex flex-row gap-4 mt-8">
        <scope-ui-input
          class="flex-1"
          [inputLabel]="getAmountLabel()"
          [initialValue]="newDiscount.value"
          [required]="true"
          [parseNumber]="true"
          [isCurrency]="amountTypeControl.value === 'AMOUNT'"
          [isPercentage]="amountTypeControl.value === 'PERCENTAGE'"
          [currency]="currencyCode"
          (onInputChange)="newDiscount.value = $event" />
        <mat-button-toggle-group name="discountType" [formControl]="amountTypeControl" aria-label="Discount type">
          <mat-button-toggle value="PERCENTAGE">Percentage</mat-button-toggle>
          <mat-button-toggle value="AMOUNT">Quantity</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </form>
  </div>
  <div class="mt-8 flex justify-end">
    <button mat-button mat-dialog-close (click)="close()">
      <span class='tracking-normal'>Cancel</span>
    </button>
    <button mat-flat-button class="ml-8" (click)="confirm()" [disabled]="!isFormValid()">
      <span class='tracking-normal'>{{discount ? 'Update discount' : 'Add discount'}}</span>
    </button>
  </div>
</mat-dialog-content>


