import { Injectable } from '@angular/core';
import countrycode from 'assets/data/countrycode.json'

@Injectable({ providedIn: "root" })
export class CountryCodeService {
  countries: any[] = countrycode;
  countriesByISO2: any = {};
  regions: any = {
    ASIA : "Asia",
    EUROPE:"Europe",
    SOUTH_AMERICA:"South America",
    NORTH_AMERICA:"North America",
    OCEANIA:"Oceania",
    AFRICA:"Africa",
    ANTARCTICA:"Antarctica"
  };

  getAllCountries() {
    return this.countries;
  };


  getAllRegions() {
    return this.regions;
  };

  getCountryByISO2(iso2: string) {
    return this.countriesByISO2[iso2];
  };

  constructor() {
    let _this = this;
    this.countries.forEach(function (country) {
      _this.countriesByISO2[country['ISO2']] = country
    })
  }
}
