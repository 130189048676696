import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { SharedModule } from '@shared/shared.module'

@Component({
  selector: 'scope-ui-financial-card',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule, SharedModule],
  templateUrl: './scope-ui-financial-card.component.html',
  styleUrls: ['./scope-ui-financial-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScopeUiFinancialCardComponent {
  @Input() heading!: string;
  @Input() sub_heading?: string;
  @Input() value!: string;
  @Input() sub_value?: string;
  @Input() sub_value_label?: string;
  @Input() expandable: boolean;
  @Input() cardClass?: string;
  @Input() headingClass?: string;
  @Input() showMenu: boolean;
  @Input() showAddButton?: boolean;
  @Input() addButtonLabel?: string;
  @Input() expanded: boolean

  @Output() expandedChange = new EventEmitter<boolean>();
  @Output() add = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  onExpand() {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }

  onAdd() {
    this.add.emit();
  }

  onEdit() {
    this.edit.emit();
  }

  onDelete() {
    this.delete.emit();
  }
}
