import { Component, EventEmitter, Input, Output } from '@angular/core'
import { NgIf } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import {
  ScopeUiDropdownComponent
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import {
  ScopeUiDatepickerComponent
} from '@shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component'

@Component({
  selector: 'scope-ui-duration',
  standalone: true,
  templateUrl: './scope-ui-duration.component.html',
  imports: [
    NgIf,
    SharedModule,
    ScopeUiDropdownComponent,
    ScopeUiInputComponent,
    ScopeUiDatepickerComponent,
  ],
  styleUrls: ['./scope-ui-duration.component.scss']
})
export class ScopeUiDuration {
  @Input() startDate?: Date
  @Input() endDate?: Date

  periods = [
    { name: 'Custom', value: 'custom' },
    { name: '3 Months', value: '3m' },
    { name: '6 Months', value: '6m' },
    { name: '12 Months', value: '12m' },
    { name: 'Count Of Days', value: 'fixed_count_days'}
  ]
  @Input() selectedPeriod: { name: string, value: string } = this.periods[0];
  @Input() countDays: number;
  @Input() hidePeriod = false
  @Input() opens?: string
  @Input() disabled?: boolean

  @Output() startDateChange= new EventEmitter<Date>()
  @Output() endDateChange= new EventEmitter<Date>()
  @Output() selectedPeriodChange= new EventEmitter<{ name: string, value: string }>()
  @Output() countDaysChange= new EventEmitter<number>()

  onSelectDuration($event: any) {
    this.selectedPeriod = $event;
    this.selectedPeriodChange.emit(this.selectedPeriod)
    this.adjustEndDate();
  }

  onCountOfDaysChange($event: any) {
    this.countDays = $event
    this.countDaysChange.emit(this.countDays)
    if (this.countDays == null || this.countDays < 0 || this.countDays > 730) {
      this.endDate = null;
    } else {
      this.endDate = new Date(this.startDate.getTime());
      this.endDate.setDate(this.endDate.getDate() + this.countDays);
    }
    this.endDateChange.emit(this.endDate);
  }

  adjustEndDate(maintainCountDays: boolean = false) {
    switch(this.selectedPeriod.value) {
      case 'custom':
        this.setMinEndDate();
        break;
      case '3m':
      case '6m':
      case '12m':
        var monthsToAdd = this.selectedPeriod.value == '3m' ? 3 : (
          this.selectedPeriod.value == '6m') ? 6 : 12;
        this.endDate = new Date(this.startDate.getTime());
        this.endDate.setMonth(this.startDate.getMonth() + monthsToAdd);
        this.endDateChange.emit(this.endDate);
        break;
      case 'fixed_count_days':
        if (maintainCountDays) {
          this.endDate = new Date(this.startDate.getTime());
          this.endDate.setDate(this.endDate.getDate() + this.countDays);
          this.endDateChange.emit(this.endDate);
        } else {
          this.setMinEndDate();
          this.countDays = this.getDaysBetweenStartEnd();
          this.countDaysChange.emit(this.countDays);
        }
        break;
    }
  }

  setMinEndDate() {
    if (this.endDate == null || this.endDate < this.startDate) {
      this.endDate = this.startDate;
      this.endDateChange.emit(this.endDate);
    }
  }

  getDaysBetweenStartEnd() {
    return this.getDaysBetween(this.endDate, this.startDate);
  }

  getDaysBetween(date_1: any, date_2: any) {
    return Math.round((date_1 - date_2) / (1000*60*60*24))
  }

  onSelectStartDate($event: Date) {
    this.startDate = $event
    this.startDateChange.emit(this.startDate)
    this.adjustEndDate(true)
  }

  onSelectEndDate($event: Date) {
    this.endDate = $event
    this.endDateChange.emit(this.endDate)
    this.selectedPeriod = this.periods[0]
  }
}
