import { Injectable } from '@angular/core';
import {
  DeliverablePreferences, DeliverablesTableKey, DeliverableTableConfig,
  ScopePreferences,
  ScopesTableConfig,
  ScopesTableKey,
} from '../models/scope-columns.model';
import { MappedEntity } from "@app/features/scoping/models/mapped-entity.model";
import { FolderVersion } from "@core/model/folder-version.model";
import { ScopeVersion } from "@core/model/scope-version";
import { Preference } from "@core/model/user-preferences.interface";
import { AuthService } from '@core/service/auth.service'

@Injectable({
  providedIn: 'root',
})
export class DataMappingService {
  constructor(private authService: AuthService) {}

  getValueByPath(obj: any, path: string): any {
    let value = path.split('.').reduce((acc, part) => acc && acc[part], obj);
    return value != null ? value : '-';
  }

  private mapScope(data: ScopeVersion, columnMapping: ScopePreferences): MappedEntity<ScopeVersion> {
    const scopeNumber = data?.identity?.localId;
    const scopeString = scopeNumber ? `S-${scopeNumber}` : undefined;

    return Object.entries(columnMapping).reduce((newObj, [key, config]) => {
      if (config.selected) {
        if (config.field === 'identity.localId' && scopeString) {
          newObj[key] = scopeString;
        } else {
          if ((!config.requiredPrivilege || data.hasPrivilege(config.requiredPrivilege, this.authService.loggedInUser!)) && (!config.isVisible || config.isVisible(data))) {
            newObj[key] = config.value ? config.value(data) : this.getValueByPath(data, config.field || '');
          } else {
            newObj[key] = config.default;
          }
        }
      }
      return newObj;
    }, {
      entity: data
    } as MappedEntity<ScopeVersion>);
  }

  private mapFolder(data: FolderVersion, columnMapping: ScopePreferences): MappedEntity<FolderVersion> {
    const folderNumber = data?.identity?.localId;
    const folderString = folderNumber ? `F-${folderNumber}` : undefined;

    return Object.entries(columnMapping).reduce((newObj, [key, config]) => {
      if (config.selected) {
        if (config.field === 'identity.localId' && folderString) {
          newObj[key] = folderString;
        } else if (key === 'BUDGET') {
          newObj[key] = this.getValueByPath(data, 'budgets');
        } else if (key === 'VALUE'){
          newObj[key] = this.getValueByPath(data, 'totalValues');
        } else {
          newObj[key] = config.value ? config.value(data) : this.getValueByPath(data, config.field || '');
        }
      }
      return newObj;
    }, {
      entity: data
    } as MappedEntity<FolderVersion>);
  }

  private mapObject<T>(data: T, columnMapping: Preference[], childrenToMap?: string): MappedEntity<T> {
    return Object.values(columnMapping).reduce((newObj, config) => {
      if (config.selected) {
        newObj[config.key] = config.value ? config.value(data) : this.getValueByPath(data, config.field || '');
      }
      return newObj;
    }, {
      entity: data,
      id: data["id"],
      children: childrenToMap ? this.transformArray(data[childrenToMap], columnMapping) : null
    } as MappedEntity<T>);
  }

  transformScopeArray(data: ScopeVersion[], columnMapping: ScopePreferences): MappedEntity<ScopeVersion>[] {
    let transformedArray: MappedEntity<ScopeVersion>[] = [];
    if (data) {
      transformedArray = data.map((obj) => this.mapScope(obj, columnMapping));
    }

    return transformedArray;
  }

  transformFolderArray(data: FolderVersion[], columnMapping: ScopePreferences): MappedEntity<FolderVersion>[] {
    let transformedArray: MappedEntity<FolderVersion>[] = [];
    if (data) {
      transformedArray = data.map((obj) => this.mapFolder(obj, columnMapping));
    }

    return transformedArray;
  }

  transformArray<T>(data: T[], columnMapping: Preference[], childrenToMap?: string): MappedEntity<T>[] {
    let transformedArray: MappedEntity<T>[] = [];
    if (data) {
      transformedArray = data.map((obj) => this.mapObject<T>(obj, columnMapping, childrenToMap));
    }

    return transformedArray;
  }

  mergePreferencesWithTableConfig(baseConfig: ScopePreferences, userPreferences: ScopePreferences): ScopesTableConfig {
    let mergedConfig: ScopePreferences = {...baseConfig}

    Object.keys(baseConfig).forEach((key) => {
      const scopedKey = key as ScopesTableKey
      if (userPreferences?.[scopedKey]) {
        mergedConfig[scopedKey] = { ...baseConfig[scopedKey], selected: userPreferences[scopedKey].selected }
      }
    })

    return mergedConfig as ScopesTableConfig
  }

  mergeDeliverablePreferencesWithTableConfig(baseConfig: DeliverablePreferences, userPreferences: DeliverablePreferences): DeliverableTableConfig {
    let mergedConfig: DeliverablePreferences = {...baseConfig}

    Object.keys(baseConfig).forEach((key) => {
      const scopedKey = key as DeliverablesTableKey
      if (userPreferences?.[scopedKey]) {
        mergedConfig[scopedKey] = { ...baseConfig[scopedKey], selected: userPreferences[scopedKey].selected }
      }
    })

    return mergedConfig as DeliverableTableConfig
  }
}
