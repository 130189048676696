import { Component, Inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ScopeVersion } from '@core/model/scope-version';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogEventsService } from '@app/features/scope-overview/service/dialog-events.service';
import { OutputBorders, OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { PipesModule } from '@shared/pipe/index.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { MatInputModule } from '@angular/material/input';
import {
  ScopeUiDropdownComponent
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';

export class SetBordersModalConfig {
  currentScope: ScopeVersion
  outputTemplate: OutputTemplate
}

@Component({
  selector: 'app-set-borders-modal',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatInputModule, NgOptimizedImage, PipesModule, MatFormFieldModule, MatIconModule, MatOptionModule, MatSelectModule, ScopeUiInputComponent, ScopeUiDropdownComponent],
  templateUrl: './set-borders-modal.component.html',
  styleUrls: ['./set-borders-modal.component.scss']
})
export class SetBordersModalComponent {
  modalForm: UntypedFormGroup
  currentTemplate: OutputTemplate
  outputBorders: OutputBorders = new OutputBorders()
  selectedStyle: any
  selectedWidth: any
  borderStyles = [
    { value: 'single', name: 'Single border' },
    { value: 'double', name: 'Double border' },
    { value: 'dashed', name: 'Dashed border' }
  ]
  borderWidths = [
    { value: 0.25, name: '0.25' },
    { value: 0.5, name: '0.5' },
    { value: 0.75, name: '0.75' },
    { value: 1, name: '1' },
    { value: 1.5, name: '1.5' },
    { value: 2.25, name: '2.25' },
    { value: 3, name: '3' },
    { value: 4.5, name: '4.5' },
    { value: 6, name: '6' },
  ]

  constructor(@Inject(MAT_DIALOG_DATA) public data: SetBordersModalConfig,
              private dialogEventsService: DialogEventsService) {
    this.modalForm = new UntypedFormGroup({ comment: new FormControl('') })
  }

  ngOnInit() {
    this.currentTemplate = this.data.outputTemplate
    if (this.data.outputTemplate.outputTemplateBorders == null) {
      this.outputBorders = new OutputBorders()
    } else {
      this.outputBorders = this.data.outputTemplate.outputTemplateBorders
      this.selectedStyle = this.borderStyles.find((s) => s.value === this.outputBorders.borderStyle)
      this.selectedWidth = this.borderWidths.find((w) => w.value === this.outputBorders.borderWidth)
    }
  }

  applyBorderSettings() {
    this.outputBorders.borderType = "PAGE_BORDER";
    this.currentTemplate.outputTemplateBorders = this.outputBorders;
    this.dialogEventsService.emitEvent({ key: 'updateTemplate' });
  }

  isFormValid() {
    return this.outputBorders.borderStyle && this.outputBorders.borderWidth && this.checkMargin()
  }

  checkMargin() {
    return !(this.outputBorders.fromTop > 31 || this.outputBorders.fromBottom > 31 ||
      this.outputBorders.fromLeft > 31 || this.outputBorders.fromRight > 31) &&
      !(this.outputBorders.fromTop < 0 || this.outputBorders.fromBottom < 0 ||
      this.outputBorders.fromLeft < 0 || this.outputBorders.fromRight < 0);
  }
}
