import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dummy-component-level2',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dummy-component-level2.component.html',
  styleUrls: ['./dummy-component-level2.component.scss']
})
export class DummyComponentLevel2Component {

}
