<div class="scope-attrs text-xs" *ngIf="scopeVersion">
  <div class="flex flex-row justify-between">
    <div>
      <span class="detail-field">
        <span class="title">{{'second_party' | lang }}:</span>
        <span class="data">{{ scopeVersion.identity.secondParty.name }}</span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.identity.rateCard">
        <span class="title">Rate Card:</span>
        <span class="data">{{ scopeVersion.identity.rateCard.name }}</span>
      </span>
      <span class="detail-field">
        <span class="title">Rate Card Version:</span>
        <span class="data"> {{ scopeVersion.identity.rateCard.version }}</span>
      </span>
      <span class="detail-field">
        <span class="title">{{'scope' | lang }} Type:</span>
        <span class="data">{{ scopeVersion.identity.scopeType ? scopeVersion.identity.scopeType.name : 'No Type' }}</span>
      </span>
      <span class="detail-field">
        <span class="title trs">Language:</span>
        <span class="data trs">{{ scopeVersion.identity.language }}</span>
      </span>
      <span class="detail-field">
        <span class="title">Version:</span>
        <span class="data">
          <scope-ui-dropdown
            [dropdownClass]="'small-dropdown'"
            [selectedValue]="selectedVersion"
            [dropdownOptions]="versionNumbers"
            [isStandardDropdown]="true"
            [displayFn]='versionDisplayFn'
            (onSelectionChange)='selectedVersion = $event; changeVersion()'>
          </scope-ui-dropdown>
        </span>
      </span>
    </div>

    <a class="view_more orange font-newJuneBold" (click)="expandedMode = !expandedMode">{{
      expandedMode ? 'View less' : 'View more'
    }}</a>
  </div>
  <div class="expandable_panel" *ngIf="expandedMode">
    <div class="flex flex-wrap">
      <span class="detail-field">
        <span class="title">Start date:</span>
        <span class="data">{{ scopeVersion.startDate | dateFormat: 'shortDate' }}</span>
      </span>
      <span class="detail-field">
        <span class="title">End date:</span>
        <span class="data">{{ scopeVersion.endDate | dateFormat: 'shortDate' }}</span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.identity.scopeTemplate">
        <span class="title">Output:</span>&nbsp;
        <span class="data">{{ scopeVersion.identity.scopeTemplate.name }}</span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.identity.brand">
        <span class="title">Brand:</span>
        <span class="data">{{ scopeVersion.identity.brand.name }}</span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.identity.benchmarkRateCard != null">
        <span class="title">Benchmark Rate Card:</span>&nbsp;
        <span class="data">{{ scopeVersion.identity.benchmarkRateCard.name }}</span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.identity.benchmarkRateCard != null">
        <span class="title">Benchmark Rate Card Version:</span>&nbsp;
        <span class="data">{{ scopeVersion.identity.benchmarkRateCard.version }}</span>
      </span>
      <span class="detail-field">
        <span class="title">Traffic Status:</span>
        <span class="data"><span *ngIf="!scopeVersion.traffickable || !scopeVersion.traffickableThroughWorkato">Not</span> Traffickable</span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.traffickable || scopeVersion.traffickableThroughWorkato">
        <span class="title">Last Trafficked:</span>
        <span class="data">{{
          scopeVersion.lastTraffickedDate | dateFormat: 'shortDate'
        }}</span>
      </span>
      <span class="detail-field">
        <span class="title">Created By:</span>
        <span class="data">{{ scopeVersion.createdBy.getFullname() }}</span>
      </span>
      <span class="detail-field">
        <span class="title">Date created:</span>
        <span class="data">{{ scopeVersion.createdTs | dateFormat: 'shortDate' }}</span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.identity.contact">
        <span class="title">Key Contact:</span>
        <span class="data">&nbsp;{{ scopeVersion.identity.contact.contactName }}</span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.identity.contact && scopeVersion.identity.contact.location">
        <span class="title">Location:</span>&nbsp;
        <span class="data">{{ scopeVersion.identity.contact.location.location }}</span>
      </span>
      <span
        *ngIf="
          scopeVersion.identity.secondParty.trafficImported &&
          scopeVersion.identity.rateCard.rateCardIdentity.trafficImported
        ">
        <span class="detail-field" *ngFor="let field of trafficMetadataLookupFields">
          <span class="title">{{ field.name }}:</span>
          <span class="data">{{ scopeVersion.trafficSystemEntityMetadata.get(field.uuid)?.name }}</span>
        </span>
      </span>
      <span class="detail-field" *ngFor="let field of scopeVersion.scopeVersionCustomFieldValueStructure?.fieldValues">
        <span class="title">{{ scopeVersion.companyScopeCustomFieldsDefinition.getName(field.id) }}:</span>
        <span>{{ field.value }}</span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.scopeOfWorkVersion && scopeVersion.useRetainedHours">
        <span class="title">Retained Hours Used:</span>&nbsp;
        <span class="data">{{ scopeRetainedHoursStats.totalHours }}</span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.scopeOfWorkVersion && scopeVersion.useRetainedHours">
        <span class="title">{{ 'sow' | lang }} Retained Hours remaining:</span>
        <span
          class="data"
          [ngClass]="{
            'highlight-error': sowRetainedHoursStats.remainingHours != null && sowRetainedHoursStats.remainingHours < 0
          }">
          {{ sowRetainedHoursStats.remainingHours }} hrs
        </span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.budget">
        <span class="title">Budget:</span>
        <span class="data">{{ scopeVersion.budget.amount | currency : scopeVersion.budget.currency }}</span>
      </span>
      <span class="detail-field" *ngIf="scopeVersion.budget">
        <span class="title">Allocated:</span>
        <span
          class="data"
          [ngClass]="{
            'highlight-error': (scopeVersion.getAllocatedBudget().amount || 0) > (scopeVersion.budget.amount || 0)
          }">
          {{ scopeVersion.getAllocatedBudget().amount | currency : scopeVersion.currencyUnit }}
        </span>
      </span>
    </div>
    <div *ngIf="scopeVersion.budget">
      <span class="detail-field">
        <span class="title">Remaining:</span>
        <span class="data" [ngClass]="{ 'highlight-error': (scopeVersion.getUnallocatedBudget().amount || 0) < 0 }">
          {{ scopeVersion.getUnallocatedBudget().amount | currency : scopeVersion.currencyUnit }}</span
        >
      </span>

      <span class="detail-field">
        <span class="title">Contracted:</span>
        <span class="data">{{  scopeVersion.getTotalSellingPrice()?.amount | currency : scopeVersion.currencyUnit }}</span>
      </span>

      <span class="detail-field">
        <span class="title">Balance:</span>
        <span class="data" [ngClass]="{ 'highlight-error': (scopeVersion.getBudgetBalance().amount || 0) < 0 }">
          {{ scopeVersion.getBudgetBalance().amount | currency : scopeVersion.currencyUnit }}
        </span>
      </span>

      <span class="detail-field" *ngIf="scopeVersion.thirdPartyCostBudget">
        <span class="title">Third Party Cost Budget:</span>
        <span class="data">{{ scopeVersion.thirdPartyCostBudget.amount | currency : scopeVersion.currencyUnit }}</span>
      </span>

      <span class="detail-field">
        <span class="title">Third Party Cost Balance:</span>
        <span class="data" [ngClass]="{ 'highlight-error': (thirdPartyCostBalance?.amount || 0) < 0 }">{{
          thirdPartyCostBalance?.amount | currency : scopeVersion.currencyUnit
        }}</span>
      </span>
    </div>
  </div>
</div>
