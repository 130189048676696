import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {SharedModule} from "@shared/shared.module";
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import {ModalConfig} from "@core/model/modal-config.model";
import {
  ScopeUiAutocompleteComponent
} from "@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component";
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import {
  ScopeUiDropdownComponent
} from "@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component";
import { Observable } from "rxjs";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { FeeItem } from '@app/features/scope-overview/model/fee-item.model'
import { ScopeSection } from '@app/features/scope-overview/model/scope-section'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { ScopeComponent } from '@app/features/scoping/models/component.model'
import { User } from '@core/model/user.model';
import {
  LinkToSelectorComponent
} from '@app/features/scope-overview/components/scope-tab/link-to-selector/link-to-selector.component';

@Component({
  selector: 'add-edit-fee-modal',
  templateUrl: './add-edit-fee-modal.component.html',
  styleUrls: ['./add-edit-fee-modal.component.scss'],
  imports: [
    SharedModule,
    ScopeUiAutocompleteComponent,
    NgxDaterangepickerMd,
    ScopeUiInputComponent,
    ScopeUiDropdownComponent,
    MatButtonToggleModule,
    LinkToSelectorComponent,
  ],
  standalone: true
})
export class AddEditFeeModalComponent {
  @Input() modalTitle: string
  @Input() existingFees$: Observable<FeeItem[]>
  @Input() feeItem: any
  @Input() currencyCode: string
  @Input() linkFee: boolean
  @Input() sections: ScopeSection[]
  @Input() deliverables: Deliverable[]
  @Input() components$: Observable<ScopeComponent[]>
  @Input() currentUser: User
  @Output() onSubmit: EventEmitter<any>
  @Output() onSearchComponents: EventEmitter<string>
  modalForm: UntypedFormGroup
  selection: FeeItem
  isNewFee: boolean = false
  typeControl = new FormControl('INCREASE')
  amountTypeControl = new FormControl('MONETARY')
  newFee: any
  level: { name: string, value: string }
  linkTo: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalConfig) {
    this.modalForm = new FormGroup({});
    this.onSubmit = new EventEmitter<any>()
    this.onSearchComponents = new EventEmitter<string>()
  }

  ngOnInit() {
    if (this.feeItem) {
      this.existingFees$.subscribe((existingFees) => {
        if (existingFees) {
          let selectedFee = existingFees.filter((f) => f.id == this.feeItem.id)[0]
          if (selectedFee) {
            this.isNewFee = false
            this.selectFee(selectedFee)
            this.newFee = {
              name: '',
              description: '',
              amount: 0,
              chargeType: 'INCREASE',
              amountType: 'MONETARY'
            }
          } else {
            this.isNewFee = true
            this.newFee = {
              ...this.feeItem,
              amount: Math.abs(this.feeItem.amount),
              chargeType: this.feeItem.amount > 0 ? 'INCREASE' : 'DECREASE'
            }
            this.typeControl.setValue(this.newFee.chargeType)
            this.amountTypeControl.setValue(this.newFee.amountType)
          }
        }
      })
    } else {
      this.newFee = {
        name: '',
        description: '',
        amount: 0,
        chargeType: 'INCREASE',
        amountType: 'MONETARY'
      }
    }
  }

  selectFee(fee: any) {
    this.selection = fee
  }

  confirm() {
    this.newFee.chargeType = this.typeControl.value
    this.newFee.amountType = this.amountTypeControl.value
    this.newFee.amount = this.newFee.chargeType === 'INCREASE' ? Math.abs(this.newFee.amount) : -Math.abs(this.newFee.amount)
    this.onSubmit.emit({ feeItem: this.isNewFee ? this.newFee : this.selection, element: this.linkTo })
  }

  protected readonly close = close;

  isFormValid() {
    return (this.isNewFee ? this.newFee.name && this.newFee.amount && this.newFee.amount > 0.01 : this.selection) &&
      (!this.linkFee || this.level?.value == 'scope' || (this.level && this.linkTo))
  }
}
