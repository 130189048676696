import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterModule } from '@angular/router';
import { BreadCrumb } from '@app/core/model/definitions/breadcrumb.interface';
import { distinctUntilChanged, filter } from 'rxjs';

@Component({
  selector: 'scope-ui-breadcrumbs',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './scope-ui-breadcrumbs.component.html',
  styleUrls: ['./scope-ui-breadcrumbs.component.scss'],
})
export class ScopeUiBreadcrumbsComponent implements OnInit {
  public breadcrumbs: BreadCrumb[];

  @Input() isParentBreadcrumb!: boolean;

  @Input() isScopeBreadcrumb!: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.breadcrumbs = this.buildBreadCrumbs(this.activatedRoute.root);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.breadcrumbs = this.buildBreadCrumbs(this.activatedRoute.root);
      });
  }

  buildBreadCrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: BreadCrumb[] = []): BreadCrumb[] {
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data?.['breadcrumb'] : '';

    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    let lastRoutePart: any = '';

    lastRoutePart = path?.split('/').pop();

    const isDynamicRoute = lastRoutePart?.startsWith(':');

    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart?.split(':')[1];
      path = path?.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }

    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: BreadCrumb = {
      label: label,
      url: nextUrl,
    };

    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumbs(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}
