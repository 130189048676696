import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum SnackbarEventType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO'
}

export const SNACKBAR_LENGTH_LONG = 6000
export const SNACKBAR_LENGTH_SHORT = 3500

export class SnackbarEvent {
  message: string
  permanent: boolean
  timeout: number
  eventType: SnackbarEventType
  prioritised: boolean

  constructor(message: string, permanent: boolean, timeout: number, eventType: SnackbarEventType, privileged: boolean = false) {
    this.message = message;
    this.permanent = permanent;
    this.timeout = timeout;
    this.eventType = eventType;
    this.prioritised = privileged;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  visible: boolean = false

  constructor() { }

  private eventSubject = new Subject<any>();
  private hideSubject = new Subject<any>();

  showSnackbar(message: string, timeoutMs: number, eventType: SnackbarEventType) {
    const event = new SnackbarEvent(message, false, timeoutMs, eventType, false)
    this.eventSubject.next(event);
  }

  showPrioritisedSnackbar(message: string, timeoutMs: number, eventType: SnackbarEventType) {
    const event = new SnackbarEvent(message, false, timeoutMs, eventType, true)
    this.eventSubject.next(event);
  }

  showDefaultErrorSnackbar() {
    const event = new SnackbarEvent('Something went wrong. Please try again', false, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR, false)
    this.eventSubject.next(event);
  }

  showPermanentSnackbar(message: string, eventType: SnackbarEventType) {
    const event = new SnackbarEvent(message, true, 0, eventType)
    this.eventSubject.next(event);
  }

  hide() {
   this.hideSubject.next(true);
  }

  getEvent(): Observable<any> {
    return this.eventSubject.asObservable();
  }

  getHideEvent(): Observable<any> {
    return this.hideSubject.asObservable();
  }
}
