import { Injectable } from "@angular/core"
import { EnvConfig } from "./env-config";
import { environment } from "./environment";

export enum Environment {
  production = 'prod',
  uat = 'uat',
  int = 'int',
  staging = 'staging',
  qa1 = 'qa1',
  qa2 = 'qa2',
  test1 = 'test',
  test2 = 'test2',
  development = 'development',
}

@Injectable({ providedIn: 'root' })
export class EnvService implements EnvConfig {
  private _env!: Environment;
  private _scopeApiUrl!: string;
  private _baseUrl!: string;
  private _baseHost!: string;
  private _baseJsUrl!: string;
  private _wsUrl!: string;
  private _production!: boolean;


  constructor() {
    this.setEnvVariables();
  }

  get env(): Environment {
    return this._env
  }

  get scopeApiUrl(): string {
    return this._scopeApiUrl
  }

  get baseUrl(): string {
    return this._baseUrl
  }

  get baseJsUrl(): string {
    return this._baseJsUrl
  }

  get wsUrl(): string {
    return this._wsUrl
  }

  get baseHost(): string {
    return this._baseHost
  }

  get production(): boolean {
    return this._production
  }

  set baseUrl(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  // init(): Promise<void> {
  //   return new Promise((resolve) => {
  //     this.setEnvVariables()
  //     resolve()
  //   })
  // }

  private setEnvVariables(): void {
    const hostname = window.location.hostname


    this._scopeApiUrl = environment.scopeApiUrl;
    this._baseUrl = environment.baseUrl;

    if (/localhost$/.test(hostname)) {
      this._baseJsUrl = `http://${window.location.hostname}:8080`
      this._wsUrl = `/${this._scopeApiUrl}`
    } else if (/.scope.expert$/.test(hostname)) {
      this._baseJsUrl = `https://${window.location.hostname}`.replace(environment.baseHost, 'js.' + environment.baseHost)
      this._wsUrl = `${this._scopeApiUrl}`
    } else {
      console.warn(`Cannot find environment for host name ${hostname}`)
    }
  }
}
