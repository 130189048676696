import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { DashboardOverviewState } from '../../model/dashboard-overview.state.interface';
import * as fromDashboardOverviewReducer from './dashboard-overview.reducer';

export const featureKey = 'dashboardState';

export interface State {
  dashboardOverview: DashboardOverviewState;
}

export const reducers: ActionReducerMap<State> = {
  dashboardOverview: fromDashboardOverviewReducer.reducer,
};

export const getDashboardOverviewState = createFeatureSelector<State>(featureKey);
