import { Privilege } from "../enums/privilege.enum";
import { PrivilegeDefinition } from "../privilege-definition.model";

const PrivilegeCategory = {
  SCOPE: {
    label: '{LANG:scope}',
    description: '{LANG:scope} section defines what an individual user is permitted to do within {LANG:scope|l}',
    showDescription: true,
  },
  OUTPUT: {
    label: '{LANG:output}',
    description: '{LANG:output} permissions define allowed formats to {LANG:output|l} the {LANG:scope|l}',
    showDescription: true,
  },
  APPROVAL: {
    label: '{LANG:scope}',
    description:
      'Approvals permissions define the user’s ability to move {LANG:scope.p|l} through the approval process. Setting the toggle to ON means the user will be added to every {LANG:scope|l} with this permission.',
    showDescription: false,
  },
  COMPANY: {
    label: 'Company',
    description: 'Company permissions define the company administration options for the user',
    showDescription: true,
  },
  SECOND_PARTY: {
    label: '{LANG:second_party}',
    description: 'In this section {LANG:second_party.p|l} management is present',
    showDescription: true,
  },
  SAML: {
    label: 'SAML',
    description: 'Permissions to manage SAML integration',
    showDescription: true,
    dependsOnApplicationSetting: 'SAML__GLOBAL',
  },
  SOW: {
    label: '{LANG:scope_of_work}',
    description: 'Permissions to manage {LANG:scope_of_work|l}',
    showDescription: true,
    dependsOnApplicationSetting: 'SOW__GLOBAL',
  },
  SCOPE_TYPE: {
    label: '{LANG:scope_type}',
    description: 'Permissions to manage {LANG:scope_type}',
    showDescription: true,
    dependsOnApplicationSetting: 'SOW__GLOBAL',
  },
  RATECARD: {
    label: 'Ratecard',
    description: 'Permissions to manage rate cards',
    showDescription: true,
  },
  RATECARD_STRUCTURE: {
    label: 'Ratecard structure',
    description: 'Permissions to manage rate card structures',
    showDescription: true,
  },
  LIBRARY: {
    label: 'Library',
    description:
      'Library permissions section defines user ability to manage library items -- {LANG:scope.p|l}, {LANG:deliverable.p|l} and {LANG:component.p|l}',
    showDescription: true,
  },
  GROUP: {
    label: 'Group',
    description: 'Group Section defines what an individual user is permitted to do within group',
    showDescription: true,
  },
  OFFICE_LOCATION: {
    label: 'Office Location',
    description: 'Office location management',
    showDescription: true,
  },
  MSA_LINE_ITEM: {
    label: 'MSA Line Items',
    description: 'MSA Line Items management',
    showDescription: true,
  },
  DASHBOARD: {
    label: 'Dashboard',
    description: 'Dashboard Section defines what an individual user is permitted to do within dashboard',
    showDescription: true,
  },
  OUTPUT_TEMPLATES: {
    label: '{LANG:output} Templates',
    description: 'Permissions to manage {LANG:output|l} templates',
    showDescription: true,
  },
  PREDICTION: {
    label: 'Data Management',
    description: 'Permissions to manage prediction data',
    showDescription: true,
  },
  INTEGRATION: {
    label: 'Integration',
    description: 'Permissions to manage integration data',
    showDescription: true,
  },
  DATA: {
    label: 'Data',
    description: 'Permissions to manage setting on Data page',
    showDescription: true,
  },
} as const;

export type PrivilegeCategory = keyof typeof PrivilegeCategory;

const privilegeDefs: PrivilegeDefinition[] = [
  /* SCOPE Privileges */
  new PrivilegeDefinition(Privilege.SCOPE__CREATE, {
    name: 'Create',
    description:
      'Permission to create {LANG:scope.p|l}. You can create based on user.  Also you can add {LANG:second_party.p|l}. {LANG:scope} value condition is not applicable to this permission.',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 2,
    restrictions: {
      bySecondParty: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__VIEW, {
    name: 'View',
    description:
      'Permission to view all company {LANG:scope.p|l}. If you click [on] user can [+ Add {LANG:second_party.p|l}] from a list of all {LANG:second_party.p|l}. Once {LANG:second_party.p|l} added you can [+ Add {LANG:scope} Value] to limit the value of a {LANG:scope} viewed.  If you set View permission to [off] the user will view only their own {LANG:scope.p|l}.',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 0,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__EDIT, {
    hide: true,
    name: 'Edit',
    description:
      'Permission to edit a {LANG:scope} including add, modify, and delete {LANG:deliverable.p}. You can limit edit to a list of {LANG:second_party.p|l} and Values.  Same as view function.',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__DELETE, {
    name: 'Delete',
    description:
      'Permission to delete a {LANG:scope}. You can limit edit to a list of {LANG:second_party.p|l} and Values.',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 3,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__ARCHIVE, {
    name: 'Archive',
    description:
      'Permission to (un)archive {LANG:scope.p|l}. You can limit Archive to a list of {LANG:second_party.p|l} and Values.',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 4,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE_COMMENT__CREATE, {
    name: 'Comment',
    description:
      "Permission to add new comments to the {LANG:scope}. It doesn\\'t prohibit viewing already added comments.  You can limit comments to a list of {LANG:second_party.p|l} and Values.",
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 5,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__VIEW_RATES, {
    name: 'View Rates',
    description:
      'Permission to view hourly rates, and any cost-related info in any section of the system ({LANG:scope.p|l} main page, {LANG:scope} details, {LANG:deliverable|l} builder, rate cards). If set to off, user will only see hours. This can be limited to {LANG:second_party.p|l}.',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 0,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE_DISCOUNT__VIEW, {
    name: 'View Discount',
    description:
      'Permission to add, edit or delete discount in the {LANG:scope}. This can be limited to {LANG:second_party.p|l}.  If edit discount is set to off the user will still see a discount but cannot amend or add a discount only view.',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 0,
    restrictions: {
      bySecondParty: true,
    },
    hide: true,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_DISCOUNT__EDIT, {
    name: 'Edit Discount',
    description:
      'Permission to edit or delete discount in the {LANG:scope}. This can be limited to {LANG:second_party.p|l}.',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
    restrictions: {
      bySecondParty: true,
      byPercentage: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE_MARKUP__VIEW, {
    name: 'View Markup and Profit',
    description:
      'Permission to view markup & profit information for the {LANG:scope}. This can be limited by {LANG:second_party.p|l}.',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 0,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE_MARGIN__VIEW, {
    name: 'View Margin',
    description:
      'Permission to view margin information for the {LANG:scope}. This can be limited by {LANG:second_party.p|l}.',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 0,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE_MARKUP__EDIT, {
    name: 'Edit Markup and Profit',
    description:
      'Permission to edit markup & profit information for the {LANG:scope}. This can be limited by {LANG:second_party.p|l}.',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE_COMPONENT_RESOURCES__DELETE, {
    name: 'Delete resources',
    description: 'Delete {LANG:component} Departments/Role',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 3,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE_ROLE__SUBSTITUTE_AND_UNMAP, {
    name: 'Unmap and Substitute roles',
    description: 'Unmap and Substitute roles',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 6,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE_DELIVERABLE__COMPLETE, {
    name: 'Set {LANG:deliverable} Complete',
    description: 'Ability to mark a {LANG:deliverable} status as completed',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 6,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_DELIVERABLE__STOP, {
    name: 'Stop {LANG:deliverable}',
    description: 'Ability to mark {LANG:deliverable} status as stopped',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 6,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_DELIVERABLE__TRADE, {
    name: 'Trade {LANG:deliverable}',
    description: 'Ability to trade {LANG:deliverable} for another',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 6,
    dependsOnApplicationSetting: 'SCOPE__DELIVERABLE__TRADING',
  }),
  new PrivilegeDefinition(Privilege.SCOPE_FIXED_COST_DELIVERABLE__EDIT, {
    name: 'Edit Fixed Cost {LANG:deliverable}',
    description: 'Ability to edit fixed cost {LANG:deliverable}',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
    dependsOnApplicationSetting: 'SCOPE__FIXED_COST_DELIVERABLE_EDITING',
  }),
  new PrivilegeDefinition(Privilege.SCOPE_FIXED_COST_COMPONENT__EDIT, {
    name: 'Edit Fixed Cost {LANG:component}',
    description: 'Ability to edit fixed cost {LANG:component|l}',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
    dependsOnApplicationSetting: 'SCOPE__FIXED_COST_DELIVERABLE_EDITING',
  }),
  new PrivilegeDefinition(Privilege.SCOPE_REVIEW__EDIT_ROLE_HOURS, {
    name: 'Edit role hours when reviewing scope',
    description: 'Ability to edit role hours while reviewing scope',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_REVIEW__EDIT_COMPONENT_QUANTITY, {
    name: 'Edit {LANG:component|l} quantity when reviewing scope',
    description: 'Ability to edit {LANG:component|l} quantity while reviewing scope',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_DELIVERABLE_COMPONENT__VIEW_ROLES, {
    name: 'View {LANG:component|l} roles',
    description: 'Ability to view {LANG:component|l} roles',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_DELIVERABLE_COMPONENT__ADD_EDIT_ROLES, {
    name: 'Edit {LANG:component|l} roles',
    description: 'Ability to add edit {LANG:component|l} roles',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_TEMPLATE__CREATE, {
    name: 'Create template',
    description: 'Ability to create template',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_TEMPLATE__EDIT, {
    name: 'Edit template',
    description: 'Ability to edit template',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_TEMPLATE__DELETE, {
    name: 'Delete template',
    description: 'Ability to delete template',
    category: PrivilegeCategory.SCOPE,
    categoryIndex: 1,
  }),
  /*Output*/
  new PrivilegeDefinition(Privilege.SCOPE__EXPORT_CSV, {
    name: 'CSV',
    description: 'Export {LANG:scope} as CSV',
    category: PrivilegeCategory.OUTPUT,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__EXPORT_DOCX, {
    name: 'DOCX',
    description: 'Export {LANG:scope} as Docx',
    category: PrivilegeCategory.OUTPUT,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__EXPORT_PDF, {
    name: 'PDF',
    description: 'Export {LANG:scope} as PDF',
    category: PrivilegeCategory.OUTPUT,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__EXPORT_DOCU_SIGN, {
    name: 'DocuSign',
    description: 'Export {LANG:scope} as DocuSign',
    category: PrivilegeCategory.OUTPUT,
    restrictions: {
      byClient: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.DOCUSIGN_VIEW__ACTIVITIES, {
    name: 'DocuSign Activities',
    description: 'View DocuSign activities',
    category: PrivilegeCategory.OUTPUT,
    restrictions: {
      byClient: true,
      byScopeValue: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__EXPORT_XLSX, {
    name: 'XLSX',
    description: 'Export {LANG:scope} as Xlsx',
    category: PrivilegeCategory.OUTPUT,
    restrictions: {
      bySecondParty: true,
      byScopeValue: true,
    },
  }),

  new PrivilegeDefinition(Privilege.SCOPE__SUBMIT, {
    name: 'Submit',
    category: PrivilegeCategory.APPROVAL,
    description: 'Permission to Submit every {LANG:scope}',
    restrictions: {
      bySecondParty: true,
      byScopeBudget: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__SUBMIT_OVERRIDE, {
    name: 'Submit for all',
    category: PrivilegeCategory.APPROVAL,
    description: 'Permission to Submit for all collaborators on every {LANG:scope}',
    restrictions: {
      bySecondParty: true,
      byScopeBudget: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__REVIEW, {
    name: 'Review',
    category: PrivilegeCategory.APPROVAL,
    description: 'Permission to Review every {LANG:scope|l}',
    restrictions: {
      bySecondParty: true,
      byScopeBudget: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__REVIEW_OVERRIDE, {
    name: 'Review for all',
    category: PrivilegeCategory.APPROVAL,
    description: 'Permission to Review for all collaborators on every {LANG:scope|l}',
    restrictions: {
      bySecondParty: true,
      byScopeBudget: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__APPROVE, {
    name: 'Approve',
    category: PrivilegeCategory.APPROVAL,
    description: 'Permission to Approve every {LANG:scope|l}',
    restrictions: {
      bySecondParty: true,
      byScopeBudget: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__APPROVE_OVERRIDE, {
    name: 'Approve for all',
    category: PrivilegeCategory.APPROVAL,
    description: 'Permission to Approve for all collaborators on every {LANG:scope|l}',
    restrictions: {
      bySecondParty: true,
      byScopeBudget: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__TRAFFIC, {
    name: 'Traffic',
    category: PrivilegeCategory.APPROVAL,
    description: 'Permission to Traffic every {LANG:scope|l}',
    restrictions: {
      bySecondParty: true,
      byScopeBudget: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__CLOSE, {
    name: 'Close',
    category: PrivilegeCategory.APPROVAL,
    description: 'Permission to Close every {LANG:scope|l}',
    restrictions: {
      bySecondParty: true,
      byScopeBudget: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE_ACTUALS__VIEW, {
    name: 'View actuals',
    category: PrivilegeCategory.SCOPE,
    description: 'Permission to view actuals of {LANG:scope|l}',
    restrictions: {
      bySecondParty: true,
      byScopeBudget: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SCOPE__VIEW_FEES, {
    name: 'View fees',
    category: PrivilegeCategory.SCOPE,
    description: 'Permission to view fees in {LANG:scope|l}',
  }),
  new PrivilegeDefinition(Privilege.SCOPE__CREATE_FEES, {
    name: 'Create fees',
    category: PrivilegeCategory.SCOPE,
    description: 'Permission to create fees in {LANG:scope|l}',
  }),
  new PrivilegeDefinition(Privilege.SCOPE__UPDATE_FEES, {
    name: 'Update fees',
    category: PrivilegeCategory.SCOPE,
    description: 'Permission to update fees in {LANG:scope|l}',
  }),
  new PrivilegeDefinition(Privilege.SCOPE__DELETE_FEES, {
    name: 'Delete fees',
    category: PrivilegeCategory.SCOPE,
    description: 'Permission to delete fees in {LANG:scope|l}',
  }),

  /* COMPANY Privileges */
  new PrivilegeDefinition(Privilege.COMPANY_DETAILS__VIEW, {
    name: 'View Details',
    description: ' Permission to view Manage tab. This contains the company configuration details.',
    categoryIndex: 0,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_DETAILS__EDIT, {
    name: 'Edit Details',
    description: 'Permission to make changes of company details in the Manage tab',
    categoryIndex: 1,
    category: PrivilegeCategory.COMPANY,
  }),

  new PrivilegeDefinition(Privilege.COMPANY_PREDICTION_DATA__VIEW, {
    name: 'View Prediction Data',
    description: 'Permission to view Prediciton Data Tab and see the available items.',
    categoryIndex: 0,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_APPLICATION_SETTINGS__EDIT, {
    name: 'Edit General Settings',
    description: 'Permission to edit General Settings.',
    categoryIndex: 1,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_CHILD_ACCOUNT_EDIT, {
    name: 'Edit Available Child Accounts',
    description: 'Permission to edit list of available for user child accounts.',
    categoryIndex: 2,
    dependsOnApplicationSetting: 'COMPANY_CHILD_ACCOUNT_ACCESS_RULES',
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_APPLICATION_SETTINGS__VIEW, {
    name: 'View Application Settings',
    description: 'Permission to view Application Settings Tab and see its content.',
    categoryIndex: 0,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_SAML_INTEGRATION__EDIT, {
    name: 'Edit SAML Integration',
    description: 'Permission to edit SAML configuration.',
    categoryIndex: 1,
    category: PrivilegeCategory.SAML,
  }),

  /* COMPANY MEMBERS Privileges */
  new PrivilegeDefinition(Privilege.COMPANY_MEMBERS__VIEW, {
    name: 'View Members',
    description: 'Permission to view all the users in the Members tab.  This includes Administrators and users.',
    categoryIndex: 0,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_MEMBERS__EDIT, {
    name: 'Edit Members',
    description: 'Edit company members',
    categoryIndex: 1,
    category: PrivilegeCategory.COMPANY,
    hide: true,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_MEMBERS_PRIVILEGES__VIEW, {
    name: 'View Permissions',
    description: 'Permission to view permission options.',
    categoryIndex: 0,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_MEMBERS_PRIVILEGES__EDIT, {
    name: 'Edit Permissions',
    description: 'Permission to change a users permission options',
    categoryIndex: 1,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_PRIVILEGES_GROUP__VIEW, {
    name: 'View Permission Profile',
    description: 'Permission to view company permission profiles.',
    categoryIndex: 0,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_PRIVILEGES_GROUP__CREATE, {
    name: 'Create Permission Profile',
    description: 'Permission to create a company permission profile',
    categoryIndex: 2,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_PRIVILEGES_GROUP__EDIT, {
    name: 'Edit Permission Profile',
    description: 'Permission to edit a company permission profile',
    categoryIndex: 1,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_PRIVILEGES_GROUP__DELETE, {
    name: 'Delete Permission Profile',
    description: 'Permission to delete a company permission profile',
    categoryIndex: 3,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_MEMBERS_ROLE_LEVEL__EDIT, {
    name: 'Edit Access Level',
    description:
      'Permission to change the role of a member. When the role is changed the appropriate default permission profile will be automatically set.',
    categoryIndex: 1,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_MEMBERS__DELETE, {
    name: 'Delete Member',
    description: 'Delete company members',
    categoryIndex: 3,
    category: PrivilegeCategory.COMPANY,
    hide: true,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_MEMBERS__INVITE, {
    name: 'Invite Member',
    description: 'Permission to send invitation for new company member to join Scope.',
    categoryIndex: 5,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_MEMBERS__SUSPEND, {
    name: 'Suspend Member',
    description:
      'Permission to deactivate a user from company.  The user will be deactivated from the member list but all detail and activity by the user will be retained in the system.',
    categoryIndex: 5,
    category: PrivilegeCategory.COMPANY,
  }),
  /* COMPANY NOTIFICATION Privileges*/
  new PrivilegeDefinition(Privilege.COMPANY_NOTIFICATION_PROFILE__EDIT, {
    name: 'Edit Notification Profiles',
    description: 'Permission to edit notification profile',
    categoryIndex: 1,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_NOTIFICATION_PROFILE__VIEW, {
    name: 'View Notification Profiles',
    description: 'Permission to view notification profile',
    categoryIndex: 0,
    category: PrivilegeCategory.COMPANY,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_NOTIFICATION_PROFILE__CREATE, {
    name: 'Create Notification Profiles',
    description: 'Permission to create new notification profile',
    categoryIndex: 2,
    category: PrivilegeCategory.COMPANY,
  }),
  /* INTEGRATION Privileges */
  new PrivilegeDefinition(Privilege.COMPANY_TRAFFIC_SYSTEM__VIEW, {
    name: 'View Integration Settings',
    description: 'Permission to view Integration tab  and see the systems Scope connects to.',
    categoryIndex: 0,
    category: PrivilegeCategory.INTEGRATION,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_TRAFFIC_SYSTEM__EDIT, {
    name: 'Edit Traffic System',
    description: 'Permission to set up or change connection to a system that Scope connects to.',
    categoryIndex: 1,
    category: PrivilegeCategory.INTEGRATION,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_TRAFFIC_SYSTEM_CUSTOM__EDIT, {
    name: 'Edit Custom Traffic System',
    description: 'Permission to set up or change connection to any Custom Traffic System.',
    categoryIndex: 1,
    category: PrivilegeCategory.INTEGRATION,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_TRAFFIC_SYSTEM__IMPORT, {
    name: 'Traffic System Import',
    description: 'Permission to import data from (clients and ratecards) from other systems.',
    categoryIndex: 5,
    category: PrivilegeCategory.INTEGRATION,
  }),

  /* GROUP Privileges */
  new PrivilegeDefinition(Privilege.COMPANY_GROUP__CREATE, {
    name: 'Create',
    description:
      'Permission to create a group.  A group is a collection of members from one or more Scope systems or {LANG:agency.p|l} who can view, collaborate and approve Scopes.',
    categoryIndex: 2,
    category: PrivilegeCategory.GROUP,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_GROUP__VIEW, {
    name: 'View',
    description:
      'Permission to access the Groups section in Manage Tab, and see any groups and members details in a group.',
    categoryIndex: 0,
    category: PrivilegeCategory.GROUP,
  }),

  /* SECOND_PARTY Privileges */
  new PrivilegeDefinition(Privilege.SECOND_PARTY__VIEW, {
    name: 'View',
    description: 'Permission to view {LANG:second_party.p|l} tab. This can be limited to {LANG:second_party.p|l}.',
    category: PrivilegeCategory.SECOND_PARTY,
    categoryIndex: 0,
    restrictions: {
      bySecondParty: true,
    },
  }),
  new PrivilegeDefinition(Privilege.SECOND_PARTY__CREATE, {
    name: 'Create',
    description: 'Permission to add new {LANG:second_party.p|l}.',
    categoryIndex: 2,
    category: PrivilegeCategory.SECOND_PARTY,
  }),
  new PrivilegeDefinition(Privilege.SECOND_PARTY__EDIT, {
    name: 'Edit',
    description: 'Permission to edit {LANG:second_party.p|l}.',
    categoryIndex: 1,
    category: PrivilegeCategory.SECOND_PARTY,
  }),
  new PrivilegeDefinition(Privilege.SECOND_PARTY__DELETE, {
    name: 'Delete',
    description: 'Permission to delete {LANG:second_party.p|l}.',
    categoryIndex: 3,
    category: PrivilegeCategory.SECOND_PARTY,
    restrictions: {
      bySecondParty: true,
    },
  }),

  /* RATECARD Privileges */
  new PrivilegeDefinition(Privilege.RATECARD__VIEW, {
    name: 'View',
    description: 'Permission to view Ratecard tab',
    categoryIndex: 0,
    category: PrivilegeCategory.RATECARD,
  }),
  new PrivilegeDefinition(Privilege.RATECARD__CREATE, {
    name: 'Create',
    description: 'Permission to create new rate card',
    categoryIndex: 2,
    category: PrivilegeCategory.RATECARD,
  }),
  new PrivilegeDefinition(Privilege.RATECARD__EDIT, {
    name: 'Edit',
    description: 'Permission to make changes in rate cards.',
    categoryIndex: 1,
    category: PrivilegeCategory.RATECARD,
  }),
  new PrivilegeDefinition(Privilege.RATECARD_ROLE_COST__VIEW, {
    name: 'View Role Cost',
    description: 'Permission to view and edit role cost in rate cards',
    categoryIndex: 0,
    category: PrivilegeCategory.RATECARD,
  }),
  new PrivilegeDefinition(Privilege.RATECARD__DELETE, {
    name: 'Delete',
    description: 'Permission to delete rate card',
    categoryIndex: 3,
    category: PrivilegeCategory.RATECARD,
  }),
  new PrivilegeDefinition(Privilege.RATECARD_LOCATION__EDIT, {
    name: 'Edit Location',
    description: 'Permission to edit Ratecard Location',
    categoryIndex: 1,
    category: PrivilegeCategory.RATECARD,
  }),

  /* RATECARD STRUCTURE Privileges */
  new PrivilegeDefinition(Privilege.RATECARD_STRUCTURE__VIEW, {
    name: 'View',
    description: 'Permission to view Ratecard_Structure tab',
    categoryIndex: 0,
    category: PrivilegeCategory.RATECARD_STRUCTURE,
  }),
  new PrivilegeDefinition(Privilege.RATECARD_STRUCTURE__CREATE, {
    name: 'Create',
    description: 'Permission to create new rate card structure',
    categoryIndex: 2,
    category: PrivilegeCategory.RATECARD_STRUCTURE,
  }),
  new PrivilegeDefinition(Privilege.RATECARD_STRUCTURE__EDIT, {
    name: 'Edit',
    description: 'Permission to make changes in rate card structures.',
    categoryIndex: 1,
    category: PrivilegeCategory.RATECARD_STRUCTURE,
  }),
  new PrivilegeDefinition(Privilege.RATECARD_STRUCTURE__DELETE, {
    name: 'Delete',
    description: 'Permission to delete rate card structure',
    categoryIndex: 3,
    category: PrivilegeCategory.RATECARD_STRUCTURE,
  }),

  /* LIBRARY Privileges */
  new PrivilegeDefinition(Privilege.LIBRARY__VIEW_RATES, {
    name: 'View Rates, Margin & Profit',
    description: 'Permission to view rates, margin & profit in the library.',
    categoryIndex: 0,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_COMPONENT__VIEW, {
    name: 'View {LANG:component.p}',
    description: 'Permission to view {LANG:component.p|l} that have been saved to the library.',
    categoryIndex: 0,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_COMPONENT__CREATE, {
    name: 'Create {LANG:component.p}',
    description: 'Ability to create {LANG:component|l} library items.',
    categoryIndex: 2,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_COMPONENT__EDIT, {
    name: 'Edit {LANG:component.p}',
    description:
      'Permission to edit the {LANG:component|l} library item, its name, its rate card, classification and resources.',
    categoryIndex: 1,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_COMPONENT__EDIT_CASCADE, {
    name: 'Cascade Updated {LANG:component.p}',
    description:
      'Permission to cascade {LANG:component|l} library items once updated. This can cascade to {LANG:scope|l} {LANG:deliverable.p} and/or Library {LANG:deliverable.p} using this {LANG:component|l}',
    categoryIndex: 7,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_COMPONENT__DELETE, {
    name: 'Delete {LANG:component.p}',
    description: 'Permission to delete {LANG:component|l} library items',
    categoryIndex: 3,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_COMPONENT__MOVE, {
    name: 'Move {LANG:component.p}',
    description: 'Permission to move {LANG:component|l} library items to folders.',
    categoryIndex: 5,
    hide: true,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_COMPONENT__UPLOAD, {
    name: 'Upload {LANG:component.p}',
    description: 'Permission to upload {LANG:component.p} into library.',
    categoryIndex: 6,
    category: PrivilegeCategory.LIBRARY,
  }),

  new PrivilegeDefinition(Privilege.LIBRARY_FOLDER__VIEW, {
    name: 'View Folders',
    description: 'Permission to view folder that have been saved to the library.',
    categoryIndex: 0,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_FOLDER__CREATE, {
    name: 'Create Folders',
    description: 'Ability to create folder library items.',
    categoryIndex: 2,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_FOLDER__EDIT, {
    name: 'Edit Folders',
    description: 'Permission to edit the Folder library item, its name, its rate card, classification and resources.',
    categoryIndex: 1,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_FOLDER__DELETE, {
    name: 'Delete Folders',
    description: 'Permission to delete Folder library items',
    categoryIndex: 3,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_FOLDER__MOVE, {
    hide: true,
    name: 'Move Folders',
    description: 'Permission to move Folder library items to folders.',
    categoryIndex: 5,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_FOLDER__ADD_ITEM, {
    hide: true,
    name: 'Add items to Folder',
    description: 'Permission to add items folder.',
    categoryIndex: 5,
    category: PrivilegeCategory.LIBRARY,
  }),

  new PrivilegeDefinition(Privilege.LIBRARY_THIRD_PARTY_COST__VIEW, {
    name: 'View {LANG:third_party_cost.p}',
    description: 'Permission to view {LANG:third_party_cost} that have been saved to the library.',
    categoryIndex: 0,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_THIRD_PARTY_COST__CREATE, {
    name: 'Create {LANG:third_party_cost.p}',
    description: 'Ability to create {LANG:third_party_cost} library items.',
    categoryIndex: 2,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_THIRD_PARTY_COST__EDIT, {
    name: 'Edit {LANG:third_party_cost.p}',
    description:
      'Permission to edit the {LANG:third_party_cost} library item, its name, its rate card, classification and resources.',
    categoryIndex: 1,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_THIRD_PARTY_COST__EDIT_CASCADE, {
    name: 'Edit {LANG:third_party_cost.p} Cascade change',
    description:
      'Permission to edit the {LANG:third_party_cost} library item, its name, its rate card, classification and resources and propagete change.',
    categoryIndex: 1,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_THIRD_PARTY_COST__DELETE, {
    name: 'Delete {LANG:third_party_cost.p}',
    description: 'Permission to delete {LANG:third_party_cost} library items',
    categoryIndex: 3,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_THIRD_PARTY_COST__MOVE, {
    hide: true,
    name: 'Move {LANG:third_party_cost.p}',
    description: 'Permission to move {LANG:third_party_cost} library items to folders.',
    categoryIndex: 5,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_THIRD_PARTY_COST_GROUP__VIEW, {
    name: 'View {LANG:third_party_cost} Group',
    description: 'Permission to view {LANG:third_party_cost} group.',
    categoryIndex: 0,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_THIRD_PARTY_COST_GROUP__CREATE, {
    name: 'Create {LANG:third_party_cost} Group',
    description: 'Permission to create {LANG:third_party_cost} group.',
    categoryIndex: 2,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_THIRD_PARTY_COST_GROUP__EDIT, {
    name: 'Edit {LANG:third_party_cost} Group',
    description: 'Permission to edit {LANG:third_party_cost} group.',
    categoryIndex: 1,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_THIRD_PARTY_COST_GROUP__DELETE, {
    name: 'Delete {LANG:third_party_cost} Group',
    description: 'Permission to delete {LANG:third_party_cost} group.',
    categoryIndex: 3,
    category: PrivilegeCategory.LIBRARY,
  }),

  new PrivilegeDefinition(Privilege.LIBRARY_DELIVERABLE__VIEW, {
    name: 'View {LANG:deliverable.p}',
    description: 'Permission to view {LANG:deliverable.p|l} that have been saved to the library.',
    categoryIndex: 0,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_DELIVERABLE__CREATE, {
    name: 'Create {LANG:deliverable.p}',
    description: 'Ability to create {LANG:deliverable} library items.',
    categoryIndex: 2,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_DELIVERABLE__EDIT, {
    name: 'Edit {LANG:deliverable.p}',
    description:
      'Permission to edit a {LANG:deliverable} in the library, and also the name, rate-card, and the {LANG:component.p|l} and their size in that {LANG:deliverable}',
    categoryIndex: 1,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_DELIVERABLE__DELETE, {
    name: 'Delete {LANG:deliverable.p}',
    description: 'Permission to delete {LANG:deliverable} library items',
    categoryIndex: 3,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_DELIVERABLE__MOVE, {
    name: 'Move {LANG:deliverable.p}',
    description: 'Permission to move {LANG:deliverable} library items to folders.',
    categoryIndex: 5,
    hide: true,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_DELIVERABLE__UPLOAD, {
    name: 'Upload {LANG:deliverable.p}',
    description: 'Permission to upload {LANG:deliverable.p} into library.',
    categoryIndex: 6,
    category: PrivilegeCategory.LIBRARY,
  }),

  new PrivilegeDefinition(Privilege.LIBRARY_FEE_CREATE, {
    name: 'Create fees',
    description: 'Ability to create fee items in library',
    categoryIndex: 8,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_FEE_EDIT, {
    name: 'Edit fees',
    description: 'Ability to update fee items in library',
    categoryIndex: 9,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_FEE_DELETE, {
    name: 'Delete fees',
    description: 'Ability to delete fee items in library',
    categoryIndex: 10,
    category: PrivilegeCategory.LIBRARY,
  }),
  new PrivilegeDefinition(Privilege.LIBRARY_FEE_VIEW, {
    name: 'View fees',
    description: 'Ability to view fee items in library',
    categoryIndex: 11,
    category: PrivilegeCategory.LIBRARY,
  }),

  /* DASHBOARD */

  new PrivilegeDefinition(Privilege.DASHBOARD__VIEW, {
    name: 'View dashboard',
    description: 'Permission to view Dashboard page.',
    categoryIndex: 0,
    category: PrivilegeCategory.DASHBOARD,
  }),
  new PrivilegeDefinition(Privilege.DASHBOARD_FINANCE_REPORTING__VIEW, {
    name: 'View Finance Reporting tab',
    description: 'Permission to view Finance Reporting dashboard tab.',
    categoryIndex: 0,
    category: PrivilegeCategory.DASHBOARD,
  }),
  new PrivilegeDefinition(Privilege.DASHBOARD_REPORT_DOWNLOADS__VIEW, {
    name: 'View Report Downloads tab',
    description: 'Permission to view Report Downloads dashboard tab.',
    categoryIndex: 0,
    category: PrivilegeCategory.DASHBOARD,
  }),
  new PrivilegeDefinition(Privilege.DASHBOARD_REPORT_DOWNLOADS_PREVIEW, {
    name: 'Report Downloads preview',
    description: 'Permission to preview reports in Report Downloads dashboard tab.',
    categoryIndex: 7,
    category: PrivilegeCategory.DASHBOARD,
  }),
  new PrivilegeDefinition(Privilege.DASHBOARD_REPORT_DOWNLOADS_DOWNLOAD, {
    name: 'Report Downloads download',
    description: 'Permission to download reports in Report Downloads dashboard tab.',
    categoryIndex: 7,
    category: PrivilegeCategory.DASHBOARD,
  }),

  /* Folder of Work */

  new PrivilegeDefinition(Privilege.SOW__VIEW, {
    name: 'View',
    description: 'Permission to view {LANG:scope_of_work} page',
    categoryIndex: 0,
    category: PrivilegeCategory.SOW,
  }),
  new PrivilegeDefinition(Privilege.SOW__EDIT, {
    name: 'Edit',
    description: 'Permission to edit a {LANG:scope_of_work}',
    categoryIndex: 1,
    category: PrivilegeCategory.SOW,
  }),
  new PrivilegeDefinition(Privilege.SOW__DELETE, {
    name: 'Delete',
    description: 'Permission to delete a {LANG:scope_of_work}',
    categoryIndex: 3,
    category: PrivilegeCategory.SOW,
  }),
  new PrivilegeDefinition(Privilege.SOW__CREATE, {
    name: 'Create',
    description: 'Permission to create a {LANG:scope_of_work}',
    categoryIndex: 2,
    category: PrivilegeCategory.SOW,
  }),
  new PrivilegeDefinition(Privilege.SOW_COMMENT__CREATE, {
    name: 'Comment',
    description: 'Permission to create a comment on {LANG:scope_of_work}',
    categoryIndex: 5,
    category: PrivilegeCategory.SOW,
  }),
  new PrivilegeDefinition(Privilege.SOW__ARCHIVE, {
    name: 'Archive',
    description: 'Permission to un/archive a {LANG:scope_of_work}',
    categoryIndex: 4,
    category: PrivilegeCategory.SOW,
  }),
  new PrivilegeDefinition(Privilege.SOW_TIMESHEET__UPLOAD, {
    name: 'Upload',
    description: 'Permission to upload timesheets to a {LANG:scope_of_work}',
    categoryIndex: 6,
    category: PrivilegeCategory.SOW,
  }),

  /* SCOPE TYPE */

  new PrivilegeDefinition(Privilege.SCOPE_TYPE__VIEW, {
    name: 'View',
    description: 'Permission to view {LANG:scope_type} page',
    categoryIndex: 0,
    category: PrivilegeCategory.SCOPE_TYPE,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_TYPE__EDIT, {
    name: 'Edit',
    description: 'Permission to edit a {LANG:scope_type}',
    categoryIndex: 1,
    category: PrivilegeCategory.SCOPE_TYPE,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_TYPE__DELETE, {
    name: 'Delete',
    description: 'Permission to delete a {LANG:scope_type}',
    categoryIndex: 3,
    category: PrivilegeCategory.SCOPE_TYPE,
  }),
  new PrivilegeDefinition(Privilege.SCOPE_TYPE__CREATE, {
    name: 'Create',
    description: 'Permission to create a {LANG:scope_type}',
    categoryIndex: 2,
    category: PrivilegeCategory.SCOPE_TYPE,
  }),

  /* OFFICE LOCATIONS */

  new PrivilegeDefinition(Privilege.OFFICE_LOCATION__VIEW, {
    name: 'View',
    description: 'Permission to view Office location page',
    categoryIndex: 0,
    category: PrivilegeCategory.OFFICE_LOCATION,
  }),
  new PrivilegeDefinition(Privilege.OFFICE_LOCATION__EDIT, {
    name: 'Edit',
    description: 'Permission to edit a Office location',
    categoryIndex: 1,
    category: PrivilegeCategory.OFFICE_LOCATION,
  }),
  new PrivilegeDefinition(Privilege.OFFICE_LOCATION__DELETE, {
    name: 'Delete',
    description: 'Permission to delete a Office location',
    categoryIndex: 3,
    category: PrivilegeCategory.OFFICE_LOCATION,
  }),
  new PrivilegeDefinition(Privilege.OFFICE_LOCATION__CREATE, {
    name: 'Create',
    description: 'Permission to create a Office location',
    categoryIndex: 2,
    category: PrivilegeCategory.OFFICE_LOCATION,
  }),

  /* MSA LINE ITEMS */

  new PrivilegeDefinition(Privilege.MSA_LINE_ITEM__VIEW, {
    name: 'View',
    description: 'Permission to view MSA Line Items',
    categoryIndex: 0,
    category: PrivilegeCategory.MSA_LINE_ITEM,
  }),
  new PrivilegeDefinition(Privilege.MSA_LINE_ITEM__EDIT, {
    name: 'Edit',
    description: 'Permission to edit MSA Line Items',
    categoryIndex: 1,
    category: PrivilegeCategory.MSA_LINE_ITEM,
  }),
  new PrivilegeDefinition(Privilege.MSA_LINE_ITEM__DELETE, {
    name: 'Delete',
    description: 'Permission to delete MSA Line Items',
    categoryIndex: 3,
    category: PrivilegeCategory.MSA_LINE_ITEM,
  }),
  new PrivilegeDefinition(Privilege.MSA_LINE_ITEM__CREATE, {
    name: 'Create',
    description: 'Permission to create MSA Line Items',
    categoryIndex: 2,
    category: PrivilegeCategory.MSA_LINE_ITEM,
  }),

  /* OUTPUT TEMPLATES */
  new PrivilegeDefinition(Privilege.OUTPUT_TEMPLATE__CREATE, {
    name: 'Create',
    description: 'Permission to create {LANG:output} template',
    categoryIndex: 3,
    category: PrivilegeCategory.OUTPUT_TEMPLATES,
    dependsOn: { element: 'OUTPUT_TEMPLATE__VIEW', disabledIfParent: false },
  }),
  new PrivilegeDefinition(Privilege.OUTPUT_TEMPLATE__VIEW, {
    name: 'View',
    description: 'Permission to view {LANG:output} template',
    categoryIndex: 0,
    category: PrivilegeCategory.OUTPUT_TEMPLATES,
  }),
  new PrivilegeDefinition(Privilege.OUTPUT_TEMPLATE__EDIT, {
    name: 'Edit (My {LANG:scope})',
    description: "Permission to edit an {LANG:output} template from My {LANG:scope}') | langf",
    categoryIndex: 1,
    category: PrivilegeCategory.OUTPUT_TEMPLATES,
  }),
  new PrivilegeDefinition(Privilege.OUTPUT_TEMPLATE_MANAGE__EDIT, {
    name: 'Edit (Manage)',
    description: 'Permission to edit an {LANG:output} template from Manage',
    categoryIndex: 2,
    category: PrivilegeCategory.OUTPUT_TEMPLATES,
    dependsOn: { element: 'OUTPUT_TEMPLATE__VIEW', disabledIfParent: false },
  }),
  new PrivilegeDefinition(Privilege.OUTPUT_TEMPLATE__DELETE, {
    name: 'Delete',
    description: 'Permission to delete an {LANG:output} template',
    categoryIndex: 4,
    category: PrivilegeCategory.OUTPUT_TEMPLATES,
    dependsOn: { element: 'OUTPUT_TEMPLATE__VIEW', disabledIfParent: false },
  }),
  new PrivilegeDefinition(Privilege.SCOPEMARK_DELIVERABLE_INFO__EDIT, {
    name: 'Edit {LANG:scope_mark} {LANG:deliverable} info',
    description: 'Ability to redefined the description of a {LANG:scope_mark} {LANG:deliverable}',
    category: PrivilegeCategory.PREDICTION,
    dependsOnApplicationSetting: 'PREDICT__SCOPEMARK__DELIVERABLES',
    categoryIndex: 0,
  }),
  new PrivilegeDefinition(Privilege.SCOPEMARK_COMPONENT_INFO__EDIT, {
    name: 'Edit {LANG:scope_mark} {LANG:component|l} info',
    description: 'Ability to redefined the description of a {LANG:scope_mark} {LANG:component|l}',
    category: PrivilegeCategory.PREDICTION,
    dependsOnApplicationSetting: 'PREDICT__SCOPEMARK__COMPONENTS',
    categoryIndex: 1,
  }),
  new PrivilegeDefinition(Privilege.SCOPEMARK_COMPONENT_RESOURCE__EDIT, {
    name: 'Edit {LANG:scope_mark} {LANG:component|l} Resources',
    description: 'Ability to redefine {LANG:scope_mark} {LANG:component.p|l}',
    category: PrivilegeCategory.PREDICTION,
    dependsOnApplicationSetting: 'PREDICT__SCOPEMARK__COMPONENTS',
    categoryIndex: 2,
  }),
  new PrivilegeDefinition(Privilege.PREDICTION_COMPONENT_RECOMMENDED__EDIT, {
    name: 'Star/Unstar {LANG:component.p}',
    description: 'Ability to star {LANG:component.p|l} on the data management page',
    category: PrivilegeCategory.PREDICTION,
    dependsOnApplicationSetting: 'PREDICT__SCOPEMARK__COMPONENTS',
    categoryIndex: 3,
  }),
  new PrivilegeDefinition(Privilege.PREDICTION_COMPONENT_ACTIVE__EDIT, {
    name: 'Hide/Show {LANG:component.p}',
    description: 'Ability to Hide/Show {LANG:component.p|l} on the data management page',
    category: PrivilegeCategory.PREDICTION,
    dependsOnApplicationSetting: 'PREDICT__SCOPEMARK__COMPONENTS',
    categoryIndex: 4,
  }),
  new PrivilegeDefinition(Privilege.PREDICTION_COMPONENT_ORDER__EDIT, {
    name: 'Reorder {LANG:component.p}',
    description: 'Ability to order {LANG:component.p|l} on the data management page',
    category: PrivilegeCategory.PREDICTION,
    dependsOnApplicationSetting: 'PREDICT__SCOPEMARK__COMPONENTS',
    categoryIndex: 5,
  }),
  new PrivilegeDefinition(Privilege.PREDICTION_COMPONENT_COPY__EDIT, {
    name: 'Copy {LANG:component.p} to {LANG:deliverable.p}',
    description: 'Ability to copy {LANG:component.p|l} to different {LANG:deliverable.p} on the data management page',
    category: PrivilegeCategory.PREDICTION,
    dependsOnApplicationSetting: 'PREDICT__SCOPEMARK__COMPONENTS',
    categoryIndex: 6,
  }),
  new PrivilegeDefinition(Privilege.PREDICTION_COMPONENT_COPY__DELETE, {
    name: 'Delete Copied {LANG:component.p}',
    description: 'Ability to delete copied {LANG:component.p|l} on the data management page',
    category: PrivilegeCategory.PREDICTION,
    dependsOnApplicationSetting: 'PREDICT__SCOPEMARK__COMPONENTS',
    categoryIndex: 7,
  }),
  new PrivilegeDefinition(Privilege.SCOPEMARK_TPC_GROUP_INFO__VIEW, {
    name: 'View {LANG:scope_mark} {LANG:third_party_cost} Group Info',
    description: 'Ability to view the info of {LANG:scope_mark} {LANG:third_party_cost} Group',
    category: PrivilegeCategory.PREDICTION,
    categoryIndex: 8,
  }),
  new PrivilegeDefinition(Privilege.SCOPEMARK_TPC_GROUP_INFO__EDIT, {
    name: 'Edit {LANG:scope_mark} {LANG:third_party_cost} Group Info',
    description: 'Ability to edit the info of {LANG:scope_mark} {LANG:third_party_cost} Group',
    category: PrivilegeCategory.PREDICTION,
    categoryIndex: 9,
  }),
  new PrivilegeDefinition(Privilege.SCOPEMARK_TPC_TYPE_INFO__EDIT, {
    name: 'Edit {LANG:scope_mark} {LANG:third_party_cost} Type Info',
    description: 'Ability to edit of {LANG:scope_mark} {LANG:third_party_cost} Type Info',
    category: PrivilegeCategory.PREDICTION,
    categoryIndex: 10,
  }),
  new PrivilegeDefinition(Privilege.SCOPEMARK_TPC_TYPE_INFO__VIEW, {
    name: 'View {LANG:scope_mark} {LANG:third_party_cost} Type Info',
    description: 'Ability to view {LANG:scope_mark} {LANG:third_party_cost} Type Info',
    category: PrivilegeCategory.PREDICTION,
    categoryIndex: 11,
  }),

  /* DATA Page privileges */

  new PrivilegeDefinition(Privilege.FEE_ITEM__CREATE, {
    name: 'Create fees',
    description: 'Ability to create new fees on Data page',
    category: PrivilegeCategory.DATA,
    categoryIndex: 1,
  }),
  new PrivilegeDefinition(Privilege.FEE_ITEM__UPDATE, {
    name: 'Edit fees',
    description: 'Ability to edit existing fees on Data page',
    category: PrivilegeCategory.DATA,
    categoryIndex: 2,
  }),
  new PrivilegeDefinition(Privilege.FEE_ITEM__VIEW, {
    name: 'View fees',
    description: 'Ability to view fees on Data page',
    category: PrivilegeCategory.DATA,
    categoryIndex: 3,
  }),
  new PrivilegeDefinition(Privilege.FEE_ITEM__DELETE, {
    name: 'Delete fees',
    description: 'Ability to delete existing fees on Data page',
    category: PrivilegeCategory.DATA,
    categoryIndex: 4,
  }),

  // GROUP PERMISSIONS
  new PrivilegeDefinition(Privilege.COMPANY_GROUP__EDIT, {
    name: 'Edit Group',
    description: 'Permission to Edit details of the group such as name of the group.',
    categoryIndex: 1,
    category: PrivilegeCategory.GROUP,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_GROUP__DELETE, {
    name: 'Delete Group',
    description: 'Permission to delete the group and all of the members and shared {LANG:second_party.p|l}.',
    categoryIndex: 3,
    category: PrivilegeCategory.GROUP,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_GROUP__SHARE, {
    name: 'Edit Share Settings',
    description: "Permission to share {LANG:second_party.p|l} within the group.'  | langf)",
    categoryIndex: 1,
    category: PrivilegeCategory.GROUP,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_GROUP__MANAGE_PERMISSIONS, {
    name: 'Manage member permissions',
    description: 'Permission to manage the permissions / roles of other members.',
    categoryIndex: 5,
    category: PrivilegeCategory.GROUP,
    hide: true,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_GROUP__MEMBER__INVITE, {
    name: 'Invite members',
    description: 'Permission to invite other members into this group.',
    categoryIndex: 6,
    category: PrivilegeCategory.GROUP,
  }),
  new PrivilegeDefinition(Privilege.COMPANY_GROUP__MEMBER__DELETE, {
    name: 'Delete members',
    description: 'Permission to remove members from this group.',
    categoryIndex: 3,
    category: PrivilegeCategory.GROUP,
  }),
];

// export type PrivilegeDefinition = { privilege: Privilege; /* Other fields... */ };

// Create maps:
const pMap = new Map<Privilege, PrivilegeDefinition>();
const sMap = new Map<string, PrivilegeDefinition>();

privilegeDefs.forEach((p) => {
  // It's not necessary to check if the privilege is already defined. Enum keys are unique.
  pMap.set(p.getPrivilege(), p);
  sMap.set(Privilege[p.getPrivilege()], p);
});

// No need to filter and parse strings. Enums in TypeScript are objects, so you can use Object.keys to get the keys.
if (privilegeDefs.length !== Object.keys(Privilege).length) {
  throw new Error('Not all privileges have definitions!');
}

export const PrivilegeMap: ReadonlyMap<Privilege, PrivilegeDefinition> = pMap;
export const PrivilegeStringMap: ReadonlyMap<string, PrivilegeDefinition> = sMap;
