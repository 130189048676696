import { Component } from "@angular/core";
import { SafePipe } from "@app/shared/pipe/safe.pipe";
import { HeaderStyleService } from "@app/shared/services/header-style.service";
import { EnvService } from "@envs/env-service";
import { environment } from "@envs/environment";
import { Router } from '@angular/router'
import { AuthService } from '@core/service/auth.service'

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.scss'],
  providers: [ SafePipe ],

})
export class CreateTemplateComponent {
  constructor(private headerStyleService: HeaderStyleService, private envService: EnvService, private router: Router, private authService: AuthService) {
    window.addEventListener('message', event => {
      if (event.data.action === 'goToScope') {
        this.router.navigateByUrl('/scopes/scope-overview/' + event.data.id)
      }
      if (event.data === 'goToScopes') {
        this.router.navigateByUrl('/scopes')
      }
    })
  }

  public get baseJsUrl(): string {
    return `${this.envService.baseJsUrl}/create-template?host=${environment.baseHost}&childCompany=${this.authService.loggedInUser?.company.id}`;
  }
}
