import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { CoreState } from '@core/store';
import { AuthService } from '@core/service/auth.service'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  private SHORT_DISPLAY_FORMAT = 'DD/MM/YYYY';
  private FULL_DISPLAY_FORMAT = 'Do MMMM YYYY HH:mm:ss';
  companyDateFormat?: string;
  constructor(private store: Store<CoreState>, authService: AuthService) {
    this.companyDateFormat = authService.loggedInUser?.company?.companyDateFormat;
  }
  transform(date: Date, arg1: string): string {
    if (!date) return '--';

    if (this.companyDateFormat) {
      return dayjs.utc(date).format(this.companyDateFormat);
    }

    let dateFormat: string;
    switch (arg1) {
      case 'shortDate':
        dateFormat = this.SHORT_DISPLAY_FORMAT;
        break;
      case 'fullDate':
      default:
        dateFormat = this.FULL_DISPLAY_FORMAT;
        break;
    }
    return dayjs.utc(date).format(dateFormat);
  }
}
