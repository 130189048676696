<div class="task-main">
  <div class="name-container">
    <div *ngIf="!editName" class="task-name" isEllipsisActive>{{task.name}}</div>
    <scope-ui-input
      *ngIf="editName"
      class="edit-name"
      (click)='$event.stopPropagation()'
      [initialValue]="task.name"
      [hasPlaceholder]="false"
      [autofocus]='true'
      (onInputBlurChange)="updateTaskName($event)"
    />
    <button
      [hidden]="editName"
      class="scope-actions-button"
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="taskOptionsMenu">
      <mat-icon class="material-symbols-rounded">more_vert</mat-icon>
    </button>
    <mat-menu
      #taskOptionsMenu="matMenu"
      backdropClass="scope-options-menu">
      <ng-container *ngFor="let option of taskActions">
        <mat-divider *ngIf="option.showDivider && (option.isVisible ? option.isVisible(currentScope) : true)"></mat-divider>
        <button
          (click)="option.click()"
          *ngIf="option.isVisible ? option.isVisible(currentScope) : true"
          mat-menu-item
          class="scope-option">
          <mat-icon class="material-symbols-rounded orange">{{ option.icon }}</mat-icon>
          <span class="scope-option-name">{{ option.name() }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
  <scope-ui-table
    class="departments-table-wrapper"
    scroll-siblings
    [target-class]="'departments-table-wrapper'"
    [tableClass]="'departments-table overview-table condensed row-gaps'"
    [loggedInUser]='currentUser'
    [dataSource]="mappedDepartmentsDataSource"
    [displayedColumns]="departmentColumns"
    [noPagination]="true"
    [noPreferences]="true"
    [showToggleMenu]="false"
    [expandable]="true"
    [headerExpandable]="true"
    [tableExpanded]="tableExpanded"
    [expandedElements]="scopeTabService.departmentSelectedStates$ | async"
    [useSourceId]="true"
    [hideToggles]="true"
    [sort]="departmentSort"
    [stickyHeader]="true"
    (expandedElementsChange)="scopeTabService.updateDepartmentSelectedStates($event)"
    (tableExpandedChange)="tableExpandedChange.emit($event)">
    <ng-template let-element let-sort="sort">
      <scope-ui-table
        [tableClass]="'roles-table nested condensed'"
        [loggedInUser]='currentUser'
        [dataSource]="element.children"
        [displayedColumns]="departmentColumns"
        [noPagination]="true"
        [noPreferences]="true"
        [showToggleMenu]="false"
        [expandable]="false"
        [hideHeader]="true"
        [sort]="sort"/>
    </ng-template>
  </scope-ui-table>
  <div class="task-footer-wrapper">
    <div class="task-footer {{tableExpanded ? 'expanded' : ''}}">
      <div>
        <label>Quantity</label>
        <scope-ui-counter [hidden]="!scopeApprovalFlowService.isScopeEditable(currentScope)"
                          [initialValue]="task.quantity"
                          [min]="1"
                          [formClass]="'extra-small'"
                          (onUpdateValue)="onUpdateQuantity($event)"></scope-ui-counter>
        <span [hidden]="scopeApprovalFlowService.isScopeEditable(currentScope)">{{task.quantity}}</span>
      </div>
      <mat-divider [has-privilege]="'SCOPE_MARGIN__VIEW'"></mat-divider>
      <div [has-privilege]="'SCOPE_MARGIN__VIEW'">
        <label>Margin</label>
        <span>{{task.getMargin()}}%</span>
      </div>
      <mat-divider [has-privilege]="'RATECARD_ROLE_COST__VIEW'"></mat-divider>
      <div [has-privilege]="'RATECARD_ROLE_COST__VIEW'">
        <label>Cost Price</label>
        <span>{{task.getTotalCostPrice().amount | currency : task.currencyUnit}}</span>
      </div>
      <mat-divider></mat-divider>
      <div>
        <label>Selling Price</label>
        <span>{{task.getTotalSellingPrice().amount | currency : task.currencyUnit}}</span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="scopeTabService.taskOpenStates[this.task.id]" class="task-info">
  <div class="info-header flex justify-between w-full">
    <h6 class="product-heading-bold">Task information</h6>
    <mat-icon class="material-symbols-rounded pointer orange" (click)="scopeTabService.taskOpenStates[this.task.id] = false">close</mat-icon>
  </div>
  <div>
    <h6 class="product-heading-bold">Duration</h6>
    <scope-ui-duration
      [has-setting]="'SCOPE__START_END_DATE'"
      [hidePeriod]="true"
      [opens]="'left'"
      [startDate]='task.startDate'
      (startDateChange)="updateStartDate($event)"
      [disabled]="!scopeApprovalFlowService.isScopeEditable(currentScope)"
      [(endDate)]='task.endDate'
      (endDateChange)="updateEndDate($event)" >
    </scope-ui-duration>
  </div>
  <div class="w-full">
    <h6 class="product-heading-bold">Description</h6>
    <description-editor
      [model]='task'
      [visible]="true"
      [showNoteBelow]="true"
      [status]="currentScope.status"
      [editable]="scopeApprovalFlowService.isScopeEditable(currentScope)"
      (onSaveDescription)="updateDescription($event)"
      (onSaveNote)="updateInternalNote($event)" >
    </description-editor>
  </div>
</div>
