<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>Duplicate {{'scope' | lang }}</h1>
    </div>

    <div class="mt-2">
      <span>"{{scopeToDuplicate.name}}" {{'scope|l' | lang }} is linked to old version of "{{scopeToDuplicate.identity.rateCard.name}}" rate card (version {{scopeToDuplicate.identity.rateCard.version}}).
                                Would you like to use the current version of "{{scopeToDuplicate.identity.rateCard.name}}" rate card (version {{scopeToDuplicate.identity.rateCard.rateCardIdentity.currentVersionNumber}}) or would like to keep the old one?</span>
    </div>

    <button class="close-button" mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-actions class="flex use-ratecard-buttons">
  <button mat-flat-button (click)="useLatest()">Use the latest version of the rate card</button>
  <button mat-flat-button (click)="useOld()">Use the old version of the rate card</button>
</mat-dialog-actions>
