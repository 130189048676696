import { MsaLineItem } from './msa-line-item.model'

export class ScopeMsaLineItem {
    name: string;
    value: number | string;
    type: string;
    chargeType: string;

    constructor(name: string, value: number | string, type: string, chargeType: string) {
        this.name = name;
        this.value = value;
        this.type = type;
        this.chargeType = chargeType;
    }

    toMsaLineItem(): MsaLineItem {
        return new MsaLineItem({
            name: this.name,
            amount: this.value,
            type: this.type,
            chargeType: this.chargeType
        });
    }

    getName(): string {
        return this.name;
    }

    getValue(): number | string {
        return this.value;
    }

    getValueFormatted(): string {
        return parseFloat(this.value.toString()).toFixed(2);
    }

    getValueByPercentage(scopePrice: number): string {
        if (this.type == 'PERCENTAGE') {
            return parseFloat((scopePrice * parseFloat(this.value.toString()) / 100).toString()).toFixed(2);
        }
        return this.value.toString();
    }

    getType(): string {
        return this.type;
    }

    setType(type: string): void {
        this.type = type;
    }

    getChargeType(): string {
        return this.chargeType;
    }

    setChargeType(chargeType: string): void {
        this.chargeType = chargeType;
    }

    setName(name: string): void {
        this.name = name;
    }

    setValue(value: number | string): void {
        this.value = value;
    }
}

