import { NgModule } from '@angular/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSelectModule } from '@angular/material/select'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CdkTableModule } from '@angular/cdk/table'
import { CommonModule } from '@angular/common'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatTableModule } from '@angular/material/table'
import { MatButtonModule } from '@angular/material/button'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { CdkDrag, CdkDragHandle, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSliderModule } from '@angular/material/slider'

export const MATERIAL_MODULES = [
  MatInputModule,
  MatSelectModule,
  MatMenuModule,
  MatDialogModule,
  MatFormFieldModule,
  MatPaginatorModule,
  MatSortModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatIconModule,
  MatExpansionModule,
  CdkTableModule,
  MatTableModule,
  MatButtonModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatChipsModule,
  MatRadioModule,
  MatAutocompleteModule,
  MatDividerModule,
  MatTooltipModule,
  CdkDrag,
  CdkDragHandle,
  CdkDropList,
  CdkDropListGroup
]

@NgModule({
  declarations: [],
  imports: [ReactiveFormsModule, FormsModule, CommonModule, ...MATERIAL_MODULES],
  exports: [ReactiveFormsModule, FormsModule, CommonModule, ...MATERIAL_MODULES],
})
export class MaterialModule {}
