import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { ScopeVersion } from '@core/model/scope-version';
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service';
import { User } from '@app/core/model/user.model';
import { ScopeComponent } from '@app/features/scoping/models/component.model';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ScopeUiTableComponent } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { plainToInstance } from 'class-transformer';
import { NgxMaskDirective } from 'ngx-mask'
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service'
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { Role } from '@app/features/scoping/models/role.model'
import { Preference } from '@core/model/user-preferences.interface'
import { MenuOptions } from '@core/model/definitions/menu-options.interface'
import { trackById } from '@shared/utils/utils';

@Component({
  selector: 'scope-component-row',
  imports: [CommonModule, SharedModule, ScopeUiTableComponent, NgxMaskDirective],
  templateUrl: './component-row.component.html',
  styleUrls: ['./component-row.component.scss'],
  standalone: true,
})
export class ComponentRowComponent {
  @Input() component!: ScopeComponent;

  @Input() isTimeline: boolean = false;

  @Input() deliverable!: Deliverable;

  @Input() currentUser!: User;

  @Input() userColumns!: any;

  @Input() componentMainColumnClass!: string

  @Input() scopeVersion!: ScopeVersion;

  @Input() componentFeeColumns!: Preference[];

  @Input() feeMenuOptions!: MenuOptions[]

  @Input() level: number

  @Input() componentSelectedStates: { [id: number]: boolean }

  @Input() departmentSelectedStates: { [id: number]: boolean }

  @Output() onUpdateComponent!: EventEmitter<ScopeComponent>;

  @Output() onAddComponentFee!: EventEmitter<ScopeComponent>;

  @Output() onUpdateFeeItem!: EventEmitter<{ feeItem: FeeItemInstance, deliverable?: Deliverable, component?: ScopeComponent }>

  @Output() onOverrideQuantity!: EventEmitter<{ deliverable: Deliverable, component: ScopeComponent }>

  @Output() onOverrideRateCardRoleHours!: EventEmitter<{ deliverable: Deliverable, component: ScopeComponent, role: Role }>

  constructor(public scopeApprovalFlowService: ApprovalFlowService, public scopeTabService: ScopeTabService) {
    this.onUpdateComponent = new EventEmitter<ScopeComponent>()
    this.onAddComponentFee = new EventEmitter<ScopeComponent>()
    this.onUpdateFeeItem = new EventEmitter<{ feeItem: FeeItemInstance, deliverable?: Deliverable, component?: ScopeComponent }>()
    this.onOverrideQuantity = new EventEmitter<{ deliverable: Deliverable, component: ScopeComponent }>()
    this.onOverrideRateCardRoleHours = new EventEmitter<{ deliverable: Deliverable, component: ScopeComponent, role: Role }>()
  }

  addComponentFee(component: ScopeComponent) {
    this.onAddComponentFee.emit(component);
  }

  selectComponent(componentId: number) {
    this.scopeTabService.toggleComponent(componentId)
  }

  selectDepartment(departmentId: number) {
    this.scopeTabService.toggleDepartment(departmentId)
  }

  saveComponentDescription(component: ScopeComponent, description: string) {
    this.onUpdateComponent.emit(plainToInstance(ScopeComponent, { ...component, description }));
  }

  saveComponentInternalNote(component: ScopeComponent, internalNote: string) {
    this.onUpdateComponent.emit(plainToInstance(ScopeComponent, { ...component, internalNote }));
  }

  showToggleMenu() {
    if (this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion)) return true
    if (this.deliverable.isPendingTrade() && this.scopeVersion.findDeliverableById(this.deliverable.tradedFrom.id).tradesCount > 1) return true
    if (this.deliverable.deliveryStatus == 'IN_PROGRESS' && this.scopeVersion.isStateApproved() &&
        this.scopeApprovalFlowService.stateChecks.deliverableStatus.isApprover &&
        this.scopeApprovalFlowService.stateChecks.deliverableStatus.isStateApproved) {
      return this.currentUser.hasPrivilege('SCOPE_DELIVERABLE__COMPLETE') ||
        (this.currentUser.company.hasApplicationSetting('SCOPE__DELIVERABLE__TRADING') && this.currentUser.hasPrivilege('SCOPE_DELIVERABLE__TRADE'))
    }
    if (this.deliverable.isBeingTraded()) {
      return this.currentUser.company.hasApplicationSetting('SCOPE__DELIVERABLE__TRADING') && this.currentUser.hasPrivilege('SCOPE_DELIVERABLE__TRADE')
    }
    if (this.deliverable.deliveryStatus == 'ON_HOLD' &&
      this.scopeApprovalFlowService.stateChecks.deliverableStatus.isStateApproved &&
      this.scopeApprovalFlowService.stateChecks.deliverableStatus.isApprover) return this.currentUser.hasPrivilege('SCOPE_DELIVERABLE__STOP')
    return false
  }

  updateFeeItem(feeItem: FeeItemInstance, deliverable?: Deliverable, component?: ScopeComponent) {
    this.onUpdateFeeItem.emit({ feeItem, deliverable, component })
  }

  overrideQuantity(deliverable: Deliverable, component: ScopeComponent) {
    return () => this.onOverrideQuantity.emit({ deliverable, component })
  }

  overrideRateCardRoleHours(deliverable: Deliverable, component: ScopeComponent, role: Role) {
    return () => this.onOverrideRateCardRoleHours.emit({ deliverable, component, role })
  }

  protected readonly trackById = trackById;
}
