import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { ScopingRoutingModule } from './scoping-routing.module';
import { MyScopesComponent } from './components/myscopes/myscopes.component';
import { ScopingNgrxModule } from './scoping-ngrx.module';
import { ScopeUiTableComponent } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { ScopeUiFilterCardComponent } from '@app/shared/components/ui-components/scope-ui-filter-card/scope-ui-filter-card.component';
import { ScopeUiTabsComponent } from '@app/shared/components/ui-components/scope-ui-tabs/scope-ui-tabs.component';
import { ScopeUiTabComponent } from '@app/shared/components/ui-components/scope-ui-tab/scope-ui-tab.component';
import { ScopeUiInputComponent } from '@app/shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { MatDividerModule } from "@angular/material/divider";
import { ScopeUiOptionsMenuComponent } from '@app/shared/components/scope-ui-options-menu/scope-ui-options-menu.component';
import { CreateTemplateComponent } from './components/create-template/create-template.component';
import { CreateFolderComponent } from './components/create-folder/create-folder.component';
import { FolderOverviewComponent } from "@app/features/scoping/components/folder-overview/folder-overview.component";
import { DuplicateFolderComponent } from '@app/features/scoping/components/duplicate-folder/duplicate-folder.component';
import { ScopeByRoleOverviewComponent } from './components/scope-by-role-overview/scope-by-role-overview.component'
import {
  ScopeUiOptionsMenuSeparatedComponent
} from '@shared/components/scope-ui-options-separated-menu/scope-ui-options-separated-menu.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ScopingRoutingModule,
    ScopingNgrxModule,
    ScopeUiTableComponent,
    ScopeUiTabComponent,
    ScopeUiTabsComponent,
    ScopeUiFilterCardComponent,
    ScopeUiOptionsMenuComponent,
    ScopeUiOptionsMenuSeparatedComponent,
    MatDividerModule,
    ScopeUiInputComponent,
  ],
  declarations: [MyScopesComponent, CreateTemplateComponent, CreateFolderComponent, FolderOverviewComponent, DuplicateFolderComponent, ScopeByRoleOverviewComponent],
  providers: [],
  exports: [RouterModule, MyScopesComponent],
})
export class ScopingModule {}
