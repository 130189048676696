import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { SharedModule } from '@shared/shared.module'
import { ScopeUiAutocompleteComponent } from '@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import * as pdfjsLib from 'pdfjs-dist'
import 'pdfjs-dist/build/pdf.worker.entry'
import { forkJoin } from "rxjs";
import { MatProgressBarModule } from "@angular/material/progress-bar";

@Component({
  selector: 'scope-preview-output',
  templateUrl: './preview-output.component.html',
  styleUrls: ['./preview-output.component.scss'],
  imports: [SharedModule, ScopeUiAutocompleteComponent, MatProgressBarModule],
  standalone: true,
})
export class PreviewOutputComponent {
  @Input() loading = true
  @Input() errorState = false
  @Input() set documentPreview(response: any) {
    this.loading = false
    const pdfData = atob(response.data)
    this.documentName = response.name
    this.downloadFileName = response.filename
    const loadingTask = pdfjsLib.getDocument({data: pdfData})
    loadingTask.promise.then((pdf) => {
      this.displayPdf(pdf)
    })
  }

  @Input() outputTemplateName: string
  @Input() templateLanguage: string
  @Output() onDownloadExport = new EventEmitter<string>()

  @ViewChild('mainCanvas') mainCanvasRef: ElementRef
  @ViewChild('leftCanvas') leftCanvasRef: ElementRef
  @ViewChild('rightCanvas') rightCanvasRef: ElementRef

  pageCount = 0
  progress = {
    count: 0
  }
  pdfDoc = null
  pageRendering = false
  pageNumPending = null
  pageNum = 1
  documentName: string
  downloadFileName: string

  constructor() {
  }

  // TODO - use a socket to monitor progress (separate ticket #TBA)
  timer = setInterval(() => {
    this.progress.count += 10
    if (this.progress.count === 100) {
      clearTimeout(this.timer)
    }
  }, 500)

  displayPdf = (pdf) => {
    this.pdfDoc = pdf
    this.pageCount = this.pdfDoc.numPages

    // Initial/first page rendering
    this.renderPage(this.pageNum)
  }

  renderPage = (num) => {
    this.pageRendering = true
    const mainCanvas: HTMLCanvasElement = this.mainCanvasRef.nativeElement
    const leftCanvas: HTMLCanvasElement = this.leftCanvasRef.nativeElement
    const rightCanvas: HTMLCanvasElement = this.rightCanvasRef.nativeElement
    if (!mainCanvas) {
      return;
    }
    const mainContext = mainCanvas.getContext('2d')
    const leftContext = leftCanvas.getContext('2d')
    const rightContext = rightCanvas.getContext('2d')
    let promises = []

    promises.push(this.pdfDoc.getPage(num))
    if (num > 1) {
      promises.push(this.pdfDoc.getPage(num - 1))
    } else {
      leftContext.clearRect(0, 0, leftCanvas.width, leftCanvas.height)
    }
    if (num < this.pageCount) {
      promises.push(this.pdfDoc.getPage(num + 1))
    } else {
      rightContext.clearRect(0, 0, rightCanvas.width, rightCanvas.height)
    }

    // Fetch the first page
    forkJoin(promises).subscribe((result) => {
      let pages = Object.values(result)
      const scale = 1.5
      const mainPage = pages[0]
      let viewport = mainPage.getViewport({scale: scale})

      // Prepare canvas using PDF page dimensions
      mainCanvas.height = viewport.height
      mainCanvas.width = viewport.width

      // Render PDF page into canvas context
      let renderContext = {
        canvasContext: mainContext,
        viewport: viewport
      }

      promises = [mainPage.render(renderContext).promise]

      if (num > 1) {
        const leftPage = pages[1]
        viewport = leftPage.getViewport({scale: scale})

        // Prepare canvas using PDF page dimensions
        leftCanvas.height = viewport.height
        leftCanvas.width = viewport.width

        // Render PDF page into canvas context
        renderContext = {
          canvasContext: leftContext,
          viewport: viewport
        }
        promises.push(leftPage.render(renderContext).promise)
      }

      if (num < this.pageCount) {
        const rightPage = pages[pages.length - 1]
        viewport = rightPage.getViewport({scale: scale})

        // Prepare canvas using PDF page dimensions
        rightCanvas.height = viewport.height
        rightCanvas.width = viewport.width

        // Render PDF page into canvas context
        renderContext = {
          canvasContext: rightContext,
          viewport: viewport
        }
        promises.push(rightPage.render(renderContext).promise)
      }

      forkJoin(promises).subscribe(() => {
        this.pageRendering = false
        if (this.pageNumPending !== null) {
          // New page rendering is pending
          this.renderPage(this.pageNumPending)
          this.pageNumPending = null
        }
      })
    })
  }

  queueRenderPage = (num) => {
    if (this.pageRendering) {
      this.pageNumPending = num
    } else {
      this.renderPage(num)
    }
  }

  onPrevPage = () => {
    if (this.pageNum <= 1) {
      return
    }
    this.pageNum--
    this.queueRenderPage(this.pageNum)
  }

  onNextPage = () => {
    if (this.pageNum >= this.pageCount) {
      return
    }
    this.pageNum++
    this.queueRenderPage(this.pageNum)
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (!this.loading && e.keyCode === 39) {
      this.onNextPage()
    } else if (!this.loading && e.keyCode === 37) {
      this.onPrevPage()
    }
  }

  download = () => {
    if (!this.loading) {
      this.onDownloadExport.emit(this.downloadFileName)
    }
  }
}
