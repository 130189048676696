import { DestroyRef, inject } from '@angular/core';
import { User } from '@app/core/model/user.model';
import { Subject, takeUntil } from 'rxjs';
import { MenuOptions } from "@core/model/definitions/menu-options.interface";
import { Deliverable } from '@app/features/scoping/models/deliverable.model'

export function isArrayOfStrings(value: any): boolean {
  return Array.isArray(value) && value.every((item) => typeof item === 'string');
}

export function enumToString(value: any, enumClass: any) {
  if (typeof value == 'string') {
    return value;
  }
  return enumClass[value];
}

export function untilDestroyed() {
  const subject = new Subject();

  inject(DestroyRef).onDestroy(() => {
    subject.next(true);
    subject.complete();
  });

  return <T>() => takeUntil<T>(subject.asObservable());
}

export function privilegeFilter(user: User, array: MenuOptions[]) {
  return array.filter(function (item, index, arr) {
    if (item.hasPrivilege){
      return user.hasPrivilege(item.hasPrivilege);
    }

    if (item.hasFeature){
      return user.company.scopePlan.hasFeature(item.hasFeature);
    }

    return true
  });
}

export function authorisedAsChildCompany() {
  return localStorage.getItem("globalParentChildAccountSwitcher-authorisedAsChildCompany");
}

export function setAuthorisedAsChildCompany(id: number | null) {
  id === null ?
    localStorage.removeItem("globalParentChildAccountSwitcher-authorisedAsChildCompany") :
    localStorage.setItem("globalParentChildAccountSwitcher-authorisedAsChildCompany", id?.toString())
}

export function isValidEmail(email) {
  var EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REGEXP.test(email)
}

export function formatMonetaryValue(value: any, defaultValue?: string): string {
  if (!value) {
    return defaultValue || ' ';
  }

  if (typeof value === 'string') {
    return String(value);
  }

  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: value.currency }).format(
    value.amount
  )
}

function parseHours(hours){
  if (hours == null) return null;

  let floor = Math.floor(hours);
  let remaining = hours - floor;
  let format = {
    hours: parseInt(String(floor)),
    minutes: null
  }
  if (remaining == 0){
    format.minutes = null;
  }
  else if (remaining <= 0.25){
    format.minutes = 15;
  }
  else if (remaining <= 0.5){
    format.minutes = 30;
  }
  else if (remaining <= 0.75){
    format.minutes = 45;
  }

  return format;
}

export function formatHours(hours, appendText="") {
  let format = parseHours(hours)
  if (format.minutes == null){
    return `${format.hours}${appendText}`
  }
  return `${format.hours}:${format.minutes}${appendText}`
}

export function formatDisplayHoursToMinutes(val){
  let hours = val
  let minutes = "0"
  if (val.includes(":")){
    let reg = new RegExp("(.*):(.*)").exec(val)
    hours = reg[1]
    minutes = reg[2]
  }
  return parseInt(hours)*60 + parseInt(minutes)
}

export function isNonTradedDeliverable(deliverable: Deliverable) {
  return deliverable.deliveryStatus !== 'TRADED' && deliverable.deliveryStatus !== 'TRADING_FROM_IN_PROGRESS';
}

export function displayCurrencyValueNearestThousand(value: number) {
  if (value) {
    var result = value.toFixed(2);
    if (result.length > 0) {
      var majors = result.substr(0, result.length - 3);
      result = majors.substr(0, majors.length - 3);
      var isThousands = result.length > 0;
      if (!isThousands) {
        result = majors;
      }
    }
    result = result + (isThousands? 'k' : '');
    return result;
  }
  return '--'
}

export function getCurrencySymbol(currency: string) {
  if (!currency) {
    return null
  }
  return (0).toLocaleString(
    'en-GB',
    {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  ).replace(/\d/g, '').trim()
}

export function trackById(index: number, item: any) {
  return item.id;
}
