import { Component, Input } from '@angular/core'
import { SafePipe } from "@app/shared/pipe/safe.pipe";
import { HeaderStyleService } from "@app/shared/services/header-style.service";
import { EnvService } from "@envs/env-service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-duplicate-folder',
  templateUrl: './duplicate-folder.component.html',
  styleUrls: ['./duplicate-folder.component.scss'],
  providers: [ SafePipe ],
})
export class DuplicateFolderComponent {
  @Input() id!: number;
  constructor(private headerStyleService: HeaderStyleService, private envService: EnvService, private router: Router) {
    window.addEventListener('message', event => {
      if (event.data.action === 'goToFolder') {
        this.router.navigateByUrl('/scopes/folder-overview/' + event.data.id)
      }
    })
  }

  public get baseJsUrl(): string {
    return `${this.envService.baseJsUrl}/duplicate-sow/${this.id}`;
  }
}
