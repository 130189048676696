import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { Observable, map, startWith, debounceTime, filter } from 'rxjs'
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete'
import { MatSelectModule } from '@angular/material/select'
import { untilDestroyed } from '@app/shared/utils/utils'
import { SharedModule } from '@shared/shared.module'

@Component({
  selector: 'scope-ui-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    SharedModule,
  ],
  templateUrl: './scope-ui-autocomplete.component.html',
  styleUrls: ['./scope-ui-autocomplete.component.scss'],
})
export class ScopeUiAutocompleteComponent implements OnInit, OnChanges {
  private readonly destroy$

  @Input() label!: string

  _options!: any[]

  @Input() set options(value: any[]) {
    this._options = value
  }

  @Input() filterByAction?: boolean

  @Input() multiselect?: boolean

  @Input() componentId!: string

  @Input() hasIcon = false

  @Input() optionClass?: string

  @Input() iconName!: string

  @Input() placeholder?: string

  @Input() markerProperty?: string;

  @Input() markerText: string;

  @Input() set initialValue(value: any) {
    this.selectedValue = value
    this.scopeUiControl.setValue(value)
    this.cdr.detectChanges()
  }

  @Input() optionFn!: (value: any) => string

  @Input() displayFn!: (value: any) => string

  @Input() noResultsMessage?: string = 'No results found'

  @Input() set disabled(value: boolean) {
    if (value) {
      this.scopeUiControl.disable()
    } else {
      this.scopeUiControl.enable()
    }
  }

  @Input() required = false

  @Input() enableDefaultOption = false

  @Input() hideToggleIcon = false

  @Input() showBreadcrumbs = false

  @Output() onSearch!: EventEmitter<{ searchString: string; event?: MatAutocompleteSelectedEvent }>

  @Output() onSelectDefaultOption!: EventEmitter<string>

  @Output() onSelectionChange!: EventEmitter<{ event: any; componentId?: any }>

  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete

  selectedValue: any

  scopeUiControl = new FormControl<string | any>('')

  filteredOptions!: Observable<any[]>

  searchText: string

  constructor(private cdr: ChangeDetectorRef) {
    this.onSearch = new EventEmitter<any>()
    this.onSelectDefaultOption = new EventEmitter<string>()
    this.onSelectionChange = new EventEmitter<any>()
    this.destroy$ = untilDestroyed()
  }

  ngOnInit() {
    if (!this.displayFn) {
      this.displayFn = (item: any) => {
        return item && item.name ? item.name : ''
      }
    }
    this.cdr.detectChanges()
    if (this.filterByAction) {
      this.autoCompleteByAction()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.filterByAction) {
      this.autoComplete()
    }
  }

  resetAutoComplete() {
    this.scopeUiControl.reset()
  }

  autoComplete() {
    this.filteredOptions = this.scopeUiControl.valueChanges.pipe(
      startWith(''),
      filter((value) => typeof value === 'string'),
      map((value) => {
        this.searchText = value
        this.onSearch.emit({ searchString: this.searchText })
        let options = this.searchText ? this._filter(this.searchText as string) : this._options?.slice()
        if (options?.length === 0) {
          options = [{ id: -1, displayName: 'No Results' }];
        }
        return options
      })
    )
  }

  autoCompleteByAction() {
    this.scopeUiControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        filter((value) => typeof value === 'string'),
        map((value) => {
          this.searchText = value
          this.onSearch.emit({ searchString: this.searchText })
        })
      )
      .pipe(this.destroy$())
      .subscribe()
  }

  onSelection(event: MatAutocompleteSelectedEvent) {
    this.selectedValue = event.option.value
    if (this.selectedValue.id === -1) {
      this.onSelectDefaultOption.emit(this.searchText)
      this.scopeUiControl.setValue(this.searchText)
      return
    }
    this.onSelectionChange.emit({ event: this.selectedValue, componentId: this.componentId })
    if (this.filterByAction) {
      this.onSearch.emit({ searchString: '', event })
    }
    if (this.multiselect) {
      this.scopeUiControl.setValue('')
    }
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase()
    return this._options?.filter((option) => {
      let value = this.optionFn ? this.optionFn(option) : option.name
      return value.toLowerCase().includes(filterValue)
    })
  }

  isMarkerPropertyAvailable(option: any): boolean {
    if (!this.markerProperty) {
      return false;
    }
    if (typeof option[this.markerProperty] === 'function') {
      return option[this.markerProperty]();
    } else {
      return option[this.markerProperty];
    }
  }

  getOptionView(option: any) {
    if (option.id == -1) {
      return `<span class="autocomplete-option">${this.noResultsMessage}</span>`
    }
    if (option.getHtml) {
      return option.getHtml(this.searchText)
    } else {
      let value = this.optionFn ? this.optionFn(option) : option.name
      if (this.searchText) {
        let search = new RegExp(this.searchText, 'gi')
        value = value.replace(search, '<b>$&</b>')
      }

      let marker = this.markerProperty && this.isMarkerPropertyAvailable(option) ?
        `<div class="option-marker"><span>${this.markerText}</span></div>` : '';

      if (this.showBreadcrumbs) {
        return `<div class="option-container">
                  <span class="autocomplete-option">${value}</span>
                  <span class='autocomplete-breadcrumb'>${option.getBreadcrumb?.() || ''}</span>
                </div>`
      }
      if (marker) {
        return `<span class="autocomplete-option">${value}</span>
              ${marker}`;
      } else {
        return `<span class="autocomplete-option">${value}</span>`;
      }
    }
  }

  clear($event?: MouseEvent) {
    $event.stopPropagation()
    this.selectedValue = null
    this.scopeUiControl.setValue('')
    if (!this.multiselect) {
      this.onSelectionChange.emit({ event: undefined, componentId: this.componentId })
    }
  }

  toggle($event: MouseEvent) {
    if (this.matAutocomplete.isOpen) {
      $event.preventDefault()
      $event.stopPropagation()
      this.matAutocompleteTrigger.closePanel()
    }
  }
}
