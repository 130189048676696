import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userToChipArray'
})
export class UserToChipArrayPipe implements PipeTransform {
  transform(value: string): any[] {
    const regex = /<user id="(\d+)">(.+?)<\/user>/g;
    let lastEnd = 0;
    const result = [];

    let match;
    while ((match = regex.exec(value)) !== null) {
      if (lastEnd !== match.index) {
        result.push({ type: 'text', value: value.slice(lastEnd, match.index) });
      }
      result.push({ type: 'user', id: match[1], name: match[2] });
      lastEnd = match.index + match[0].length;
    }

    if (lastEnd < value?.length) {
      result.push({ type: 'text', value: value.slice(lastEnd) });
    }

    return result;
  }
}
