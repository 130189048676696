import { NgModule } from '@angular/core';
import { HasPrivilegeDirective } from './hasprivilege.directive';
import { AnchorBottomDirective } from './anchorBottom.directive';
import { TTLClassDirective } from './tmpclass.directive';
import { FallBackImageDirective } from './fallbackImage.directive';
import { HasSettingDirective } from "@shared/directives/hasSetting.directive";
import { IsEllipsisActiveDirective } from '@shared/directives/isEllipsisActive.directive'
import { ScrollSiblingsDirective } from '@shared/directives/scrollSiblings.directive';

@NgModule({
  declarations: [TTLClassDirective, HasPrivilegeDirective, FallBackImageDirective, HasSettingDirective, AnchorBottomDirective, ScrollSiblingsDirective, IsEllipsisActiveDirective],
  exports: [TTLClassDirective, HasPrivilegeDirective, FallBackImageDirective, HasSettingDirective, AnchorBottomDirective, ScrollSiblingsDirective, IsEllipsisActiveDirective],
})
export class DirectivesModule {}
