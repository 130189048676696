import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dummy-component-level1',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dummy-component-level1.component.html',
  styleUrls: ['./dummy-component-level1.component.scss']
})
export class DummyComponentLevel1Component {

}
