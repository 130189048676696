import { NgModule } from '@angular/core';
import { LangPipe } from './lang.pipe';
import { LangfPipe } from './langf.pipe';
import { UrlStaticMediaPipe } from './url-staticmedia.pipe';
import { SafePipe } from './safe.pipe';
import { DateFormatPipe } from './dateFormat.pipe';
import { GetMenuItemNamePipe } from './menuItemName.pipe';
import { OrderByPipe } from "@shared/pipe/orderBy.pipe";
import { UserToChipArrayPipe } from './user-to-chip.pipe';
import { CommentTagPipe } from './comment-tag.pipe';
import { ReviewStatusClassPipe } from './review-status-cell.pipe';
import { FormatHoursPipe } from '@shared/pipe/formatHours.pipe'

@NgModule({
  declarations: [UrlStaticMediaPipe, LangPipe, LangfPipe, SafePipe, DateFormatPipe, GetMenuItemNamePipe, OrderByPipe, UserToChipArrayPipe, CommentTagPipe, ReviewStatusClassPipe, FormatHoursPipe],
  exports: [UrlStaticMediaPipe, LangPipe, LangfPipe, SafePipe, DateFormatPipe, GetMenuItemNamePipe, OrderByPipe, UserToChipArrayPipe, CommentTagPipe, ReviewStatusClassPipe, FormatHoursPipe],
})
export class PipesModule {}
