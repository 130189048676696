import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreNgrxStateModule } from '@core/core-ngrx.module';
import { MaterialModule } from '@plugins/material/material.module';
import { DirectivesModule } from '@shared/directives/index.module';
import { PipesModule } from '@shared/pipe/index.module';
import { AgencySiteHeaderContentComponent } from './components/agency-site-header/agency-site-header.component';
import { ClientSiteHeaderContentComponent } from './components/client-site-header/client-site-header.component';
import { LegalSiteHeaderContentComponent } from './components/legal-site-header/legal-site-header.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { NotificationStatusComponent } from './components/notification-status/notification-status.component';
import { SiteHeaderComponent } from './site-header.component';
import { SiteHeaderRoutingModule } from './site-header-routing.module';
import { ParentChildSwitcherComponent } from './components/parent-child-switcher/parent-child-switcher.component';
import { NotificationSidebarComponent } from '@shared/components/notification-sidebar/notification-sidebar.component';

@NgModule({
  declarations: [
    SiteHeaderComponent,
    MainNavComponent,
    NotificationStatusComponent,
    AgencySiteHeaderContentComponent,
    LegalSiteHeaderContentComponent,
    ClientSiteHeaderContentComponent,
    ParentChildSwitcherComponent,
  ],
  imports: [
    CommonModule,
    CoreNgrxStateModule,
    DirectivesModule,
    PipesModule,
    RouterModule,
    SiteHeaderRoutingModule,
    MaterialModule,
    NotificationSidebarComponent,
  ],
  exports: [SiteHeaderComponent],
})
export class SiteHeaderComponentModule {}
