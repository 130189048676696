<div class="track-trade-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{ data.title }}</h1>
      </div>

      <div class="mt-2">
        <span>
          Trading {{'deliverable' | lang }} "{{data.deliverable.name}}"{{data.currentScope.budget ? ' with budget of ' + formatMonetaryValue(data.deliverable.budget, '--') : ''}} for the following:
        </span>
      </div>

      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Generic modal" class="track-trade-content">
    <form [formGroup]="trackTradeFormGroup" class="track-trade-modal-content">
      <!-- New Deliverable Details Section -->
      <div class="deliverable-details">
        <h2>New {{ 'deliverable' | lang }} Details</h2>

        <div class="form-control">
          <mat-form-field>
            <label>{{ 'deliverable' | lang }} Name</label>
            <input matInput formControlName="name" />
          </mat-form-field>
          <mat-error
            *ngIf="
              (trackTradeFormGroup.controls['name'].touched || formInvalid) &&
              trackTradeFormGroup.controls['name'].invalid
            ">
            Please enter a {{ 'deliverable' | lang }} name
          </mat-error>
        </div>

        <div class="form-control">
          <scope-ui-autocomplete
            formControlName="deliverableType"
            ngDefaultControl
            [options]="data.deliverables$ | async"
            [filterByAction]="true"
            [placeholder]="'Deliverable Type'"
            [initialValue]="deliverableType"
            [optionClass]="'deliverable-type'"
            [hasIcon]="true"
            [iconName]="'search'"
            (onSearch)="onSearch($event)"
            (onSelectionChange)="onSelectionChange($event)" />
          <mat-error
            *ngIf="trackTradeFormGroup.controls['deliverableType'].touched && trackTradeFormGroup.controls['deliverableType'].invalid">
            Please select a {{ 'deliverable' | lang }} to trade
          </mat-error>
        </div>

        <ng-container *ngIf="data.currentScope.identity.secondParty.trafficImported && data.currentScope.identity.rateCard.rateCardIdentity.trafficImported">
          <div class="flex-row pt-6" *ngFor="let field of trafficFields">
            <ng-container *ngIf="!field.url">
              <scope-ui-input class="resize-none"
                              [hasPlaceholder]="true"
                              [inputPlaceholder]="field.name"
                              [inputLabel]="field.name"
                              [initialValue]="trafficSystemEntityMetadata[field.uuid]"
                              (onInputChange)="updateTrafficSystemMetadataField(field.uuid, $event)" />
            </ng-container>
            <ng-container *ngIf="field.url">
              <scope-ui-autocomplete
                [options]="(traffickingService.trafficSystemEntityMetadataSearch$[field.uuid] | async)!"
                [label]="field.name"
                [filterByAction]="true"
                [optionFn]="traffickingService.trafficFieldOption"
                [initialValue]="trafficSystemEntityMetadata[field.uuid]"
                (onSearch)="traffickingService.onSearchTrafficField($event, field.uuid, data.currentScope.identity.id)"
                (onSelectionChange)="selectTrafficField($event, field.uuid)"/>
            </ng-container>
          </div>
        </ng-container>

        <mat-form-field *ngIf="data.currentScope.budget">
          <mat-label>Budget</mat-label>
          <input name="budget" mask="separator.2" thousandSeparator="," prefix="{{symbol}}" matInput formControlName="budget" />
        </mat-form-field>

        <div *ngIf="data.currentScope.budget && hasSlider" class="range-slider">
          <ngx-slider [(value)]="budgetValue"
                      [options]="{ceil: maxComplexityPrice, ticksArray: complexityPrices, hideLimitLabels: true, showTicks: true, translate: translate,
                        getLegend: getSliderLegend, customValueToPosition: customValueToPosition, customPositionToValue: customPositionToValue}"
                      (userChange)="onSliderChange()">
          </ngx-slider>
        </div>

        <div class="form-group w-full pb-4 pt-3">
          <span class='font-newJuneBold cursor-pointer flex items-center' [hidden]='editDescription' (click)='editDescription = true'>
            <mat-icon class="material-symbols-rounded orange">edit_note</mat-icon>
            <span class='tracking-normal'>{{trackTradeFormGroup.get('description')?.value == '' ? 'Add Description' : 'Edit Description'}}</span>
          </span>
          <ckeditor [hidden]="!editDescription" [editor]="Editor" formControlName="description"></ckeditor>
          <div class="pt-4" [hidden]='!editDescription' style='display: flex; justify-content: flex-end;'>
            <button mat-button (click)="editDescription = false">
              <span class='tracking-normal'>Cancel</span>
            </button>
            <button class="confirm ml-8" (click)="editDescription = false">
              <span class='tracking-normal'>Save</span>
            </button>
          </div>
        </div>

        <div class="flex flex-row gap-4 mt-4 items-baseline" [has-setting]="'SCOPE__DELIVERABLE__STARTEND_DATE'">
          <scope-ui-datepicker
            class="flex-1"
            [singleDatePicker]="true"
            [inputLabel]="'Start Date'"
            [initialValue]="selection.startDate"
            (onSelect)="onStartDateChange($event)">
          </scope-ui-datepicker>
          <scope-ui-datepicker
            class="flex-1"
            [singleDatePicker]="true"
            [inputLabel]="'End Date'"
            [initialValue]="selection.endDate"
            [minDate]="selection.startDate"
            (onSelect)="onEndDateChange($event)">
          </scope-ui-datepicker>

          <button (click)="onResetDatePickers()" class="reset-datepicker">
            <span>Reset Dates</span>
          </button>
        </div>

        <div class="mt-4">
          <button mat-flat-button class='right' [disabled]="!trackTradeFormGroup.valid || !deliverableType" type="submit" (click)="submitTradeForm()">
            <mat-icon class="material-symbols-rounded" *ngIf='!isEditing'>add</mat-icon>
            {{ isEditing ? 'Update' : 'Add' }}
          </button>

          <button *ngIf='isEditing' (click)="cancelUpdate()">
            Cancel
          </button>
        </div>
      </div>

      <!-- Selected New Deliverables Section -->
      <div class="selected-deliverables">
        <h2>Selected new {{ 'deliverable' | lang }}</h2>
        <p *ngIf="!selectedDeliverableTypes?.length">Enter deliverables details on the left then click ‘+ Add’.</p>
        <ng-container *ngFor="let selectedDeliverable of selectedDeliverableTypes; let i = index">
          <div class="selected-deliverable-box" (click)="loadDeliverable(i)">
            <div class="selected-deliverable-icon">
              <span>{{ selectedDeliverable.name }}</span>
              <i (click)="removeSelectedDeliverable(i)" class="material-symbols-rounded">delete</i>
            </div>
            <span>{{ selectedDeliverable.deliverableType?.name }}</span>
            <span>{{ selectedDeliverable.budget.amount | currency: selectedDeliverable.budget.currency }}</span>
          </div>
        </ng-container>

        <button (click)="initiateTrade()" mat-flat-button class="initate-trade-button"
          [disabled]="!(selectedDeliverableTypes.length > 0 && !isEditing)">Start Trade</button>
      </div>

    </form>
  </mat-dialog-content>
</div>
