import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScopeUiBreadcrumbsComponent } from '@app/shared/components/scope-ui-breadcrumbs/scope-ui-breadcrumbs.component';

const routes: Routes = [
  {
    path: 'test',
    data: {
      breadcrumb: '',
    },
    children: [
      {
        path: 'first-breadcrumb',
        component: ScopeUiBreadcrumbsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BreadcrumbRoutingModule {}
