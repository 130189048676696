import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FromToComponentComponent } from '../from-to-component/from-to-component.component'
import { ActivitiesService } from '@app/features/scope-overview/service/activities.service'
import {
  ActivityActionConfigItem,
  ActivityComment,
  Attributes,
  activityActionConfig, Item,
} from '@app/features/scope-overview/model/activity.model'
import { PipesModule } from '@app/shared/pipe/index.module'

@Component({
  selector: 'activity-event',
  standalone: true,
  imports: [CommonModule, FromToComponentComponent, PipesModule],
  templateUrl: './activity-event.component.html',
  styleUrls: ['./activity-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityEventComponent implements OnInit, OnChanges {
  @Input() action!: string

  @Input() attributes!: Attributes

  @Input() title!: string

  @Input() comment!: ActivityComment

  @Output() navigateTo!: EventEmitter<void>

  preparedAttributes: Attributes = {} as Attributes

  activityActionConfig: Record<string, ActivityActionConfigItem[]>

  constructor(private activitiesService: ActivitiesService) {
    this.navigateTo = new EventEmitter()
    this.activityActionConfig = activityActionConfig
    this.preparedAttributes = {} as Attributes
  }

  ngOnInit(): void {
    this.populatePreparedAttributes()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['action'] || changes['attributes']) {
      this.populatePreparedAttributes()
    }
  }

  navigateToActionSource() {
    this.navigateTo.emit()
  }

  protected populatePreparedAttributes(): void {
    if(this.preparedAttributes) {
      this.preparedAttributes = this.activitiesService.getActionAttributes(this.action, this.attributes)

    }
  }
}
