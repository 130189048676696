import { Observable } from 'rxjs'
import { Filter } from "@core/model/scope-filter.model";
import { ScopeTeamMember } from "@core/model/scope-team.model";

export interface FilterModalConfig {
  options: FilterOptions
  filterDefaults: Filter
  reset: Function
  seeResults: Function
}

export class FilterOption<T> {
  name!: string
  type!: string
  label?: string
  options?: any[]
  options$?: Observable<any[]>
  isVisible?: Function
  value?: T
  onSearch?: Function
  minimum?: number
  maximum?: number
  hideDropdownToggle?: boolean

  constructor(
    name: string,
    type: string,
    label?: string,
    options?: any[],
    options$?: Observable<any[]>,
    isVisible?: Function,
    value?: T,
    onSearch?: Function,
    minimum?: number,
    maximum?: number,
    hideDropdownToggle?: boolean
  ) {
    this.name = name
    this.type = type
    this.label = label
    this.options = options
    this.options$ = options$
    this.isVisible = isVisible
    this.value = value
    this.onSearch = onSearch
    this.minimum = minimum
    this.maximum = maximum
    this.hideDropdownToggle = hideDropdownToggle
  }

  tag() {
    if (Array.isArray(this.value)) {
      return this.value.length
    } else if (this.value instanceof ComponentsFilterValue) {
      return `${this.value?.complexity?.name || ''}${
        this.value?.complexity && this.value?.components?.length ? ' • ' : ''
      }${this.value?.components?.length || ''}`
    }
    return this.value?.toString()
  }
}

export class GenericFilterValue {
  id!: number
  name?: string

  toJSON(): Object | number {
    return this.id
  }

  toString() {
    return `${this.name}`
  }
}

export class TypedFilterValue extends GenericFilterValue {
  type?: string
  originalName?: string

  getHtml(searchText: string) {
    let search = new RegExp(searchText, 'gi');
    let result = `<span>${(this.name || this.originalName)?.replace(search, "<b>$&</b>")}</span>`;
    if (this.name != null && this.originalName != this.name) {
      result += `<br/><span><b>Original:</b> ${(this.originalName)?.replace(search, "<b>$&</b>")}</span>`;
    }
    return result;
  };

  override toJSON() {
    return { id: this.id, name: this.name, originalName: this.originalName, type: this.type };
  }

  override toString() {
    return `${this.name && this.name.length > 0 ? this.name : this.originalName}`
  }
}

export class DateFilterValue {
  action?: any
  timeframe?: any
  start?: Date
  end?: Date

  toJSON() {
    return { action: this.action?.value, start: this.start?.toISOString(), end: this.end?.toISOString() }
  }

  toString() {
    return `${this.action?.name || ''}${this.action?.name && this.timeframe?.name ? ' • ' : ''}${
      this.timeframe?.name || ''
    }`
  }
}

export class RangeFilterValue {
  maximum?: number | string = ""
  minimum?: number | string = ""

  toString() {
    return `${this.minimum != undefined && this.minimum != "" ? '>' + this.minimum : ''}${
      this.minimum != undefined && this.minimum != "" && this.maximum != undefined && this.maximum != "" ? ' • ' : ''
    }${this.maximum != undefined && this.maximum != "" ? '<' + this.maximum : ''}`
  }
}

export class ComponentsFilterValue {
  complexity?: any
  components?: TypedFilterValue[] = []

  toJSON() {
    return { complexity: this.complexity?.value, components: this.components }
  }

  toString() {
    return `${this.complexity?.name || ''}${this.complexity && this.components?.length ? ' • ' : ''}${
      this.components?.join(', ') || ''
    }`
  }
}

export type FilterOptions = Record<string, FilterOption<any>>
