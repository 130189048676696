import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers/index';
import { AuthState } from '../reducers/auth.reducer';
import { AuthError } from '@app/core/model/definitions/auth-error.interface';
import { User } from '@core/model/user.model';
import { Company } from '@core/model/company.model';
import { UserNotification } from '@core/model/user-notification.model';

export const getAuthenticationFromState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.CoreState) => state.auth
);

const getErrorMessage = (state: AuthState): AuthError => state.authenticationError;
const getAuthenticationLoading = (state: AuthState): boolean => state.loading;
const getAuthenticationLoaded = (state: AuthState): boolean => state.loaded;

const getUsername = (state: AuthState): string => state.username;

const getForgotPasswordSuccess = (state: AuthState): boolean | null => state.forgotSuccess;

const getChildCompanies = (state: AuthState): Company[] => state.childCompanies.filter(ch => ch.enabled);
const getChildCompaniesAccessRules = (state: AuthState): any[] => state.childCompaniesAccessRules;

const getUserNotifications = (state: AuthState): UserNotification[] => state.notifications;
const getUnreadUserNotificationsCount = (state: AuthState): number => state.unreadNotificationsCount;
const getUser = (state: AuthState): User => state.user;
const getLoaded = (state: AuthState): boolean => state.loaded;

const selectErrorMessage = createSelector(getAuthenticationFromState, getErrorMessage);
const selectAuthenticationLoading = createSelector(getAuthenticationFromState, getAuthenticationLoading);
const selectAuthenticationLoaded = createSelector(getAuthenticationFromState, getAuthenticationLoaded);
const selectUsername = createSelector(getAuthenticationFromState, getUsername);
const selectForgotPasswordSuccess = createSelector(getAuthenticationFromState, getForgotPasswordSuccess);
const selectChildCompanies = createSelector(getAuthenticationFromState, getChildCompanies);
const selectChildCompaniesAccessRules = createSelector(getAuthenticationFromState, getChildCompaniesAccessRules);
const selectUserNotifications = createSelector(getAuthenticationFromState, getUserNotifications);
const selectUnreadUserNotifications = createSelector(getAuthenticationFromState, getUnreadUserNotificationsCount);
const selectUser = createSelector(getAuthenticationFromState, getUser);
const selectLoaded = createSelector(getAuthenticationFromState, getLoaded);

export const AuthSelectors = {
  selectErrorMessage,
  selectAuthenticationLoading,
  selectAuthenticationLoaded,
  selectUsername,
  selectForgotPasswordSuccess,
  selectChildCompanies,
  selectChildCompaniesAccessRules,
  selectUserNotifications,
  selectUnreadUserNotifications,
  selectUser,
  selectLoaded
};
