import { ErrorHandler } from '@angular/core'

declare global {
  interface Window {
    newrelic: any;
  }
}

export class GenericErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    let newrelic = window.newrelic || {
      noticeError: () => {
      },
    }
    console.error(error);
    newrelic.noticeError(error)
  }
}
