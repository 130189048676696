import { Action, createReducer, on } from '@ngrx/store';
import { BootstrapConfig } from '@core/model/bootstrap-config.model';
import { BootstrapActions } from '../actions';
import { IdpDiscovery } from '@app/core/model/definitions/idp-discovery.interface';
import {
  IdpDiscoveryAction,
  IdpDiscoveryFailAction,
  IdpDiscoverySuccessAction,
  LoadBootstrapConfigFailAction,
  LoadBootstrapConfigSuccessAction,
  SetCompanyTypeAction,
} from '../models/bootstrap.action.interface';
import { Preference, UserPreferences } from '@core/model/user-preferences.interface';
import { scopeColumnBaseConfig } from '@app/features/scoping/models/scope-columns.model';

export interface BootstrapState {
  bootstrapConfig: BootstrapConfig | null;
  companyType: string;
  loaded: boolean;
  loading: boolean;
  idpDiscovery: IdpDiscovery;
  error: Error | null;
  sowOverviewColumns: Preference[];
  scopeOverviewColumns: Preference[];
  preferenceError: Error | null;
}

export const initialState: BootstrapState = {
  bootstrapConfig: null,
  companyType: '',
  loaded: false,
  loading: false,
  error: null,
  idpDiscovery: { name: '', idp: '', isAvailable: false },
  sowOverviewColumns: [],
  scopeOverviewColumns: [],
  preferenceError: null,
};

function loadBoostrapConfigHandler(state: BootstrapState) {
  return {
    ...state,
    loading: true,
    loaded: false,
  };
}

function loadBoostrapConfigSuccessHandler(state: BootstrapState, action: LoadBootstrapConfigSuccessAction) {
  return {
    ...state,
    bootstrapConfig: action.bsConfig,
    loading: false,
    loaded: true,
  };
}

function loadBoostrapConfigFailHandler(state: BootstrapState, action: LoadBootstrapConfigFailAction) {
  return {
    ...state,
    loading: false,
    loaded: true,
    error: action.error,
  };
}

function setSowOverviewColumnsSuccessHandler(state: BootstrapState, action: any) {
  return {
    ...state,
    sowOverviewColumns: action.preferences,
  };
}

function setSowOverviewColumnFailHandler(state: BootstrapState, action: any) {
  return {
    ...state,
    preferenceError: action.error,
  };
}

function scopeOverviewColumnsColumnsSuccessHandler(state: BootstrapState, action: any) {
  return {
    ...state,
    scopeOverviewColumns: action.preferences,
  };
}

function scopeOverviewColumnsColumnFailHandler(state: BootstrapState, action: any) {
  return {
    ...state,
    preferenceError: action.error,
  };
}

function setCompanyTypeHandler(state: BootstrapState, action: SetCompanyTypeAction) {
  return {
    ...state,
    companyType: action.companyType,
  };
}

function idpDiscovery(state: BootstrapState, action: IdpDiscoveryAction) {
  return {
    ...state,
  };
}

function idpDiscoverySuccessHandler(state: BootstrapState, action: IdpDiscoverySuccessAction): BootstrapState {
  const { idpDiscovery } = action;

  return {
    ...state,
    idpDiscovery: {
      ...state.idpDiscovery,
      name: idpDiscovery?.name || state.idpDiscovery.name,
      idp: idpDiscovery?.idp || state.idpDiscovery.idp,
      isAvailable: !!idpDiscovery?.idp || false,
    },
  };
}

function idpDiscoveryFailHandler(state: BootstrapState, action: IdpDiscoveryFailAction) {
  return {
    ...state,
    error: action.error,
  };
}

const BootstrapReducer = createReducer(
  initialState,
  on(BootstrapActions.loadBootstrapConfig, loadBoostrapConfigHandler),
  on(BootstrapActions.loadBootstrapConfigSuccess, loadBoostrapConfigSuccessHandler),
  on(BootstrapActions.loadBootstrapConfigFail, loadBoostrapConfigFailHandler),
  on(BootstrapActions.setSowOverviewColumnsSuccess, setSowOverviewColumnsSuccessHandler),
  on(BootstrapActions.setSowOverviewColumnsFail, setSowOverviewColumnFailHandler),
  on(BootstrapActions.setScopeOverviewColumnsSuccess, scopeOverviewColumnsColumnsSuccessHandler),
  on(BootstrapActions.setScopeOverviewColumnsFail, scopeOverviewColumnsColumnFailHandler),
  on(BootstrapActions.setCompanyType, setCompanyTypeHandler),
  on(BootstrapActions.idpDiscovery, idpDiscovery),
  on(BootstrapActions.idpDiscoverySuccess, idpDiscoverySuccessHandler),
  on(BootstrapActions.idpDiscoveryFail, idpDiscoveryFailHandler)
);

export function reducer(state: BootstrapState | undefined, action: Action): BootstrapState {
  return BootstrapReducer(state, action);
}
