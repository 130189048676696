import {OfficeLocation} from "@core/model/office-location";
import {Type} from "class-transformer";
import { RatecardIdentity } from "@app/features/scope-overview/model/ratecard-identity.model";
import { SecondPartyBrandRatecard } from '@app/features/scope-overview/model/second-party-brand-ratecard.model';

export class Brand {
  id!: number
  name!: string
  @Type(() => OfficeLocation) location?: OfficeLocation
  keyContact: any
  @Type(() => RatecardIdentity) rateCardIdentities!: RatecardIdentity[]
  @Type(() => SecondPartyBrandRatecard) ratecardAssociations!: SecondPartyBrandRatecard[];
}
