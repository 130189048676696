import { Type } from 'class-transformer';
import { SecondParty } from '@core/model/second-party.model';
import { User } from '@core/model/user.model';
import { Money } from '@app/features/scope-overview/model/money.model';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model';
import { ScopeVersion } from '@core/model/scope-version'
import { UserComment } from '@core/model/comment.model'
import { isNonTradedDeliverable } from '@shared/utils/utils'

export class ScopeSection {
  id!: number;
  order!: number;
  name!: string;
  @Type(() => SecondParty) secondParty!: SecondParty;
  @Type(() => Date) startDate!: Date;
  @Type(() => Date) endDate!: Date;
  @Type(() => Date) createdTs!: Date;
  @Type(() => Date) updatedTs!: Date;
  @Type(() => User) createdBy!: User;
  @Type(() => User) updatedBy!: User;
  @Type(() => Money) naturalSellingPrice!: Money;
  @Type(() => Money) totalCostPrice!: Money;
  @Type(() => Money) totalProfit!: Money;
  @Type(() => Money) totalSellingPrice!: Money;
  @Type(() => Money) unitCostPrice!: Money;
  @Type(() => Money) unitProfit!: Money;
  @Type(() => Money) unitSellingPrice!: Money;
  @Type(() => Deliverable) deliverables!: Deliverable[];
  currencyUnit!: string;
  markup!: number;
  @Type(() => FeeItemInstance) feeItems!: FeeItemInstance[];
  description!: string
  internalNote!: string
  @Type(() => UserComment) comments!: UserComment[]

  getNaturalSellingPrice(scope: ScopeVersion) {
    let total = new Money(0, this.currencyUnit);
    let deliverables = this.getDeliverables(scope)
    deliverables?.filter(isNonTradedDeliverable).forEach((d) => {
      total = total.add(d.getTotalSellingPrice());
    });
    return total;
  }

  getFeePrice(fee: FeeItemInstance, amount: number): number {
    if (fee.feeItem.amountType === 'MONETARY') {
      return parseFloat(fee.feeItem.amount.toFixed(2));
    } else {
      return parseFloat(((amount * fee.feeItem.amount) / 100).toFixed(2));
    }
  }

  getTotalFees(scope: ScopeVersion, amount: number | null = null) {
    let currentTotal = 0;
    let fees = this.feeItems;
    if (fees != null && fees.length !== 0) {
      if (amount == null && this.feeItems.find((fee) => fee.feeItem.amountType !== 'MONETARY')) {
        amount = this.getNaturalSellingPrice(scope).amount
      }
      for (let i = 0; i < this.feeItems.length; i++) {
        currentTotal += Number(this.getFeePrice(this.feeItems[i], amount))
      }
    }
    return parseFloat(currentTotal.toFixed(2));
  }

  getTotalSellingPrice(scope: ScopeVersion) {
    const naturalSellingPrice = this.getNaturalSellingPrice(scope);
    return naturalSellingPrice.addValue(this.getTotalFees(scope));
  }

  getAgencyHours(scope: ScopeVersion) {
    let agencyHours = 0;
    let deliverables = this.getDeliverables(scope)
    if (deliverables?.length > 0) {
      agencyHours = deliverables.map((d) => d.rateCardTotalMinutes).reduce((o, n) => o + n, 0) / 60;
    }
    return agencyHours;
  }

  scopemarkHours(scope: ScopeVersion) {
    var scopemarkHours = 0;
    let deliverables = this.getDeliverables(scope)
    if (deliverables.length > 0){
      scopemarkHours = deliverables.map(d => d.scopeMarkTotalHours).reduce((o, n) => o +n, 0);
    }
    return scopemarkHours;
  }

  getScopemarkPrice(scope: ScopeVersion) {
    let currency = scope.getRateCardCurrency()
    let deliverables = this.getDeliverables(scope)
    if (deliverables.length > 0){
      let deliverableValues = deliverables.map(d => d.scopeMarkTotalSellingPrice?.amount).reduce((o, n) => o +n, 0);
      deliverableValues += this.getTotalFees(scope);
      return new Money(deliverableValues, currency);
    }
    return new Money(0, currency)
  }

  getDeliverables = (scope: ScopeVersion) => {
    return this.deliverables || scope?.deliverables?.filter((d) => d.section?.id === this.id)
  }
}
