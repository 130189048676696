import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { environment } from '@envs/environment';
import { map } from 'rxjs';
import { UserNotifications } from '@core/model/user-notifications.model';
import { UserPreferences } from '@core/model/user-preferences.interface';
import { plainToInstance } from 'class-transformer';
import { Company } from '@core/model/company.model';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly BASE_URL: string = environment.scopeApiUrl + 'user';

  constructor(private http: HttpClient) { }

  getUserPreferences(preference: string): Observable<UserPreferences> {
    return this.http
      .get<UserPreferences>(environment.scopeApiUrl + `user/preferences/${preference}`)
      .pipe(map((res) => res));
  }

  setUserPreferences(preference: string, updatedPreferences: UserPreferences): Observable<UserPreferences> {
    return this.http
      .put<UserPreferences>(environment.scopeApiUrl + `user/preferences/${preference}`, updatedPreferences)
      .pipe(map((res) => res));
  }

  getUserNotifications(size: number, page: number): Observable<UserNotifications> {
    return this.http
      .get<UserNotifications>(this.BASE_URL + '/account/notifications', { params: { size: size, page: page } })
      .pipe(map((res) => plainToInstance(UserNotifications, res)));
  }

  markNotificationAsRead(notificationId: string | number): Observable<any> {
    return this.http
      .post<UserNotifications>(this.BASE_URL + '/account/notifications/' + notificationId + '/read', null);
  }

  markAllNotificationsAsRead(): Observable<any> {
    return this.http
      .post<UserNotifications>(this.BASE_URL + '/account/notifications/read-all', null);
  }

  getChildCompanies(): Observable<Company[]> {
    return this.http
      .get<Company[]>(environment.scopeApiUrl + 'company/child-companies')
      .pipe(map((res) => plainToInstance(Company, res)));
  }

  getUserChildCompanyAccessRules(): Observable<any[]> {
    return this.http
      .get<any[]>(environment.scopeApiUrl + 'company/member/child-company/access-rule')
      .pipe(map((res) => res));
  }

  saveCookieSettings(categories) {
    return this.http.post(this.BASE_URL + '/cookie-settings', categories);
  };
}
