import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ScopeVersion } from '@app/core/model/scope-version'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { SharedModule } from '@app/shared/shared.module'
import { PipesModule } from '@app/shared/pipe/index.module'
import { User } from '@app/core/model/user.model'
import { ExportDocumentService } from '../../service/export-document-service'
import { ScopeTraffickingService } from '../../service/scope-trafficking.service'
import { ExportModalConfig } from '../../model/export-modal-config'


@Component({
  selector: 'export-excel',
  standalone: true,
  imports: [CommonModule, PipesModule, MatDialogModule, SharedModule],
  templateUrl: './export-excel.component.html',
  styleUrls: ['./export-excel.component.scss'],
})
export class ExportExcelModal {

  hasCustomXlsxTemplate: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ExportModalConfig,
    private exportDocumentService: ExportDocumentService,
    public dialogRef: MatDialogRef<ExportExcelModal>
  ) {
    this.exportDocumentService.setXlsxTemplateVisibility(this.data.currentScope)
    this.hasCustomXlsxTemplate = this.exportDocumentService.hasCustomXlsxTemplate
  }

  exportScopeToXlsxTemplate() {
    this.exportDocumentService.exportScopeToXlsxTemplate(this.data.scopeId);
    this.dialogRef.close();
  }

  exportScopeToExcel()  {
    this.exportDocumentService.exportScopeToExcel(this.data.scopeId, this.data.currentScope.name);
    this.dialogRef.close();
  }

  exportScopeToExcelSummary() {
    this.exportDocumentService.exportScopeToExcelSummary(this.data.scopeId);
    this.dialogRef.close();
  }

  exportScopeBreakdownToExcelSummary() {
    this.exportDocumentService.exportScopeBreakdownXlsxTemplate(this.data.scopeId);
    this.dialogRef.close();
  }

  exportScopeBreakdownComponentToExcelSummary() {
    this.exportDocumentService.exportScopeComponentToExcelSummary(this.data.scopeId);
    this.dialogRef.close();
  }

  exportScopeDepartmentsHoursToExcelSummary() {
    this.exportDocumentService.exportScopeDepartmentsHoursXlsxTemplate(this.data.scopeId);
    this.dialogRef.close();
  }
}
