import { Component } from '@angular/core'
import { EnvService } from '@envs/env-service';
import { environment } from '@envs/environment'
import { AuthService } from '@core/service/auth.service'

@Component({
    selector: 'system-admin-overview',
    templateUrl: './system-admin-overview.component.html',
    styleUrls: ['./system-admin-overview.component.scss'],
})
export class SystemAdminOverviewComponent {

    constructor(private envService: EnvService, private authService: AuthService) {
    }

    public get baseJsUrl(): string {
      return `${this.envService.baseJsUrl}/system-management?host=${environment.baseHost}&childCompany=${this.authService.loggedInUser?.company.id}`;
    }
}
