<div class="main-nav-container">
  <div class="site-header__logo">
    <a title="Logo" href="/" class="site-header__logo__image">
      <img [src]="'v2/scope-matters-125px.png' | urlStaticMedia" alt="Scope Logo" />
    </a>
  </div>

  <div class="inner-nav-container" *ngIf="authService.loggedInUser">
    <div class="site-header__nav">
      <main-nav [loggedInUser]='authService.loggedInUser'></main-nav>
    </div>
  </div>

  <div class="flex-1" *ngIf="authService.loggedInUser">
    <ul class="site-header__support__nav">
      <li class="support-link">
        <a title="Support" target="_blank" href="https://thevirtugroup.zendesk.com/hc/en-gb">Support</a>
      </li>
      <li class="support-link">
        <a title="Get in Touch" target="_blank" href="https://thevirtugroup.zendesk.com/hc/en-gb/requests/new">Get in Touch</a>
      </li>

      <li>
        <notification-status></notification-status>
      </li>

      <li class="menu-profile-button">
        <button class="" [matMenuTriggerFor]="profileMenu">
          <img src="{{ userLogo$ | async }}" imgFallback />
        </button>

        <mat-menu #profileMenu="matMenu">
          <span mat-menu-item disabled>{{ authService.loggedInUser.getFullname() }}</span>
          <hr />
          <button mat-menu-item routerLink="profile">Profile</button>
          <button mat-menu-item routerLink="notifications">Notification Settings</button>
          <button mat-menu-item (click)="logout()">Sign out</button>
        </mat-menu>
      </li>

      <li class="menu-profile-button">
        <a
          class="btn--img no-hover"
          title="Manage"
          href="{{
            authService.loggedInUser.hasPrivilege('COMPANY_MEMBERS__VIEW') ||
            authService.loggedInUser.hasPrivilege('SECOND_PARTY__VIEW') ||
            authService.loggedInUser.hasPrivilege('RATECARD__VIEW') ||
            authService.loggedInUser.hasPrivilege('COMPANY_TRAFFIC_SYSTEM__VIEW') ||
            authService.loggedInUser.hasPrivilege('COMPANY_DETAILS__VIEW')
              ? '/manage'
              : '/'
          }}">
          <img src="{{ companyLogo$ | async }}" imgFallback alt="" />
        </a>
      </li>
    </ul>
  </div>
</div>
