import { Type } from 'class-transformer';
import { Money } from '@app/features/scope-overview/model/money.model';

export class ScopeRetainedHoursStats {
  sowId!: number
  scopeId!: number
  scopeIdentityId!: number
  sowRetainedTeamId!: number
  totalHours!: number
  @Type(() => Money) totalSellingPrice!: Money;
}
