import { Directive, ElementRef, Renderer2 } from "@angular/core";

@Directive({ selector: '[anchor-bottom]' })
export class AnchorBottomDirective {
  constructor(private _elemRef: ElementRef, private _renderer: Renderer2) {}

  ngOnInit() {
    let obs = new ResizeObserver(entries => {
      let elementBottom = this._elemRef.nativeElement.getBoundingClientRect().bottom
      let viewportBottom = window.visualViewport!.height

      if (elementBottom > viewportBottom) {
        this._renderer.setStyle(this._elemRef.nativeElement, 'bottom', '30px')
      }
    });
    obs.observe(this._elemRef.nativeElement);
  }
}
