import { Type } from 'class-transformer'
import { User } from '@core/model/user.model'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { Money } from '@app/features/scope-overview/model/money.model'
import { Department } from '@app/features/scoping/models/department.model'
import { ThirdPartyCost } from '@app/features/scoping/models/third-party-cost.model'
import { FeeItemInstance } from "@app/features/scope-overview/model/fee-item.model";
import { UserComment } from '@core/model/comment.model'

export class ScopeComponent {
  id!: number
  name?: string
  complexity!: string
  quantity!: number
  @Type(() => User) createdBy!: User
  @Type(() => Date) createdTs!: Date
  currencyUnit!: string
  @Type(() => Deliverable) deliverable!: Deliverable
  @Type(() => FeeItemInstance) feeItems!: FeeItemInstance[]
  fixedPricing!: boolean
  departmentsInitialised!: boolean
  markup!: number
  @Type(() => Money) naturalSellingPrice!: Money
  @Type(() => Money) fixedCost!: Money
  @Type(() => Department) departments!: Department[]
  @Type(() => ThirdPartyCost) thirdPartyCosts!: ThirdPartyCost[]
  order!: number
  prediction!: {
    riskIndicator: string
    stDev: number
    sampleSize: number
    likelihood: number
    source: string
  }
  rateCardTotalHours!: number
  cachedRatecardHours!: number
  rateCardTotalMinutes!: number
  @Type(() => Money) rateCardTotalResourceSellingPrice!: Money
  scopeMarkTotalHours!: number
  @Type(() => Money) scopeMarkTotalSellingPrice!: Money
  source!: {
    description: string
    foreignSource: any
    id: number
    name: string
    nonTranslatedDescription: string
    nonTranslatedName: string
    parentId: number
    scopeType: string
  }
  sourceType!: string
  @Type(() => Money) totalCostPrice!: Money
  @Type(() => Money) totalProfit!: Money
  @Type(() => Money) totalRateCardSellingPrice!: Money
  @Type(() => Money) totalSellingPrice!: Money
  trafficSyncStatus!: string
  @Type(() => Money) unitCostPrice!: Money
  @Type(() => Money) unitProfit!: Money
  @Type(() => Money) unitSellingPrice!: Money
  @Type(() => User) updatedBy!: User
  @Type(() => Date) updatedTs!: Date
  breadcrumb?: string
  section: any
  @Type(() => UserComment) comments!: UserComment[]
  costPlusValue: number
  @Type(() => Date) startDate: Date
  @Type(() => Date) endDate: Date
  updatedName : string = ''
  costAmount: number

  getTotalRateCardHours() {
    if (!this.departmentsInitialised && this.cachedRatecardHours != null) {
      return this.cachedRatecardHours
    }
    let total = 0
    for (let i = 0; i < this.departments.length; i++) {
      let deptTotal = this.departments[i].getTotalRateCardHours()
      if (isNaN(deptTotal)) {
        return null
      }
      total += deptTotal
    }
    return total * this.quantity
  }

  totalRateCardHoursWithoutQuantity() {
    if (!this.departmentsInitialised && this.cachedRatecardHours != null) {
      return this.cachedRatecardHours
    }
    let total = 0
    for (let i = 0; i < this.departments.length; i++) {
      total += this.departments[i].getTotalRateCardHours()
    }
    return total
  }

  totalScopeMarkHours() {
    let total = 0
    for (let i = 0; i < this.departments.length; i++) {
      total += this.departments[i].totalScopeMarkHours()
    }
    return total * this.quantity
  }

  totalScopeMarkSellingPrice(opts?: any) {
    opts = opts ? opts : { includeQuantity: true }

    let total = new Money(0, this.currencyUnit)
    for (var i = 0; i < this.departments.length; i++) {
      var department = this.departments[i]
      var price = department.totalScopeMarkSellingPrice()
      if (price == null) {
        return null
      }
      total = total.add(price)
    }

    return new Money((total.getValue() || 0) * (opts.includeQuantity ? this.quantity : 1), total.getCurrencyCode())
  }

  getFixedCostMultipliedByQuantity() {
    if (this.fixedPricing && this.fixedCost != null) {
      return new Money(this.fixedCost.amount, this.fixedCost.currency).multiply(this.quantity)
    }
    return new Money(0, this.currencyUnit)
  }

  getTotalSellingPrice(ctx = { excludeThirdPartyCosts: false, excludeComponentFees: false }) {
    let total = new Money(0, this.currencyUnit)
    if (this.fixedPricing && this.fixedCost != null) {
      total = new Money(this.fixedCost.amount, this.fixedCost.currency).multiply(this.quantity)
    } else {
      for (let i = 0; i < this.departments.length; i++) {
        let department = this.departments[i]
        let price = department.getTotalSellingPrice({ ...ctx, markup: this.costPlusValue ? this.costPlusValue : null });
        if (price == null) return null
        total = total.add(price)
      }
      total = total.multiply(this.quantity)

      if (!ctx.excludeThirdPartyCosts) {
        total = total.add(this.thirdPartyCostTotal())
      }

      if (!ctx.excludeComponentFees) {
        total = total.addValue(this.getTotalFees(total.amount))
      }
    }
    return new Money(total.getValue(), total.getCurrencyCode())
  }

  thirdPartyCostTotal() {
    if (this.thirdPartyCosts && this.thirdPartyCosts.length) {
      return this.thirdPartyCosts.map((tpc) => tpc.calculateSellingPrice()).reduce((acc, next) => acc.add(next))
    }
    return new Money(0, this.currencyUnit)
  }

  getTotalCostPrice() {
    let total = new Money(0, this.currencyUnit)
    if (this.fixedPricing && this.costAmount) {
      total = total.add(new Money(this.costAmount, this.currencyUnit));
    }
    for (let i = 0; i < this.departments.length; i++) {
      let department = this.departments[i]
      let price = department.getTotalCostPrice()
      if (price == null) {
        return null
      }
      total = total.add(price)
    }
    total = total.multiply(this.quantity)

    this.thirdPartyCosts.forEach(function (tpc) {
      total = total.add(tpc.calculateCostPrice())
    })

    return new Money(total.getValue(), total.getCurrencyCode())
  }

  getMarkup() {
    let sellingPrice = this.getTotalSellingPrice({ excludeThirdPartyCosts: false, excludeComponentFees: true });
    let totalRateCardCost = this.getTotalCostPrice();
    if (totalRateCardCost == null) {
      return null;
    }
    var cost = totalRateCardCost;
    if (cost.amount == 0) {
      return 100;
    }
    if (sellingPrice && (cost.amount || 0) > 0) {
      var grossProfit = sellingPrice.amount - (cost.amount || 0);
      var markup = (grossProfit / (cost.amount || 0)) * 100;
      return parseFloat(markup.toFixed(2));
    }
    return 0;
  };

  getTotalFees(amount: number | null = null) {
    let total = 0
    if (this.feeItems?.length !== 0) {
      if (amount == null && this.feeItems.find((fee) => fee.feeItem.amountType !== 'MONETARY')) {
        amount = this.getTotalSellingPrice({ excludeThirdPartyCosts: false, excludeComponentFees: true }).amount
      }
      for (let i = 0; i < this.feeItems.length; i++) {
        total += Number(this.getFeePrice(this.feeItems[i], amount))
      }
    }
    return total
  }

  getFeePrice(fee: FeeItemInstance, amount: number): number {
    if (fee.feeItem.amountType === 'MONETARY') {
      return parseFloat(fee.feeItem.amount.toFixed(2))
    } else {
      return (Number(amount) * Number(fee.feeItem.amount)) / 100
    }
  }

  hasCostPlus(): boolean {
    return this.costPlusValue && !this.fixedPricing || this.costPlusValue && this.fixedPricing && this.departments.length > 0;
  }

  getUpdatedName(){
    return this.updatedName
  }

  setUpdatedName(name){
    return this.updatedName = name
  }

  getBreadcrumb() {
    return this.breadcrumb
  }

  getCommentsCount() {
    let ownCommentsCount = this.comments.length;
    let departmentComments = 0;
    for (let i = 0; i < this.departments.length; i++) {
      departmentComments += this.departments[i].getCommentsCount();
    }
    let tpcCommentsCount = 0;
    for (let j = 0; j < this.thirdPartyCosts.length; j++) {
      tpcCommentsCount += this.thirdPartyCosts[j].getCommentsCount();
    }
    return ownCommentsCount + departmentComments + tpcCommentsCount;
  }

}
