import { Component, EventEmitter, Input, Output } from '@angular/core';
import {SharedModule} from "@shared/shared.module";
import {
  ScopeUiAutocompleteComponent
} from "@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component";
import { RatecardVersion } from "@app/features/scope-overview/model/ratecard-version.model";
import { Deliverable } from "@app/features/scoping/models/deliverable.model";
import { untilDestroyed } from "@shared/utils/utils";
import { Role } from "@app/features/scoping/models/role.model";
import _ from "lodash";
import {
  ScopeUiDropdownComponent
} from "@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component";
import { MapRolesComponent } from '@app/features/scoping/components/map-roles/map-roles.component'

@Component({
  selector: 'map-scope-roles',
  templateUrl: './map-scope-roles.component.html',
  styleUrls: ['./map-scope-roles.component.scss'],
  imports: [
    SharedModule,
    ScopeUiAutocompleteComponent,
    ScopeUiDropdownComponent,
    MapRolesComponent,
  ],
  standalone: true
})
export class MapScopeRolesComponent {
  private readonly destroy$;
  @Input() myNewScope!: any
  @Input() isTemplate: boolean = false
  @Input() scopeToDuplicate: any
  @Input() deliverables?: Deliverable[]
  @Input() ratecard?: RatecardVersion

  @Output() onConfirm = new EventEmitter<any>();

  isMapStep: boolean = false
  mappingRoles: any[] = []
  targetRoles: any[] = [{name: 'Unassigned', id: null}]
  mappingIsEmpty: boolean = false
  scopeTemplate: any;
  sourceRateCard!: RatecardVersion;
  private ratecardId!: number;
  private distinctRolesMap: any = {};

  constructor() {
    this.destroy$ = untilDestroyed();
  }

  ngOnInit() {
    if (this.isTemplate) {
      this.scopeTemplate = this.myNewScope.scopeTemplate;
      this.sourceRateCard = this.scopeTemplate.identity.rateCard;
    } else {
      this.sourceRateCard = this.scopeToDuplicate.identity.rateCard;
    }
    this.ratecardId = this.myNewScope.ratecard.rateCardIdentity.id;
  }

  mapRoles() {
    this.prepareMapping();
    this.isMapStep = true;
  };

  prepareMapping() {
    let targetDepartments = this.ratecard!.departments;
    if (targetDepartments) {
      let _this = this
      let targetDepartmentsRolesByName: {[key: string]: any} = {};

      targetDepartments.forEach(function (targetDepartment) {
        targetDepartment.roles.forEach(function (targetRole) {
          targetDepartmentsRolesByName[`${targetRole.name.toLowerCase()} : ${targetDepartment.name}`] = targetRole;
        });
      });

      this.deliverables!.forEach(function (deliverable: Deliverable) {
        deliverable.getAllComponents().forEach(function (component) {
          component.departments.flatMap(dept => dept.roles).forEach(function (role: Role) {
            if (role.sourceType != 'SCOPE') {
              let key = role.source.name.toLowerCase() + " : " + role.departmentType.name;
              if (!_this.distinctRolesMap[key]) {
                let distinctRole: any = {
                  name: role.source.name,
                  id: role.source.id,
                  rate: role.rateCardRate
                };
                var targetRole = targetDepartmentsRolesByName[key];
                if (targetRole) {
                  distinctRole.targetMappingRole = targetRole;
                } else {
                  distinctRole.targetMappingRole = null;
                }
                _this.distinctRolesMap[key] = distinctRole;
              }
            }
          });
        })
      });
      this.mappingRoles = Object.values(this.distinctRolesMap)
      this.targetRoles = this.targetRoles.concat(targetDepartments.flatMap(department => department.roles))
    }
    if (_.isEmpty(this.mappingRoles)) {
      this.mappingIsEmpty = true
    }
  }

  confirmConvertAndInsert() {
    let _this = this
    let roles: any = {}
    let mappingRoleKeys = Object.keys(this.distinctRolesMap)
    mappingRoleKeys.forEach(function (key) {
      var mapRole = _this.distinctRolesMap[key]
      roles[mapRole.id] = mapRole.targetMappingRole
    })
    this.myNewScope.rolesMapping = roles
    this.onConfirm.emit(this.myNewScope)
  }
}
