import { ScopeSection } from '@app/features/scope-overview/model/scope-section'
import { Department } from '@app/features/scoping/models/department.model'
import { ScopeComponent } from '@app/features/scoping/models/component.model'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'

export interface Rate {
  amount: number;
  currency: string;
}

export interface FindReplaceRole {
  id?: number;
  roleId: number;
  name: string;
  rate: Rate;
  minutes: number;
  department: { id: number, name: string};
  component: { id: number, name: string};
  deliverable: { id: number, name: string};
  stage: { id: number, name: string};
}

export class ScopeReplacementRole {
  id?: number;
  oldRole?: any;
  newRole?: any;
  hierarchy?: any;
  comments?: any[];
}

export class ScopeReplacementPath {
  id?: number;
  name?: string;
  minutes?: number;
  hours?: number;
  oldHours?: number;
  stage?: ScopeSection;
  department?: Department;
  component?: ScopeComponent;
  deliverable?: Deliverable;
  comments?: any[];
}
