<section class="page-header">
  <div class="scope-row">
    <div class="four--columns">
      <h2 class="page-header__title">Forgot Password</h2>
    </div>
  </div>
</section>

<section class="login-container container container--grey">
  <div class="login-content scope-pod">
    <h4 class="scope-pod__title">Forgot Password</h4>

    <div class="login-form-content scope-pod__form">
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()" class="login-form">
        <mat-form-field class="form-group">
          <input
            matInput
            type="email"
            name="username"
            placeholder="Email"
            formControlName="username"
            class="login-input"
            required />
        </mat-form-field>
        <ng-container>
          <re-captcha
            class="mb-2"
            formControlName="recaptcha"
            (resolved)="captchaResolved($event)"
            [siteKey]="(companyBootstrap$ | async)?.cdnConfig.googleRecaptchaPublicKey"></re-captcha>
        </ng-container>

        <div *ngIf="forgotPasswordForm.invalid && (forgotPasswordForm.dirty || forgotPasswordForm.touched)">
          <div class="warning warning--broken">
            <mat-error *ngIf="username?.errors?.['pattern']"> Invalid email </mat-error>

            <mat-error *ngIf="username?.errors?.['required']"> Please enter your email </mat-error>
            <mat-error *ngIf="recaptcha?.hasError('required')">
              Please complete the reCAPTCHA verification to proceed.
            </mat-error>
          </div>
        </div>

        <button
          [disabled]="forgotPasswordForm.invalid"
          [ngClass]="{ 'btn--disabled': forgotPasswordForm.invalid }"
          class="btn btn--inline btn--action btn--large">
          Reset Password
        </button>
      </form>
    </div>
  </div>
</section>
