import {Component, Input} from '@angular/core';
import {HeaderStyleService} from "@shared/services/header-style.service";
import {EnvService} from "@envs/env-service";
import {Router} from "@angular/router";
import {AuthService} from "@core/service/auth.service";

@Component({
  selector: 'app-duplicate-scope-template',
  templateUrl: './duplicate-scope-template.component.html',
  styleUrls: ['./duplicate-scope-template.component.scss']
})
export class DuplicateScopeTemplateComponent {
  @Input() id!: number;
  @Input() templateId!: number;
  constructor(private headerStyleService: HeaderStyleService, private envService: EnvService, private router: Router, private authService: AuthService) {
    window.addEventListener('message', event => {
      if (event.data.action === 'goToScope') {
        if(event.data.id){
          this.id = event.data.id
        }
        this.router.navigateByUrl('/scopes/scope-overview/' + this.id)
      }
    })
  }

  public get baseJsUrl(): string {
    return `${this.envService.baseJsUrl}/duplicate-template/${this.id}`;
  }
}
