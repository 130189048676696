import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { featureKey, reducers } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { SystemAdminEffects } from './store/effects/system-admin.effects';

const EFFECTS = [SystemAdminEffects];

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(featureKey, reducers, {}), EffectsModule.forFeature(EFFECTS)],
})
export class SystemAdminNgrxModule {}
