<mat-select (valueChange)="onChangeCurrentCompany($event)" [(value)]="selectedCompanyName" class="child-switcher company-list"
            [ngClass]="{'active': authorisedAsChildCompany, 'showChildSwitcher': showChildSwitcher}"
            (openedChange)="this.showChildSwitcher = $event"
            *ngIf="childCompanies.length > 0" [hidden]="removeAccessToSwitcher()">
  <mat-option [value]="originalCompany?.name">
    {{originalCompany?.name}}
  </mat-option>
  <mat-option [value]="child.name" [ngClass]="{'orange': authorisedAsChildCompany }"
              *ngFor="let child of childCompanies$ | async; trackBy: trackById">
    {{child.name}}
  </mat-option>
</mat-select>

<div class="overlay" *ngIf="loading">
  <div class="overlay-content">
    <span class="text-sm">Switching to</span>
    <p class="text-2xl">{{selectedCompanyName}}</p>
    <div class="loading">
      <div class="loading__dot"></div>
      <div class="loading__dot"></div>
      <div class="loading__dot"></div>
    </div>
  </div>
</div>
