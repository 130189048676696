// @ts-nocheck
import Gantt from 'frappe-gantt-angular15/src/index'
import Bar from 'frappe-gantt-angular15/src/bar'
import date_utils from "frappe-gantt-angular15/src/date_utils"
import { $, createSVG } from 'frappe-gantt-angular15/src/svg_utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const VIEW_MODE = {
  QUARTER_DAY: 'Quarter Day',
  HALF_DAY: 'Half Day',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
};

export default class CustomGantt extends Gantt {
  $container: HTMLElement

  constructor(wrapper, tasks, options) {
    super(wrapper, tasks, options)
  }

  override refresh(tasks) {
    this.setup_tasks(tasks);
    this.change_view_mode();
  }

  override setup_gantt_dates() {
    if (this.options.maximum_date && this.options.minimum_date) {
      this.gantt_start = date_utils.add(this.options.minimum_date, -1, 'day');
      this.gantt_end = date_utils.add(this.options.maximum_date, 1, 'day');
      return;
    }

    this.gantt_start = this.gantt_end = null;

    for (let task of this.tasks) {
      // set global start and end date
      if (!this.gantt_start || task._start < this.gantt_start) {
        this.gantt_start = task._start;
      }
      if (!this.gantt_end || task._end > this.gantt_end) {
        this.gantt_end = task._end;
      }
    }

    this.gantt_start = date_utils.start_of(this.gantt_start, 'day');
    this.gantt_end = date_utils.start_of(this.gantt_end, 'day');

    // add date padding on both sides
    if (this.view_is([VIEW_MODE.QUARTER_DAY, VIEW_MODE.HALF_DAY])) {
      this.gantt_start = date_utils.add(this.gantt_start, -7, 'day');
      this.gantt_end = date_utils.add(this.gantt_end, 7, 'day');
    } else if (this.view_is(VIEW_MODE.MONTH)) {
      this.gantt_start = date_utils.start_of(this.gantt_start, 'year');
      this.gantt_end = date_utils.add(this.gantt_end, 1, 'year');
    } else if (this.view_is(VIEW_MODE.YEAR)) {
      this.gantt_start = date_utils.add(this.gantt_start, -2, 'year');
      this.gantt_end = date_utils.add(this.gantt_end, 2, 'year');
    } else {
      this.gantt_start = date_utils.add(this.gantt_start, -7, 'day');
      this.gantt_end = date_utils.add(this.gantt_end, 7, 'day');
    }
  }

  override setup_date_values() {
    this.dates = [];
    let cur_date = null;

    while (cur_date === null || cur_date < this.gantt_end) {
      if (!cur_date) {
        cur_date = date_utils.clone(this.gantt_start);
      } else {
        if (this.view_is(VIEW_MODE.YEAR)) {
          cur_date = date_utils.add(cur_date, 1, 'year');
        } else if (this.view_is(VIEW_MODE.MONTH)) {
          cur_date = date_utils.add(cur_date, 1, 'month');
        } else {
          cur_date = date_utils.add(
            cur_date,
            this.options.step,
            'hour'
          );
        }
      }
      if (cur_date < this.gantt_end) {
        this.dates.push(cur_date);
      }
    }
  }

  override render() {
    let scrollPosition = this.$svg.parentElement.scrollLeft;
    this.clear();
    this.setup_layers();
    this.make_grid();
    this.make_dates();
    this.make_bars();
    this.set_width();
    this.$svg.parentElement.scrollLeft = scrollPosition;
  }

  override make_grid() {
    this.make_grid_rows();
    this.make_grid_background();
    this.make_grid_header();
    this.make_grid_ticks();
    this.make_grid_highlights();
  }

  override make_grid_background() {
    $.attr(this.$svg, {
      height: '100%',
      width: '100%',
    });
  }

  override make_grid_rows() {
    const rows_layer = createSVG('g', { append_to: this.layers.grid });
    const lines_layer = createSVG('g', { append_to: this.layers.grid });

    const row_width = this.dates.length * this.options.column_width;
    const row_height = this.options.bar_height + this.options.padding;

    this.row_y = this.options.header_height + this.options.padding / 2;

    for (let task of this.tasks) {
      this.row_y += (task.row_gap || 0) + (task.row_gap_after || 0);

      createSVG('rect', {
        x: 0,
        y: this.row_y,
        width: row_width,
        height: row_height,
        class: 'grid-row',
        append_to: rows_layer,
      });

      createSVG('line', {
        x1: 0,
        y1: this.row_y + row_height,
        x2: row_width,
        y2: this.row_y + row_height,
        class: 'row-line',
        append_to: lines_layer,
      });

      this.row_y += this.options.bar_height + this.options.padding;
    }
  }

  override make_grid_header() {
    const header_width = this.dates.length * this.options.column_width;
    const header_height = this.options.header_height + 10;
    createSVG('rect', {
      x: 0,
      y: 0,
      width: header_width,
      height: header_height,
      class: 'grid-header',
      append_to: this.layers.grid,
    });
    createSVG('line', {
      x1: 0,
      x2: header_width,
      y1: header_height - 2,
      y2: header_height,
      class: 'header-border',
      append_to: this.layers.grid,
    })
  }

  make_no_access_area(x: number, y: number, id: string) {
    this.clipPath = createSVG('clipPath', {
      id: id,
      append_to: this.layers.grid
    })

    createSVG('rect', {
      x: x,
      y: y,
      width: 20,
      height: 1400,
      append_to: this.clipPath
    })

    this.no_access_area = createSVG('g', {
      x: x,
      y: y,
      width: 20,
      height: 1400,
      append_to: this.layers.grid
    });

    createSVG('image', {
      x: x,
      y: y,
      width: 140,
      height: 1400,
      preserveAspectRatio: 'none',
      append_to: this.no_access_area,
      href: '/assets/icons/not-available-area.svg',
      style: `clip-path: url(#${id})`
    });
  }

  override make_grid_ticks() {
    let tick_x = 0;
    let tick_y = this.options.header_height + this.options.padding / 2;

    this.make_no_access_area(0, tick_y, 'start')

    for (let date of this.dates) {
      let tick_offset = 0;
      let tick_class = 'tick';
      if (tick_x === 0) {
        tick_offset = 20;
        tick_class += ' first';
      }
      // thick tick for monday
      if (this.view_is(VIEW_MODE.DAY) && date.getDate() === 1) {
        tick_class += ' thick';
      }
      // thick tick for first week
      if (
        this.view_is(VIEW_MODE.WEEK) &&
        date.getDate() >= 1 &&
        date.getDate() < 8 &&
        tick_x !== 0
      ) {
        tick_class += ' thick';
        tick_offset = -(date.getDate() - 1) * (this.options.column_width / 7)
      }
      // thick ticks for quarters
      if (
        this.view_is(VIEW_MODE.MONTH) &&
        (date.getMonth() + 1) % 3 === 0
      ) {
        tick_class += ' thick';
      }

      if (tick_x === 0 || (tick_x + tick_offset) !== 20) {
        createSVG('path', {
          d: `M ${tick_x + tick_offset} ${tick_y} v 1400`,
          class: tick_class,
          append_to: this.layers.grid,
        });
      }

      if (this.view_is(VIEW_MODE.MONTH)) {
        tick_x +=
          (date_utils.get_days_in_month(date) *
            this.options.column_width) /
          30;
      } else {
        tick_x += this.options.column_width;
      }
    }

    let days_between = Math.round((this.gantt_end - this.gantt_start) / (1000 * 60 * 60 * 24))

    createSVG('path', {
      d: `M ${days_between * this.options.column_width / 7} ${tick_y} v 1400`,
      class: 'tick last',
      append_to: this.layers.grid,
    });

    this.make_no_access_area((days_between * this.options.column_width / 7) + 1, tick_y, 'end')
  }

  override get_date_info(date, last_date, i) {
    if (!last_date) {
      last_date = date_utils.add(date, -1, 'month');
    }
    const date_text = {
      'Quarter Day_lower': date_utils.format(
        date,
        'HH',
        this.options.language
      ),
      'Half Day_lower': date_utils.format(
        date,
        'HH',
        this.options.language
      ),
      Day_lower:
        date.getDate() !== last_date.getDate()
          ? date_utils.format(date, 'D', this.options.language)
          : '',
      Week_lower:
        dayjs.utc(date).date(),
      Month_lower: date_utils.format(date, 'MMMM', this.options.language),
      Year_lower: date_utils.format(date, 'YYYY', this.options.language),
      'Quarter Day_upper':
        date.getDate() !== last_date.getDate()
          ? date_utils.format(date, 'D MMM', this.options.language)
          : '',
      'Half Day_upper':
        date.getDate() !== last_date.getDate()
          ? date.getMonth() !== last_date.getMonth()
            ? date_utils.format(
              date,
              'D MMM',
              this.options.language
            )
            : date_utils.format(date, 'D', this.options.language)
          : '',
      Day_upper:
        date.getMonth() !== last_date.getMonth()
          ? date_utils.format(date, 'MMMM', this.options.language)
          : '',
      Week_upper:
        date.getMonth() !== last_date.getMonth() && date.getDate() < 15
          ? date_utils.format(date, 'MMMM', this.options.language)
          : '',
      Month_upper:
        date.getFullYear() !== last_date.getFullYear()
          ? date_utils.format(date, 'YYYY', this.options.language)
          : '',
      Year_upper:
        date.getFullYear() !== last_date.getFullYear()
          ? date_utils.format(date, 'YYYY', this.options.language)
          : '',
    };

    const base_pos = {
      x: i * this.options.column_width,
      lower_y: this.options.header_height - 8,
      upper_y: this.options.header_height - 40,
    };

    const x_pos = {
      'Quarter Day_lower': (this.options.column_width * 4) / 2,
      'Quarter Day_upper': 0,
      'Half Day_lower': (this.options.column_width * 2) / 2,
      'Half Day_upper': 0,
      Day_lower: this.options.column_width / 2,
      Day_upper: (this.options.column_width * 30) / 2,
      Week_lower: 0,
      Week_upper: ((this.options.column_width / 7) * 15) - (date.getDate() * (this.options.column_width / 7)),
      Month_lower: this.options.column_width / 2,
      Month_upper: (this.options.column_width * 12) / 2,
      Year_lower: this.options.column_width / 2,
      Year_upper: (this.options.column_width * 30) / 2,
    };

    return {
      upper_text: date_text[`${this.options.view_mode}_upper`],
      lower_text: date_text[`${this.options.view_mode}_lower`],
      upper_x: base_pos.x + x_pos[`${this.options.view_mode}_upper`],
      upper_y: base_pos.upper_y,
      lower_x: base_pos.x + x_pos[`${this.options.view_mode}_lower`],
      lower_y: base_pos.lower_y,
    };
  }

  override make_bars() {
    let row_gap_total = 0;
    this.bars = this.tasks.map((task) => {
      row_gap_total += task.row_gap || 0
      task.row_gap = row_gap_total
      const bar = new CustomBar(this, task);
      this.layers.bar.appendChild((bar as Bar).group);
      row_gap_total += task.row_gap_after || 0
      return bar;
    });
  }

  override set_width() {
    const cur_width = this.$svg.getBoundingClientRect().width;
    const actual_width = parseInt(this.$svg
      .querySelector('.grid .grid-row')?.getAttribute('width'));
    if (cur_width < actual_width) {
      this.$svg.setAttribute('width', actual_width + 48);
    }
  }

  override bind_bar_events() {
    let is_dragging = false;
    let x_on_start = 0;
    let y_on_start = 0;
    let is_resizing_left = false;
    let is_resizing_right = false;
    let parent_bar_id = null;
    let bars = []; // instanceof Bar
    this.bar_being_dragged = null;

    function action_in_progress() {
      return is_dragging || is_resizing_left || is_resizing_right;
    }

    $.on(this.$svg, 'mousedown', '.bar-wrapper, .handle', (e, element) => {
      const bar_wrapper = $.closest('.bar-wrapper', element);

      if (element.classList.contains('left')) {
        is_resizing_left = true;
      } else if (element.classList.contains('right')) {
        is_resizing_right = true;
      } else if (element.classList.contains('bar-wrapper') && !element.classList.contains('drag-disabled')) {
        is_dragging = true;
      }

      bar_wrapper.classList.add('active');

      x_on_start = e.offsetX;
      y_on_start = e.offsetY;

      parent_bar_id = bar_wrapper.getAttribute('data-id');
      const ids = [
        parent_bar_id,
        ...this.get_all_dependent_tasks(parent_bar_id),
      ];
      bars = ids.map((id) => this.get_bar(id));

      this.bar_being_dragged = parent_bar_id;

      bars.forEach((bar) => {
        const $bar = bar.$bar;
        $bar.ox = $bar.getX();
        $bar.oy = $bar.getY();
        $bar.owidth = $bar.getWidth();
        $bar.finaldx = 0;
      });
    });

    $.on(this.$svg, 'mousemove', (e) => {
      if (!action_in_progress()) return;
      const dx = e.offsetX - x_on_start;
      const dy = e.offsetY - y_on_start;
      let prevent_child_move = false

      bars.forEach((bar) => {
        const $bar = bar.$bar;
        $bar.finaldx = this.get_snap_position(dx);
        this.hide_popup();
        if (is_resizing_left) {
          if (parent_bar_id === bar.task.id) {
            let { new_start_date } = bar.compute_start_end_date($bar.ox + $bar.finaldx, $bar.owidth - $bar.finaldx);
            if (new_start_date < this.options.minimum_date || new_start_date > this.options.maximum_date) {
              prevent_child_move = true;
              return;
            }

            bar.update_bar_position({
              x: $bar.ox + $bar.finaldx,
              width: $bar.owidth - $bar.finaldx
            });
          } else if (!prevent_child_move) {
            bar.update_bar_position({
              x: $bar.ox + $bar.finaldx
            });
          }
        } else if (is_resizing_right) {
          if (parent_bar_id === bar.task.id) {
            let { new_end_date } = bar.compute_start_end_date(null, $bar.owidth + $bar.finaldx);
            if (new_end_date > this.options.maximum_date) return;

            bar.update_bar_position({
              width: $bar.owidth + $bar.finaldx
            });
          }
        } else if (is_dragging) {
          if (parent_bar_id === bar.task.id) {
            let { new_start_date, new_end_date } = bar.compute_start_end_date($bar.ox + $bar.finaldx);
            if (new_start_date < this.options.minimum_date || new_end_date > this.options.maximum_date) {
              prevent_child_move = true;
              return
            }
          }

          if (!prevent_child_move) bar.update_bar_position({ x: $bar.ox + $bar.finaldx });
        }
      });
    });

    document.addEventListener('mouseup', (e) => {
      if (is_dragging || is_resizing_left || is_resizing_right) {
        bars.forEach((bar) => bar.group.classList.remove('active'));
      }

      is_dragging = false;
      is_resizing_left = false;
      is_resizing_right = false;
    });

    $.on(this.$svg, 'mouseup', (e) => {
      this.bar_being_dragged = null;
      bars.forEach((bar) => {
        const $bar = bar.$bar;
        if (!$bar.finaldx) return;
        bar.date_changed();
        bar.set_action_completed();
      });
    });

    this.bind_bar_progress();
  }

  override show_popup(options) {
    this.trigger_event('edit', [options]);
  }
}

class CustomBar extends Bar {
  constructor(gantt, task) {
    super(gantt, task)
  }

  override prepare_values() {
    this.invalid = this.task.invalid;
    this.height = this.gantt.options.bar_height;
    this.x = this.compute_x();
    this.y = this.compute_y();
    this.corner_radius = this.gantt.options.bar_corner_radius;
    this.duration =
      (dayjs(this.task._end).diff(dayjs(this.task._start), 'day') + 1) / 7;
    this.width = this.gantt.options.column_width * this.duration;
    this.progress_width =
      this.gantt.options.column_width *
      this.duration *
      (this.task.progress / 100) || 0;
    this.group = createSVG('g', {
      class: `bar-wrapper ${(this.task.custom_class || '')} ${this.gantt.options.disabled || this.task.disable_resize ? 'drag-disabled' : '' }`,
      'data-id': this.task.id,
    });
    this.bar_group = createSVG('g', {
      class: 'bar-group',
      append_to: this.group,
    });
    this.handle_group = createSVG('g', {
      class: `handle-group ${this.gantt.options.disabled ? 'hidden' : '' }`,
      append_to: this.group,
    });
  }

  override draw() {
    this.draw_bar();
    this.draw_label();
    this.draw_resize_handles();
  }

  override draw_label() {
    if (!this.task.disable_expand) {
      let d = this.task.isOpen ?
        'M7.71679 7.58333L5.11679 10.1833C4.99457 10.3056 4.83901 10.3667 4.65012 10.3667C4.46123 10.3667 4.30568 10.3056 4.18346 10.1833C4.06123 10.0611 4.00012 9.90556 4.00012 9.71667C4.00012 9.52778 4.06123 9.37222 4.18346 9.25L7.25012 6.18333C7.31679 6.11667 7.38901 6.06944 7.46679 6.04167C7.54457 6.01389 7.6279 6 7.71679 6C7.80568 6 7.88901 6.01389 7.96679 6.04167C8.04457 6.06944 8.11679 6.11667 8.18346 6.18333L11.2501 9.25C11.3723 9.37222 11.4335 9.52778 11.4335 9.71667C11.4335 9.90556 11.3723 10.0611 11.2501 10.1833C11.1279 10.3056 10.9723 10.3667 10.7835 10.3667C10.5946 10.3667 10.439 10.3056 10.3168 10.1833L7.71679 7.58333Z' :
        'M7.71679 10.3667C7.6279 10.3667 7.54457 10.3528 7.46679 10.325C7.38901 10.2972 7.31679 10.25 7.25012 10.1833L4.18346 7.11667C4.06123 6.99444 4.00012 6.83889 4.00012 6.65C4.00012 6.46111 4.06123 6.30556 4.18346 6.18333C4.30568 6.06111 4.46123 6 4.65012 6C4.83901 6 4.99457 6.06111 5.11679 6.18333L7.71679 8.78333L10.3168 6.18333C10.439 6.06111 10.5946 6 10.7835 6C10.9723 6 11.1279 6.06111 11.2501 6.18333C11.3723 6.30556 11.4335 6.46111 11.4335 6.65C11.4335 6.83889 11.3723 6.99444 11.2501 7.11667L8.18346 10.1833C8.11679 10.25 8.04457 10.2972 7.96679 10.325C7.88901 10.3528 7.80568 10.3667 7.71679 10.3667Z'
      createSVG('path', {
        d: d,
        innerHtml: '-',
        class: 'bar-toggle',
        style: `transform: translateX(${this.x}px) translateY(${this.y - 12 + this.height / 2}px) scale(1.4)`,
        append_to: this.bar_group
      });
    }

    createSVG('text', {
      x: this.x + (this.task.disable_expand ? 4 : 22),
      y: this.y + this.height / 2,
      innerHTML: this.task.name,
      class: 'bar-label',
      append_to: this.bar_group
    });

    // labels get BBox in the next tick
    requestAnimationFrame(() => this.update_label_position());

    if (this.task.duration) {
      let label = this.group.querySelector('.bar-label');
      createSVG('text', {
        x: Math.max(this.x + this.width - 22, label.getX() + label.getBBox().width + 8),
        y: this.y + this.height / 2,
        innerHTML: this.task.duration,
        class: 'duration',
        append_to: this.bar_group
      });
    }
  }

  override draw_resize_handles() {
    if (this.invalid || this.task.disable_resize) return;

    const bar = this.$bar;

    this.edit_group = createSVG('g', {
      class: 'edit-group',
      append_to: this.handle_group,
    });

    createSVG('circle', {
      cx: bar.getX() - 22,
      cy: bar.getY() + 11,
      r: 8,
      class: 'edit-shadow',
      append_to: this.edit_group
    });

    this.edit = createSVG('image', {
      x: bar.getX() - 28,
      y: bar.getY() + 5,
      width: 12,
      height: 12,
      class: 'edit',
      append_to: this.edit_group,
      href: '/assets/icons/pencil.svg'
    });

    createSVG('image', {
      x: bar.getX() + bar.getWidth(),
      y: bar.getY() + 3,
      width: 16,
      height: 16,
      class: 'handle right',
      append_to: this.handle_group,
      href: '/assets/icons/handle.svg'
    });

    createSVG('image', {
      x: bar.getX() - 16,
      y: bar.getY() + 3,
      width: 16,
      height: 16,
      class: 'handle left',
      append_to: this.handle_group,
      href: '/assets/icons/handle.svg',
      transform: `rotate(180, ${bar.getX() - 8}, ${bar.getY() + 11})`
    });

    if (this.task.progress && this.task.progress < 100) {
      this.$handle_progress = createSVG('polygon', {
        points: this.get_progress_polygon_points().join(','),
        class: 'handle progress',
        append_to: this.handle_group,
      });
    }
  }

  override setup_click_event() {
    if (!this.task.disable_resize) {
      $.on(this.edit_group, 'click', (e) => {
        if (this.action_completed) {
          // just finished a move action, wait for a few seconds
          return;
        }

        this.show_popup();
        this.gantt.unselect_all();
        this.group.classList.add('active');
      });
    }

    $.on(this.bar_group, 'click', (e) => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }

      this.gantt.trigger_event('click', [this.task]);
    });
  }

  override show_popup() {
    if (this.gantt.bar_being_dragged) return;

    this.gantt.show_popup({
      target_element: this.edit,
      task: this.task,
    });
  }

  override update_bar_position({ x = null, width = null }) {
    const bar = this.$bar;
    if (x) {
      // get all x values of parent task
      const xs = this.task.dependencies.map((dep) => {
        return this.gantt.get_bar(dep).$bar.getX();
      });
      // child task must not go before parent
      const valid_x = xs.reduce((prev, curr) => {
        return x >= curr;
      }, x);
      if (!valid_x) {
        width = null;
        return;
      }
      this.update_attr(bar, 'x', x);
    }
    if (width && width >= this.gantt.options.column_width / 7) {
      this.update_attr(bar, 'width', width);
    }
    this.update_label_position();
    this.update_handle_position();
  }

  override date_changed() {
    let changed = false;
    const { new_start_date, new_end_date } = this.compute_start_end_date();

    if (Number(this.task._start) !== Number(new_start_date)) {
      changed = true;
      this.task._start = new_start_date;
    }

    if (Number(this.task._end) !== Number(new_end_date)) {
      changed = true;
      this.task._end = new_end_date;
    }

    if (!changed) return;

    this.gantt.trigger_event('date_change', [
      this.task,
      new_start_date,
      new_end_date,
    ]);
  }

  override compute_start_end_date(x = null, width = null) {
    const bar = this.$bar;
    const x_in_units = (x != null ? x : bar.getX()) / this.gantt.options.column_width;
    let new_start_date = date_utils.add(
      this.gantt.gantt_start,
      Math.round(x_in_units * this.gantt.options.step),
      'hour'
    );
    const width_in_units = (width != null ? width : bar.getWidth()) / this.gantt.options.column_width;
    let new_end_date = date_utils.add(
      new_start_date,
      Math.round(width_in_units * this.gantt.options.step) - 24,
      'hour'
    );

    const start_offset = this.gantt.gantt_start.getTimezoneOffset() - new_start_date.getTimezoneOffset();
    if (start_offset !== 0) {
      new_start_date = date_utils.add(
        new_start_date,
        start_offset,
        'minute'
      );
    }
    const end_offset = new_start_date.getTimezoneOffset() - new_end_date.getTimezoneOffset();
    if (end_offset !== 0) {
      new_end_date = date_utils.add(
        new_end_date,
        end_offset,
        'minute'
      );
    }

    return { new_start_date, new_end_date };
  }

  override compute_x() {
    const { step, column_width } = this.gantt.options;
    const task_start = this.task._start;
    const gantt_start = this.gantt.gantt_start;

    const start_offset = gantt_start.getTimezoneOffset() - task_start.getTimezoneOffset();
    const diff = date_utils.diff(task_start, gantt_start, 'hour') + start_offset / 60;
    let x = (diff / step) * column_width;

    if (this.gantt.view_is('Month')) {
      const diff = date_utils.diff(task_start, gantt_start, 'day');
      x = (diff * column_width) / 30;
    }
    return x;
  }

  override compute_y() {
    return (
      this.gantt.options.header_height +
      this.gantt.options.padding +
      this.task._index * (this.height + this.gantt.options.padding) +
      this.task.row_gap
    );
  }

  override update_label_position() {
    const bar = this.$bar,
      label = this.group.querySelector('.bar-label'),
      toggle = this.group.querySelector('.bar-toggle'),
      duration = this.group.querySelector('.duration'),
      editShadow = this.group.querySelector('.edit-shadow'),
      edit = this.group.querySelector('.edit');

    label.setAttribute('x', bar.getX() + (this.task.disable_expand ? 4 : 22));

    if (toggle) {
      toggle.setAttribute('style', `transform: translateX(${bar.getX()}px) translateY(${bar.getY()}px) scale(1.4)`);
    }

    if (duration) {
      duration.setAttribute('x', Math.max(bar.getX() + bar.getWidth() - duration.getBBox().width - 4, label.getX() + label.getBBox().width + 8));
    }

    if (bar.getX() + bar.getWidth() - duration.getBBox().width - 4 < label.getX() + label.getBBox().width + 8) {
      this.group.classList.add('overflow');
    }

    if (editShadow) {
      editShadow.setAttribute('cx', bar.getX() - 22);
    }

    if (edit) {
      edit.setAttribute('x', bar.getX() - 28);
    }
  }

  override update_handle_position() {
    const bar = this.$bar;
    const handleLeft = this.handle_group.querySelector('.handle.left')
    handleLeft && handleLeft.setAttribute('x', bar.getX() - 16);
    handleLeft && handleLeft.setAttribute('transform', `rotate(180, ${bar.getX() - 8}, ${bar.getY() + 11})`);
    const handleRight = this.handle_group.querySelector('.handle.right')
    handleRight && handleRight.setAttribute('x', bar.getEndX());
    const handle = this.group.querySelector('.handle.progress');
    handle && handle.setAttribute('points', this.get_progress_polygon_points());
  }
}
