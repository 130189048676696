import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Observable, catchError, mapTo, of, take } from 'rxjs'
import { ScopeOverviewActions } from '../store/actions/scope-overview.action'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class ScopeGuard implements CanActivate {
  constructor(private store: Store, private actions$: Actions, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const scopeId = parseInt(route.paramMap.get('id'))

    this.store.dispatch(ScopeOverviewActions.getCurrentScope({ scopeId }))

    if (this.router.getCurrentNavigation().extras.state?.skipWaitForLoad) {
      this.router.getCurrentNavigation().extras.state.skipWaitForLoad = false
      return of(true)
    }

    return this.actions$.pipe(
      ofType(ScopeOverviewActions.getCurrentScopeSuccess),
      take(1),
      mapTo(true),
      catchError(() => of(false))
    )
  }
}
