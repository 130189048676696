<section class="page-header">
  <div class="scope-row">
    <div class="four--columns">
      <h2 class="page-header__title">Forgot Password</h2>
    </div>
  </div>
</section>

<section class="container container--grey">
  <div class="scope-pod">
    <h4 class="scope-pod__title">Password email sent</h4>

    <div class="scope-pod__text">
      <p>You should receive an email shortly to complete your password reset.</p>
    </div>

    <br />
    <a routerLink="/login" class="btn btn--inline btn--large">Back</a>
  </div>
</section>
