<loader-modal *ngIf="(isLoading$ | async) || (isLoadingArchive$ | async) || (isLoadingColumns$ | async)"></loader-modal>
<div class="top-filters-container" [class.collapsed]='!showMainFilters'>
  <div>
    <h4 class="product-heading-bold">Top filters</h4>
    <button class="right text-link" (click)="toggleMainFilters()">
      <mat-icon class="material-symbols-rounded orange">{{ showMainFilters ? 'visibility' : 'visibility_off' }}</mat-icon
      >{{ showMainFilters ? 'Hide top filters' : 'Show Panel' }}
    </button>
  </div>
  <div>
    <div class="inner-container w-full" *ngIf="showMainFilters">
      <scope-ui-filter-card
        [icon_name]="'close'"
        [card_data]="(highlights$ | async)?.toReviewAndSign"
        [heading]="('scope.p' | lang) + ' to review and approve'"
        [sub_heading]="loggedInUser.lastLoginDate | dateFormat : 'shortDate'"
        [card_type]="'toReviewAndSign'"
        [cardActive]="cardActiveStates['toReviewAndSign']"
        (cardClicked)="handleCardClick($event)" />
      <scope-ui-filter-card
        [icon_name]="'close'"
        [card_data]="(highlights$ | async)?.created"
        [heading]="'New ' + ('scope.p' | lang) + ' have been created'"
        [sub_heading]="loggedInUser.lastLoginDate | dateFormat : 'shortDate'"
        [card_type]="'created'"
        [cardActive]="cardActiveStates['created']"
        (cardClicked)="handleCardClick($event)" />
      <scope-ui-filter-card
        [icon_name]="'close'"
        [card_data]="(highlights$ | async)?.approved"
        [heading]="('scope.p' | lang) + ' have been approved'"
        [sub_heading]="loggedInUser.lastLoginDate | dateFormat : 'shortDate'"
        [card_type]="'approved'"
        [cardActive]="cardActiveStates['approved']"
        (cardClicked)="handleCardClick($event)" />
    </div>
  </div>
</div>
<div class="scope-container">
  <div class="flex flex-row justify-end scope-sub-container items-center">
    <span *ngIf="getDisplayFilters().length > 0 || filters.name" class="filtered-count flex-1">{{(scopeTableConfig$ | async)?.totalCount}} results{{ filters.name ? ' for "' + filters.name + '"' : '' }}</span>
    <scope-ui-input
      [initialValue]='filters.name'
      [hasPlaceholder]="false"
      [inputPlaceholder]="''"
      [icon]="'search'"
      [cancelIcon]="'cancel'"
      [inputLabel]="'Search'"
      [inputClass]="'search-field'"
      (onCancel)="onCancelPressed()"
      (onEnter)="onSearchKeyPressed($event)" />
    <button class="ml-4 mr-4 text-link" aria-label="Show filters" (click)="onSelectFilters()">
      Show filters
      <mat-icon class="material-symbols-rounded orange">arrow_forward</mat-icon>
    </button>
    <mat-divider class="top-0 inline-block" vertical></mat-divider>
    <scope-ui-options-menu
      class="ml-4"
      [menuOptions]="menuOptions"
      [buttonText]="'Create New'" />
    <mat-menu #menu="matMenu" backdropClass="customize">
      <button (click)="option.click()" *ngFor="let option of scopeTypes" mat-menu-item>
        <mat-icon class="orange material-symbols-rounded">{{ option.icon }}</mat-icon
        >{{ option.name }}
      </button>
    </mat-menu>
  </div>
  <div class="scope-sub-container">
    <mat-divider/>
  </div>
  <div class="flex flex-wrap scope-sub-container">
    <ng-container *ngIf="getDisplayFilters() as displayFilters">
      <ng-container *ngFor="let filter of displayFilters">
        <div class="filter-tag">
          <span>{{scopesFilterConfig[filter[0]]}} </span>
          <span class="tag-value">{{Array.isArray(filter[1]) ? filter[1]?.join(', ') : filter[1]?.toString()}}</span><mat-icon class="material-symbols-rounded" (click)="removeFilter(filter[0])">close</mat-icon></div>
      </ng-container>
      <button *ngIf="displayFilters.length > 0" mat-button class="clear-all-tag" aria-label="Clear all filters" (click)="resetFilters()">
        Clear all
        <mat-icon iconPositionEnd class="material-symbols-rounded orange">close</mat-icon>
      </button>
    </ng-container>
  </div>
  <scope-ui-tabs (onSelect)="selectTab($event, true)" (onInit)="selectTab($event)" [fontSize]="'24px'" class="pt-4 scope-tabs">
    <scope-ui-tab tabTitle="{{('scope.p' | lang)}}">
      <div class="mt-6 scope-sub-container" [hidden]="!(isLoaded$ | async) || (isLoadingColumns$ | async)">
        <ng-container class="py-3" *ngIf="scopeTableConfig$ | async as tableData">
          <scope-ui-table
            (pageChange)="onChangePage($event)"
            (onSort)="onSort($event)"
            (onToggle)="onToggleScopePreference($event)"
            (onSelect)="onSelect($event)"
            [loggedInUser]='loggedInUser'
            [dataSource]="mappedScopesDataSource$ | async"
            [displayedColumns]="columns$ | async"
            [totalCount]="tableData?.totalCount"
            [page]="tableData?.page"
            [filterPreferences]="scopeColumnFilterPreferences$ | async"
            [deliverableColumns]="deliverableColumns"
            [tableClass]="'row-gaps'"
            [expandable]="true"
            [menuOptions]="scopeMenuOptions" >
          </scope-ui-table>
        </ng-container>
        <button *ngIf="!showArchive && (archivedScopeTableConfig$ | async)?.totalCount" class='text-link archive-btn' aria-label="View Archive" (click)="showArchive = true">
          View Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_more</mat-icon>
        </button>
        <button *ngIf="showArchive" class='text-link archive-btn' aria-label="View Archive" (click)="showArchive = false">
          Hide Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
        </button>
      </div>
      <div *ngIf="showArchive" class="archive-container" [hidden]="!(isLoaded$ | async) || !(isLoadedArchive$ | async) || (isLoadingColumns$ | async)">
        <div class="scope-sub-container">
          <ng-container class="py-3" *ngIf="archivedScopeTableConfig$ | async as tableData">
            <scope-ui-table
              (pageChange)="onChangePageArchived($event)"
              (onSort)="onSortArchived($event)"
              (onToggle)="onToggleScopePreference($event)"
              (onSelect)="onSelect($event)"
              [loggedInUser]='loggedInUser'
              [dataSource]="mappedArchivedScopesDataSource$ | async"
              [displayedColumns]="columns$ | async"
              [totalCount]="tableData?.totalCount"
              [page]="tableData?.page"
              [filterPreferences]="scopeColumnFilterPreferences$ | async"
              [deliverableColumns]="deliverableColumns"
              [tableClass]="'row-gaps'"
              [expandable]="true"
              [menuOptions]="scopeMenuOptions">
            </scope-ui-table>
          </ng-container>
          <button class='text-link archive-btn' aria-label="View Archive" (click)="showArchive = false">
            Hide Archive
            <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
          </button>
        </div>
      </div>
    </scope-ui-tab>
    <scope-ui-tab tabTitle="{{('scope_of_work.p' | lang)}}" [requiredSetting]="'SOW__GLOBAL'" [active]='route.snapshot.queryParamMap.get("tab") === "folders"'>
      <div class="mt-6 scope-sub-container" [hidden]="!(isLoaded$ | async) || (isLoadingColumns$ | async)">
        <ng-container class="py-3" *ngIf="scopeTableConfig$ | async as tableData">
          <scope-ui-table
            (pageChange)="onChangePage($event)"
            (onSort)="onSort($event)"
            (onToggle)="onToggleScopePreference($event)"
            (onSelect)="onSelect($event)"
            [loggedInUser]='loggedInUser'
            [dataSource]="mappedFoldersDataSource$ | async"
            [displayedColumns]="folderColumns$ | async"
            [totalCount]="tableData?.totalCount"
            [page]="tableData?.page"
            [filterPreferences]="folderColumnFilterPreferences$ | async"
            [tableClass]="'row-gaps'"
            [expandable]="true"
            [menuOptions]="folderMenuOptions">
          </scope-ui-table>
        </ng-container>
        <button *ngIf="!showArchive && (archivedScopeTableConfig$ | async)?.totalCount" class='text-link archive-btn' aria-label="View Archive" (click)="showArchive = true">
          View Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_more</mat-icon>
        </button>
        <button *ngIf="showArchive" class='text-link archive-btn' aria-label="View Archive" (click)="showArchive = false">
          Hide Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
        </button>
      </div>
      <div *ngIf="showArchive" class="archive-container" [hidden]="!(isLoaded$ | async) || !(isLoadedArchive$ | async) || (isLoadingColumns$ | async)">
        <div class="scope-sub-container">
          <ng-container class="py-3" *ngIf="archivedScopeTableConfig$ | async as tableData">
            <scope-ui-table
              (pageChange)="onChangePageArchived($event)"
              (onSort)="onSortArchived($event)"
              (onToggle)="onToggleScopePreference($event)"
              (onSelect)="onSelect($event)"
              [loggedInUser]='loggedInUser'
              [dataSource]="mappedArchivedFoldersDataSource$ | async"
              [displayedColumns]="folderColumns$ | async"
              [totalCount]="tableData?.totalCount"
              [page]="tableData?.page"
              [filterPreferences]="folderColumnFilterPreferences$ | async"
              [tableClass]="'row-gaps'"
              [expandable]="true"
              [menuOptions]="folderMenuOptions">
            </scope-ui-table>
          </ng-container>
          <button class='text-link archive-btn' aria-label="View Archive" (click)="showArchive = false">
            Hide Archive
            <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
          </button>
        </div>
      </div>
    </scope-ui-tab>
    <scope-ui-tab tabTitle="Templates" [active]='route.snapshot.queryParamMap.get("tab") === "templates"'>
      <div class="mt-6 scope-sub-container" [hidden]="!(isLoaded$ | async) || (isLoadingColumns$ | async)">
        <ng-container class="py-3" *ngIf="scopeTableConfig$ | async as tableData">
          <scope-ui-table
            (pageChange)="onChangePage($event)"
            (onSort)="onSort($event)"
            (onToggle)="onToggleScopePreference($event)"
            (onSelect)="onSelect($event)"
            [loggedInUser]='loggedInUser'
            [dataSource]="mappedScopesDataSource$ | async"
            [displayedColumns]="templateColumns$ | async"
            [totalCount]="tableData?.totalCount"
            [page]="tableData?.page"
            [filterPreferences]="templateColumnFilterPreferences$ | async"
            [deliverableColumns]="deliverableColumns"
            [tableClass]="'row-gaps'"
            [expandable]="true"
            [menuOptions]="scopeMenuOptions">
          </scope-ui-table>
        </ng-container>
        <button *ngIf="!showArchive && (archivedScopeTableConfig$ | async)?.totalCount" class='text-link archive-btn' aria-label="View Archive" (click)="showArchive = true">
          View Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_more</mat-icon>
        </button>
        <button *ngIf="showArchive" class='text-link archive-btn' aria-label="View Archive" (click)="showArchive = false">
          Hide Archive ({{(archivedScopeTableConfig$ | async)?.totalCount}})
          <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
        </button>
      </div>
      <div *ngIf="showArchive" class="archive-container" [hidden]="!(isLoaded$ | async) || !(isLoadedArchive$ | async) || (isLoadingColumns$ | async)">
        <div class="scope-sub-container">
          <ng-container class="py-3" *ngIf="archivedScopeTableConfig$ | async as tableData">
            <scope-ui-table
              (pageChange)="onChangePageArchived($event)"
              (onSort)="onSortArchived($event)"
              (onToggle)="onToggleScopePreference($event)"
              (onSelect)="onSelect($event)"
              [loggedInUser]='loggedInUser'
              [dataSource]="mappedArchivedScopesDataSource$ | async"
              [displayedColumns]="templateColumns$ | async"
              [totalCount]="tableData?.totalCount"
              [page]="tableData?.page"
              [filterPreferences]="templateColumnFilterPreferences$ | async"
              [deliverableColumns]="deliverableColumns"
              [tableClass]="'row-gaps'"
              [expandable]="true"
              [menuOptions]="scopeMenuOptions">
            </scope-ui-table>
          </ng-container>
          <button class='text-link archive-btn' aria-label="View Archive" (click)="showArchive = false">
            Hide Archive
            <mat-icon class="material-symbols-rounded orange">expand_less</mat-icon>
          </button>
        </div>
      </div>
    </scope-ui-tab>
  </scope-ui-tabs>
</div>
