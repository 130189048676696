import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiDemoComponent } from './ui-demo/ui-demo.component';
import { RouterModule } from '@angular/router';
import { BreadcrumbsDemoComponent } from '@app/ui-demo/breadcrumbs-demo/breadcrumbs-demo.component';
import { UiDemoRoutingModule } from './ui-demo-routing.module';
import { ScopeUiBreadcrumbsComponent } from '@app/shared/components/scope-ui-breadcrumbs/scope-ui-breadcrumbs.component';

@NgModule({
  declarations: [UiDemoComponent],
  imports: [CommonModule, RouterModule, BreadcrumbsDemoComponent, UiDemoRoutingModule, ScopeUiBreadcrumbsComponent],
  exports: [RouterModule],
})
export class UiDemoModule {}
