import { Injectable } from "@angular/core";
import { SecondParty } from "../model/second-party.model";
import { map, Observable } from "rxjs";
import { environment } from "@envs/environment";
import { HttpClient } from '@angular/common/http';
import { plainToInstance } from "class-transformer";


@Injectable({ providedIn: 'root' })
export class SecondPartyService {
  private readonly BASE_URL: string = environment.scopeApiUrl + "/second-party";

  constructor(private httpClient: HttpClient) { }

  retrieveSecondParties(): Observable<SecondParty[]> {
    return this.httpClient.get<SecondParty[]>(this.BASE_URL)
      .pipe(
        map(res => res.map(api => plainToInstance(SecondParty, api)))
      )
  }
}
