import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import {MenuOptions} from "@core/model/definitions/menu-options.interface";

@Component({
  selector: 'scope-ui-options-separated-menu',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './scope-ui-options-separated-menu.component.html',
  styleUrls: ['./scope-ui-options-separated-menu.component.scss'],
})
export class ScopeUiOptionsMenuSeparatedComponent {
  @Input() menuOptions!: MenuOptions[];

  @Input() secondaryMenuOptions!: MenuOptions[];

  @Input() buttonText!: string;

  @Input() hidden!: boolean;

  @Output() onClickEvent = new EventEmitter<any>();

  @Output() onOptionClickEvent = new EventEmitter<any>();

  onClick() {
    this.onClickEvent.emit();
  }

  onOptionClick(option: any) {
    this.onOptionClickEvent.emit(option);
  }
}
