import { Directive, ElementRef, Input } from '@angular/core';

@Directive({ selector: '[scroll-siblings]' })
export class ScrollSiblingsDirective {
  @Input('target-class') targetClass!: string;

  constructor(private _elemRef: ElementRef) {}

  ngOnInit() {
    let active = null;
    this._elemRef.nativeElement.addEventListener('mouseover', () => active = this._elemRef.nativeElement);
    this._elemRef.nativeElement.addEventListener('mouseover', () => active = this._elemRef.nativeElement);
    this._elemRef.nativeElement.addEventListener('mousemove', () => active = this._elemRef.nativeElement);
    this._elemRef.nativeElement.addEventListener('scroll', () => {
      document.querySelectorAll(`.${this.targetClass}`).forEach(target => {
        if (active === target) return;
        if (active === null) return;
        target.scrollTop = active.scrollTop;
      })
    });
    this._elemRef.nativeElement.addEventListener('mouseleave', () => active = null);

    setTimeout(() => {
      let firstElement = document.querySelectorAll(`.${this.targetClass}`).item(0)
      this._elemRef.nativeElement.scrollTop = firstElement.scrollTop
    })
  }
}
