import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromLibraryManagement from './store/reducers/index';
import { EffectsModule } from '@ngrx/effects';
import { LibraryManagementEffects } from './store/effects/library-management.effects';

const EFFECTS = [LibraryManagementEffects];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromLibraryManagement.featureKey, fromLibraryManagement.reducers),
    EffectsModule.forFeature(EFFECTS),
  ],
})
export class LibraryManagementNgrxModule {}
