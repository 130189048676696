export class MsaLineItem {
    id: string | undefined;
    name: string | undefined;
    amount: number | string | undefined;
    type: string | undefined;
    chargeType: string | undefined;

    constructor(context?: { id?: string, name?: string, amount?: number | string, type?: string, chargeType?: string }) {
        context = context ? context : {};
        this.id = context.id;
        this.name = context.name;
        this.amount = context.amount;
        this.type = context.type;
        this.chargeType = context.chargeType;
    }

    getValueAsStr(): string {
        if (this.chargeType == null) {
            throw "Charge type is not set";
        }
        const name = this.name;
        const chargeType = this.chargeType == 'FEE' ? 'Fee' : 'Rebate';
        const amount = this.amount + (this.type == 'AMOUNT' ? '$' : '%');
        return `${name} (${chargeType}, ${amount})`;
    }
}
