import { Component, Input } from '@angular/core';

@Component({
  selector: 'message-display',
  templateUrl: './message-display.component.html',
  styleUrls: ['./message-display.component.scss'],
})
export class MessageDisplayComponent {
  @Input() message!: string;
  @Input() heading!: string;
  @Input() subHeading!: string;
  @Input() link!: string;
  @Input() linkName!: string;
}
