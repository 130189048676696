import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { ScopeSection } from '@app/features/scope-overview/model/scope-section'
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service'
import { merge } from 'rxjs'
import { ScopeComponent } from '@app/features/scoping/models/component.model'
import { DeliverableSection } from "@app/features/scope-overview/model/deliverable-section"
import { Department } from '@app/features/scoping/models/department.model'
import { Role } from '@app/features/scoping/models/role.model'
import { plainToInstance } from 'class-transformer'
import { NgIf } from '@angular/common'
import {
  ScopeUiDatepickerComponent
} from '@shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component'
import { SharedModule } from '@shared/shared.module'
import { MatMenuTrigger } from '@angular/material/menu'
import CustomGantt from '@app/features/scope-overview/components/scope-tab/scope-timeline/custom-gantt'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

@Component ({
  selector: 'scope-timeline',
  templateUrl: './scope-timeline.component.html',
  styleUrls: ['./scope-timeline.component.scss'],
  imports: [
    NgIf,
    ScopeUiDatepickerComponent,
    SharedModule,
    ScopeUiInputComponent,
  ],
  standalone: true,
})
export class ScopeTimelineComponent implements AfterViewInit {
  @ViewChild (MatMenuTrigger) dateEditorTrigger: MatMenuTrigger
  @ViewChild ('dateEditorTrigger') dateEditorTriggerRef: ElementRef
  @Input() scopeStartDate: Date
  @Input() scopeEndDate: Date
  @Input() sections: ScopeSection[]
  @Input() set deliverables(value: Deliverable[]) {
    this._deliverables = value
    this.drawGanttChart();
    this.gantt?.refresh(this.tasks)
  }
  @Input() dateFormat: string
  @Input() disabled: boolean
  @Output() onUpdateElement!: EventEmitter<ScopeSection | Deliverable | ScopeComponent | DeliverableSection>;
  @Output() onFetchDeliverable!: EventEmitter<number>;

  gantt: CustomGantt
  _deliverables: Deliverable[]
  tasks: any[]
  selectedTask: any

  constructor(private scopeTabService: ScopeTabService) {
    this.onUpdateElement = new EventEmitter<ScopeSection | Deliverable | ScopeComponent | DeliverableSection>()
    this.onFetchDeliverable = new EventEmitter<number>()
  }

  ngAfterViewInit() {
    this.drawGanttChart()
    let minDate = dayjs.utc(this.scopeStartDate).startOf('day').toDate()
    let maxDate = dayjs.utc(this.scopeEndDate).startOf('day').toDate()

    this.gantt = new CustomGantt('#gantt', this.tasks, {
      minimum_date: minDate,
      maximum_date: maxDate,
      view_mode: 'Week',
      header_height: 63,
      bar_height: 24,
      padding: 16,
      on_click: this.toggleElement,
      on_date_change: this.updateDate,
      on_edit: this.onEditElement,
      disabled: this.disabled
    })

    merge(this.scopeTabService.sectionSelectedStates$, this.scopeTabService.deliverableSelectedStates$, this.scopeTabService.deliverableSectionSelectedStates$,
          this.scopeTabService.componentSelectedStates$, this.scopeTabService.departmentSelectedStates$).subscribe(() => {
      this.drawGanttChart();
      this.gantt?.refresh(this.tasks)
    })
  }

  toggleElement = (task) => {
    switch (task.taskType) {
      case 'section':
        this.scopeTabService.toggleSection(parseInt(task.id))
        break
      case 'deliverable':
        if (!task.componentsInitialised) {
          this.onFetchDeliverable.emit(parseInt(task.id))
        }
        this.scopeTabService.toggleDeliverable(parseInt(task.id))
        break
      case 'deliverable-section':
        this.scopeTabService.toggleDeliverableSection(parseInt(task.id))
        break
      case 'component':
        this.scopeTabService.toggleComponent(parseInt(task.id))
        break
      case 'department':
        this.scopeTabService.toggleDepartment(parseInt(task.id))
    }
  }

  updateDate = (task: any, start: Date, end: Date) => {
    if (this.selectedTask) {
      this.selectedTask.startDate = start
      this.selectedTask.endDate = end
    }
    switch (task.taskType) {
      case 'section':
        this.onUpdateElement.emit(
          plainToInstance(ScopeSection, { ...task, id: parseInt(task.id), startDate: start, endDate: end })
        );
        break
      case 'deliverable':
        this.onUpdateElement.emit(
          plainToInstance(Deliverable, { ...task, id: parseInt(task.id), startDate: start, endDate: end })
        );
        break
      case 'deliverable-section':
        this.onUpdateElement.emit(
          plainToInstance(DeliverableSection, { ...task, id: parseInt(task.id), startDate: start, endDate: end })
        );
        break
      case 'component':
        this.onUpdateElement.emit(
          plainToInstance(ScopeComponent, { ...task, id: parseInt(task.id), startDate: start, endDate: end })
        );
    }
  }

  onSelectStartDate(startDate: Date) {
    this.selectedTask.startDate = startDate
    const endDate = startDate > this.selectedTask.endDate ? startDate : this.selectedTask.endDate
    this.updateDate(this.selectedTask, startDate, endDate)
  }

  onSelectEndDate(endDate: Date) {
    this.selectedTask.endDate = endDate
    this.updateDate(this.selectedTask, this.selectedTask.startDate, endDate)
  }

  onEditElement = (args) => {
    this.selectedTask = args.task
    this.dateEditorTriggerRef.nativeElement.style.top = args.target_element.y.baseVal.value + this.gantt.$container.offsetTop + "px"
    this.dateEditorTriggerRef.nativeElement.style.left = args.target_element.x.baseVal.value + this.gantt.$container.offsetLeft + "px"
    this.dateEditorTrigger.openMenu()
  }

  drawGanttChart () {
    this.tasks = []
    this.sections.forEach((section) => {
      this.mapSection(section)
    })

    this._deliverables.forEach((deliverable) => {
      this.mapDeliverable(deliverable)
    })
  }

  duration(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24)) + 1;
  }

  mapSection(section: ScopeSection) {
    let isOpen = this.scopeTabService.sectionSelectedStates[section.id]
    let sectionTask = {
      ...section,
      id: section.id.toString() + '-s',
      start: dayjs.utc(section.startDate || this.scopeStartDate).startOf('day').toDate(),
      end: dayjs.utc(section.endDate || this.scopeEndDate).startOf('day').toDate(),
      minDate: this.scopeStartDate,
      maxDate: this.scopeEndDate,
      minDateClass: 'min min-scope',
      maxDateClass: 'max max-scope',
      taskType: 'section',
      custom_class: isOpen && !section.deliverables?.length ? 'section empty-section' : 'section',
      row_gap: 10,
      row_gap_after: isOpen && !section.deliverables?.length ? 100 : 0,
      isOpen: isOpen,
      duration: this.duration(section.startDate || this.scopeStartDate, section.endDate || this.scopeEndDate) + 'd'
    }
    this.tasks.push(sectionTask)

    if (this.scopeTabService.sectionSelectedStates[section.id]) {
      section.deliverables.forEach((deliverable) => {
        this.mapDeliverable(deliverable, section, sectionTask.id)
      })
    }
  }

  mapDeliverable(deliverable: Deliverable, section: ScopeSection = null, sectionTaskId: string = null) {
    let isOpen = this.scopeTabService.deliverableSelectedStates[deliverable.id]
    let deliverableTask = {
      ...deliverable,
      id: deliverable.id.toString() + '-d',
      start: dayjs.utc(deliverable.startDate).startOf('day').toDate(),
      end: dayjs.utc(deliverable.endDate).startOf('day').toDate(),
      minDate: section ? section.startDate : this.scopeStartDate,
      maxDate: section ? section.endDate : this.scopeEndDate,
      minDateClass: section ? 'min min-stage' : 'min min-scope',
      maxDateClass: section ? 'max max-stage' : 'max max-scope',
      taskType: 'deliverable',
      custom_class: 'deliverable',
      row_gap: !section ? 10 : 1,
      isOpen: isOpen,
      duration: this.duration(deliverable.startDate, deliverable.endDate) + 'd',
      dependencies: sectionTaskId ? [sectionTaskId] : []
    }
    this.tasks.push(deliverableTask)

    if (this.scopeTabService.deliverableSelectedStates[deliverable.id] && deliverable.components) {
      deliverable.defaultSectionComponents
        .sort((a, b) => a.order - b.order)
        .forEach((component) => {
          this.mapComponent(deliverable, null, null, deliverableTask.id, component)
        })

      deliverable.sections.forEach((section) => {
        this.mapDeliverableSection(deliverable, deliverableTask.id, section)
      })
    }
  }

  mapComponent(deliverable: Deliverable, section: DeliverableSection, sectionTaskId: string, deliverableTaskId: string, component: ScopeComponent) {
    let isOpen = this.scopeTabService.componentSelectedStates[component.id]
    let componentTask = {
      ...component,
      id: component.id.toString() + '-c',
      start: dayjs.utc(component.startDate || section?.startDate || deliverable.startDate).startOf('day').toDate(),
      end: dayjs.utc(component.endDate || section?.endDate || deliverable.endDate).startOf('day').toDate(),
      minDate: section ? section.startDate : deliverable.startDate,
      maxDate: section ? section.endDate : deliverable.endDate,
      minDateClass: section ? 'min min-section' : 'min min-deliverable',
      maxDateClass: section ? 'max max-section' : 'max max-deliverable',
      taskType: 'component',
      custom_class: 'component',
      row_gap: 1,
      isOpen: isOpen,
      duration: this.duration(component.startDate || deliverable.startDate, component.endDate || deliverable.endDate) + 'd',
      dependencies: sectionTaskId ? [sectionTaskId] : [deliverableTaskId]
    }
    this.tasks.push(componentTask)

    if (this.scopeTabService.componentSelectedStates[component.id]) {
      component.departments.forEach((department) => {
        this.mapDepartment(deliverable, component, componentTask.id, department)
      })
    }
  }

  mapDeliverableSection(deliverable: Deliverable, deliverableTaskId, section: DeliverableSection) {
    let isOpen = this.scopeTabService.deliverableSectionSelectedStates[section.id]
    let sectionTask = {
      ...section,
      id: section.id.toString() + '-ds',
      start: dayjs.utc(section.startDate || deliverable.startDate).startOf('day').toDate(),
      end: dayjs.utc(section.endDate || deliverable.endDate).startOf('day').toDate(),
      minDate: deliverable.startDate,
      maxDate: deliverable.endDate,
      minDateClass: 'min min-deliverable',
      maxDateClass: 'max max-deliverable',
      taskType: 'deliverable-section',
      custom_class: 'deliverable-section',
      row_gap: 1,
      isOpen: isOpen,
      duration: this.duration(section.startDate || deliverable.startDate, section.endDate || deliverable.endDate) + 'd',
      deliverableId: deliverable.id,
      dependencies: [deliverableTaskId]
    }
    this.tasks.push(sectionTask)

    if (this.scopeTabService.deliverableSectionSelectedStates[section.id]) {
      section.getComponents(deliverable)
        .sort((a, b) => a.order - b.order)
        .forEach((component) => {
          this.mapComponent(deliverable, section, sectionTask.id, deliverableTaskId, component)
        })
    }
  }

  mapDepartment(deliverable: Deliverable, component: ScopeComponent, componentTaskId: string, department: Department) {
    let isOpen = this.scopeTabService.departmentSelectedStates[department.id]
    let endDate = new Date(component.startDate || deliverable.startDate)
    endDate.setHours(endDate.getHours()+department.getTotalRateCardHours())

    let departmentTask = {
      ...department,
      id: department.id.toString() + '-dep',
      start: dayjs.utc(component.startDate || deliverable.startDate).startOf('day').toDate(),
      end: dayjs.utc(endDate).startOf('day').toDate(),
      taskType: 'department',
      custom_class: 'department',
      row_gap: 1,
      isOpen: isOpen,
      disable_resize: true,
      duration: department.getTotalRateCardHours() + 'h',
      dependencies: [componentTaskId]
    }
    this.tasks.push(departmentTask)

    if (this.scopeTabService.departmentSelectedStates[department.id]) {
      department.roles.forEach((role) => {
        this.mapRole(deliverable, component, departmentTask.id, role)
      })
    }
  }

  mapRole(deliverable: Deliverable, component: ScopeComponent, departmentTaskId: string, role: Role) {
    let endDate = new Date(component.startDate || deliverable.startDate)
    endDate.setHours(endDate.getHours()+role.getRateCardHours())

    this.tasks.push({
      ...role,
      id: role.id.toString() + '-r',
      start: dayjs.utc(component.startDate || deliverable.startDate).startOf('day').toDate(),
      end: dayjs.utc(endDate).startOf('day').toDate(),
      taskType: 'role',
      custom_class: 'role',
      row_gap: 1,
      disable_resize: true,
      disable_expand: true,
      duration: role.getRateCardHours() + 'h',
      dependencies: [departmentTaskId]
    })
  }
}
