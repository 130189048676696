import { Type } from 'class-transformer';
import { CompanySummary } from '@core/model/company-summary.model';
import { SecondParty } from '@core/model/second-party.model';
import { SecondPartyLocation } from '@app/features/scope-overview/model/second-party-location.model';

export class SecondPartyContact {
  id!: number
  contactName!: string
  phone!: string
  email!: string
  @Type(() => CompanySummary) company!: CompanySummary;
  @Type(() => SecondParty) secondParty!: SecondParty;
  @Type(() => SecondPartyLocation) location!: SecondPartyLocation;
}
