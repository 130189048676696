import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { MatSidenavModule } from '@angular/material/sidenav'
import { CommonModule } from '@angular/common'
import { ScopeUiAutocompleteComponent } from '../ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { ScopeUiInputComponent } from '../ui-components/scope-ui-input/scope-ui-input.component'
import { SharedModule } from '@app/shared/shared.module'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { untilDestroyed } from '@app/shared/utils/utils'
import { FindReplaceBreadcrumb } from '@app/core/model/definitions/find-replace-breadcrumb.interface'
import { ScopeReplacementRole } from '@app/features/scope-overview/model/find-repace-roles.model'
import { FindRolesSearchParams } from '@core/model/definitions/findRoleSearchParams.interface'
import { NgxMaskDirective } from 'ngx-mask'

@Component({
  selector: 'find-replace',
  standalone: true,
  imports: [CommonModule, MatSidenavModule, ScopeUiAutocompleteComponent, ScopeUiInputComponent, SharedModule, NgxMaskDirective],
  templateUrl: './find-replace.component.html',
  styleUrls: ['./find-replace.component.scss'],
})
export class FindReplaceComponent implements OnInit, OnDestroy {
  events: string[] = []
  isReplaceSelected!: boolean

  breadcrumbsForm!: FormGroup

  @Input() availableRoles!: any[]
  @Input() availableDeliverables!: any[]
  @Input() availableComponents!: any[]
  @Input() availableDepartments!: any[]
  @Input() replaceSummaryResponse!: ScopeReplacementRole
  @Input() replaceSummarySuccess!: boolean | any
  @Input() revertSummaryResponse!: any
  @Input() revertSummarySuccess!: boolean | any
  @Input() filteredRoles!: any
  @Input() breadcrumbs: any[] = []
  @Input() deliverablesCount!: any
  @Input() isLoading!: boolean | null
  @Input() currencyCode!: string

  @Output() onSearchReplaceRoleEvent = new EventEmitter<string>()
  @Output() onSelectionChange = new EventEmitter<any>()
  @Output() onSearchKeyPressedEvent = new EventEmitter<any>()
  @Output() onSearch = new EventEmitter<FindRolesSearchParams>()
  @Output() onSubmitFindReplace = new EventEmitter<any>()
  @Output() onRevert = new EventEmitter<any>()
  @Output() onCloseDrawer = new EventEmitter<any>()
  @Output() onOpenDrawer = new EventEmitter<any>()

  selectedRole!: any
  selectedReplaceRole: any
  searchFilters: FindRolesSearchParams = {}
  showFilters: boolean
  showMoreFilters: boolean

  private readonly destroy$

  constructor(private fb: FormBuilder) {
    this.destroy$ = untilDestroyed()
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    console.log('Items destroyed')
  }

  ngOnInit(): void {
    this.breadcrumbsForm = this.fb.group({
      breadcrumbs: this.fb.array([]),
    })

    this.addBreadcrumbs()

    this.breadcrumbsForm
      ?.get('breadcrumbs')
      ?.valueChanges.pipe(this.destroy$())
      .subscribe((values: any) => {
        this.isReplaceSelected = values.some((value: any) => value.isChecked)
      })
  }

  get breadcrumbsArray() {
    return this.breadcrumbsForm.get('breadcrumbs') as FormArray
  }

  addBreadcrumbs() {
    for (const breadcrumb of this.breadcrumbs) {
      const breadcrumbGroup = this.fb.group({
        isChecked: [false],
        minutes: [this.minutesToHours(breadcrumb.minutes)],
      })
      this.breadcrumbsArray.push(breadcrumbGroup)
    }
  }

  minutesToHours(minutes: number): string {
    const hours = Math.floor(minutes / 60)
    return `${hours.toString()}`
  }

  hoursToMinutes(hours: string | number): number {
    return Number(hours) * 60
  }

  onExpandFilter() {
    this.showMoreFilters = !this.showMoreFilters
  }

  onSearchAutoComplete(event: any) {
    this.searchFilters = { ...this.searchFilters, roleName: event.searchString }
    this.onSearch.emit(this.searchFilters)
  }

  onSelectDeliverableFilter(event: any) {
    if (event.event) {
      this.searchFilters = { ...this.searchFilters, deliverableName: event.event?.name }
    } else {
      let { deliverableName: _, ...result } = this.searchFilters
      this.searchFilters = result
    }
    this.onSearch.emit(this.searchFilters)
  }

  onSelectComponentFilter(event: any) {
    if (event.event) {
      this.searchFilters = { ...this.searchFilters, componentName: event.event?.name }
    } else {
      let { componentName: _, ...result } = this.searchFilters
      this.searchFilters = result
    }
    this.onSearch.emit(this.searchFilters)
  }

  onSelectDepartmentFilter(event: any) {
    if (event.event) {
      this.searchFilters = { ...this.searchFilters, departmentName: event.event?.name }
    } else {
      let { departmentName: _, ...result } = this.searchFilters
      this.searchFilters = result
    }
    this.onSearch.emit(this.searchFilters)
  }

  onSelectedValue($event: { event: any; componentId?: any }) {
    this.selectedRole = $event.event
    this.showFilters = !!$event.event
    if (!$event.event) this.showMoreFilters = false
    this.onSelectionChange.emit($event)
    this.generateBreadcrumbs()
  }

  selectReplacement($event: { event: any; componentId?: any }) {
    this.selectedReplaceRole = $event.event
    this.onSelectionChange.emit($event)
  }

  generateBreadcrumbs() {
    const formArray = this.breadcrumbsForm.get('breadcrumbs') as FormArray
    formArray.clear()
    this.addBreadcrumbs()
  }

  onSearchKeyPressed(event: any) {
    this.onSearchKeyPressedEvent.emit(event)
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement
    input.value = input.value.replace(/[^0-9]/g, '')
  }

  onHoursInputChange(deliverableBreadcrumb: FindReplaceBreadcrumb, controlIndex: number) {
    const updatedBreadcrumb = {
      ...deliverableBreadcrumb,
      showHideHoursInput: !deliverableBreadcrumb.showHideHoursInput,
    }

    this.breadcrumbs = [
      ...this.breadcrumbs.slice(0, controlIndex),
      updatedBreadcrumb,
      ...this.breadcrumbs.slice(controlIndex + 1),
    ]

    if (updatedBreadcrumb.showHideHoursInput) {
      this.breadcrumbsArray.at(controlIndex).get('minutes')?.setValue(this.minutesToHours(updatedBreadcrumb.minutes))
    } else {
      updatedBreadcrumb.minutes = this.hoursToMinutes(this.breadcrumbsArray.at(controlIndex).get('minutes')?.value)
    }
  }

  onSearchReplace(event: any) {
    this.onSearchReplaceRoleEvent.emit(event.searchString)
  }

  closeDrawer() {
    this.onCloseDrawer.emit()
    this.isReplaceSelected = false
  }

  submitFindReplace() {
    const selectedBreadcrumbs: any = []

    this.breadcrumbsArray.controls.forEach((control: any, index: any) => {
      if (control.value.isChecked) {
        selectedBreadcrumbs.push(this.breadcrumbs[index])
      }
    })

    if (selectedBreadcrumbs.length === 0) {
      this.onSubmitFindReplace.emit({ roles: this.breadcrumbs, roleId: this.selectedRole.roleId, replaceRoleId: this.selectedReplaceRole.id })
    } else {
      this.onSubmitFindReplace.emit({ roles: selectedBreadcrumbs, roleId: this.selectedRole.roleId, replaceRoleId: this.selectedReplaceRole.id })
    }
  }

  revertReplacements(responseId: number) {
    this.onRevert.emit(responseId)
  }
}
