import { Company } from '@core/model/company.model';
import { User } from '@core/model/user.model';
import {
  setAuthorisedAsChildCompany, trackById,
  untilDestroyed,
} from '@shared/utils/utils';
import { Component, HostListener } from '@angular/core';
import { Store } from '@ngrx/store'
import { AuthActions } from '@core/store';
import { AuthSelectors } from '@core/store/selectors/auth.selector';
import { LoginService } from '@app/features/user-account/services/login.service';
import { distinctUntilChanged } from 'rxjs';
import { AuthService } from '@core/service/auth.service'

@Component({
  selector: 'app-parent-child-switcher',
  templateUrl: './parent-child-switcher.component.html',
  styleUrls: ['./parent-child-switcher.component.scss']
})
export class ParentChildSwitcherComponent {
  private readonly destroy$;

  showChildSwitcher: boolean = false
  authorisedAsChildCompany!: boolean
  childCompanies: Company[] = []
  childCompaniesAccessRules: any[] = []
  loggedInUser!: User
  originalCompany!: Company | undefined
  selectedCompany!: Company
  selectedCompanyName!: string
  loading: boolean = false

  childCompanies$ = this.store.select(AuthSelectors.selectChildCompanies)
  childCompaniesAccessRules$ = this.store.select(AuthSelectors.selectChildCompaniesAccessRules)

  constructor(private loginService: LoginService,
              private store: Store,
              private authService: AuthService) {
    this.destroy$ = untilDestroyed();
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data === 'updateParentChildAccessRules') {
      this.store.dispatch(AuthActions.getChildCompaniesAccessRules());
    }
    if (event.data === 'updateCompanySettings') {
      this.store.dispatch(AuthActions.myAccount())
    }
  }

  ngOnInit() {
    this.loggedInUser = this.authService.loggedInUser;
    if (!this.originalCompany){
      this.setOriginalCompany();
    }
    this.store.dispatch(AuthActions.getChildCompanies());
    this.store.dispatch(AuthActions.getChildCompaniesAccessRules());
    this.childCompanies$.subscribe(c => this.childCompanies = c);
    this.childCompaniesAccessRules$.subscribe(c => this.childCompaniesAccessRules = c);
  }

  accountUnavailable(childId: number | string) {
    if (this.loggedInUser.company.hasApplicationSetting("COMPANY_CHILD_ACCOUNT_ACCESS_RULES") || this.loggedInUser.company.parentCompany != null) {
      this.childCompaniesAccessRules$.pipe(distinctUntilChanged()).subscribe(c => this.childCompaniesAccessRules = c);
      if (this.childCompaniesAccessRules.length == 0) {
        return true
      } else {
        return !this.childCompaniesAccessRules
          .filter(c => c.member.id === this.loggedInUser.id)
          .map(r => r.childCompany).find(c => c.id == childId)
      }
    } else {
      return false
    }
  }

  removeAccessToSwitcher() {
    if (this.loggedInUser.company.hasApplicationSetting("COMPANY_CHILD_ACCOUNT_ACCESS_RULES")) {
      return this.childCompaniesAccessRules.filter(c => c.member.id === this.loggedInUser.id).length == 0;
    } else {
      return false
    }
  }

  onChangeCurrentCompany(companyName: string) {
    this.loading = true;
    const company = this.findCompany(companyName);
    this.changeCurrentCompany(company)
  }

  changeCurrentCompany(newCompany: Company) {
    if (newCompany.id === this.loggedInUser.company.parentCompany?.id) {
      setAuthorisedAsChildCompany(null);
    } else {
      setAuthorisedAsChildCompany(newCompany.id);
    }
    setTimeout(() => {
      location.reload();
    }, 1500)
  }

  findCompany(companyName: string) {
    let company;
    if (this.originalCompany?.name == companyName) {
      company = this.originalCompany
    } else {
      this.childCompanies.forEach(c=> {
        if (c.name == companyName) {
          company = c
        }
      })
    }
    return company;
  }

  setOriginalCompany() {
    if (this.loggedInUser.company.parentCompany == null) {
      this.originalCompany = this.loggedInUser.company
      this.selectedCompany = this.loggedInUser.company
      this.selectedCompanyName = this.loggedInUser.company.name
    } else if (!this.originalCompany) {
      this.originalCompany = this.loggedInUser.company.parentCompany
      this.selectedCompany = this.loggedInUser.company
      this.selectedCompanyName = this.loggedInUser.company.name
      this.authorisedAsChildCompany = true
    }
  }

  protected readonly trackById = trackById;
}
