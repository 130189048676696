<div class="trade-container">
  <span class="title">{{ title }}</span>
  <div class="trade-columns gap-2">
    <div class="trade-col">
      <div class="trade-labels">
        <i class="material-symbols-rounded">description</i>
        <p>FROM:</p>
      </div>
      <div class="flex pt-1">
        <ng-content select="[activity-content]"></ng-content>
      </div>
      <div>
        <span class="inner-labels">
          Type: <b>{{ attributes?.traded_from?.sourceType }}</b>
        </span>

        <span class="inner-labels" *ngIf="attributes?.traded_from?.discipline?.name">
          Discipline: <b>{{ attributes?.traded_from?.discipline?.name }}</b>
        </span>

        <span class="inner-labels">
          Budget: <b>{{ attributes?.traded_from.budget ? (attributes?.traded_from?.budget?.amount | currency: attributes?.traded_from?.budget?.currency) : '-' }}</b>
        </span>
      </div>
    </div>
    <div class="trade-col">
      <div class="trade-labels">
        <i class="material-symbols-rounded">description</i>
        <p>TO:</p>
      </div>
      <div class="flex pt-1">
        <ng-content></ng-content>
      </div>
      <div>
        <span class="inner-labels">
          Type: <b>{{ tradedTo?.sourceType }}</b>
        </span>

        <span class="inner-labels" *ngIf="tradedTo?.discipline?.name">
          Discipline: <b>{{ tradedTo?.discipline?.name }}</b>
        </span>

        <span class="inner-labels">
          Budget: <b>{{ tradedTo?.budget ? (tradedTo?.budget?.amount | currency: tradedTo?.budget?.currency) : '-' }}</b>
        </span>
      </div>
    </div>
  </div>
</div>
