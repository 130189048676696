import { inject } from '@angular/core';
import { ResolveFn, Router, UrlTree } from '@angular/router';
import { selectRouteData } from '@core/store/selectors/router.selector';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from '@core/service/auth.service'
import { tap } from 'rxjs/operators'

export const roleGuard: ResolveFn<Observable<boolean | UrlTree>> = () => {
  const store = inject(Store);
  const router = inject(Router);
  const authService = inject(AuthService);

  return store.pipe(
    select(selectRouteData),
    tap((routeData: any) => {
      const requiredRole = routeData?.role;
      const hasRole = authService.loggedInUser?.userRole === requiredRole;
      return hasRole ? true : router.parseUrl('/unauthorised');
    })
  );
};
