import { Component, Inject } from '@angular/core';
import { ScopeVersion } from '@core/model/scope-version';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { Preference } from '@core/model/user-preferences.interface';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogEventsService } from '@app/features/scope-overview/service/dialog-events.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { PipesModule } from '@shared/pipe/index.module';
import { ScopeUiTableComponent } from '@shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { CdnConfig } from "@app/core/model/cdn-config.model";
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
import { User } from '@core/model/user.model';
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '@shared/directives/index.module'
import { trackById } from '@shared/utils/utils';

export class ChangeScopeOutputModalConfig {
  currentScope: ScopeVersion
  user: User
  deliverables: Deliverable[]
  deliverableColumns: ReviewSubmitModalDeliverableColumns
  limitModalWidth?: boolean
  title: string
  body?: string
  text: string
}

export class ParentChildOutputWhitelistInfo {
  inheritAll: boolean
  templatesIds: number[]
}

export type ReviewSubmitModalDeliverableColumns = string[] | Preference[]


@Component({
  selector: 'app-change-scope-output-modal',
  standalone: true,
  imports: [CommonModule, PipesModule, ScopeUiTableComponent, MatDialogModule, NgOptimizedImage, MatTooltipModule, DirectivesModule],
  templateUrl: './change-scope-output-modal.component.html',
  styleUrls: ['./change-scope-output-modal.component.scss']
})
export class ChangeScopeOutputModalComponent {
  modalForm: UntypedFormGroup

  comment: string = ''
  cdnConfig: CdnConfig
  cdnConfig$: Observable<CdnConfig | undefined>
  listOutputTemplates : OutputTemplate[] = [];
  inheritAll: boolean
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChangeScopeOutputModalConfig,
    private scopeService: ScopeOverviewService, private store: Store, private dialogEventsService: DialogEventsService
  ) {
    this.modalForm = new UntypedFormGroup({ comment: new FormControl('') })
    this.cdnConfig$ = this.store.select(BootstrapSelectors.selectCdnConfig)
    this.getListOfCompanyOutputTemplates()
  }

  ngOnInit() {
    this.getCdnConfig()
  }

  changeMasterTemplate(text: number, hasOverride: boolean) {
    this.dialogEventsService.emitEvent({ key: 'changeMasterTemplate', optionalData: { text, hasOverride } })
  }

  getCdnConfig() {
    this.cdnConfig$.subscribe((config: CdnConfig) => {
      this.cdnConfig = config!
    })
  }

  getListOfCompanyOutputTemplates() {
    const whitelistPromise = this.scopeService.getParentOutputTemplatesWhitelist(this.data.user.company.id);
    this.scopeService.getAllCompanyCustomOutputTemplatesScopeType().subscribe(templates => {
      this.listOutputTemplates = templates;
      whitelistPromise.subscribe(whitelist => {
        this.inheritAll = whitelist.inheritAll
        this.listOutputTemplates.forEach(template =>{
          if ((whitelist.inheritAll && template.company.id === this.data.user.company.parentCompany.id) || (whitelist.templatesIds.length > 0 && whitelist.templatesIds?.contains(template.id))){
            template.inherited = true;
          }
        })
      })
    })
  }

  protected readonly trackById = trackById;
}
