import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { map } from 'rxjs';
import { Store } from '@ngrx/store';
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector';
import { BootstrapConfig } from '@core/model/bootstrap-config.model';

export function conditionalRequiredValidator(store: Store): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let captchaEnabled: boolean | undefined;

    store
      .select(BootstrapSelectors.selectBootstrapConfig)
      .pipe(
        map((bsConfig: BootstrapConfig) => {
          captchaEnabled = bsConfig.companyBootstrap?.captchaEnabled;
        })
      )
      .subscribe();

    if (!captchaEnabled) {
      return null; // Skip validation if captcha is enabled
    } else {
      return control.value ? null : { required: true };
    }
  };
}
