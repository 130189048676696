import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ScopeTooltipComponent } from '../scope-tooltip/scope-tooltip.component'
import { ScopeVersion, StatusType } from '@app/core/model/scope-version';
import { ApprovalChecks } from '../../model/approval-checks.interface'
import { DirectivesModule } from '@app/shared/directives/index.module'
import { User } from '@app/core/model/user.model'
import { ScopeStateMember } from '@app/core/model/scope-team.model'
import { CdnConfig } from '@core/model/cdn-config.model'
import { trackById } from '@shared/utils/utils';

@Component({
  selector: 'scope-approval-flow',
  standalone: true,
  imports: [CommonModule, ScopeTooltipComponent, DirectivesModule],
  templateUrl: './scope-approval-flow.component.html',
  styleUrls: ['./scope-approval-flow.component.scss'],
})
export class ScopeApprovalFlowComponent {

  @Input() currentApprovalState!: StatusType

  @Input() isTemplate!: boolean

  @Input() currentUser!: User

  @Input() stateChecks!: ApprovalChecks

  @Input() cdnConfig?: CdnConfig

  @Input() currentScope: ScopeVersion

  @Input() companyIntegrations: []

  @Output() onSubmitDraft!: EventEmitter<void>

  @Output() onWithdrawSubmission!: EventEmitter<void>

  @Output() onCompleteReview!: EventEmitter<void>

  @Output() onAgreeToScope!: EventEmitter<void>

  @Output() onDeclineScope!: EventEmitter<void>

  @Output() onAcceptScope!: EventEmitter<void>

  @Output() onRejectScope!: EventEmitter<void>

  @Output() onCloseScope!: EventEmitter<void>

  @Output() onSendReminder!: EventEmitter<User>

  @Output() onAddTeamMember!: EventEmitter<void>

  @Output() onOpenExportModal!: EventEmitter<void>

  @Output() onTrafficExportModal!: EventEmitter<void>

  selectedMember?: ScopeStateMember

  showExtendedMembersList: boolean = false

  constructor() {
    this.onSubmitDraft = new EventEmitter<void>()
    this.onWithdrawSubmission = new EventEmitter<void>()
    this.onCompleteReview = new EventEmitter<void>()
    this.onAgreeToScope = new EventEmitter<void>()
    this.onDeclineScope = new EventEmitter<void>()
    this.onAcceptScope = new EventEmitter<void>()
    this.onRejectScope = new EventEmitter<void>()
    this.onCloseScope = new EventEmitter<void>()
    this.onSendReminder = new EventEmitter<User>()
    this.onAddTeamMember = new EventEmitter<void>()
    this.onOpenExportModal = new EventEmitter<void>()
    this.onTrafficExportModal = new EventEmitter<void>()
  }

  submitDraft() {
    this.onSubmitDraft.emit()
  }

  withdrawSubmission() {
    this.onWithdrawSubmission.emit()
  }

  completeReview() {
    this.onCompleteReview.emit()
  }

  agreeToScope() {
    this.onAgreeToScope.emit()
  }

  declineScope() {
    this.onDeclineScope.emit()
  }

  acceptScope() {
    this.onAcceptScope.emit()
  }

  rejectScope() {
    this.onRejectScope.emit()
  }

  closeScope() {
    this.onCloseScope.emit()
  }

  exportToIntegration() {
    this.onTrafficExportModal.emit()
  }

  openUserTooltip(clickedMember: ScopeStateMember) {
    if (clickedMember === this.selectedMember) {
      this.selectedMember = null
      return;
    }

    this.showExtendedMembersList = false
    this.selectedMember = clickedMember
  }

  sendReminder() {
    this.onSendReminder.emit()
  }

  showAddTeamMemberModal() {
    this.onAddTeamMember.emit()
  }

  openExportModal() {
    this.onOpenExportModal.emit()
  }

  toggleExtendedMembers() {
    this.showExtendedMembersList = !this.showExtendedMembersList
    this.selectedMember = null
  }

  protected readonly trackById = trackById;
}
