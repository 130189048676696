<div class="scope-ui-card" [ngClass]="onClick ? 'pressed' : 'unpressed'">
  <div class="status">
    <!-- TODO - replace with input icon name -->
    <div class="flex flex-col">
      <div class="flex">
        <ng-container *ngIf="hasIcon">
          <i [ngClass]="statusIconClass" class="material-icons material-symbols-rounded">{{ statusIcon }}</i>
        </ng-container>
        <ng-container *ngIf="hasStatus">
          <p class="status-text">{{ status }}</p>
        </ng-container>
      </div>
      <div class="card-title">{{ cardTitle }}</div>
      <ng-container *ngIf="hasSubtitle">
        <div [ngClass]="{ bold: isBold }" class="sub-title">{{ subTitle }}</div>
      </ng-container>
    </div>
    <ng-container *ngIf="hasProfilePic">
      <div class="profile-pic">
        <img src="https://via.placeholder.com/200" alt="" />
        <!-- TODO - replace with user profile pic -->
        <!-- <img src="{{ userProfilePicUrl }}" /> -->
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="hasDropDown">
    <scope-ui-dropdown
      class="pt-3"
      [controlLabel]="controlLabel"
      [dropdownOptions]="dropdownOptions"
      [isStandardDropdown]="isStandardDropdown"
      [customSelectIcon]="customSelectIcon"
      (onSelectionChange)="onSelection($event)" />
  </ng-container>

  <ng-container *ngIf="hasMetadata">
    <div class="meta-data">{{ metaData }}</div>
  </ng-container>

  <ng-container *ngIf="hasInputField">
    <scope-ui-input
      [hasPlaceholder]="hasPlaceholder"
      [inputPlaceholder]="inputPlaceholder"
      [inputLabel]="inputLabel"
      (onInputChange)="onInput($event)" />
  </ng-container>

  <ng-container *ngIf="hasButtons">
    <div class="action-buttons">
      <button *ngFor="let btn of actionButtons" (click)="handleButtonClick(btn)">
        <i class="material-symbols-rounded">{{ btn.icon }}</i>
        <span>{{ btn.name }}</span>
      </button>
    </div>
  </ng-container>
</div>
