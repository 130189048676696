import { Component } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthActions } from '@core/store';
import { AuthSelectors } from '@core/store/selectors/auth.selector';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BootstrapConfig } from '@core/model/bootstrap-config.model'
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  forgotPasswordForm: UntypedFormGroup;
  forgotSuccess$: Observable<boolean | null>;
  companyBootstrap$: Observable<BootstrapConfig | null>;
  constructor(private store: Store) {
    this.forgotPasswordForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.pattern(/^.+\@.+\..+/)]),
      recaptcha: new FormControl('', [Validators.required]),
    });
    this.forgotSuccess$ = this.store.pipe(select(AuthSelectors.selectForgotPasswordSuccess));
    this.companyBootstrap$ = this.store.pipe(select(BootstrapSelectors.selectBootstrapConfig));
  }

  get username() {
    return this.forgotPasswordForm.get('username');
  }
  get recaptcha() {
    return this.forgotPasswordForm.get('recaptcha');
  }


  forgotPassword() {
    this.store.dispatch(AuthActions.forgotPassword({
      username: this.username?.value,
      captchaToken: this.recaptcha?.value,
    }))
  }

  captchaResolved(captchaResponse: string) {
    this.recaptcha.setValue(captchaResponse);
  }
}
