import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { CompanyManagementState } from '../../models/company-management.state.model';
import * as fromCompanyManagementReducer from './company-management.reducer';

export const featureKey = 'companyManagement';

export interface State {
  companyManagement: CompanyManagementState;
}

export const reducers: ActionReducerMap<State> = {
  companyManagement: fromCompanyManagementReducer.reducer,
};

export const getCompanyManagementState = createFeatureSelector<State>(featureKey);
