<div class="filter-modal__header" mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h4>Select Filters</h4>
    </div>

    <button class="close-button" mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content aria-label="Select Filters" class="modal-container filter-modal md-dialog-container">
  <div class="modal">
    <div class="filter-modal__body">
      <div class="options-container">
        <div *ngFor="let optionKeyValue of data.options | keyvalue : unsorted">
          <div *ngIf="optionKeyValue.value as option">
            <div
              class="flex option-row"
              [ngClass]="{ selected: isEqual(option, selectedOption) }"
              [hidden]="option.isVisible && !option.isVisible()"
              (click)="selectOption(option)">
              <div class="bar"></div>
              <mat-icon class="material-symbols-rounded orange px-2" style="width: auto">chevron_left</mat-icon>
              <span [ngClass]="{ bold: (!isEqual(option.value, data.filterDefaults[optionKeyValue.key]) && isValidValue(option.value)) || isEqual(option, selectedOption) }">{{
                option.name
              }}</span>
              <span class="tag" *ngIf="!isEqual(option.value, data.filterDefaults[optionKeyValue.key]) && isValidValue(option.value)">{{
                option.tag()
              }}</span>
            </div>
            <ng-container *ngIf="option === selectedOption">
              <div class="option-input checkbox-input" anchor-bottom *ngIf="option.type === 'radio'">
                <div *ngFor="let setting of option.options">
                  <mat-checkbox
                    value="{{ setting.value }}"
                    [checked]="option.value === setting.value"
                    (change)="option.value = setting.value"
                    >{{ setting.name }}</mat-checkbox
                  >
                </div>
              </div>
              <div class="option-input date-input flex flex-col" anchor-bottom *ngIf="option.type === 'date'">
                <div class="flex" [ngClass]="option.value?.timeframe?.value === 'custom' ? 'flex-row' : 'flex-col'">
                  <scope-ui-dropdown
                    [controlLabel]="'Action'"
                    [dropdownOptions]="option.options!"
                    [isStandardDropdown]="true"
                    [selectedValue]="option.value?.action"
                    (onSelectionChange)="onSelectAction($event)" />
                  <scope-ui-dropdown
                    [ngClass]="option.value?.timeframe?.value === 'custom' ? 'ml-9' : 'pt-3'"
                    [controlLabel]="'Timeframe'"
                    [dropdownOptions]="timeframeOptions"
                    [isStandardDropdown]="true"
                    [selectedValue]="option.value?.timeframe"
                    (onSelectionChange)="onSelectTimeframe($event)" />
                </div>
                <scope-ui-datepicker
                  *ngIf="option.value?.timeframe?.value === 'custom'"
                  [initialValue]="{startDate: option.value?.start, endDate: option.value?.end}"
                  (onSelect)="_onSelectedChange($event)">
                </scope-ui-datepicker>
                <mat-error class="text-sm pt-4" *ngIf="option.value?.action && !option.value?.timeframe || !option.value?.action && option.value?.timeframe">
                  Both an action and a timeframe must be selected
                </mat-error>
              </div>
              <div class="option-input" anchor-bottom *ngIf="option.type === 'range'">
                <scope-ui-input
                  [hasPlaceholder]="false"
                  [inputLabel]="'Minimum'"
                  [initialValue]="option.value?.minimum"
                  [type]="'number'"
                  [minimum]="option.minimum"
                  [maximum]="option.maximum"
                  [maximumLength]="10"
                  (onInputChange)="onChangeMinimum($event)" />
                <scope-ui-input
                  [hasPlaceholder]="false"
                  [inputLabel]="'Maximum'"
                  [initialValue]="option.value?.maximum"
                  [type]="'number'"
                  [minimum]="option.minimum"
                  [maximum]="option.maximum"
                  [maximumLength]="10"
                  (onInputChange)="onChangeMaximum($event)" />
                <mat-error class="text-sm" *ngIf="!validateMinMax(option)">
                  Maximum amount cannot be less than minimum
                </mat-error>
              </div>
              <div class="option-input" anchor-bottom *ngIf="option.type === 'genericSearch'">
                <scope-ui-autocomplete
                  [label]="'Name'"
                  [options]="option.options || (option.options$ | async)!"
                  [filterByAction]="option.onSearch"
                  [multiselect]="true"
                  [hasIcon]="true"
                  [iconName]="'search'"
                  [hideToggleIcon]="option.hideDropdownToggle"
                  (onSearch)="option.onSearch?.($event)"
                  (onSelectionChange)="onSelection($event)" />
                <div class="flex flex-wrap">
                  <div class="filter-tag" *ngFor="let value of option.value; index as index">
                    <span class="tag-value">{{ value.name }}</span
                    ><mat-icon class="material-symbols-rounded" (click)="removeValue(index)">close</mat-icon>
                  </div>
                </div>
              </div>
              <div class="option-input flex flex-col" anchor-bottom *ngIf="option.type === 'componentSearch'">
                <scope-ui-dropdown
                  class="pt-3"
                  [controlLabel]="'Size'"
                  [dropdownOptions]="sizeOptions"
                  [isStandardDropdown]="true"
                  [selectedValue]="option.value?.complexity"
                  (onSelectionChange)="onSelectComponentSize($event)" />
                <scope-ui-autocomplete
                  class="mt-4"
                  [label]="'Name'"
                  [options]="(option.options$ | async)!"
                  [filterByAction]="true"
                  [multiselect]="true"
                  [hasIcon]="true"
                  [iconName]="'search'"
                  [hideToggleIcon]="option.hideDropdownToggle"
                  (onSearch)="option.onSearch($event)"
                  (onSelectionChange)="onSelectComponentName($event)" />
                <div class="flex flex-wrap">
                  <div class="filter-tag" *ngFor="let value of option.value?.components; index as index">
                    <span class="tag-value">{{ value.toString() }}</span
                    ><mat-icon class="material-symbols-rounded" (click)="removeComponent(index)">close</mat-icon>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="filter-modal__actions">
  <button mat-flat-button class="w-full mx-0 my-2" [disabled]="!isValid()" (click)="onSeeResults()">See Results{{ filterCount() }}</button>
  <button mat-button mat-dialog-close (click)="onReset()">Reset filters</button>
</mat-dialog-actions>
