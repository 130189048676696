import { Type } from "class-transformer";
import { CompanySummary } from "./company-summary.model";
import { Brand } from "@app/features/scope-overview/model/brand.model";
import { OfficeLocation } from "@core/model/office-location";
import { RatecardIdentity } from "@app/features/scope-overview/model/ratecard-identity.model";
import { SecondPartyContact } from '@app/features/scope-overview/model/second-party-contact.model'
import { SecondPartyRatecard } from '@app/features/scope-overview/model/second-party-ratecard.model';

export class SecondParty {
  id!: number;
  name!: string;
  logoUri!: string;
  relationshipSince!: string;
  trafficImported!: boolean;
  @Type(() => SecondPartyContact) contacts: SecondPartyContact[] = [];
  @Type(() => Brand) brands?: Brand[];
  @Type(() => CompanySummary) company!: CompanySummary;
  @Type(() => OfficeLocation) locations?: OfficeLocation[];
  @Type(() => RatecardIdentity) rateCardIdentities!: RatecardIdentity[]
  @Type(() => SecondPartyRatecard) secondPartyRatecards!: SecondPartyRatecard[];
  fteValue: number
}
