import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SiteHeaderComponentModule } from '@shared/site-header/site-header.module';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreNgrxStateModule } from './core/core-ngrx.module';
import { UserAccountModule } from './features/user-account/user-account.module';
import { MaterialModule } from './plugins/material/material.module';
import { SharedModule } from './shared/shared.module';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoginFormComponent } from './features/user-account/components/login/login-form.component';
import { EffectsModule } from '@ngrx/effects';
import { LanguageService } from '@core/service/language.service';
import { AuthActions, BootstrapActions } from '@core/store'
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector';
import { CompanyType } from '@app/core/model/enums/company-type.enum';
import { ScopingModule } from './features/scoping/scoping.module';
import { CompanyManagementModule } from './features/company-management/company-management.module';
import { DashboardOverviewModule } from './features/dashboards/dashboard-overview.module';
import { LibraryManagementModule } from './features/library-management/library-management.module';
import { SystemAdminModule } from './features/system-admin/system-admin.module';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { environment } from '@envs/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@app/core/interceptor/auth-interceptor';
import { GoogleAnalyticsService } from './core/service/google-analytics.service';
import { registerLocaleData } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import localeEsCo from '@angular/common/locales/es-CO';
import { NotificationSettingsComponent } from './features/user-account/components/notification-settings/notification-settings.component';
import { ProfileComponent } from './features/user-account/components/profile/profile.component';
import { ErrorInterceptor } from './core/interceptor/error.interceptor';
import { ScopeUiCardMasterComponent } from './shared/components/ui-components/scope-ui-card-master/scope-ui-card-master.component';
import { UiDemoModule } from './ui-demo/ui-demo/ui-demo.module';
import { UiDemoRoutingModule } from '@app/ui-demo/ui-demo/ui-demo-routing.module';
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { ScopeOverviewModule } from './features/scope-overview/scope-overview.module';
import { ParentChildInterceptor } from '@core/interceptor/parent-child-interceptor';
import { provideEnvironmentNgxMask } from "ngx-mask";
import {
  AccountVerificationComponent
} from '@app/features/user-account/components/account-verification/account-verification.component';
import { ScopeUiSnackbarComponent } from '@shared/components/scope-ui-snackbar/scope-ui-snackbar.component';
import { CookieFooterComponent } from '@app/features/user-account/components/cookie-footer/cookie-footer.component'
import { AuthService } from '@core/service/auth.service'
import { GenericErrorHandler } from '@core/service/generic-error-handler.service'

registerLocaleData(localeEsCo);

export function initializeBootstrapConfig(store: Store) {
  return () => {
    store.dispatch(BootstrapActions.loadBootstrapConfig());
  };
}

export function initializeLanguage(languageService: LanguageService, store: Store): () => Promise<void> {
  return () => {
    return new Promise<void>((resolve) => {
      store.select(BootstrapSelectors.selectCompanyType).subscribe((companyType: CompanyType) => {
        if (companyType) {
          languageService.setLangDataByCompanyType(companyType).then((lang) => {
            resolve();
          });
        }
      });
    });
  };
}

export function initializeAccount(authService: AuthService) {
  return () => authService.loadAccount()
}

@NgModule({
  declarations: [AppComponent, LoginFormComponent, AccountVerificationComponent, NotificationSettingsComponent, ProfileComponent, CookieFooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreNgrxStateModule,
    SharedModule,
    UiDemoModule,
    UiDemoRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    SiteHeaderComponentModule,
    UserAccountModule,
    CompanyManagementModule,
    DashboardOverviewModule,
    LibraryManagementModule,
    ScopingModule,
    ScopeOverviewModule,
    SystemAdminModule,
    ScopeUiCardMasterComponent,
    NgxDaterangepickerMd.forRoot(),
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      trace: true,
      features: {
        pause: true,
        lock: true,
      },
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    MatDatepickerModule,
    MatNativeDateModule,
    ScopeUiSnackbarComponent,
  ],
  providers: [
    CookieService,
    GoogleAnalyticsService,
    { provide: ErrorHandler, useClass: GenericErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeBootstrapConfig,
      multi: true,
      deps: [Store],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLanguage,
      multi: true,
      deps: [LanguageService, Store],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAccount,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ParentChildInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    provideEnvironmentNgxMask()
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
