import { Directive, ElementRef, Input } from '@angular/core';
import { PrivilegeAware } from '@app/core/model/definitions/privileged-aware.interface';
import { CoreState } from '@core/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from '@core/service/auth.service'

@Directive({
  selector: '[has-privilege]',
})
export class HasPrivilegeDirective {
  @Input('has-privilege') hasPrivilege!: string;
  @Input('has-privilege-ignore-check') hasPrivilegeIgnoreCheck?: string;
  @Input('has-privilege-mask') hasPrivilegeMask?: string;
  @Input('has-privilege-entity') hasPrivilegeEntity?: PrivilegeAware | Observable<PrivilegeAware>;

  ngOnInit() {
    if (this.hasPrivilegeIgnoreCheck == 'true' || !this.hasPrivilege || this.hasPrivilege == 'ANY') {
      return;
    }
    let strictMode = false;

    let privileges: string[];
    if (this.hasPrivilege.includes('&&') && this.hasPrivilege.includes('||')) {
      throw new Error('Unsupported use of && and ||');
    }
    if (this.hasPrivilege.includes('&&')) {
      privileges = this.hasPrivilege.split('&&');
      strictMode = true;
    } else if (this.hasPrivilege.includes('||')) {
      privileges = this.hasPrivilege.split('||');
    } else {
      privileges = [this.hasPrivilege.trim()];
    }

    var blindElementContent = () => {
      var mask = this.hasPrivilegeMask;
      if (mask && mask != "" ) { //!String.isNullOrEmpty(mask)) {
        this.el.nativeElement.html(mask);
      } else {
        // Add hidden class as native element isn't always in the dom at this point
        this.el.nativeElement.classList.add("hidden")
        this.el.nativeElement.remove();
      }
    };

    var runPrivilegeCheck = (opts: any) => {
      if (this.authService.loggedInUser) {
        let keepElement: boolean = false;
        var privilegesChecked = new Promise((resolve: Function, reject) => {
          for (var i = 0; i < privileges.length; i++) {
            var hasNext = i < privileges.length - 1;
            var privilege = privileges[i].trim();
            var privilegedEntity = opts.privilegedEntity;

            var hasPrivilege = this.authService.loggedInUser.hasPrivilege(privilege);
            if (!hasPrivilege && privilegedEntity != null && privilegedEntity.userAdditionalPrivileges != null) {
              if (privilegedEntity.hasAdditionalPrivilege) {
                hasPrivilege = privilegedEntity.hasAdditionalPrivilege(privilege);
              }
            }

            if (strictMode) {
              keepElement = hasPrivilege;
              if (!keepElement) {
                resolve();
                break;
              }
            } else {
              keepElement = keepElement || hasPrivilege;
            }

            if (keepElement && privilegedEntity != null && privilegedEntity.userPrivilegeRestrictions != null) {
              if (privilegedEntity.isPrivilegeRestricted(privilege)) {
                keepElement = false;
                if (strictMode) {
                  resolve();
                  break;
                }
              }
            }

            if (!hasNext) {
              resolve();
            }
          }
        });

        privilegesChecked.finally(function () {
          if (!keepElement) {
            blindElementContent();
          }
        });
        privilegesChecked.catch((error) => {
          console.error(error);
        });
      }
    };

    var privilegedEntity = this.hasPrivilegeEntity;
    if (privilegedEntity) {
      if (privilegedEntity instanceof Observable) {
        privilegedEntity.subscribe((entity) => {
          runPrivilegeCheck({
            privilegedEntity: entity,
          });
        });
      } else {
        runPrivilegeCheck({
          privilegedEntity: privilegedEntity,
        });
      }
    } else {
      runPrivilegeCheck({});
    }
  }

  constructor(private el: ElementRef, private store: Store<CoreState>, private authService: AuthService) { }
}
