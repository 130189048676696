import { Deliverable } from '@app/features/scoping/models/deliverable.model';

export interface DeliverableReviewTableData {
  name: string;
  cols: TableColumn[];
  rowClickAction: (e: Deliverable, event: Event) => void;
  rowData?: {
    items: Deliverable[];
    cells: VoteTableCell[][];  // Array of arrays of cells, each sub-array represents a row's cells
  };
}

export interface TableColumn {
  name: string;
  selected: boolean;
  width: number;
  naturalOrderBy?: (nameA: string, nameB: string) => number;
}

export interface VoteTableCell {
  value: (e: Deliverable) => string;
  icon?: (e: Deliverable) => string;
  iconVote?: (e: Deliverable) => Votes[string];
  deliverableIconVote?: (e: Deliverable) => VoteReviewStatus;
}

export type VoteTableCellArray = VoteTableCell[];

export type Votes = {
  [key: string]: {
    icon: 'help_outline' | 'done' | 'clear';
    classes: 'pending' | 'accepted' | 'rejected';
  };
};

export enum VoteReviewStatus {
  NotReviewedYet = 'Not Reviewed Yet',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}
