<ul class="flex justify-end gap-4" *ngIf="pages.length > 0">
  <li *ngIf='currentPage > 0' (click)="onChangePage(currentPage - 1)" class='forward-back-btn'><mat-icon class="material-symbols-rounded orange">arrow_back</mat-icon></li>
  <li *ngFor="let page of pages | slice:visiblePages.first-1:visiblePages.last" [class]="{active: page == currentPage+1}" (click)="onChangePage(page-1)">{{page}}</li>
  <li *ngIf='currentPage+1 !== pages.length' (click)="onChangePage(currentPage + 1)" class='forward-back-btn'><mat-icon class="material-symbols-rounded orange">arrow_forward</mat-icon></li>
</ul>
<div class="flex justify-end pagination-footer" *ngIf="total > 0">
  <span>Pages</span>
  <scope-ui-dropdown
    class='ml-2 -mt-0.5'
    [dropdownClass]="'small-dropdown'"
    [selectedValue]="visiblePages"
    [dropdownOptions]="pageSets"
    [isStandardDropdown]="true"
    [displayFn]='displayFn'
    (onSelectionChange)='visiblePages = $event; onChangePage($event.first - 1)'/>
  <mat-divider vertical />
  <span>{{(pageSize * currentPage) + 1}}-{{(pageSize * currentPage) + pageSize < total ? (pageSize * currentPage) + pageSize : total}} of {{total}} items</span>
</div>
