import { Component } from '@angular/core'
import { EnvService } from '@envs/env-service';
import { environment } from '@envs/environment';
import { AuthService } from '@core/service/auth.service'

@Component({
    selector: 'notification-settings',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent {
    constructor(private envService: EnvService, private authService: AuthService) {
    }

    public get baseJsUrl(): string {
      return `${this.envService.baseJsUrl}/notifications?host=${environment.baseHost}&childCompany=${this.authService.loggedInUser?.company.id}`;
    }
}
