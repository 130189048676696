import {Company} from "@core/model/company.model";
import {Type} from "class-transformer";
import {User} from "@core/model/user.model";
import {SecondParty} from "@core/model/second-party.model";

export class OfficeLocation {
  id!: number;
  name!: string;
  location!: string;
  region?: string;
  countryName?: string;
  countryISO2?: string;
  country?: string;
  phone?: string;
  address1?: string;
  address2?: string;
  city?: string;
  postcode?: string;
  @Type(() => SecondParty) restrictedSecondParties?: SecondParty[];
  @Type(() => Company) company!: Company;
  @Type(() => User) createdBy!: User;
  @Type(() => User) updatedBy!: User;

  getAddress() {
    let fullAddress = "";
    if (this.address1 == null && this.address2 == null) {
      fullAddress = 'No Address';
    } else {
      fullAddress += (this.address1 != null) ? this.address1 : '';
      fullAddress += (this.address2 != null) ?
        (fullAddress.length > 0 ? ", " : '') + this.address2 : '';
    }
    fullAddress += ", " + (this.city != null ? this.city : 'No Country');
    fullAddress += ", " + (this.countryName != null ? this.countryName : 'No Country');
    fullAddress += ", " + (this.postcode != null ? this.postcode : 'No postal code');
    return fullAddress;
  };
}
