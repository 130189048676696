import { User } from "@core/model/user.model";
import { Type } from "class-transformer";
import { ScopeSection } from '@app/features/scope-overview/model/scope-section'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { ScopeComponent } from '@app/features/scoping/models/component.model'

export class FeeItem {
  amount: number
  amountType: string
  @Type(() => User) createdBy: User
  @Type(() => Date) createdTs: Date
  description: string
  id: number
  name: string
  @Type(() => User) updatedBy: User
  @Type(() => Date) updatedTs: Date
}

export class FeeItemInstance {
  id: number
  @Type(() => FeeItem) feeItem: FeeItem
  scopeSection?: ScopeSection
  deliverable?: Deliverable
  component?: ScopeComponent
}
