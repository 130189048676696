export enum ScopePlanType {
  FULLY_RESTRICTED = 'FULLY_RESTRICTED',
  TRIAL = 'TRIAL',
  BASIC = 'BASIC',
  PRIME = 'PRIME',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
}

export enum ScopePlanTypeFeature {
  SCOPE_APPROVALS = 'SCOPE_APPROVALS'
}

export const ScopePlanTypeMap: {[key: string]: any} =
{

  TRIAL: {
    name: "Trial",
    excluded_features:
    [
      ScopePlanTypeFeature.SCOPE_APPROVALS
    ]
  },
  BASIC: {
    name: "Basic",
    excluded_features:
    [
      ScopePlanTypeFeature.SCOPE_APPROVALS
    ]
  },
  PRIME: {
    name: "Prime",
    excluded_features:
    [
      ScopePlanTypeFeature.SCOPE_APPROVALS
    ]
  },
  PRO: {
    name: "Pro"
  },
  ENTERPRISE: {
    name: "Enterprise"
  },
  ENTERPRISE_PLUS: {
    name: "Enterprise Plus"
  }
}
