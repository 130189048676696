import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserRoleLevel } from '@app/core/model/enums/user-role-level.enum';
import { PasswordParams } from '@app/core/model/definitions/password-params.interface';
import { environment } from '@envs/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PasswordValidatorService {
  constructor(private http: HttpClient) {}

  containsLowerCase(control: AbstractControl): ValidationErrors | null {
    const password = control.value as string;
    const hasLowerCase = /[a-z]/.test(password);
    return hasLowerCase ? null : { hasLowerCase: true };
  }

  containsUpperCase(control: AbstractControl): ValidationErrors | null {
    const password = control.value as string;
    const hasUpperCase = /[A-Z]/.test(password);
    return hasUpperCase ? null : { hasUpperCase: true };
  }

  containsSpecialCharacter(control: AbstractControl): ValidationErrors | null {
    const password = control.value as string;
    const hasSpecialChar = /[!$*&]/.test(password);
    return hasSpecialChar ? null : { hasSpecialChar: true };
  }

  containsDigit(control: AbstractControl): ValidationErrors | null {
    const password = control.value as string;
    const hasDigit = /\d/.test(password);
    return hasDigit ? null : { hasDigit: true };
  }

  getRequiredPasswordCharacterCount(role: UserRoleLevel): any {
    if (role) {
      return role === UserRoleLevel.MEMBER ? 8 : 14;
    }
    return 8;
  }

  minLengthWithCustomLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (Validators.minLength(minLength)(control)) {
        return { minLengthWithCustomLength: true };
      }
      return null;
    };
  }

  validateNewPassword(passwordParams: PasswordParams): Observable<[{ key: string; valid: boolean }]> {
    const { password, token } = passwordParams;
    return this.http.post<[{ key: string; valid: boolean }]>(`${environment.scopeApiUrl}user/validate-new-password`, {
      password,
      token,
    });
  }
}
