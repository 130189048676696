import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@plugins/material/material.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { SsoLoginFormComponent } from './components/sso-login-form/sso-login-form.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotPasswordSuccessComponent } from './components/forgot-password-success/forgot-password-success.component';
import { PasswordValidatorService } from '@core/service/password-validator.service';
import { UserAccountRoutingModule } from './user-account-routing.module';
import { UserService } from './services/user.service';

@NgModule({
  imports: [CommonModule, UserAccountRoutingModule, MaterialModule, SharedModule],
  declarations: [
    SsoLoginFormComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ForgotPasswordSuccessComponent,
  ],
  providers: [PasswordValidatorService, UserService],
  exports: [
    RouterModule,
    SsoLoginFormComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ForgotPasswordSuccessComponent,
  ],
})
export class UserAccountModule {}
