<div class="financial-card {{expanded ? 'expanded' : ''}} {{expandable ? 'expandable_panel' : ''}} {{cardClass}}">
  <div class="flex w-full gap-1">
    <span class="heading {{headingClass}}">{{ heading }}</span>
    <button
      *ngIf="showAddButton"
      class="toggle-button"
      (click)="onAdd()"
      mat-mini-fab
      matTooltip="{{addButtonLabel}}">
      <mat-icon class="material-symbols-rounded orange">add</mat-icon>
    </button>
    <button
      *ngIf="showMenu"
      class="toggle-button"
      mat-mini-fab
      [matMenuTriggerFor]="cardMenu"
      matTooltip="More options">
      <mat-icon class="material-symbols-rounded">more_vert</mat-icon>
    </button>
    <mat-menu #cardMenu="matMenu" backdropClass="customize">
      <button
        mat-menu-item
        (click)="onEdit()">
        <mat-icon class="material-symbols-rounded orange">edit</mat-icon>
        <span>Edit</span>
      </button>
      <button
        mat-menu-item
        (click)="onDelete()">
        <mat-icon class="material-symbols-rounded orange">delete</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
    <span class="flex justify-end flex-1 sub_value" matTooltip="{{sub_value_label}}">{{ sub_value }}</span>
  </div>
  <span class="sub_heading">{{ sub_heading }}</span>
  <div class="flex justify-end flex-1 items-end value">
    {{ value }}
  </div>
  <button
    *ngIf="expandable"
    class="toggle-button expand-btn"
    (click)="onExpand()"
    mat-mini-fab
    matTooltip="View summary">
    <mat-icon class="material-symbols-rounded orange">expand_more</mat-icon>
  </button>
</div>
