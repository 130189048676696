import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { ScopeVersion } from '@core/model/scope-version';
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service';
import { User } from '@app/core/model/user.model';
import { ScopeComponent } from '@app/features/scoping/models/component.model';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ScopeUiTableComponent } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { NgxMaskDirective } from 'ngx-mask'
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service'
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { Role } from '@app/features/scoping/models/role.model'
import { Preference } from '@core/model/user-preferences.interface'
import { DeliverableSection } from '@app/features/scope-overview/model/deliverable-section'
import {
  ComponentRowComponent
} from '@app/features/scope-overview/components/scope-tab/component-row/component-row.component'
import { MenuOptions } from '@core/model/definitions/menu-options.interface'
import { trackById } from '@shared/utils/utils';

@Component({
  selector: 'deliverable-section-row',
  imports: [CommonModule, SharedModule, ScopeUiTableComponent, NgxMaskDirective, ComponentRowComponent],
  templateUrl: './deliverable-section-row.component.html',
  styleUrls: ['./deliverable-section-row.component.scss'],
  standalone: true,
})
export class DeliverableSectionRowComponent {
  @Input() section!: DeliverableSection;

  @Input() isTimeline: boolean = false;

  @Input() deliverable!: Deliverable;

  @Input() currentUser!: User;

  @Input() userColumns!: any;

  @Input() componentMainColumnClass!: string

  @Input() scopeVersion!: ScopeVersion;

  @Input() componentFeeColumns!: Preference[];

  @Input() feeMenuOptions!: MenuOptions[]

  @Input() level: number

  @Input() deliverableSectionSelectedStates: { [id: number]: boolean }

  @Output() onUpdateComponent!: EventEmitter<ScopeComponent>;

  @Output() onAddComponentFee!: EventEmitter<ScopeComponent>;

  @Output() onUpdateFeeItem!: EventEmitter<{ feeItem: FeeItemInstance, deliverable?: Deliverable, component?: ScopeComponent }>

  @Output() onOverrideQuantity!: EventEmitter<{ deliverable: Deliverable, component: ScopeComponent }>

  @Output() onOverrideRateCardRoleHours!: EventEmitter<{ deliverable: Deliverable, component: ScopeComponent, role: Role }>

  constructor(public scopeApprovalFlowService: ApprovalFlowService, public scopeTabService: ScopeTabService) {
    this.onUpdateComponent = new EventEmitter<ScopeComponent>()
    this.onAddComponentFee = new EventEmitter<ScopeComponent>()
    this.onUpdateFeeItem = new EventEmitter<{ feeItem: FeeItemInstance, deliverable?: Deliverable, component?: ScopeComponent }>()
    this.onOverrideQuantity = new EventEmitter<{ deliverable: Deliverable, component: ScopeComponent }>()
    this.onOverrideRateCardRoleHours = new EventEmitter<{ deliverable: Deliverable, component: ScopeComponent, role: Role }>()
  }

  addComponentFee(component: ScopeComponent) {
    this.onAddComponentFee.emit(component);
  }

  selectSection(sectionId: number) {
    this.scopeTabService.toggleDeliverableSection(sectionId)
  }

  updateComponent(component: ScopeComponent) {
    this.onUpdateComponent.emit(component);
  }

  updateFeeItem(feeItem: FeeItemInstance, deliverable?: Deliverable, component?: ScopeComponent) {
    this.onUpdateFeeItem.emit({ feeItem, deliverable, component })
  }

  overrideQuantity($event: { deliverable: Deliverable, component: ScopeComponent }) {
    this.onOverrideQuantity.emit($event)
  }

  overrideRateCardRoleHours($event: { deliverable: Deliverable, component: ScopeComponent, role: Role }) {
    this.onOverrideRateCardRoleHours.emit($event)
  }

  protected readonly trackById = trackById;
}
