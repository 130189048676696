<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1>{{ modalTitle }}</h1>
    </div>

    <div class="mt-2 w-450">
      <span>Select existing fee or create a new one</span>
    </div>

    <button class="close-button" mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content aria-label="Generic modal" class="modal-container md-dialog-container">
  <div class="w-full">
    <form [formGroup]="modalForm">
      <ng-container *ngIf="!isNewFee">
        <div class="flex flex-row gap-8">
          <scope-ui-dropdown
            class="flex-1"
            [selectedValue]="selection"
            [controlLabel]="'Select fee'"
            [dropdownOptions]="(existingFees$ | async)!"
            [isStandardDropdown]="true"
            (onSelectionChange)="selectFee($event)" />
          <scope-ui-input
            *ngIf="selection"
            class="flex-1"
            [inputLabel]="'Amount'"
            [initialValue]="selection.amount"
            [isDisabled]="true"
            [isCurrency]="selection.amountType === 'MONETARY'"
            [isPercentage]="selection.amountType === 'PERCENTAGE'"
            [currency]="currencyCode" />
        </div>
        <div class="flex flex-row pt-6" *ngIf="selection">
          <scope-ui-input
            class="flex-1"
            [inputLabel]="'Description'"
            [initialValue]="selection.description"
            [isDisabled]="true"
            [isTextarea]="true"/>
        </div>
      </ng-container>

      <ng-container *ngIf="isNewFee">
        <div class="flex flex-row gap-8">
          <mat-button-toggle-group name="feeDiscount" [formControl]="typeControl" aria-label="Fee or Discount">
            <mat-button-toggle value="INCREASE">Fee</mat-button-toggle>
            <mat-button-toggle value="DECREASE">Discount</mat-button-toggle>
          </mat-button-toggle-group>
          <scope-ui-input
            class="flex-1"
            [inputLabel]="'Name'"
            [initialValue]="newFee.name"
            [required]="true"
            (onInputChange)="newFee.name = $event" />
        </div>
        <div class="flex flex-row pt-6">
          <scope-ui-input
            class="flex-1"
            [inputLabel]="'Description'"
            [maximumLength]="10000"
            [isTextarea]="true"
            [initialValue]="newFee.description"
            (onInputChange)="newFee.description = $event"/>
        </div>
        <div class="flex flex-row gap-4 mt-8">
          <scope-ui-input
            class="flex-1"
            [inputLabel]="'Amount'"
            [initialValue]="newFee.amount"
            [required]="true"
            [parseNumber]="true"
            [isCurrency]="amountTypeControl.value === 'MONETARY'"
            [isPercentage]="amountTypeControl.value === 'PERCENTAGE'"
            [currency]="currencyCode"
            (onInputChange)="newFee.amount = $event" />
          <mat-button-toggle-group name="feeDiscount" [formControl]="amountTypeControl" aria-label="Fee or Discount">
            <mat-button-toggle value="PERCENTAGE">Percentage</mat-button-toggle>
            <mat-button-toggle value="MONETARY">Quantity</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </ng-container>

      <div class="mt-8" *ngIf="linkFee">
        <h3 class="product-heading-bold">Link to</h3>
        <link-to-selector
        [currentUser]='currentUser'
        [(level)]='level'
        [(linkTo)]='linkTo'></link-to-selector>
      </div>
    </form>
  </div>
  <div class="mt-8 flex justify-end">
    <button mat-button mat-dialog-close (click)="close()">
      <span class='tracking-normal'>Cancel</span>
    </button>
    <button mat-flat-button class="ml-8" (click)="confirm()" [disabled]="!isFormValid()">
      <span class='tracking-normal'>{{feeItem ? 'Edit fee' : (isNewFee ? 'Create fee' : 'Add fee')}}</span>
    </button>
  </div>
  <div class="custom-del-button flex justify-center">
    <button mat-button (click)="isNewFee = !isNewFee">
      <span class='font-newJuneBold flex items-center'>
        <mat-icon class="material-symbols-rounded orange mb-1">add</mat-icon>
        {{isNewFee ? 'Select existing fee' : 'Create new fee'}}
      </span>
    </button>
  </div>
</mat-dialog-content>


