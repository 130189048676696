import { Injectable, inject } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { AuthError } from '@app/core/model/definitions/auth-error.interface';
import { untilDestroyed } from '@app/shared/utils/utils';
import { AuthSelectors } from '@core/store/selectors/auth.selector';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, map, of } from 'rxjs';
import { environment } from '@envs/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private store = inject(Store);

  private readonly destroy$;

  formErrors$!: Observable<AuthError>;

  constructor(private http: HttpClient) {
    this.destroy$ = untilDestroyed();
    this.formErrors$ = this.store.pipe(select(AuthSelectors.selectErrorMessage));
  }

  setFormErrors(loginForm: UntypedFormGroup): Observable<UntypedFormGroup> {
    return this.formErrors$.pipe(
      map((error: AuthError) => {
        if (error) {
          if (error.emailVerificationNeeded) {
            let email = loginForm.get('username')?.value;
          } else {
            let emailErrors: ValidationErrors | null = null;
            let passwordErrors: ValidationErrors | null = null;
            let recaptchaError: ValidationErrors | null = null;

            if (error.accountDisabled) {
              loginForm.setErrors({ accountDisabled: true });
            } else if (error.errorMessage === 'Bad credentials.') {
              loginForm.setErrors({ badCreds: true });
            } else if (error.blackListed) {
              loginForm.setErrors({ userDisabled: true });
            }

            if (emailErrors && Object.keys(emailErrors).length === 0) {
              emailErrors = null;
            }

            if (passwordErrors && Object.keys(passwordErrors).length === 0) {
              passwordErrors = null;
            }

            if (recaptchaError && Object.keys(recaptchaError).length === 0) {
              recaptchaError = null;
            }

            loginForm.controls['username'].setErrors(emailErrors);
            loginForm.controls['password'].setErrors(passwordErrors);
          }
        }
        return loginForm;
      })
    );
  }

  verifyEmail(key: string ): Observable<any> {
    return this.http
      .post<any>(environment.scopeApiUrl + `user/verify-account?verificationKey=${key}`, null)
      .pipe(map((res) => res));
  }
}
