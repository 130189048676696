import { Injectable } from '@angular/core';
import { ScopeActions } from '@app/features/scoping/store/actions/scoping.actions';
import { Store } from '@ngrx/store';
import { ScopingSelectors } from '@app/features/scoping/store/selectors/scoping.selector'
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action'
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ScopeDetailsService {
  officeLocations$ = this.store.select(ScopingSelectors.selectOfficeLocations)

  secondParties$ = this.store.select(ScopingSelectors.selectSecondParties)

  xlsxTemplates$ = this.store.select(ScopingSelectors.selectXlsxOutputTemplates)

  secondParty$ = this.store.select(ScopeOverviewSelectors.selectSecondPartyForScope)

  officeLocation$ = this.store.select(ScopeOverviewSelectors.selectOfficeLocationForScope)

  customFieldsOutdated$ = this.store.select(ScopeOverviewSelectors.selectCustomFieldsOutdated)

  retainedTeamStats$ = this.store.select(ScopeOverviewSelectors.selectRetainedTeamStats)

  benchmarkRateCards$ = this.store.select(ScopeOverviewSelectors.selectAllRateCards)

  defaultRateCard$ = this.store.select(ScopeOverviewSelectors.selectDefaultRateCard)

  benchmarkRateCardOptions$ = combineLatest([this.benchmarkRateCards$, this.defaultRateCard$])
    .pipe(
      map(([rateCards, defaultRateCard]) => {
        if (rateCards?.length == 0 && defaultRateCard) {
          let result = [...rateCards]
          result.push(defaultRateCard)
          return result
        }
        return rateCards
      })
    )

  constructor(private store: Store) {}

  getOfficeLocations = (): void => {
    this.store.dispatch(ScopeActions.getOfficeLocations())
  }

  getScopeTemplates = (): void => {
    this.store.dispatch(ScopeActions.getScopeTemplates())
  }

  getWordOutputTemplates = (): void => {
    this.store.dispatch(ScopeActions.getWordOutputTemplates())
  }

  getXlsxOutputTemplates = (): void => {
    this.store.dispatch(ScopeActions.getXlsxOutputTemplates())
  }

  getBenchmarkRateCards = (): void => {
    this.store.dispatch(ScopeOverviewActions.getAllRateCardsForScopeCreate())
  }

  getDefaultRateCard = (): void => {
    this.store.dispatch(ScopeOverviewActions.getDefaultRateCard())
  }

  getScopeTypes = (): void => {
    this.store.dispatch(ScopeActions.getScopeTypes())
  }

  getSecondParties = (): void => {
    this.store.dispatch(ScopeActions.getSecondParties())
  }

  getSecondParty = (id: number): void => {
    this.store.dispatch(ScopeOverviewActions.getSecondParty({ secondPartyId: id }))
  }

  getOfficeLocation = (id: number): void => {
    this.store.dispatch(ScopeOverviewActions.getOfficeLocation({ officeLocationId: id }))
  }

  getRetainedTeamStats = (id: number): void => {
    this.store.dispatch(ScopeOverviewActions.getSowRetainedTeamStats({ folderId: id }))
  }

  getCustomFieldsOutdated = (id: number): void => {
    this.store.dispatch(ScopeOverviewActions.getScopeCustomFieldsDefinitionOutdated({ scopeId: id }))
  }
}
