import { Pipe, PipeTransform } from '@angular/core'
import { CoreState } from '@core/store'
import { Store } from '@ngrx/store'

import { LanguageService } from '@core/service/language.service'
import { LangPipe } from './lang.pipe'

@Pipe({ name: 'langf', pure: true })
export class LangfPipe implements PipeTransform {
    constructor(private store: Store<CoreState>, private langService: LanguageService) {}

    transform(text: string) {
        if (!text) {
            return text
        }
        const fetched: any = {}
        const replacements: Array<any> = []
        const groups = text.match(/([^{LANG:\}]+(?=}))/g)
        if (groups == null) {
            return text
        }
        groups.forEach((g) => {
            if (fetched[g] == null) {
                fetched[g] = new LangPipe(this.store, this.langService).transform(g)
            }
            replacements.push({
                original: g,
                replacement: fetched[g],
            })
        })

        let newText = text
        replacements.forEach((r) => {
            newText = newText.replace(`{LANG:${r.original}}`, r.replacement)
        })
        return newText
    }
}
