import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {SharedModule} from "@shared/shared.module";
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import {ModalConfig} from "@core/model/modal-config.model";
import {
  ScopeUiAutocompleteComponent
} from "@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component";
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import {
  ScopeUiDropdownComponent
} from "@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { ScopeMsaLineItem } from '@core/model/scope-msa-line-item.model'

@Component({
  selector: 'edit-msa-modal',
  templateUrl: './edit-msa-modal.component.html',
  styleUrls: ['./edit-msa-modal.component.scss'],
  imports: [
    SharedModule,
    ScopeUiAutocompleteComponent,
    NgxDaterangepickerMd,
    ScopeUiInputComponent,
    ScopeUiDropdownComponent,
    MatButtonToggleModule,
  ],
  standalone: true
})
export class EditMsaModalComponent {
  @Input() msaLineItem: ScopeMsaLineItem
  @Input() currencyCode: string
  @Output() onSubmit: EventEmitter<any>
  modalForm: UntypedFormGroup
  typeControl = new FormControl('FEE')
  amountTypeControl = new FormControl('AMOUNT')
  updatedMsaLineItem: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalConfig) {
    this.modalForm = new FormGroup({});
    this.onSubmit = new EventEmitter<any>()
  }

  ngOnInit() {
    this.updatedMsaLineItem = {
      ...this.msaLineItem
    }
    this.amountTypeControl.setValue(this.msaLineItem.type)
    this.typeControl.setValue(this.msaLineItem.chargeType)
  }

  confirm() {
    this.updatedMsaLineItem.chargeType = this.typeControl.value
    this.updatedMsaLineItem.type = this.amountTypeControl.value
    this.onSubmit.emit(this.updatedMsaLineItem)
  }

  protected readonly close = close;

  isFormValid() {
    return this.updatedMsaLineItem.name && this.updatedMsaLineItem.value && this.updatedMsaLineItem.value > 0.01
  }
}
