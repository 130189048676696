export enum ActivityStatus {
  ComponentUpdate = 'COMPONENT_UPDATE',
  ScopeMsaUpdated = 'SCOPE_MSA_UPDATED',
  ScopeMsaRemoved = 'SCOPE_MSA_REMOVED',
  DocXCreationApproval = 'DOCX_CREATION_ON_APPROVAL',
  DeliverableTradingCancelled = 'DELIVERABLE_TRADING_CANCELLED',
  DeliverableTradingInProgress = 'DELIVERABLE_TRADING_IN_PROGRESS',
  DeliverableTraded = 'DELIVERABLE_TRADED',
  Comment = 'COMMENT',
  Submitted = 'SUBMITTED',
  ReviewerSubmitted = 'REVIEWER_SUBMITTED',
  ReviewRejected = 'REVIEW_REJECTED',
  ApproverRejected = 'APPROVER_REJECTED',
  ClientRejected = 'CLIENT_APPROVAL_REJECTED',
  ApproverAccepted = 'APPROVER_ACCEPTED',
  ClientAccepted = 'CLIENT_APPROVAL_ACCEPTED',
  SubmittedWithdrew = 'SUBMISSION_WITHDREW',
  Approved = 'APPROVED',
  DeliverableComplete = 'DELIVERABLE_COMPLETE',
  DeliverableStopped = 'DELIVERABLE_STOPPED',
  DeliverableResumed = 'DELIVERABLE_RESUMED',
  DeliverableAccepted = 'DELIVERABLE_REVIEW_ACCEPTED',
  DeliverableRejected = 'DELIVERABLE_REVIEW_REJECTED',
  Closed = 'SCOPE_CLOSED',
  SecondPartyRejected = 'SECOND_PARTY_REJECTED',
  SecondPartyAccepted = 'SECOND_PARTY_ACCEPTED',
  Published = 'PUBLISHED',
  Trafficked = 'TRAFFICKED'
}
