import { TableColumn } from '@app/core/model/definitions/table-column-key.interface';
import { Preference } from '@app/core/model/user-preferences.interface';
import {ScopeVersion} from "@core/model/scope-version";
import {Privilege} from "@core/model/enums/privilege.enum";
import {FolderVersion} from "@core/model/folder-version.model";

export type ScopesTableKey =
  | 'SECOND_PARTY'
  | 'NAME'
  | 'SCOPE_NUMBER'
  | 'RATECARD'
  | 'SOW_NAME'
  | 'BRAND'
  | 'REGION'
  | 'LOCATION'
  | 'STATUS'
  | 'CREATOR'
  | 'CREATED_BY'
  | 'DATE_CREATED'
  | 'DATE_APPROVED'
  | 'LAST_EDITED'
  | 'BUDGET'
  | 'VALUE'
  | 'MARGIN';

export type DeliverablesTableKey =
  | 'QUANTITY'
  | 'CREATED_BY'
  | 'DATE_CREATED'
  | 'START_DATE'
  | 'LAST_EDITED'
  | 'END_DATE'
  | 'MARK_UP'
  | 'PROFIT'
  | 'SCOPEMARK_HOURS'
  | 'SCOPEMARK_PRICE'
  | 'AGENCY_HOURS'
  | 'BUDGET'
  | 'BALANCE'
  | 'DELIVERY_STATUS'
  | 'AGENCY_PRICE'
  | 'OWNER'
  | 'SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS';


export interface DeliverableTableConfig {
  QUANTITY: TableColumn<ScopesTableKey>;
  CREATED_BY: TableColumn<ScopesTableKey>;
  DATE_CREATED: TableColumn<ScopesTableKey>;
  START_DATE: TableColumn<ScopesTableKey>;
  LAST_EDITED: TableColumn<ScopesTableKey>;
  END_DATE: TableColumn<ScopesTableKey>;
  MARK_UP: TableColumn<ScopesTableKey>;
  PROFIT: TableColumn<ScopesTableKey>;
  SCOPEMARK_HOURS: TableColumn<ScopesTableKey>;
  SCOPEMARK_PRICE: TableColumn<ScopesTableKey>;
  AGENCY_HOURS: TableColumn<ScopesTableKey>;
  BUDGET: TableColumn<ScopesTableKey>;
  BALANCE: TableColumn<ScopesTableKey>;
  DELIVERY_STATUS: TableColumn<ScopesTableKey>;
  AGENCY_PRICE: TableColumn<ScopesTableKey>;
  OWNER: TableColumn<ScopesTableKey>;
  SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS: TableColumn<ScopesTableKey>;
}

export interface ScopesTableConfig {
  NAME: TableColumn<ScopesTableKey>;
  SECOND_PARTY: TableColumn<ScopesTableKey>;
  SCOPE_NUMBER: TableColumn<ScopesTableKey>;
  RATECARD: TableColumn<ScopesTableKey>;
  SOW_NAME: TableColumn<ScopesTableKey>;
  BRAND: TableColumn<ScopesTableKey>;
  REGION: TableColumn<ScopesTableKey>;
  LOCATION: TableColumn<ScopesTableKey>;
  STATUS: TableColumn<ScopesTableKey>;
  CREATOR: TableColumn<ScopesTableKey>;
  CREATED_BY: TableColumn<ScopesTableKey>;
  DATE_CREATED: TableColumn<ScopesTableKey>;
  DATE_APPROVED: TableColumn<ScopesTableKey>;
  LAST_EDITED: TableColumn<ScopesTableKey>;
  BUDGET: TableColumn<ScopesTableKey>;
  VALUE: TableColumn<ScopesTableKey>;
  MARGIN: TableColumn<ScopesTableKey>;
}

export type ScopePreferences = Record<ScopesTableKey, Preference>;

export type DeliverablePreferences = Record<DeliverablesTableKey, Preference>;
// We can re-use this type for other tables, all we have to do is replace the TableKey with the new options and new column base config object.
// export type <Name>Preferences = Record<NameTableKey, Preference>;

export const deliverableColumnBaseConfig: DeliverablePreferences = {
  QUANTITY: {
    key: 'QUANTITY',
    name: 'Quantity',
    selected: true
  },
  CREATED_BY: {
    key: 'CREATED_BY',
    name: 'Created by',
  },
  DATE_CREATED: {
    key: 'DATE_CREATED',
    name: 'Created date',
  },
  START_DATE: {
    key: 'START_DATE',
    name: 'Start date',
  },
  LAST_EDITED: {
    key: 'LAST_EDITED',
    name: 'Last edited',
    selected: true
  },
  END_DATE: {
    key: 'END_DATE',
    name: 'End date',
  },
  MARK_UP: {
    key: 'MARK_UP',
    name: 'Markup',
  },
  PROFIT: {
    key: 'PROFIT',
    name: 'Profit',
    selected: true
  },
  SCOPEMARK_HOURS: {
    key: 'SCOPEMARK_HOURS',
    name: '(\'scope_mark\' | lang)\' hours',
  },
  SCOPEMARK_PRICE: {
    key: 'SCOPEMARK_PRICE',
    name: '(\'scope_mark\' | lang)\' price',
  },
  AGENCY_HOURS: {
    key: 'AGENCY_HOURS',
    name: '(\'agency\' | lang)\' hours',
    selected: true
  },
  BUDGET: {
    key: 'BUDGET',
    name: '(\'budget\' | lang)\'',
  },
  BALANCE: {
    key: 'BALANCE',
    name: 'Balance',
  },
  DELIVERY_STATUS: {
    key: 'DELIVERY_STATUS',
    name: 'Delivery status',
  },
  AGENCY_PRICE: {
    key: 'AGENCY_PRICE',
    name: '(\'agency\' | lang)\' price',
    selected: true
  },
  OWNER: {
    key: 'OWNER',
    name: 'Owner',
  },
  SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS: {
    key: 'SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS',
    name: 'Show actuals',
    isVisibleInList: () => false,
  },
};

export const scopeColumnBaseConfig: ScopePreferences = {
  NAME: {
    key: 'NAME',
    name: 'NAME',
    field: 'name',
    valueIconFunction: (entity: any) => {
      if (entity instanceof FolderVersion) {
        return 'format_list_bulleted';
      } else if (entity instanceof ScopeVersion) {
        return entity.isTemplate() ? 'copy_all' : (entity.identity.identificationType === 'SCOPE_BY_ROLE' ? 'table_chart' : 'equalizer')
      }
      return ''
    },
    selected: true
  },
  SECOND_PARTY: {
    key: 'SECOND_PARTY',
    name: '(\'second_party\' | lang)',
    field: 'identity.secondParty.name',
  },
  RATECARD: {
    key: 'RATECARD',
    name: 'Ratecard',
    field: 'identity.rateCard.name',
  },
  STATUS: {
    key: 'STATUS',
    name: 'Status',
    field: 'status',
    selected: true
  },
  CREATOR: {
    key: 'CREATOR',
    name: 'Creator',
    field: 'identity.createdBy.fullName',
    selected: true
  },
  CREATED_BY: {
    key: 'CREATED_BY',
    name: 'Created By',
    field: 'createdBy.company.name',
    selected: true
  },
  DATE_CREATED: {
    key: 'DATE_CREATED',
    name: 'Date created',
    field: 'createdTs',
  },
  DATE_APPROVED: {
    key: 'DATE_APPROVED',
    name: 'Date approved',
    field: 'agencyApprovedTs',
  },
  LAST_EDITED: {
    key: 'LAST_EDITED',
    name: 'Last edited',
    field: 'updatedTs',
  },
  BUDGET: {
    key: 'BUDGET',
    name: '(\'budget\' | lang)',
    field: 'budget',
  },
  VALUE: {
    key: 'VALUE',
    name: '(\'value\' | lang)',
    value: (entity: any) => {
      if (entity instanceof FolderVersion) {
        return entity.totalValues || '-';
      } else if (entity instanceof ScopeVersion) {
        return entity.totalSellingPrice
      }
      return ''
    },
    isVisible: function (scope: ScopeVersion) {
      return !scope.containsUnmappedScopeMarkRoles;
    },
    requiredPrivilege: Privilege.SCOPE__VIEW_RATES,
    default: '-',
    noSort: true
  },
  SCOPE_NUMBER: {
    key: 'SCOPE_NUMBER',
    name: '(\'scope\' | lang) Number',
    field: 'identity.localId',
    selected: true
  },
  SOW_NAME: {
    key: 'SOW_NAME',
    name: '(\'sow\' | lang)',
    field: 'scopeOfWorkVersion.name',
  },
  BRAND: {
    key: 'BRAND',
    name: '(\'brand\' | lang)',
    field: 'identity.brand.name',
  },
  REGION: {
    key: 'REGION',
    name: 'Region',
    field: 'identity.location.region',
    noSort: true
  },
  LOCATION: {
    key: 'LOCATION',
    name: 'Location',
    field: 'identity.location.location',
  },
  MARGIN: {
    key: 'MARGIN',
    name: 'Margin',
    field: 'margin',
    requiredSetting: 'SCOPE__MARGIN_SHOW',
    selected: true
  },
};
