import { Type } from 'class-transformer';
import { OutputBorders } from '@app/features/scope-overview/model/output-template.model';

export class OutputField {
  id!: number
  disabled!: boolean
  editable!: boolean
  headerRepeatable!: boolean
  footerRepeatable!: boolean
  outputFieldBorders!: any
  softDeleted!: boolean
  fieldType!: string
  name!: string
  content!: string
  order!: number
  index!: number
  mouseover!: boolean
  active!: boolean
  activeSection!: boolean
  showAlignment!: boolean
  editName!: boolean
  children!: Array<OutputField>
  @Type(() => OutputBorders) outputTemplateBorders!: OutputBorders;
}
