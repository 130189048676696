import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Activities, ActivityComment, GroupedCommments, Comment } from '../model/activity.model'
import { LanguageService } from '@app/core/service/language.service'
import { ActivityStatus } from '@app/core/model/enums/activity-status.enum'

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {

  activityStatusMap:  { [key in ActivityStatus]: { icon: string; color: string } };

  constructor(private lang: LanguageService) {
    this.activityStatusMap = {
      [ActivityStatus.ComponentUpdate]: { icon: 'done', color: '#5B9F34' },
      [ActivityStatus.ScopeMsaUpdated]: { icon: 'autorenew', color: '#0B818C' },
      [ActivityStatus.DocXCreationApproval]: { icon: 'description', color: '#0B818C' },
      [ActivityStatus.DeliverableTradingCancelled]: { icon: 'horizontal_rule', color: '#DD2C00' },
      [ActivityStatus.DeliverableTradingInProgress]: { icon: 'compare_arrows', color: '#D6A000' },
      [ActivityStatus.DeliverableTraded]: { icon: 'done', color: '#5B9F34' },
      [ActivityStatus.Comment]: { icon: 'add_comment', color: '#FF6B00' },
      [ActivityStatus.Submitted]: { icon: 'assignment', color: '#D6A000' },
      [ActivityStatus.ReviewerSubmitted]: { icon: 'assignment', color: '#D6A000' },
      [ActivityStatus.ReviewRejected]: { icon: 'close', color: '#DD2C00' },
      [ActivityStatus.ApproverRejected]: { icon: 'close', color: '#DD2C00' },
      [ActivityStatus.ClientRejected]: { icon: 'close', color: '#DD2C00' },
      [ActivityStatus.DeliverableComplete]: { icon: 'done', color: '#5B9F34' },
      [ActivityStatus.DeliverableStopped]: { icon: 'stop', color: '#DD2C00' },
      [ActivityStatus.DeliverableResumed]: { icon: 'play_arrow', color: '#5B9F34' },
      [ActivityStatus.DeliverableAccepted]: { icon: 'done', color: '#5B9F34' },
      [ActivityStatus.DeliverableRejected]: { icon: 'close', color: '#DD2C00' },
      [ActivityStatus.Closed]: { icon: 'close', color: '#DD2C00' },
      [ActivityStatus.SubmittedWithdrew]: { icon: 'horizontal_rule', color: '#DD2C00' },
      [ActivityStatus.Approved]: { icon: 'done', color: '#5B9F34' },
      [ActivityStatus.ApproverAccepted]: { icon: 'done', color: '#5B9F34' },
      [ActivityStatus.ClientAccepted]: { icon: 'done', color: '#5B9F34' },
      [ActivityStatus.ScopeMsaRemoved]: { icon: 'horizontal_rule', color: '#DD2C00' },
      [ActivityStatus.SecondPartyRejected]: { icon: 'close', color: '#DD2C00' },
      [ActivityStatus.SecondPartyAccepted]: { icon: 'done', color: '#5B9F34' },
      [ActivityStatus.Published]: { icon: 'done', color: '#0B818C' },
      [ActivityStatus.Trafficked]: { icon: 'swap_vert', color: '#0B818C' },
    }
  }

  groupComments(comments$: Observable<Activities>): Observable<GroupedCommments> {
    return comments$.pipe(
      map((comments) => {
        const commentsCopy = JSON.parse(JSON.stringify(comments))
        commentsCopy.sort(
          (a: ActivityComment, b: ActivityComment) => new Date(a.createdTs).getTime() - new Date(b.createdTs).getTime()
        )

        return this.groupCommentsByDate(comments)
      })
    )
  }

  private groupCommentsByDate(comments: Activities): GroupedCommments {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)

    const groupedComments: GroupedCommments = {
      today: [],
      yesterday: [],
      current: [],
    }

    for (const comment of comments) {
      const commentDate = new Date(comment.createdTs)
      commentDate.setHours(0, 0, 0, 0)

      if (commentDate.getTime() === today.getTime()) {
        groupedComments.today.push(comment)
      } else if (commentDate.getTime() === yesterday.getTime()) {
        groupedComments.yesterday.push(comment)
      } else {
        groupedComments.current.push(comment)
      }
    }

    return groupedComments
  }

  getCommentSourceAttributeTag(comment: any): any {
    switch (comment.sourceAttribute) {
      case 'name':
        return 'Name'
      case 'scopeMarkTotalHours':
        return this.lang.get('scope') + ' Mark Total Hours'
      case 'scopeMarkTotalSellingPrice':
        return this.lang.get('scope') + ' Mark Total Price'
      case 'rateCardTotalHours':
        return this.lang.get('agency') + ' Total Hours'
      case 'rateCardTotalPrice':
        return 'Agency Total Price'
      case 'quantity':
        return 'Quantity'
      case 'complexity':
        return this.lang.get('complexity')
      case 'scopeMarkHours':
        return this.lang.get('scope') + ' Mark Hours'
      case 'scopeMarkSellingPrice':
        return this.lang.get('scope') + ' Mark Price'
      case 'rateCardHours':
        return this.lang.get('agency') + ' Hours'
      case 'agencySellingPrice':
        return this.lang.get('agency') + ' Price'
      case 'scopeMarkRate':
        return this.lang.get('scope') + ' Mark Rate'
      case 'rateCardRate':
        return this.lang.get('agency') + ' Rate'
      case 'cost':
        return 'Cost'
      case 'markup':
        return 'Markup'
      case 'sellingPrice':
        return 'Price'
      case 'totalComponentUnitRateCardSellingPrice':
        return this.lang.get('component') + ' ' + this.lang.get('agency') + ' Price'
      case 'totalComponentUnitRateCardHours':
        return this.lang.get('component') + ' ' + this.lang.get('agency') + ' Hours'
      case 'tpcValue':
        return this.lang.get('third_party_cost') + ' Value'
      case 'discountName':
        return 'Discount Name'
      case 'discountValue':
        return 'Discount Value'
      case 'scopeTotalSMHours':
        return this.lang.get('scope') + ' Total ' + this.lang.get('scope') + ' Mark Hours'
      case 'scopeTotalSMPrice':
        return this.lang.get('scope') + ' Total ' + this.lang.get('scope') + ' Mark Price'
      case 'scopeTotalRateCardHours':
        return this.lang.get('scope') + ' Total ' + this.lang.get('agency') + ' Hours'
      case 'scopeTotalRateCardPrice':
        return this.lang.get('scope') + ' Total ' + this.lang.get('agency') + ' Price'
      case 'scopeTotalLabel':
        return this.lang.get('scope') + ' Total'
    }
  }

  getCommentTag(comment: ActivityComment): any {
    switch (comment.action) {
      case ActivityStatus.Comment:
        return 'Comment'
      case ActivityStatus.SubmittedWithdrew:
        return 'Submission Withdrew'
      case ActivityStatus.Submitted:
        return 'Submitted'
      case ActivityStatus.ReviewRejected:
        return 'Review rejected'
      case ActivityStatus.ApproverRejected:
        return 'Approver declined'
      case ActivityStatus.ApproverAccepted:
        return 'Approver agreed'
      case ActivityStatus.ClientRejected:
        return 'Client Approval declined'
      case ActivityStatus.ClientAccepted:
        return 'Client Approval agreed'
      case ActivityStatus.DeliverableAccepted:
        return 'Accepted "' + comment.source.name + `" ${this.lang.get('deliverable')} Review`
      case ActivityStatus.DeliverableRejected:
        return 'Rejected "' + comment.source.name + `" ${this.lang.get('deliverable')} Review`
      case ActivityStatus.ReviewerSubmitted:
        return 'Submitted ' + this.lang.get('scope') + ' Review'
      case ActivityStatus.Closed:
        return 'Closed'
      case ActivityStatus.Trafficked:
        return 'Trafficked'
      case ActivityStatus.Published:
        return 'Published'
      case ActivityStatus.SecondPartyAccepted:
        return 'Review accepted'
      case ActivityStatus.SecondPartyRejected:
        return 'Review rejected'
      case ActivityStatus.DeliverableResumed:
        return `Reopened ${this.lang.get('deliverable')} '${comment.source.name}'`
      case ActivityStatus.DeliverableStopped:
        return `Stopped ${this.lang.get('deliverable')} '${comment.source.name}'`
      case ActivityStatus.DeliverableComplete:
        return `${this.lang.get('deliverable')} '${comment.source.name}' complete`
      case ActivityStatus.ComponentUpdate:
        return this.lang.get('component') + ' Updated'
      case ActivityStatus.DeliverableTraded:
        return `${this.lang.get('deliverable')} traded`
      case ActivityStatus.DeliverableTradingCancelled:
        return `${this.lang.get('deliverable')} trade cancelled`
      case ActivityStatus.DocXCreationApproval:
        return 'Automatic webhook docx generation'
      case ActivityStatus.ScopeMsaUpdated:
        return 'MSA updated'
      case ActivityStatus.ScopeMsaRemoved:
        return 'MSA Removed'
      case ActivityStatus.DeliverableTradingInProgress:
        var isUpdated = comment.attributes['deleted'] != null || comment.attributes['added'] != null;
        var string = `${this.lang.get('deliverable')} trade `;
        if (isUpdated) {
          string += 'updated';
        } else {
          string += "in progress";
        }
        return string;
    }
  }

  getSourceName(comment: ActivityComment) {
    if (comment.action === 'COMMENT') {
      return this.getCommentTagFromComment(comment.comment)
    }
    return comment.comment?.source?.name || comment.source?.name
  }

  getCommentTagFromComment(comment: Comment) {
    var attrTag = ''
    var commentSourceAttributeTag = this.getCommentSourceAttributeTag(comment)
    if (commentSourceAttributeTag) {
      attrTag = " '" + commentSourceAttributeTag + "'"
    }
    return `${comment.source.name}${attrTag}`
  }

  getCommentTypeTag(comment: ActivityComment): any {
    switch (comment.comment?.sourceType || comment.sourceType) {
      case 'SOW': {
        return this.lang.get('scope_of_work')
      }
      case 'SCOPE': {
        return this.lang.get('scope')
      }
      case 'DELIVERABLE': {
        return this.lang.get('deliverable')
      }
      case 'COMPONENT': {
        return this.lang.get('component')
      }
      case 'SECTION': {
        return 'Section'
      }
      case 'DEPARTMENT': {
        return 'Department'
      }
      case 'ROLE': {
        return 'Role'
      }
      case 'DELIVERABLE_TPC': {
        return this.lang.get('third_party_cost')
      }
      case 'SCOPE_TPC': {
        return this.lang.get('third_party_cost')
      }
      case 'COMPONENT_TPC': {
        return this.lang.get('third_party_cost')
      }
    }
  }

  formatFromTo(attr: any): { from: any, to: any, hasChanged: boolean } {
    if (attr && (attr.hasChanged || attr.hasChanged === undefined)) {
      return { from: attr['from'], to: attr['to'], hasChanged: true };
    }
    return { from: null, to: null, hasChanged: false };
  }

  getActionAttributes(action: string, attributes: any): any {
    switch (action) {
      case "COMPONENT_UPDATE":
        return this.getComponentUpdate(attributes);
      case "SCOPE_MSA_UPDATED":
        return this.getScopeMsaUpdated(attributes);
      case "DOCX_CREATION_ON_APPROVAL":
        return this.getDocxCreationOnApproval(attributes);
      case "DELIVERABLE_TRADING_CANCELLED":
      case "DELIVERABLE_TRADING_IN_PROGRESS":
      case "DELIVERABLE_TRADED":
        return this.getDeliverableTrading(attributes);
      default:
        return {};
    }
  }

  private getComponentUpdate(attributes: any): any {
    return {
      description: this.formatFromTo(attributes.description),
      quantity: this.formatFromTo(attributes.quantity),
    };
  }

  private getScopeMsaUpdated(attributes: any): any {
    return {
      amount: this.formatFromTo(attributes.value),
      name: this.formatFromTo(attributes.name),
      type: this.formatFromTo(attributes.type),
      chargeType: this.formatFromTo(attributes.chargetype)
    };
  }

  private getDocxCreationOnApproval(attributes: any): any {
    return {
      docxSecureLinkId: attributes['docx_securelink_id']
    };
  }

  private getDeliverableTrading(attributes: any): any {
    return {
      traded_to: attributes['traded_to'],
      traded_from: attributes['traded_from'],
      deleted: attributes['deleted'],
      added: attributes['added'] ? (Array.isArray(attributes['added']) ? attributes['added'] : [attributes['added']]) : null
    };
  }
}
