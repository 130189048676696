import { Action, createReducer, on } from '@ngrx/store';
import { CompanyManagementState } from '../../models/company-management.state.model';

export const initialState: CompanyManagementState = {};

export const CompanyManagementReducer = createReducer(initialState);

export function reducer(state: CompanyManagementState | undefined, action: Action): CompanyManagementState {
  return CompanyManagementReducer(state, action);
}
