<section class="page-header">
  <div class="scope-row">
    <div class="four--columns">
      <h2 class="page-header__title">{{ heading }}</h2>
    </div>
    <div class="eight--columns"></div>
  </div>
</section>

<section class="container container--grey">
  <div class="scope-pod">
    <h4>{{ subHeading }}</h4>
    <p>{{ message }}</p>
    <a [routerLink]="link" class="btn btn--inline btn--large">{{ linkName }}</a>
  </div>
</section>
