<div class="tabs-container flex flex-col">
  <div class='tabs-list-container'>
    <ul class="nav nav-tabs">
      <li
        *ngFor="let tab of tabs"
        [has-setting]="tab.requiredSetting"
        class="tab"
        [ngClass]="{ active: tab.active === true }"
        [ngStyle]="{ 'font-size': fontSize }"
        (click)="selectTab(tab)">
        <div class='flex items-center'>
          <mat-icon *ngIf='tab.icon' class='material-symbols-rounded'>{{ tab.icon }}</mat-icon>
          {{ tab.tabTitle }}
        </div>
      </li>
    </ul>
  </div>
  <ng-content></ng-content>
</div>
