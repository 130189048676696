import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import {
  ChosenDate, StartDate,
} from 'ngx-daterangepicker-material/daterangepicker.component';
import { DaterangepickerDirective, NgxDaterangepickerMd } from 'ngx-daterangepicker-material'
import { NgIf } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import { FormControl } from "@angular/forms";
import { DaterangepickerComponent } from "ngx-daterangepicker-material";
import dayjs, { Dayjs } from 'dayjs'
import { AuthService } from '@core/service/auth.service'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

@Component({
  selector: 'scope-ui-datepicker',
  standalone: true,
  templateUrl: './scope-ui-datepicker.component.html',
  imports: [
    NgxDaterangepickerMd,
    NgIf,
    SharedModule
  ],
  styleUrls: ['./scope-ui-datepicker.component.scss']
})
export class ScopeUiDatepickerComponent implements AfterViewInit {
  @Input() singleDatePicker?: boolean;
  @Input() inlineDatePicker?: boolean;
  @Input() inputLabel!: string;
  @Input() disabled: boolean = false;
  @Input() set initialValue(value: Date | DateRange) {
    if (value instanceof Date) {
      this._initialValue = dayjs.utc(value)
    } else {
      this._initialValue = {
        startDate: dayjs.utc(value.startDate),
        endDate: dayjs.utc(value.endDate)
      }
    }
    this.setInitialValue()
  }
  @Input() set minDate(value: Date) {
    if (value) {
      this._minDate = dayjs(value.toISOString().slice(0, 10)).startOf('day');
      this._minDateValue = this._minDate.toISOString();
      if (this._initialValue && this._initialValue < this._minDate) {
        this._initialValue = this._minDate
        this.dateControl.setValue({
          startDate: this._initialValue,
          endDate: this._initialValue
        })
      }
    }
  }
  @Input() set maxDate(value: Date) {
    if (value) {
      this._maxDate = dayjs(value.toISOString().slice(0, 10)).endOf('day');
      this._maxDateValue = this._maxDate.toISOString();
      if (this._initialValue && this._initialValue > this._maxDate) {
        this._initialValue = this._maxDate
        this.dateControl.setValue({
          startDate: this._initialValue,
          endDate: this._initialValue
        })
      }
    }
  }
  @Input() minDateClass?: string;
  @Input() maxDateClass?: string;
  @Input() opens?: string = 'auto';
  @Output() onSelect = new EventEmitter<any>();
  dateControl = new FormControl<string | any>('')
  @ViewChild(DaterangepickerComponent) datePicker!: DaterangepickerComponent
  @ViewChild(DaterangepickerDirective) datePickerDirective!: DaterangepickerDirective
  _minDateValue: any
  _minDate: Dayjs
  _maxDateValue: any
  _maxDate: Dayjs
  _initialValue: Dayjs | DateRange
  companyDateFormat?: string;

  constructor(authService: AuthService) {
    this.companyDateFormat = authService.loggedInUser?.company?.companyDateFormat;
  }

  _onSelectedChange(date: ChosenDate): void {
    this.onSelect.emit({
      startDate: new Date(date.startDate.format('YYYY-MM-DD')),
      endDate: new Date(date.endDate.format('YYYY-MM-DD'))
    })
  }

  ngAfterViewInit() {
    this.setInitialValue()
    this.datePickerDirective?.setDisabledState(this.disabled)
  }

  setInitialValue() {
    if (this._initialValue) {
      if (this.singleDatePicker && !this.inlineDatePicker) {
        if (this._initialValue < this._minDate) {
          this._initialValue = this._minDate
        }
        if (this._initialValue > this._maxDate) {
          this._initialValue = this._maxDate
        }
        this.dateControl.setValue({
          startDate: this._initialValue,
          endDate: this._initialValue
        })
      } else if (this.singleDatePicker && this.inlineDatePicker) {
        this.datePicker.setStartDate(dayjs.utc(this._initialValue as Dayjs))
        this.datePicker.setEndDate(dayjs.utc(this._initialValue as Dayjs))
        this.datePicker.updateView()
      } else if (this.datePicker) {
        this.datePicker.setStartDate(dayjs.utc((this._initialValue as DateRange).startDate))
        this.datePicker.setEndDate(dayjs.utc((this._initialValue as DateRange).endDate))
        this.datePicker.updateView()
      }
    } else {
      this.datePickerDirective?.clear()
    }
  }

  protected readonly locale = {
    format: this.companyDateFormat || 'DD/MM/YYYY',
    displayFormat: this.companyDateFormat || 'DD/MM/YYYY',
    daysOfWeek: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  }

  isCustomDate = (date: Dayjs): any => {
    if (date.format('YYYY-MM-DD') === dayjs(this._minDate)?.format('YYYY-MM-DD')) {
      return this.minDateClass
    } else if (date.format('YYYY-MM-DD') === dayjs(this._maxDate)?.format('YYYY-MM-DD')) {
      return this.maxDateClass
    } else if (date.get('month') < this._minDate?.get('month') && date.get('year') === this._minDate?.get('year')) {
      return 'invisible'
    } else if (date.get('month') > this._maxDate?.get('month') && date.get('year') === this._maxDate?.get('year')) {
      return 'invisible'
    }
    return false
  }

  onChange($event: ChosenDate | StartDate) {
    if ($event.startDate.format('YYYY-MM-DD') !== (this._initialValue as Dayjs).format('YYYY-MM-DD')) {
      this.onSelect.emit(new Date($event.startDate.format('YYYY-MM-DD')))
    }
  }
}

export class DateRange {
  startDate: Dayjs
  endDate: Dayjs
}
