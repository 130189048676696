<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{ data.title }}</h1>
      </div>

      <div class="mt-2" [ngClass]="{ 'w-450': data.limitBodyWidth }">
        <span>{{ data.body }}</span>
      </div>

      <button class="close-button" mat-dialog-close>
        <mat-icon class="material-symbols-rounded">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content aria-label="Generic modal" class="modal-container md-dialog-container" *ngIf="data.inputs?.length">
    <div class="modal__body pb-7">
      <form [formGroup]="modalForm">
        <div class="flex flex-col" *ngFor="let input of data.inputs">
          <!--Add new input types here as required-->
          <mat-form-field *ngIf="input.type === 'textarea'" class="form-group w-full pb-8" subscriptSizing="dynamic">
            <mat-label *ngIf='input.label'>{{ input.label }}</mat-label>
            <textarea
              class="resize-none"
              matInput
              maxlength='{{ input.maxLength }}'
              formControlName="{{ input.name }}"
              placeholder="{{ input.placeholder }}"></textarea>
          </mat-form-field>
          <mat-form-field *ngIf="input.type === 'text'" class="form-group pb-8 w-full">
            <mat-label>{{ input.label }}</mat-label>
            <input
              class="resize-none"
              matInput
              formControlName="{{ input.name }}"
              placeholder="{{ input.placeholder }}" />
          </mat-form-field>
          <mat-form-field *ngIf="input.type === 'number'" class="form-group pb-8 w-full">
            <mat-label>{{ input.label }}</mat-label>
            <input
              class="resize-none"
              type="number"
              max='999'
              min='0'
              matInput
              formControlName="{{ input.name }}"
              placeholder="{{ input.placeholder }}" />
          </mat-form-field>
          <mat-form-field *ngIf="input.type === 'hours'" class="form-group pb-8 w-full">
            <mat-label>{{ input.label }}</mat-label>
            <input
              type="text"
              mask="000000"
              matInput
              formControlName="{{ input.name }}"
              placeholder="{{ input.placeholder }}" />
          </mat-form-field>
          <mat-form-field *ngIf="input.type === 'hoursMinutes'" class="form-group pb-8 w-full">
            <mat-label>{{ input.label }}</mat-label>
            <input
              type="text"
              mask="h99999:m0"
              [dropSpecialCharacters]="false"
              matInput
              formControlName="{{ input.name }}"
              placeholder="{{ input.placeholder }}" />
          </mat-form-field>
          <mat-form-field
            *ngIf="input.type === 'select' && input.options$ !== undefined"
            class="form-group w-full pb-8"
            subscriptSizing="dynamic">
            <mat-select formControlName="{{ input.name }}" placeholder="{{ input.placeholder }}">
              <mat-option *ngFor="let option of input.options$ | async" [value]="option">
                {{ option[input.displayField!] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="input.type === 'select' && input.options !== undefined"
            class="form-group w-full pb-8"
            subscriptSizing="dynamic">
            <mat-select formControlName="{{ input.name }}" placeholder="{{ input.placeholder }}">
              <mat-option *ngFor="let option of input.options" [value]="option">
                {{ option[input.displayField!] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <scope-ui-autocomplete
            *ngIf="input.type === 'autocomplete' && input.options$ !== undefined"
            [label]="input.label!"
            [options]="input.options$ | async"
            [hasIcon]="true"
            [iconName]="'search'"
            [multiselect]="input.multiselect"
            [optionFn]='input.optionFn'
            [displayFn]="input.optionFn"
            (onSelectionChange)="onAutocompleteSelect($event, input)" />
          <scope-ui-autocomplete
            *ngIf="input.type === 'autocomplete' && input.options !== undefined"
            [label]="input.label!"
            [options]="input.options"
            [hasIcon]="true"
            [iconName]="'search'"
            [multiselect]="input.multiselect"
            [optionFn]='input.optionFn'
            [displayFn]="input.optionFn"
            (onSelectionChange)="onAutocompleteSelect($event, input)" />
          <description-editor
            *ngIf="input.type === 'description'"
            [model]='{}'
            [(visible)]="editDescription"
            [hideNote]='true'
            [status]="'DRAFT'"
            (onSaveDescription)="saveDescription($event)" >
          </description-editor>
          <scope-ui-duration
            class="pt-6 block"
            *ngIf="input.type === 'duration'"
            (startDateChange)='setStartDate($event)'
            (endDateChange)='setEndDate($event)' >
          </scope-ui-duration>
          <ng-container *ngIf="data.isMemberModal && selections.length">
            <ul class="pt-4 pb-8">
              <li *ngFor="let selection of selections" class="flex member-row items-center">
                <div class="flex items-center">
                  <ng-container *ngIf="{ showAlt: false } as memberScope">
                    <div *ngIf='memberScope.showAlt' class="member-img d-flex-inline mr-2">
                      <p>{{ selection.firstName.charAt(0) }}</p>
                    </div>
                    <img *ngIf='!memberScope.showAlt' class="member-img d-flex-inline mr-2" src="{{ selection.getPhotoUri(cdnConfig!.userProfileUrl) }}" alt="" (error)="memberScope.showAlt = true">
                  </ng-container>
                  <div class="flex-col d-flex-inline">
                    <h6 class="product-heading-bold">{{ selection.fullName }}</h6>
                    <span class="text-m">{{ selection.email }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </ng-container>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="flex gap-2" style="justify-content: flex-end" [ngClass]="{ 'no-body': !data.inputs?.length }">
    <button
      mat-button
      *ngIf="data.hasCancelButton"
      [ngClass]="data.flatButton ? 'scope-ui-modal-flat-button' : 'scope-ui-modal-button'"
      (click)="cancel()">
      {{ data.cancelText || 'Cancel' }}
    </button>
    <button
      mat-button
      class="confirm"
      [ngClass]="data.flatButton ? 'scope-ui-modal-flat-button' : 'scope-ui-modal-button'"
      [disabled]="data.isFormValid && !data.isFormValid(modalForm, selections)"
      (click)="confirm()">{{ data.confirmText || 'Confirm' }}
    </button>
  </mat-dialog-actions>
</div>

